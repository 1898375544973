import PropTypes from 'prop-types'
import React, { memo } from 'react'
import * as types from 'common/types'
import BaseEntity from 'client/components/core/BaseEntity'
import CommonCustomerType from 'common/components/entities/CustomerType/CustomerType'

function CustomerType({ entity, isMoveDownAllowed, isMoveUpAllowed }) {
  return (
    <BaseEntity
      entity={entity}
      isMoveUpAllowed={isMoveUpAllowed}
      isMoveDownAllowed={isMoveDownAllowed}
    >
      <CommonCustomerType
        styles={entity.styles}
        mobileStyles={entity.mobileStyles}
      />
    </BaseEntity>
  )
}

CustomerType.propTypes = {
  entity: types.entity.isRequired,
  isMoveUpAllowed: PropTypes.bool.isRequired,
  isMoveDownAllowed: PropTypes.bool.isRequired,
}

export default memo(CustomerType)
