import PropTypes from 'prop-types'
import React, { memo } from 'react'
import CommonTimer from 'common/components/entities/Timer/TimerOld'
import { countdownTypes } from 'common/constants/countdownTypes'
import * as types from 'common/types'
import BaseEntity from 'client/components/core/BaseEntity'
import usePresentPage, { selectors } from '../../../hooks/usePresentPage'

function Countdown({ entity, isMoveDownAllowed, isMoveUpAllowed }) {
  const pageLocale = usePresentPage(selectors.getPageLocale)

  return (
    <BaseEntity
      entity={entity}
      isMoveUpAllowed={isMoveUpAllowed}
      isMoveDownAllowed={isMoveDownAllowed}
    >
      {emulatedEntity => (
        <CommonTimer
          locale={pageLocale}
          wrapperStyles={emulatedEntity.styles}
          labelStyles={emulatedEntity.options.labelStyles}
          timeStyles={emulatedEntity.options.timeStyles}
          hours={entity.options.hours}
          minutes={entity.options.minutes}
          seconds={entity.options.seconds}
          hideDays={entity.options.type === countdownTypes.daily}
        />
      )}
    </BaseEntity>
  )
}

Countdown.propTypes = {
  entity: types.entity.isRequired,
  isMoveUpAllowed: PropTypes.bool.isRequired,
  isMoveDownAllowed: PropTypes.bool.isRequired,
}

export default memo(Countdown)
