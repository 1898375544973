import { PayPalScriptProvider } from '@paypal/react-paypal-js'
import React from 'react'
import Button from 'common/components/entities/Button/button'
import { CommonPaypalRestButton } from 'common/components/entities/paypal-rest-button'
import EntityTypeEnum from 'common/enums/entityTypeEnum'
import { MasterBlockId } from 'common/types/entities/EntityInterface'
import { PaymentButtonInterface } from 'common/types/entities/PaymentButtonInterface'
import BaseEntityNew from 'client/components/core/BaseEntity/BaseEntityNew'
import { isPaypalSettingsOpened } from 'client/reducers/managementReducer'
import { useManagement } from 'client/store'
import { generateBaseEntity } from 'client/utils/createStructureUtils'

export interface PaymentButtonProps {
  entity: PaymentButtonInterface
  isMoveDownAllowed: boolean
  isMoveUpAllowed: boolean
  isRemoveAllowed: boolean
  isSaveAllowed: boolean
  isCopyAllowed: boolean
  dropBoxAllowedEntityIds?: string[]
}

const PaymentButtonNew = ({
  entity,
  isMoveDownAllowed,
  isMoveUpAllowed,
  isRemoveAllowed,
  isSaveAllowed,
  isCopyAllowed,
  dropBoxAllowedEntityIds,
}: PaymentButtonProps) => {
  const showPaypalSettings = useManagement(isPaypalSettingsOpened)

  return (
    <BaseEntityNew
      entity={entity}
      flex
      flexColumn
      isMoveUpAllowed={isMoveUpAllowed}
      isMoveDownAllowed={isMoveDownAllowed}
      isRemoveAllowed={isRemoveAllowed}
      isSaveAllowed={isSaveAllowed}
      isCopyAllowed={isCopyAllowed}
      dropBoxAllowedEntityIds={dropBoxAllowedEntityIds}
    >
      {showPaypalSettings ? (
        <div style={{ marginTop: 10 }}>
          <PayPalScriptProvider options={{ clientId: 'test' }}>
            <CommonPaypalRestButton
              backgroundColor={entity.paypal?.backgroundColor}
              borderRadius={entity.border}
              disabled
            />
          </PayPalScriptProvider>
        </div>
      ) : (
        <Button
          attrId={entity.htmlAttrId}
          text={entity.text}
          subText={entity.subText}
          width={entity.width}
          mobileWidth={entity.mobileWidth}
          padding={entity.padding}
          mobilePadding={entity.mobilePadding}
          border={entity.border}
          mobileBorder={entity.mobileBorder}
          alignSelf={entity.alignSelf}
          mobileAlignSelf={entity.mobileAlignSelf}
          textColor={entity.textColor}
          mobileTextColor={entity.mobileTextColor}
          textFontSize={entity.textFontSize}
          mobileTextFontSize={entity.mobileTextFontSize}
          lineHeight={entity.lineHeight}
          mobileLineHeight={entity.mobileLineHeight}
          textFontFamily={entity.textFontFamily}
          textFontStyle={entity.textFontStyle}
          textFontWeight={entity.textFontWeight}
          subTextFontSize={entity.subTextFontSize}
          mobileSubTextFontSize={entity.mobileSubTextFontSize}
          subTextColor={entity.subTextColor}
          mobileSubTextColor={entity.mobileSubTextColor}
          subTextFontFamily={entity.subTextFontFamily}
          subTextFontStyle={entity.subTextFontStyle}
          subTextFontWeight={entity.subTextFontWeight}
          boxShadow={entity.boxShadow}
          mobileBoxShadow={entity.mobileBoxShadow}
          hover={entity.hover}
          mobileHover={entity.mobileHover}
          background={entity.background ?? entity.backgroundColor}
          mobileBackground={
            entity.mobileBackground ?? entity.mobileBackgroundColor
          }
          iconClassNameBefore={entity.iconClassNameBefore}
          iconClassNameAfter={entity.iconClassNameAfter}
        />
      )}
    </BaseEntityNew>
  )
}
export default PaymentButtonNew

export const createPaymentButton = (
  parentId: string,
  masterBlockId?: MasterBlockId,
  loadingText?: string,
  text?: string,
  subText?: string,
): PaymentButtonInterface => {
  return {
    ...generateBaseEntity(
      EntityTypeEnum.PaymentButton,
      parentId,
      masterBlockId,
    ),
    type: EntityTypeEnum.PaymentButton,
    parentId: parentId,
    alignSelf: 'center',
    textFontSize: 20,
    border: {
      width: 1,
      style: 'none',
    },
    background: 'rgba(1, 116, 199, 1)',
    textColor: 'rgba(255, 255, 255, 1)',
    padding: {
      paddingTop: 9,
      paddingRight: 25,
      paddingBottom: 9,
      paddingLeft: 25,
    },
    mobilePadding: {
      paddingTop: 5,
      paddingRight: 5,
      paddingBottom: 5,
      paddingLeft: 5,
    },
    margin: {
      marginTop: 10,
      marginRight: 0,
      marginBottom: 10,
      marginLeft: 0,
    },
    mobileMargin: {
      marginTop: 5,
      marginBottom: 5,
      marginLeft: 0,
      marginRight: 0,
    },
    mobileTextFontSize: 20,
    mobileWidth: '100%',
    width: '100%',
    subTextFontSize: 14,
    loadingText,
    text: text ?? '',
    subText,
  }
}
