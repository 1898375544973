import { ColumnTypeEnum } from 'common/components/entities/Column/enums/ColumnTypeEnum'
import { ColumnInterface } from 'common/components/entities/Column/types/ColumnInterface'
import EntityTypeEnum from 'common/enums/entityTypeEnum'
import { MasterBlockId } from 'common/types/entities/EntityInterface'
import {
  generateHtmlAttrId,
  generateId,
} from 'client/utils/createStructureUtils'

export function createColumn({
  parentId,
  columnType = ColumnTypeEnum.COLUMN_12,
  masterBlockId,
  options,
}: {
  parentId: string
  columnType?: ColumnTypeEnum
  masterBlockId?: MasterBlockId
  disableStacking?: boolean
  options?: Partial<ColumnInterface>
}) {
  const column: ColumnInterface = {
    id: generateId(),
    parentId: parentId,
    htmlAttrId: generateHtmlAttrId(EntityTypeEnum.Column),
    type: EntityTypeEnum.Column,
    size: getColumnSize(columnType),
    alignSelf: 'flex-start',
    childIds: [],
    ...options,
  }
  if (masterBlockId) {
    column.masterBlockId = masterBlockId
  }
  return column
}
const getColumnSize = (type: ColumnTypeEnum) => {
  switch (type) {
    case ColumnTypeEnum.COLUMN_12:
      return 12
    case ColumnTypeEnum.COLUMN_8:
      return 8
    case ColumnTypeEnum.COLUMN_6:
      return 6
    case ColumnTypeEnum.COLUMN_4:
      return 4
    case ColumnTypeEnum.COLUMN_3:
      return 3
  }
}
