import { StripeError } from '@stripe/stripe-js'
import { useRollbar } from '@rollbar/react'
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import StripeErrorUi from 'common/components/entities/PaymentMethods/Stripe/ui/StripeErrorUi'
import ExpressCheckout from 'publisher/pages/offer-page/components/PaymentMethod/PaymentMethodDetails/methods/stripe/ExpressCheckout'
import creditCardBaseElementOptions from 'publisher/pages/offer-page/components/PaymentMethod/PaymentMethodDetails/methods/stripe/constants/creditCardBaseElementOptions'
import {
  stripeCardExpirationId,
  stripeCardNumberId,
  stripeCardSecurityCodeId,
} from 'publisher/pages/offer-page/components/PaymentMethod/PaymentMethodDetails/methods/stripe/constants/creditCardFieldIds'
import CreditCardFieldUi from 'publisher/pages/offer-page/components/PaymentMethod/PaymentMethodDetails/shared/ui/CreditCardFieldUi'
import CreditCardLabelUi from 'publisher/pages/offer-page/components/PaymentMethod/PaymentMethodDetails/shared/ui/CreditCardLabelUi'
import CreditCardRowUi from 'publisher/pages/offer-page/components/PaymentMethod/PaymentMethodDetails/shared/ui/CreditCardRowUi'

export interface StripeCreditCardProps {
  isApplePayEnabled?: boolean
  isCardPaymentEnabled?: boolean
}

const StripeCreditCard = ({
  isCardPaymentEnabled = true,
  isApplePayEnabled = false,
}: StripeCreditCardProps) => {
  const { t } = useTranslation('common')
  const rollbar = useRollbar()

  const [isCardNumberError, setIsCardNumberError] = useState(false)
  const [isCardExpirationError, setIsCardExpirationError] = useState(false)
  const [isCardCvcError, setIsCardCvcError] = useState(false)

  const handleLoadError = async ({ error }: { error: StripeError }) => {
    if (error) {
      rollbar.error('stripe load error', error)
    }
  }

  return (
    <React.Fragment>
      {isApplePayEnabled && <ExpressCheckout />}
      {isCardPaymentEnabled && (
        <>
          <CreditCardRowUi flexDirectionColumn>
            <CreditCardFieldUi>
              <CreditCardLabelUi htmlFor={stripeCardNumberId}>
                {t('components.payment_methods.card_number')}
              </CreditCardLabelUi>
              <CardNumberElement
                options={{
                  ...creditCardBaseElementOptions,
                  showIcon: true,
                }}
                className="Card"
                onChange={({ error }) => {
                  if (!error) {
                    if (isCardNumberError) {
                      setIsCardNumberError(false)
                    }
                  } else if (error.message) {
                    setIsCardNumberError(true)
                  }
                }}
                onLoadError={handleLoadError}
                id={stripeCardNumberId}
              />
            </CreditCardFieldUi>
            {isCardNumberError && (
              <StripeErrorUi>
                {t('components.payment_methods.stripe.invalid_card_number')}
              </StripeErrorUi>
            )}
          </CreditCardRowUi>
          <CreditCardRowUi>
            <CreditCardFieldUi width="50%">
              <CreditCardLabelUi htmlFor={stripeCardExpirationId}>
                {t('components.payment_methods.expiration')}
              </CreditCardLabelUi>
              <CardExpiryElement
                options={creditCardBaseElementOptions}
                id={stripeCardExpirationId}
                className="Card"
                onChange={({ error }) => {
                  if (!error) {
                    if (isCardExpirationError) {
                      setIsCardExpirationError(false)
                    }
                  } else if (error.message) {
                    setIsCardExpirationError(true)
                  }
                }}
              />
              {isCardExpirationError && (
                <StripeErrorUi>
                  {t(
                    'components.payment_methods.stripe.invalid_card_expiration_date',
                  )}
                </StripeErrorUi>
              )}
            </CreditCardFieldUi>
            <CreditCardFieldUi width="50%">
              <CreditCardLabelUi htmlFor={stripeCardSecurityCodeId}>
                {t('components.payment_methods.cvc')}
              </CreditCardLabelUi>
              <CardCvcElement
                options={creditCardBaseElementOptions}
                id={stripeCardSecurityCodeId}
                className="Card"
                onChange={({ error }) => {
                  if (!error) {
                    if (isCardCvcError) {
                      setIsCardCvcError(false)
                    }
                  } else if (error.message) {
                    // https://systeme-team.slack.com/archives/C6UURE03D/p1668177169203109
                    setIsCardCvcError(true)
                  }
                }}
              />
              {isCardCvcError && (
                <StripeErrorUi>
                  {t(
                    'components.payment_methods.stripe.invalid_card_security_code',
                  )}
                </StripeErrorUi>
              )}
            </CreditCardFieldUi>
          </CreditCardRowUi>
        </>
      )}
    </React.Fragment>
  )
}

export default StripeCreditCard
