import { RowInterface } from 'common/components/entities/Row/types/RowInterface'
import { readOnlyRowEntities } from 'client/components/entities/Row/constants/readOnlyRowEntities'
import { useContactUsDescendantIds } from 'client/pages/contact-us/entities/ContactUs'
import { usePage } from 'client/store'
import pageSelectors from 'client/store/page/pageSelectors'

export const useRowRestrictions = (entity: RowInterface) => {
  const parentEntity = usePage(page =>
    pageSelectors.getEntityById(page, entity.parentId),
  )
  const childContactUsElements = usePage(page =>
    pageSelectors.getEntityDescendantsContactUsElements(page, entity.id),
  )
  const isReadOnly = parentEntity
    ? readOnlyRowEntities.includes(parentEntity.type)
    : false
  const dropBoxAllowedContactUsEntityIds = useContactUsDescendantIds()
  const isDropBoxRestricted = isReadOnly && childContactUsElements.length > 0
  const dropBoxAllowedEntityIds = isDropBoxRestricted
    ? dropBoxAllowedContactUsEntityIds
    : undefined
  return { isReadOnly, dropBoxAllowedEntityIds }
}
