import styled from 'styled-components'

const AlphaUi = styled.div`
  position: relative;
  height: 10px;
  margin-top: 4px;
  overflow: hidden;
`

export default AlphaUi
