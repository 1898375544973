import styled from 'styled-components'

const FileIconContainerUi = styled.div`
  width: 115px;
  height: 115px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  &:hover .fas.fa-times {
    display: block;
    opacity: 1;
  }
`

export default FileIconContainerUi
