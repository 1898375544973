import styled from 'styled-components'

const VideoPlaceholder = styled.div`
  position: relative;
  padding-bottom: 56.25% !important;
  padding-top: 25px !important;
  overflow: hidden;
  ${({ styles = {} }) => styles}
`

export default VideoPlaceholder
