import PropTypes from 'prop-types'
import React, { memo } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import CommonOrderBump from 'common/components/entities/OrderBump'
import * as types from 'common/types'
import BaseEntity from 'client/components/core/BaseEntity'
import ChildrenEntities from 'client/components/core/ChildrenEntities'
import EntityExampleWrapper from 'client/components/core/EntityExampleWrapper'
import { bumpStub } from 'client/constants/editorSettings'
import usePayment from 'client/hooks/usePayment'
import { isBlockSaveDialogOpened } from 'client/reducers/managementReducer'
import { getIsFetching, getOfferBumps } from 'client/reducers/paymentReducer'
import { usePage } from 'client/store'
import pageSelectors from 'client/store/page/pageSelectors'

function OrderBumpContainer({ entity, isMoveDownAllowed, isMoveUpAllowed }) {
  const isFetching = usePayment(getIsFetching)
  const offerBumps = usePayment(getOfferBumps)
  const isTemplate = usePage(pageSelectors.isPageTemplate)
  const isCreateBlockDialogActive = useSelector(
    state => isBlockSaveDialogOpened(state.management, entity.id),
    shallowEqual,
  )

  if (isFetching && !isTemplate) {
    return null
  }
  const isExample = offerBumps.length === 0
  const calculatedBumps = isExample ? bumpStub : offerBumps

  const renderOrderBump = entity => (
    <CommonOrderBump
      bumps={calculatedBumps}
      styles={entity.styles}
      mobileStyles={entity.mobileStyles}
      options={entity.options}
    >
      <ChildrenEntities
        id={entity.id}
        childIds={entity.childIds}
        type={entity.type}
      />
    </CommonOrderBump>
  )

  return (
    <BaseEntity
      entity={entity}
      isMoveUpAllowed={isMoveUpAllowed}
      isMoveDownAllowed={isMoveDownAllowed}
    >
      {emulatedEntity =>
        isExample && !isCreateBlockDialogActive ? (
          <EntityExampleWrapper label="entities.order_bump.warnings.no_price_plan">
            {renderOrderBump(emulatedEntity)}
          </EntityExampleWrapper>
        ) : (
          renderOrderBump(emulatedEntity)
        )
      }
    </BaseEntity>
  )
}

OrderBumpContainer.propTypes = {
  entity: types.entity.isRequired,
  isMoveUpAllowed: PropTypes.bool.isRequired,
  isMoveDownAllowed: PropTypes.bool.isRequired,
}

export function getHeaderFontProperties(entity) {
  const fontProperties = [
    {
      fontFamily: entity.options.headerStyles.fontFamily,
      fontStyle: entity.options.headerStyles.fontStyle,
      fontWeight: entity.options.headerStyles.fontWeight,
    },
  ]

  if (entity.mobileOptions.headerStyles) {
    fontProperties.push({
      fontFamily: entity.mobileOptions.headerStyles.fontFamily,
      fontStyle: entity.mobileOptions.headerStyles.fontStyle,
      fontWeight: entity.mobileOptions.headerStyles.fontWeight,
    })
  }

  return fontProperties.filter(fontProperty => Boolean(fontProperty.fontFamily))
}

export function getHeaderCustomFontProperties(entity) {
  const fontProperties = [
    {
      fontFamily: entity.options.headerStyles.fontFamily,
      fontFileId: entity.options.fontFileId,
    },
  ]

  if (entity.mobileOptions.headerStyles) {
    fontProperties.push({
      fontFamily: entity.mobileOptions.headerStyles.fontFamily,
      fontFileId: entity.mobileOptions.fontFileId,
    })
  }

  return fontProperties.filter(fontProperty => Boolean(fontProperty.fontFamily))
}

export default memo(OrderBumpContainer)
