import { css } from 'styled-components'

const dropColor = 'rgba(75, 182, 255, 0.8)'

export const dropMixin =
  props => props.canDrop && css`
    transition: box-shadow 0.2s ease, background-image 0.2s ease;
    outline-offset: -1px;
    outline: 1px dotted #149efc;
    background-color: rgba(75, 182, 255, 0.1);
    ${props => props.dropTop && css`
      box-shadow: ${`inset 0px 3px 0px 0px ${dropColor}`};  /* todo try without function (props) =>*/
    `}
    ${props => props.dropCenter && css`
      background-image: ${`repeating-linear-gradient(
        180deg,
        transparent,
        transparent 50%,
        ${dropColor} calc(50% - 5px),
        ${dropColor} calc(50% + 5px),
        transparent calc(50% + 5px),
        transparent
       )`};
      display: inherit; // because it just a middleware in the entity tree
    `}
    ${props => props.dropBottom && css`
      box-shadow: ${`inset 0px -3px 0px 0px ${dropColor}`};
    `}
  `

export const inlineMixin = p => p.inline && css`
  display: flex;
  justify-content: center;
`

export const stretchMixin = p => p.stretch && css`
  height: 100%;
`
