import React from 'react'
import { PaymentMethodEnum } from 'common/enums/PaymentMethodEnum'
import { usePaymentMethodDetails } from 'publisher/components/entities/PaymentMethod/hooks/paymentMethodConsts'
import PaymentMethodDetailsUi from './ui/PaymentMethodDetailsUi'

interface PaymentMethodDetailsProps {
  paymentMethod: PaymentMethodEnum
}

const PaymentMethodDetails = ({ paymentMethod }: PaymentMethodDetailsProps) => {
  const details = usePaymentMethodDetails(paymentMethod)

  if (!details) return null

  return <PaymentMethodDetailsUi>{details}</PaymentMethodDetailsUi>
}

export default PaymentMethodDetails
