import React from 'react'
import PropTypes from 'prop-types'
import BaseEntity from 'client/components/core/BaseEntity'
import CommonAudio from 'common/components/entities/Audio'

function Audio({ entity, isMoveDownAllowed, isMoveUpAllowed }) {
  return (
    <BaseEntity
      entity={entity}
      isMoveUpAllowed={isMoveUpAllowed}
      isMoveDownAllowed={isMoveDownAllowed}
    >
      <CommonAudio
        styles={entity.styles}
        mobileStyles={entity.mobileStyles}
        htmlAttrId={entity.options.attrId}
      />
    </BaseEntity>
  )
}

Audio.propTypes = {
  entity: PropTypes.shape({
    id: PropTypes.string.isRequired,
    options: PropTypes.objectOf(PropTypes.any).isRequired,
    styles: PropTypes.objectOf(PropTypes.any).isRequired,
    mobileStyles: PropTypes.objectOf(PropTypes.any).isRequired,
    childIds: PropTypes.arrayOf(PropTypes.string),
    type: PropTypes.string.isRequired,
  }).isRequired,
  isMoveUpAllowed: PropTypes.bool.isRequired,
  isMoveDownAllowed: PropTypes.bool.isRequired,
}

export default Audio
