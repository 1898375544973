import PropTypes from 'prop-types'
import React, { memo } from 'react'
import Timer from 'common/components/entities/Timer/TimerOld'
import * as types from 'common/types'
import BaseEntity from 'client/components/core/BaseEntity'

function StartTimer({ entity, isMoveDownAllowed, isMoveUpAllowed }) {
  return (
    <BaseEntity
      entity={entity}
      isMoveUpAllowed={isMoveUpAllowed}
      isMoveDownAllowed={isMoveDownAllowed}
    >
      {emulatedEntity => (
        <Timer
          wrapperStyles={emulatedEntity.styles}
          labelStyles={emulatedEntity.options.labelStyles}
          timeStyles={emulatedEntity.options.timeStyles}
          mobileTimeStyles={emulatedEntity.mobileOptions.timeStyles}
          mobileLabelStyles={emulatedEntity.mobileOptions.labelStyles}
          hours={emulatedEntity.options.hours}
          minutes={emulatedEntity.options.minutes}
          seconds={emulatedEntity.options.seconds}
        />
      )}
    </BaseEntity>
  )
}

StartTimer.propTypes = {
  entity: types.entity.isRequired,
  isMoveUpAllowed: PropTypes.bool.isRequired,
  isMoveDownAllowed: PropTypes.bool.isRequired,
}

export default memo(StartTimer)
