import React from 'react'
import { useTranslation } from 'react-i18next'
import CurrencyBadgeUi from 'common/components/entities/OfferPriceNew/ui/CurrencyBadgeUi'
import DividerUi from 'common/components/entities/OfferPriceNew/ui/DividerUi'
import PricePlanAmountUi from 'common/components/entities/OfferPriceNew/ui/PricePlanAmountUi'
import PricePlanItemWithoutLeftOffsetUi from 'common/components/entities/OfferPriceNew/ui/PricePlanItemWithoutLeftOffsetUi'
import { OneShotPlan } from 'common/types/OfferInterface'
import { ProductInterface } from 'common/types/entities/ProductInterface'
import { OfferPricing } from 'common/types/pricingTypes'
import { centsToEuro, getReadablePrice } from 'common/utils/priceCalculator'
import PricePlanNameUi from '../ui/PricePlanNameUi'

interface ProductAndPricePlanBumpTotalProps {
  product?: ProductInterface
  bumpPlan: OneShotPlan
  productPricing?: OfferPricing
  bumpPlanPricing: OfferPricing
  amountFontFamily?: string
  locale: string
  mobileAmountFontFamily?: string
  amountFontSize: number
  mobileAmountFontSize?: number
  amountFontStyle?: string
  mobileAmountFontStyle?: string
  amountFontWeight?: string
  mobileAmountFontWeight?: string
  amountLineHeight: number
  mobileAmountLineHeight?: number
  amountColor: string
  mobileAmountColor?: string
  fontFamily?: string
  mobileFontFamily?: string
  fontSize: number
  mobileFontSize?: number
  fontStyle?: string
  mobileFontStyle?: string
  fontWeight?: string
  mobileFontWeight?: string
  lineHeight: number
  mobileLineHeight?: number
  color: string
  mobileColor?: string
}

function ProductAndPricePlanBumpTotal({
  product,
  productPricing,
  bumpPlanPricing,
  locale,
  amountFontSize,
  mobileAmountFontSize,
  amountFontStyle,
  mobileAmountFontStyle,
  amountFontWeight,
  mobileAmountFontWeight,
  amountFontFamily,
  mobileAmountFontFamily,
  amountLineHeight,
  mobileAmountLineHeight,
  amountColor,
  mobileAmountColor,
  fontSize,
  mobileFontSize,
  fontStyle,
  mobileFontStyle,
  fontWeight,
  mobileFontWeight,
  fontFamily,
  mobileFontFamily,
  lineHeight,
  mobileLineHeight,
  color,
  mobileColor,
}: ProductAndPricePlanBumpTotalProps) {
  const { t } = useTranslation(['common', 'publisher'])
  const totalAmountWithTax =
    (productPricing?.grossAmount ?? 0) + bumpPlanPricing.grossAmount

  return (
    <>
      <DividerUi />
      <PricePlanItemWithoutLeftOffsetUi>
        <PricePlanNameUi
          fontSize={fontSize}
          mobileFontSize={mobileFontSize}
          fontStyle={fontStyle}
          mobileFontStyle={mobileFontStyle}
          fontWeight={fontWeight}
          mobileFontWeight={mobileFontWeight}
          fontFamily={fontFamily}
          mobileFontFamily={mobileFontFamily}
          lineHeight={lineHeight}
          mobileLineHeight={mobileLineHeight}
          color={color}
          mobileColor={mobileColor}
          data-test-id={`offer-price-product-bump-total-tax-${product?.name}`}
        >
          {t('components.offer_price.one_shots_summary.total_amount_with_tax')}
        </PricePlanNameUi>
        <PricePlanAmountUi
          fontSize={amountFontSize}
          mobileFontSize={mobileAmountFontSize}
          fontStyle={amountFontStyle}
          mobileFontStyle={mobileAmountFontStyle}
          fontWeight={amountFontWeight}
          mobileFontWeight={mobileAmountFontWeight}
          fontFamily={amountFontFamily}
          mobileFontFamily={mobileAmountFontFamily}
          lineHeight={amountLineHeight}
          mobileLineHeight={mobileAmountLineHeight}
          color={amountColor}
          mobileColor={mobileAmountColor}
        >
          {product && (
            <>
              <CurrencyBadgeUi
                color={amountColor}
                mobileColor={mobileAmountColor}
              >
                {product.currency}
              </CurrencyBadgeUi>

              {getReadablePrice(
                centsToEuro(totalAmountWithTax),
                product?.currency,
                locale,
              )}
            </>
          )}
        </PricePlanAmountUi>
      </PricePlanItemWithoutLeftOffsetUi>
    </>
  )
}

export default ProductAndPricePlanBumpTotal
