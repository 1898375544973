import PropTypes from 'prop-types'
import { isVisible } from 'tools/utils/entityUtils'
import React, { useMemo, memo } from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import { useActions } from 'common/hooks/useActions'
import * as entityActions from 'client/actions/entityActions'
import { createEmptyEntityElement } from 'client/pages/createCommonEntityElement'
import { getChildrenEntities } from 'client/reducers/pageReducer'
import { getAllowedTypes } from 'client/utils/validators'
import { useCreateEntityElement } from '../../../context/CreateEntityContext'
import { useDeviceModeContext } from '../../../context/DeviceModeContext'
import DropBoxEmpty from './DropBoxEmpty'
import DropBoxEntity from './DropBoxEntity'

/**
 * Retrieves the child entities based on provided parameters and renders them in the appropriate way.
 *
 * @param {Object} param0 - The parameters for retrieving and rendering child entities.
 * @param {string} param0.id - The parent entity ID.
 * @param {string} param0.type - The type of entities to retrieve.
 * @param {string[]} [param0.childIds=[]] - Array of child entity IDs.
 * @param {string} [param0.placeholderText] - The placeholder text for empty drop zones.
 * @param {boolean} [param0.expandedDropZone] - Flag indicating if drop zone is expanded.
 * @param {boolean|undefined} [param0.isReadOnly] - Flag indicating read-only mode.
 *
 * @return {React.Element[]} - An array of React elements representing the child entities.
 */
function ChildrenEntities({
  id,
  type,
  childIds = [],
  placeholderText = null,
  expandedDropZone = false,
  isReadOnly,
}) {
  const { isDesktop } = useDeviceModeContext()
  const { createEntityElement, excludedEntitiesIds } = useCreateEntityElement()
  const entities = useSelector(
    state => getChildrenEntities(state.page.present, childIds),
    shallowEqual,
  )
  const [createEntity, moveEntity, createBlock] = useActions([
    entityActions.createIn,
    entityActions.moveNew,
    entityActions.createBlockIn,
  ])
  const allowedTypes = useMemo(() => getAllowedTypes(type), [type])
  const noVisibleEntities = useMemo(
    () => entities.every(entity => !isVisible(entity, isDesktop)),
    [isDesktop, entities],
  )

  if (isReadOnly) {
    return entities.map(entity =>
      excludedEntitiesIds.includes(entity.id)
        ? createEmptyEntityElement(entity)
        : createEntityElement(entity),
    )
  }

  if (noVisibleEntities) {
    return (
      <DropBoxEmpty
        placeholderText={placeholderText}
        parentId={id}
        allowedTypes={allowedTypes}
        createEntity={createEntity}
        createBlock={createBlock}
        moveEntity={moveEntity}
      />
    )
  }

  return entities.map((entity, index) => {
    if (excludedEntitiesIds.includes(entity.id)) {
      return <div />
    }
    return (
      <DropBoxEntity
        key={entity.id}
        position={index}
        parentId={id}
        allowedTypes={allowedTypes}
        createEntity={createEntity}
        createBlock={createBlock}
        moveEntity={moveEntity}
        expanded={expandedDropZone}
      >
        {createEntityElement(entity, {
          isMoveDownAllowed:
            entities.length > 1 && entities.length !== index + 1,
          isMoveUpAllowed: entities.length > 1 && index !== 0,
        })}
      </DropBoxEntity>
    )
  })
}

ChildrenEntities.propTypes = {
  childIds: PropTypes.arrayOf(PropTypes.string),
  placeholderText: PropTypes.string,
  expandedDropZone: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  id: PropTypes.string,
  type: PropTypes.string,
}

export default memo(ChildrenEntities)
