import styled from 'styled-components'

const ModalUi = styled.div`
  border-radius: 6px;
  position: relative;
  transition: transform 0.3s ease-out;
  display: block;
  max-width: 600px; /* need for iframe width detection */
  width: 100%;
  ${p => p.theme.phone} {
    max-width: 350px;
    width: 100%;
  }
`

export default ModalUi
