import styled, { css } from 'styled-components'

type MenuCloseButtonUiProps = {
  active?: boolean
  marginLeft?: string
}

const MenuCloseButtonUi = styled.button<MenuCloseButtonUiProps>`
  position: absolute;
  right: 0;
  top: 0;
  margin: 2px -10px 2px;
  padding: 5px 8px;
  background-color: transparent;
  font-family: var(--font-family);
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  border: none;
  cursor: pointer;
  outline: none;
  height: 25px;
  ${p =>
    p.marginLeft &&
    css`
      margin-left: ${p.marginLeft};
    `};
  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
  &:first-of-type {
    border-top-left-radius: 5px;
  }
  ${props =>
    props.active &&
    css`
      background-color: rgba(0, 0, 0, 0.1);
      &:after {
        height: 3px;
      }
    `}
`

export default MenuCloseButtonUi
