import React from 'react'
import CommonAudio from 'common/components/entities/Audio/AudioNew'
import EntityTypeEnum from 'common/enums/entityTypeEnum'
import { AudioInterface } from 'common/types/entities/AudioInterface'
import { MasterBlockId } from 'common/types/entities/EntityInterface'
import BaseEntityNew from 'client/components/core/BaseEntity/BaseEntityNew'
import { EntityProps } from 'client/types'
import { generateBaseEntity } from 'client/utils/createStructureUtils'

function AudioNew({
  entity,
  isMoveDownAllowed,
  isMoveUpAllowed,
}: EntityProps<AudioInterface>) {
  return (
    <BaseEntityNew
      entity={entity}
      isMoveDownAllowed={isMoveDownAllowed}
      isMoveUpAllowed={isMoveUpAllowed}
    >
      <CommonAudio htmlAttrId={entity.htmlAttrId} />
    </BaseEntityNew>
  )
}

export function createAudio(
  parentId: string,
  masterBlockId?: MasterBlockId,
): AudioInterface {
  return {
    ...generateBaseEntity(EntityTypeEnum.Audio, parentId, masterBlockId),
    type: EntityTypeEnum.Audio,
    url: '',
  }
}

export default AudioNew
