import React, { useEffect, useState } from 'react'
import CommonAgreement from 'common/components/entities/Agreement/new-agreement'
import { AgreementInterface } from 'common/types/entities/AgreementInterface'
import { getAgreement } from 'client/api/agreementApi'
import BaseEntityNew from 'client/components/core/BaseEntity/BaseEntityNew'
import { usePage } from 'client/store'
import pageSelectors from 'client/store/page/pageSelectors'
import { EntityProps } from 'client/types'

const NewAgreement = ({
  entity,
  isMoveDownAllowed,
  isMoveUpAllowed,
}: EntityProps<AgreementInterface>) => {
  const pageId = usePage(pageSelectors.getPageId)

  const [agreementText, setAgreementText] = useState<string>('')

  useEffect(() => {
    getAgreement(pageId).then(res => {
      // remove escape characters
      const sanitizedText = res.data.replace(/\\"/g, '"')
      setAgreementText(sanitizedText)
    })
  }, [pageId])

  if (!agreementText) {
    return null
  }

  return (
    <BaseEntityNew
      entity={entity}
      isMoveDownAllowed={isMoveDownAllowed}
      isMoveUpAllowed={isMoveUpAllowed}
    >
      <CommonAgreement text={agreementText} />
    </BaseEntityNew>
  )
}

export default NewAgreement
