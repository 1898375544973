import PropTypes from 'prop-types'
import React, { memo } from 'react'
import CommonContentBoxOld from 'common/components/entities/ContentBox/ContentBoxOld'
import * as types from 'common/types'
import BaseEntity from 'client/components/core/BaseEntity'
import ChildrenEntities from 'client/components/core/ChildrenEntities'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'

function ContentBoxOld({ entity, isMoveDownAllowed, isMoveUpAllowed }) {
  const { isMobile } = useDeviceModeContext()
  const emulatedOptions = isMobile
    ? {
        ...entity.options,
        ...entity.mobileOptions,
        headerStyles: {
          ...entity.options.headerStyles,
          ...entity.mobileOptions.headerStyles,
        },
      }
    : entity.options

  return (
    <BaseEntity
      entity={entity}
      isMoveUpAllowed={isMoveUpAllowed}
      isMoveDownAllowed={isMoveDownAllowed}
    >
      {emulatedEntity => (
        <CommonContentBoxOld
          styles={emulatedEntity.styles}
          mobileStyles={emulatedEntity.mobileStyles}
          options={emulatedOptions}
        >
          <ChildrenEntities
            id={emulatedEntity.id}
            childIds={emulatedEntity.childIds}
            type={emulatedEntity.type}
            isReadOnly={entity.isReadOnly}
          />
        </CommonContentBoxOld>
      )}
    </BaseEntity>
  )
}

ContentBoxOld.propTypes = {
  entity: types.entity.isRequired,
  isMoveUpAllowed: PropTypes.bool.isRequired,
  isMoveDownAllowed: PropTypes.bool.isRequired,
}

export default memo(ContentBoxOld)
