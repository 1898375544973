export const SWITCH_ENTITY_INNER_ITEM_ACTIVE_INDEX =
  'SWITCH_ENTITY_INNER_ITEM_ACTIVE_INDEX'
export const SET_EDITING_ENTITY = 'SET_EDITING_ENTITY'
export const SET_USER_COLORS = 'SET_USER_COLORS'
export const NEED_SCROLL = 'NEED_SCROLL'
export const TOGGLE_INSTANT_UPLOAD = 'TOGGLE_INSTANT_UPLOAD'
export const TOGGLE_PAYPAL_SETTINGS = 'TOGGLE_PAYPAL_SETTINGS'
export interface NeedScrollAction {
  type: typeof NEED_SCROLL
  payload: boolean
}

export interface ToggleInstantUpload {
  type: typeof TOGGLE_INSTANT_UPLOAD
  payload: boolean
}

export interface TogglePayPalSettings {
  type: typeof TOGGLE_PAYPAL_SETTINGS
  payload: boolean
}

export interface SwitchEntityInnerItemActiveIndex {
  type: typeof SWITCH_ENTITY_INNER_ITEM_ACTIVE_INDEX
  payload: {
    entityId: string
    entityInnerItemActiveIndex: number
  }
}

export interface SetEditingEntity {
  type: typeof SET_EDITING_ENTITY
  payload: string
}

export interface SetUserColors {
  type: typeof SET_USER_COLORS
  payload: string[]
}
