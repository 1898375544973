import styled from 'styled-components'

export const XenditIframeContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 420px;
  height: 60%;
  position: fixed;
  top: 20%;
  left: 40%;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 5px;
  text-align: center;
  z-index: 11;
`

export const XenditIframeUi = styled.iframe`
  border: 0;
  flex-grow: 1;
  width: 100%;
  height: 100%;
`
