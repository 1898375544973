import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import StyleWrapper from 'common/components/StyleWrapper'
import Field from 'common/components/entities/Field'
import FieldPasswordButtonUi from 'common/components/entities/Field/FieldPasswordButton'
import FieldWrapperUi from 'common/components/entities/Field/FieldWrapperUi'
import { OldEntityInterface } from 'common/types/entities/OldEntityInterface'
import { changeField } from 'publisher/actions/optInActions'
import { optInSelectors, useOptIn } from 'publisher/store'
import fieldValidation from 'publisher/utils/fieldValidation'
import FieldErrors from '../../FieldErrors'

interface PasswordFieldProps {
  entity: OldEntityInterface
  dataTestId?: string
}

function PasswordField({ entity, dataTestId }: PasswordFieldProps) {
  const [showPassword, setShowPassword] = useState(false)
  const slug = entity.options.slug
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [inlineErrors, setInlineErrors] = useState([])
  const value = useOptIn(optIn => optInSelectors.getFieldValue(optIn, slug))
  const errors = useOptIn(optIn => optInSelectors.getFieldErrors(optIn, slug))

  const handleChange = useCallback(
    (value: string) => {
      if (slug) {
        dispatch(changeField(slug, value))
      }
    },
    [dispatch, slug],
  )

  const handleBlur = (value: string) => {
    const errors = fieldValidation(slug, value, entity.options.optional)
    setInlineErrors(errors.map((error: string) => t(error)))
  }

  const calculatedErrors = inlineErrors.length > 0 ? inlineErrors : errors

  const { marginTop, marginLeft, marginBottom, marginRight, ...restStyles } =
    entity.styles
  const {
    marginTop: mobileMarginTop,
    marginRight: mobileMarginRight,
    marginBottom: mobileMarginBottom,
    marginLeft: mobileMarginLeft,
    ...restMobileStyles
  } = entity.mobileStyles

  return (
    <StyleWrapper
      desktop={entity.options.appearance.desktop}
      mobile={entity.options.appearance.mobile}
      styles={{
        marginTop,
        marginLeft,
        marginBottom,
        marginRight,
      }}
      mobileStyles={{
        marginTop: mobileMarginTop,
        marginLeft: mobileMarginRight,
        marginBottom: mobileMarginBottom,
        marginRight: mobileMarginLeft,
      }}
    >
      <FieldWrapperUi>
        <FieldPasswordButtonUi
          color={entity.styles.color}
          mobileColor={entity.mobileStyles.color}
          onClick={() => setShowPassword(!showPassword)}
        >
          <i className={showPassword ? 'far fa-eye-slash' : 'far fa-eye'} />
        </FieldPasswordButtonUi>
        <Field
          //@ts-ignore
          attrId={entity.options.attrId}
          type={showPassword ? 'text' : 'password'}
          name={entity.options.slug}
          hasIcon={true}
          dataTestId={dataTestId}
          placeholderColor={entity.options.placeholderColor}
          mobilePlaceholderColor={entity.mobileOptions.placeholderColor}
          placeholder={entity.options.placeholder}
          styles={restStyles}
          mobileStyles={restMobileStyles}
          borderType={entity.options.borderType}
          mobileBorderType={entity.mobileOptions.borderType}
          onChange={e => handleChange(e.target.value)}
          onBlur={e => handleBlur(e.target.value)}
          value={value}
          hasErrors={calculatedErrors.length > 0}
        />
      </FieldWrapperUi>
      <FieldErrors errors={calculatedErrors} />
    </StyleWrapper>
  )
}

export default PasswordField
