import styled from 'styled-components'

const MenuSelectUi = styled.select`
  background-color: rgba(255, 255, 255, 0.1);
  width: 170px;
  color: #fff;
  border-color: transparent;
  outline: none;
  height: 21px;
  margin-left: 2px;
`

export default MenuSelectUi
