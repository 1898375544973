import PropTypes from 'prop-types'
import React, { memo, useEffect } from 'react'
import CommonAgreement from 'common/components/entities/Agreement'
import { useActions } from 'common/hooks/useActions'
import * as types from 'common/types'
import { fetchAgreement } from 'client/actions/paymentActions'
import BaseEntity from 'client/components/core/BaseEntity'
import usePayment from 'client/hooks/usePayment'
import { getAgreement } from 'client/reducers/paymentReducer'

function Agreement({ entity, isMoveDownAllowed, isMoveUpAllowed }) {
  const agreement = usePayment(getAgreement)
  const fetchAgreementAction = useActions(fetchAgreement)

  useEffect(() => {
    fetchAgreementAction()
  })

  if (!agreement) {
    return null
  }

  return (
    <BaseEntity
      entity={entity}
      isMoveUpAllowed={isMoveUpAllowed}
      isMoveDownAllowed={isMoveDownAllowed}
    >
      {emulatedEntity => (
        <CommonAgreement
          options={emulatedEntity.options}
          styles={emulatedEntity.styles}
          mobileStyles={emulatedEntity.mobileStyles}
          text={agreement}
        />
      )}
    </BaseEntity>
  )
}

Agreement.propTypes = {
  entity: types.entity.isRequired,
  isMoveUpAllowed: PropTypes.bool.isRequired,
  isMoveDownAllowed: PropTypes.bool.isRequired,
}

export default memo(Agreement)
