import { BaseEntityWithChildIdsInterface } from 'common/types/entities/EntityInterface'
import { OldEntityWithChildIds } from 'common/types/entities/OldEntityInterface'
import { ButtonInterface } from 'common/types/entities/button-interface'
import { createButtonNew } from 'client/components/core/Sidebar/components/Settings/entities/button-settings/utils/create-button'
import i18n from 'client/i18n'
import {
  createContainerStructure,
  insertChild,
} from 'client/utils/createStructureUtils'

export const createButtonFromLibrary = (
  container: BaseEntityWithChildIdsInterface | OldEntityWithChildIds,
  locale: string,
  position = 0,
  options?: Partial<ButtonInterface>,
) => {
  const fixedT = i18n.getFixedT(locale)

  const { structure, descendantEntity } = createContainerStructure(
    container,
    locale,
    position,
  )

  const button = createButtonNew(
    container.id,
    fixedT('entities.button.default_text'),
    fixedT('entities.button.default_sub_text'),
    container.masterBlockId,
  )

  return {
    ...structure,
    [descendantEntity.id]: {
      ...descendantEntity,
      childIds: insertChild(descendantEntity.childIds, button.id, position),
    },
    [button.id]: {
      ...button,
      ...options,
      parentId: descendantEntity.id,
    },
  }
}
