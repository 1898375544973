import PropTypes from 'prop-types'
import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import CommonExplicitConsent from 'common/components/entities/ExplicitConsent'
import { useActions } from 'common/hooks/useActions'
import * as types from 'common/types'
import { update } from 'client/actions/entityActions'
import BaseEntity from 'client/components/core/BaseEntity'
import TextEditor from 'client/components/core/TextEditor'
import usePresentPage from 'client/hooks/usePresentPage'
import { getIsEditing } from 'client/reducers/managementReducer'
import {
  getGlobalFontFamily,
  getGlobalLinkColor,
  getGlobalTextColor,
  getGlobalMobileTextFontSize,
  getGlobalMobileTextLineHeight,
  getGlobalTextFontSize,
  getGlobalTextLineHeight,
} from 'client/reducers/pageReducer'

function ExplicitConsent({ entity, isMoveDownAllowed, isMoveUpAllowed }) {
  const updateAction = useActions(update)
  const isEditing = useSelector(state =>
    getIsEditing(state.management, entity.id),
  )
  const linkColor = usePresentPage(getGlobalLinkColor)
  const textColor = usePresentPage(getGlobalTextColor)
  const fontFamily = usePresentPage(getGlobalFontFamily)
  const fontSize = usePresentPage(getGlobalTextFontSize)
  const mobileFontSize = usePresentPage(getGlobalMobileTextFontSize)
  const lineHeight = usePresentPage(getGlobalTextLineHeight)
  const mobileLineHeight = usePresentPage(getGlobalMobileTextLineHeight)

  const handleUpdateText = rawContentState => {
    updateAction({
      ...entity,
      options: {
        ...entity.options,
        rawContentState,
      },
    })
  }

  const handleUpdateStyles = styles => {
    updateAction({
      ...entity,
      styles,
    })
  }

  return (
    <BaseEntity
      entity={entity}
      mobileEmulationStyles={{
        fontSize: mobileFontSize,
        lineHeight: mobileLineHeight,
      }}
      isMoveUpAllowed={isMoveUpAllowed}
      isMoveDownAllowed={isMoveDownAllowed}
    >
      {(emulatedEntity, addToggleableNode) => (
        <CommonExplicitConsent isCheckboxDisabled>
          <TextEditor
            textWrapperProps={{
              linkColor,
              textColor,
              lineHeight,
              fontFamily,
              fontSize,
              flexBasis: '100%', // stretch width if inside flex container
            }}
            rawContentState={entity.options.rawContentState}
            update={handleUpdateText}
            updateStyles={handleUpdateStyles}
            addToggleableNode={addToggleableNode}
            isEditing={isEditing}
            styles={emulatedEntity.styles}
          />
        </CommonExplicitConsent>
      )}
    </BaseEntity>
  )
}

ExplicitConsent.propTypes = {
  entity: types.entity.isRequired,
  isMoveUpAllowed: PropTypes.bool.isRequired,
  isMoveDownAllowed: PropTypes.bool.isRequired,
}

export default memo(ExplicitConsent)
