import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  getCountryCodeByVatNumber,
  isFromEU,
} from 'common/constants/countryVatList'
import { slugs } from 'common/constants/inputTypes'
import { FieldInterface } from 'common/types/entities/field-interface'
import StyleWrapper from 'publisher/components/core/StyleWrapper'
import BaseFieldNew from 'publisher/components/entities/field-new/base-field'
import { useOptIn, usePayment } from 'publisher/store'
import { getFieldValueBySlug } from 'publisher/store/opt-in/optInSelectors'
import paymentSelectors from 'publisher/store/payment/paymentSelectors'

interface CompanyVatFieldProps {
  entity: FieldInterface
}

const CompanyVatFieldNew = ({ entity }: CompanyVatFieldProps) => {
  const { t } = useTranslation()
  const value = useOptIn(state => getFieldValueBySlug(state, entity.slug))
  const customerCountryCode = useOptIn(state =>
    getFieldValueBySlug(state, slugs.COUNTRY),
  )
  const [notSuitableForSelectedCountry, setNotSuitableForSelectedCountry] =
    useState(false)
  const isPersonal = usePayment(paymentSelectors.isCustomerPersonal)

  useEffect(() => {
    if (isFromEU(customerCountryCode)) {
      const countryCodeByVatNumber = value && getCountryCodeByVatNumber(value)
      const isNotSuitableForSelectedCountry =
        countryCodeByVatNumber && customerCountryCode !== countryCodeByVatNumber

      setNotSuitableForSelectedCountry(isNotSuitableForSelectedCountry)
    }
  }, [value, customerCountryCode])

  useEffect(() => {
    const countryCodeByVatNumber = value && value.slice(0, 2).toUpperCase()
    const isNotSuitableForSelectedCountry =
      countryCodeByVatNumber && customerCountryCode !== countryCodeByVatNumber
    setNotSuitableForSelectedCountry(!!isNotSuitableForSelectedCountry)
  }, [value, customerCountryCode])

  if (typeof window === 'undefined') {
    return (
      <StyleWrapper
        margin={entity.margin}
        mobileMargin={entity.mobileMargin}
        appearance={entity.appearance}
      />
    )
  }

  return isPersonal ? (
    <div />
  ) : (
    <BaseFieldNew
      entity={entity}
      extraErrors={
        notSuitableForSelectedCountry
          ? [t('validation.vat_does_not_suitable_for_selected_country')]
          : []
      }
    />
  )
}

export default CompanyVatFieldNew
