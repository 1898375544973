import styled from 'styled-components'

const ColorPickerUi = styled.div`
  width: 200px;
  padding: 10px 10px 10px 10px;
  box-sizing: initial;
  background-color: #fff;
  color: var(--default-text-color);
  font-weight: normal;
  border-radius: 4px;
  box-shadow: none;
`
export default ColorPickerUi
