import { borderTypeKeys } from 'common/constants/settings'
import { RedirectionTypesEnum } from 'common/enums/ButtonActionEnum'
import { ButtonActionTypeEnum } from 'common/enums/ButtonActionTypeEnum'
import EntityTypeEnum from 'common/enums/entityTypeEnum'
import { MasterBlockId } from 'common/types/entities/EntityInterface'
import { ButtonInterface } from 'common/types/entities/button-interface'
import { generateBaseEntity } from 'client/utils/createStructureUtils'

export const createButtonNew = (
  parentId: string,
  text: string,
  subText: string,
  masterBlockId?: MasterBlockId,
) => {
  const button: ButtonInterface = {
    ...generateBaseEntity(EntityTypeEnum.Button, parentId, masterBlockId),
    type: EntityTypeEnum.Button,
    action: ButtonActionTypeEnum.SendForm,
    redirectionType: RedirectionTypesEnum.nextStep,
    iconClassNameBefore: '',
    iconClassNameAfter: '',
    textFontSize: 20,
    subTextFontSize: 14,
    textColor: 'rgba(255, 255, 255, 1)',
    subTextColor: 'rgba(255,255,255,0.8)',
    background: 'rgba(1, 116, 199, 1)',
    alignSelf: 'center',
    text,
    subText,
    width: 'auto',
    boxShadow: 'none',
    mobileSubTextFontSize: 14,
    mobileTextFontSize: 20,
    mobileWidth: '100%',
    mobileAlignSelf: 'center',
    mobileBoxShadow: 'none',
    border: {
      color: 'rgba(1,77,130,1)',
      radius: {
        borderBottomLeftRadius: 3,
        borderBottomRightRadius: 3,
        borderTopLeftRadius: 3,
        borderTopRightRadius: 3,
      },
      width: 3,
      style: 'solid',
      type: borderTypeKeys.bottomOnly,
    },
    padding: {
      paddingTop: 15,
      paddingRight: 90,
      paddingBottom: 15,
      paddingLeft: 90,
    },
    margin: {
      marginTop: 25,
      marginRight: 0,
      marginBottom: 0,
      marginLeft: 0,
    },
    mobileMargin: {
      marginTop: 5,
      marginRight: 0,
      marginBottom: 5,
      marginLeft: 0,
    },
    mobilePadding: {
      paddingTop: 5,
      paddingRight: 5,
      paddingBottom: 5,
      paddingLeft: 5,
    },
  }

  if (masterBlockId) {
    button.masterBlockId = masterBlockId
  }

  return button
}
