import styled, { css } from 'styled-components'

const BlueButtonUi = styled.button`
  font-family: AvertaPE, Helvetica, Arial, sans-serif;
  font-size: 14px;
  margin-right: 2px;
  margin-left: 2px;
  color: #fff;
  background-color: rgba(20, 158, 252, 1);
  font-weight: 400;
  border: 1px solid transparent;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  line-height: 1.42857143;
  outline: none;
  position: relative;
  transition: background-color 0.25s ease;
  box-shadow: ${({ shadow }) => (shadow ? '0 2px 3px 0 #d5e0e8' : 'none')};
  padding: ${({ wide }) => (wide ? '6px 20px' : '6px 10px')};
  &:hover {
    background-color: rgba(20, 158, 252, 0.8);
  }
  &:disabled {
    opacity: 0.5;
    cursor: default;
    pointer-events: none;
  }
  ${p =>
    p.small &&
    css`
      padding: 3px 5px;
      font-size: 12px;
    `}
  ${({ styles = {} }) => styles}
`

export default BlueButtonUi
