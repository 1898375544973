enum ButtonActionEnum {
  SendForm = 'sendForm',
  OpenUrl = 'openUrl',
  NextStepRedirection = 'nextStepRedirection',
  ShowPopup = 'showPopup',
}

export default ButtonActionEnum

export function getReadableButtonActionName(action: ButtonActionEnum) {
  switch (action) {
    case ButtonActionEnum.SendForm:
      return 'components.button.actions.send_form'
    case ButtonActionEnum.ShowPopup:
      return 'components.button.actions.show_popup'
    case ButtonActionEnum.OpenUrl:
      return 'components.button.actions.open_url'
    case ButtonActionEnum.NextStepRedirection:
      return 'components.button.actions.next_step_redirection'
  }
}

export enum RedirectionTypesEnum {
  nextStep = 'nextStep',
  customUrl = 'customUrl',
  login = 'login',
  none = 'none',
}

export const sendFormRedirectionChoices: Partial<
  Record<RedirectionTypesEnum, string>
> = {
  [RedirectionTypesEnum.nextStep]:
    'entity_settings.button.send_form.redirection.choices.next_step',
  [RedirectionTypesEnum.customUrl]:
    'entity_settings.button.send_form.redirection.choices.custom_url',
  [RedirectionTypesEnum.none]:
    'entity_settings.button.send_form.redirection.choices.none',
}
