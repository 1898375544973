import PropTypes from 'prop-types'
import React, { memo } from 'react'
import CommonButton, { wrapperStyles } from 'common/components/entities/Button'
import { buttonActionsTypes } from 'common/constants/settings'
import * as types from 'common/types'
import { splitMarginsAndRestStyles } from 'common/utils/styleUtils'
import BaseEntity from 'client/components/core/BaseEntity'

function Button({
  entity,
  isMoveDownAllowed,
  isMoveUpAllowed,
  isRemoveAllowed = true,
  isSaveAllowed = true,
  isCopyAllowed = true,
  dropBoxAllowedEntityIds,
}) {
  return (
    <BaseEntity
      styles={wrapperStyles}
      entity={entity}
      isMoveUpAllowed={isMoveUpAllowed}
      isMoveDownAllowed={isMoveDownAllowed}
      isRemoveAllowed={isRemoveAllowed}
      isSaveAllowed={isSaveAllowed}
      isCopyAllowed={isCopyAllowed}
      dropBoxAllowedEntityIds={dropBoxAllowedEntityIds}
    >
      {emulatedEntity => (
        <CommonButton
          styles={{
            ...emulatedEntity.styles,
            zIndex: 1,
          }}
          asLink={emulatedEntity.options.action === buttonActionsTypes.openUrl}
          borderType={emulatedEntity.options.borderType}
          mobileBorderType={emulatedEntity.mobileOptions.borderType}
          mobileStyles={
            // same issue with duplicated margins calculation as in RowOld
            splitMarginsAndRestStyles(emulatedEntity.mobileStyles)[1]
          }
          subText={emulatedEntity.options.subText}
          hoverStyles={{
            textColor: emulatedEntity.options?.hover?.textColor,
            backgroundColor: emulatedEntity.options?.hover?.backgroundColor,
            subTextColor: emulatedEntity.options?.hover?.subTextColor,
            transform: emulatedEntity.options?.hover?.transform,
            offset: emulatedEntity.options?.hover?.offset,
          }}
          hoverMobileStyles={{
            textColor: emulatedEntity.mobileOptions?.hover?.textColor,
            backgroundColor:
              emulatedEntity.mobileOptions?.hover?.backgroundColor,
            subTextColor: emulatedEntity.mobileOptions?.hover?.subTextColor,
            transform: emulatedEntity.mobileOptions?.hover?.transform,
            offset: emulatedEntity.mobileOptions?.hover?.offset,
          }}
          subTextStyles={{
            color: emulatedEntity.options.subTextColor,
            fontSize: emulatedEntity.options.subTextFontSize,
            fontWeight: emulatedEntity.options.subTextFontWeight,
            fontStyle: emulatedEntity.options.subTextFontStyle,
            fontFamily: emulatedEntity.options.subTextFontFamily,
          }}
          subTextMobileStyles={{
            color: emulatedEntity.mobileOptions.subTextColor,
            fontSize: emulatedEntity.mobileOptions.subTextFontSize,
            fontWeight: emulatedEntity.mobileOptions.subTextFontWeight,
            fontStyle: emulatedEntity.mobileOptions.subTextFontStyle,
            fontFamily: emulatedEntity.mobileOptions.subTextFontFamily,
          }}
          iconClassNameBefore={emulatedEntity.options.iconClassNameBefore}
          iconClassNameAfter={emulatedEntity.options.iconClassNameAfter}
        >
          {emulatedEntity.options.text}
        </CommonButton>
      )}
    </BaseEntity>
  )
}

Button.propTypes = {
  entity: types.entity.isRequired,
  isMoveUpAllowed: PropTypes.bool.isRequired,
  isMoveDownAllowed: PropTypes.bool.isRequired,
  isCopyAllowed: PropTypes.bool.isRequired,
  isRemoveAllowed: PropTypes.bool.isRequired,
  isSaveAllowed: PropTypes.bool.isRequired,
  dropBoxAllowedEntityIds: PropTypes.arrayOf(PropTypes.string),
}

export function getButtonFontProperties(entity) {
  return [
    {
      fontFamily: entity.styles.fontFamily,
      fontStyle: entity.styles.fontStyle,
      fontWeight: entity.styles.fontWeight,
    },
    {
      fontFamily: entity.mobileStyles.fontFamily,
      fontStyle: entity.mobileStyles.fontStyle,
      fontWeight: entity.mobileStyles.fontWeight,
    },
    {
      fontFamily: entity.options.subTextFontFamily,
      fontStyle: entity.options.subTextFontStyle,
      fontWeight: entity.options.subTextFontWeight,
    },
    {
      fontFamily: entity.mobileOptions.subTextFontFamily,
      fontStyle: entity.mobileOptions.subTextFontStyle,
      fontWeight: entity.mobileOptions.subTextFontWeight,
    },
  ].filter(fontProperty => Boolean(fontProperty.fontFamily))
}

export function getButtonCustomFontProperties(entity) {
  return [
    {
      fontFamily: entity.styles.fontFamily,
      fontFileId: entity.options.textFontFileId,
    },
    {
      fontFamily: entity.mobileStyles.fontFamily,
      fontFileId: entity.mobileOptions.textFontFileId,
    },
    {
      fontFamily: entity.options.subTextFontFamily,
      fontFileId: entity.options.subTextFontFileId,
    },
    {
      fontFamily: entity.mobileOptions.subTextFontFamily,
      fontFileId: entity.mobileOptions.subTextFontFileId,
    },
  ].filter(fontProperty => Boolean(fontProperty.fontFamily))
}

export default memo(Button)
