import { RowInterface } from 'common/components/entities/Row/types/RowInterface'
import EntityTypeEnum from 'common/enums/entityTypeEnum'
import { MasterBlockId } from 'common/types/entities/EntityInterface'
import { generateBaseEntity } from 'client/utils/createStructureUtils'

export function createRow(
  parentId: string,
  masterBlockId?: MasterBlockId,
  options?: Partial<RowInterface>,
) {
  const row: RowInterface = {
    ...generateBaseEntity(EntityTypeEnum.Row, parentId, masterBlockId),
    type: EntityTypeEnum.Row,
    background: {},
    padding: {
      paddingTop: 10,
      paddingBottom: 10,
      paddingLeft: 10,
      paddingRight: 10,
    },
    mobilePadding: {
      paddingTop: 5,
      paddingBottom: 5,
      paddingLeft: 5,
      paddingRight: 5,
    },
    childIds: [],
    ...options,
  }

  return row
}
