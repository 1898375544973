import styled, { css } from 'styled-components'
import TextUi, {
  TextUiProps,
} from 'common/components/entities/TextNew/ui/TextUi'
import {
  mobileBackgroundColorCss,
  mobilePaddingsCss,
  mobileTextCss,
} from 'common/utils/styleUtilsNew'

interface TextEditorUiProps extends TextUiProps {
  isEditing: boolean
}

const editorCss = css<{ isEditing: boolean }>`
  ${p =>
    p.isEditing
      ? css`
          cursor: text;
          position: relative;
          z-index: 11;
        `
      : css`
          &::before {
            position: absolute;
            content: ' ';
            z-index: 10;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
          }
          cursor: pointer;
          z-index: 10;
        `};
`

const TextEditorUi = styled(TextUi)<TextEditorUiProps>`
  width: 100%;
  ${editorCss};
  ${p =>
    p.theme.isEditorMobileMode &&
    css`
      ${mobilePaddingsCss};
      ${mobileTextCss};
      ${mobileBackgroundColorCss};
      ${p.enableHeadings &&
      css`
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          ${mobileTextCss};
        }
      `};
    `}
`

export default TextEditorUi
