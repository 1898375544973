import React from 'react'
import { useTranslation } from 'react-i18next'
import SelectNewUi from 'common/components/core/Select/ui/select-new'
import CommonField from 'common/components/entities/Field/FieldUi'
import FieldWrapperUi from 'common/components/entities/Field/FieldWrapperUi'
import FieldIconUiNew from 'common/components/entities/Field/field-icon-ui'
import PhoneNumberFieldUiNew from 'common/components/entities/Field/phone-number-field-ui'
import { slugs } from 'common/constants/inputTypes'
import EntityTypeEnum from 'common/enums/entityTypeEnum'
import { MasterBlockId } from 'common/types/entities/EntityInterface'
import { FieldInterface } from 'common/types/entities/field-interface'
import BaseEntity from 'client/components/core/BaseEntity/BaseEntityNew'
import EntityExampleWrapper from 'client/components/core/EntityExampleWrapper'
import { useInputTypes, usePage } from 'client/store'
import inputTypesSelectors from 'client/store/inputTypes/inputTypesSelectors'
import pageSelectors from 'client/store/page/pageSelectors'
import { generateBaseEntity } from 'client/utils/createStructureUtils'

interface FieldNewProps {
  entity: FieldInterface
  isMoveDownAllowed: boolean
  isMoveUpAllowed: boolean
  isSaveAllowed?: boolean
  isRemoveAllowed?: boolean
  isCopyAllowed?: boolean
  dropBoxAllowedEntityIds?: string[]
}

const FieldNew = ({
  entity,
  isMoveDownAllowed,
  isMoveUpAllowed,
  isRemoveAllowed = true,
  isSaveAllowed = true,
  isCopyAllowed = true,
  dropBoxAllowedEntityIds,
}: FieldNewProps) => {
  const { t } = useTranslation()

  let inputTypeName = useInputTypes(state =>
    inputTypesSelectors.getTypeNameBySlug(state, entity.slug),
  )

  const globalFontFamily = usePage(pageSelectors.getGlobalFontFamily)

  if (
    entity.slug === slugs.PASSWORD ||
    entity.slug === slugs.CONFIRM_PASSWORD
  ) {
    inputTypeName = 'PASSWORD'
  }

  const fontFamily =
    globalFontFamily === 'inherit' ? 'initial' : globalFontFamily // otherwise it inherits Editor's UI Averta

  const iconColor = entity.color || 'initial'
  const mobileIconColor = entity.mobileColor || iconColor

  if (entity.slug === slugs.COUNTRY) {
    return (
      <BaseEntity
        entity={entity}
        isMoveUpAllowed={isMoveUpAllowed}
        isMoveDownAllowed={isMoveDownAllowed}
        isRemoveAllowed={isRemoveAllowed}
        isSaveAllowed={isSaveAllowed}
        isCopyAllowed={isCopyAllowed}
        dropBoxAllowedEntityIds={dropBoxAllowedEntityIds}
      >
        <SelectNewUi
          placeholderColor={entity.placeholderColor}
          mobilePlaceholderColor={entity.mobilePlaceholderColor}
          fontFamily={fontFamily}
          textAlign={entity.textAlign}
          mobileTextAlign={entity.mobileTextAlign}
          lineHeight={entity.lineHeight}
          mobileLineHeight={entity.mobileLineHeight}
          padding={entity.padding}
          mobilePadding={entity.mobilePadding}
          border={entity.border}
          mobileBorder={entity.mobileBorder}
          backgroundColor={entity.backgroundColor}
          mobileBackgroundColor={entity.mobileBackgroundColor}
          color={entity.color}
          mobileColor={entity.mobileColor}
          fontSize={entity.fontSize}
          mobileFontSize={entity.mobileFontSize}
          boxShadow={entity.boxShadow}
          mobileBoxShadow={entity.mobileBoxShadow}
        >
          <option>{t('entities.field.country.placeholder')}</option>
        </SelectNewUi>
      </BaseEntity>
    )
  }

  return (
    <BaseEntity
      entity={entity}
      isMoveDownAllowed={isMoveDownAllowed}
      isMoveUpAllowed={isMoveUpAllowed}
      isRemoveAllowed={isRemoveAllowed}
      isSaveAllowed={isSaveAllowed}
      isCopyAllowed={isCopyAllowed}
      dropBoxAllowedEntityIds={dropBoxAllowedEntityIds}
    >
      <EntityExampleWrapper
        label={inputTypeName ?? 'entities.field.warnings.no_input_name'}
        warning={Boolean(inputTypeName) === false}
        needTranslate={Boolean(inputTypeName) === false}
      >
        <FieldWrapperUi>
          {entity.iconClassName && (
            <FieldIconUiNew
              color={iconColor}
              fontSize={entity.fontSize}
              mobileFontSize={entity.mobileFontSize}
              mobileColor={mobileIconColor}
              className={entity.iconClassName}
            />
          )}
          {entity.slug === slugs.PHONE_NUMBER ? (
            <PhoneNumberFieldUiNew
              hasIcon={!!entity.iconClassName}
              textAlign={entity.textAlign}
              mobileTextAlign={entity.mobileTextAlign}
              padding={entity.padding}
              mobilePadding={entity.mobilePadding}
              border={entity.border}
              mobileBorder={entity.mobileBorder}
              backgroundColor={entity.backgroundColor}
              mobileBackgroundColor={entity.mobileBackgroundColor}
              color={entity.color}
              mobileColor={entity.mobileColor}
              placeholder={entity.placeholder}
              placeholderColor={entity.placeholderColor}
              mobilePlaceholderColor={entity.mobilePlaceholderColor}
              fontSize={entity.fontSize}
              mobileFontSize={entity.mobileFontSize}
              boxShadow={entity.boxShadow}
              mobileBoxShadow={entity.mobileBoxShadow}
              inputProps={{ readOnly: true }}
              countrySelectorProps={{
                buttonProps: { onClick: undefined },
                buttonStyle: { cursor: 'default' },
              }}
            />
          ) : (
            <CommonField
              placeholder={entity.placeholder}
              padding={entity.padding}
              lineHeight={entity.lineHeight}
              mobileLineHeight={entity.mobileLineHeight}
              textAlign={entity.textAlign}
              mobileTextAlign={entity.mobileTextAlign}
              mobilePadding={entity.mobilePadding}
              id={entity.htmlAttrId}
              border={entity.border}
              mobileBorder={entity.mobileBorder}
              backgroundColor={entity.backgroundColor}
              mobileBackgroundColor={entity.mobileBackgroundColor}
              color={entity.color}
              mobileColor={entity.mobileColor}
              placeholderColor={entity.placeholderColor}
              mobilePlaceholderColor={entity.mobilePlaceholderColor}
              fontSize={entity.fontSize}
              mobileFontSize={entity.mobileFontSize}
              boxShadow={entity.boxShadow}
              mobileBoxShadow={entity.mobileBoxShadow}
            />
          )}
        </FieldWrapperUi>
      </EntityExampleWrapper>
    </BaseEntity>
  )
}

export function createField(
  parentId: string,
  masterBlockId?: MasterBlockId,
  options?: Partial<FieldInterface>,
): FieldInterface {
  return {
    ...generateBaseEntity(EntityTypeEnum.Field, parentId, masterBlockId),
    type: EntityTypeEnum.Field,
    optional: false,
    fontSize: 18,
    backgroundColor: 'rgba(255, 255, 255, 1)',
    margin: {
      marginTop: 25,
      marginRight: 0,
      marginBottom: 0,
      marginLeft: 0,
    },
    mobileMargin: {
      marginTop: 10,
      marginRight: 0,
      marginBottom: 0,
      marginLeft: 0,
    },
    padding: {
      paddingTop: 12,
      paddingRight: 0,
      paddingBottom: 12,
      paddingLeft: 0,
    },
    border: {
      radius: {
        borderBottomLeftRadius: 5,
        borderBottomRightRadius: 5,
        borderTopLeftRadius: 5,
        borderTopRightRadius: 5,
      },
      style: 'solid',
      color: 'rgba(176, 186, 202, 1)',
      width: 1,
    },
    ...options,
  }
}

export default FieldNew
