import { CurrencyEnum } from 'common/types/CurrencyType'

// https://docs.stripe.com/currencies#minor-units
export const zeroDecimalCurrencies: CurrencyEnum[] = [
  CurrencyEnum.CHILEAN_PESO,
  CurrencyEnum.GUINEAN_FRANC,
  CurrencyEnum.JAPANESE_YEN,
  CurrencyEnum.RWANDAN_FRANC,
  CurrencyEnum.UGANDAN_SHILLING,
  CurrencyEnum.CENTRAL_AFRICAN_FRANC,
  CurrencyEnum.WEST_AFRICAN_CFA_FRANC_BCEAO,
]
