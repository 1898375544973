import React, { useEffect, useRef } from 'react'
import { CarouselSlideUi } from 'common/components/entities/Carousel'
import { AlignSelfType } from 'common/types/styleTypes'
import { alignSelfVariants } from 'client/constants/alignSelfOptions'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'

type CarouselSlideProps = {
  children: React.ReactElement
  activeSlideIndex: number
  slideIndex: number
  alignSelf?: AlignSelfType
  mobileAlignSelf?: AlignSelfType
}

function CarouselSlide({
  children,
  activeSlideIndex,
  slideIndex,
  alignSelf = alignSelfVariants.center,
  mobileAlignSelf,
}: CarouselSlideProps) {
  const ref = useRef<HTMLDivElement>(null)
  const active = slideIndex === activeSlideIndex
  const { isMobile } = useDeviceModeContext()

  useEffect(() => {
    if (ref.current && active) {
      ref.current.scrollIntoView({
        block: 'nearest',
        behavior: 'smooth',
      })
    }
  }, [active])

  return (
    <CarouselSlideUi
      ref={ref}
      active={active}
      alignSelf={alignSelf}
      mobileAlignSelf={mobileAlignSelf}
    >
      {children}
    </CarouselSlideUi>
  )
}

export default React.memo(CarouselSlide)
