import { useState } from 'react'
import { OldEntityInterface } from 'common/types/entities/OldEntityInterface'
import { PaymentButtonInterface } from 'common/types/entities/PaymentButtonInterface'
import {
  buyMainOffer,
  captureOrder,
  continuePurchase,
  CreateOrderResponse,
  CreateSubscriptionResponse,
  validateSubscription,
} from 'publisher/api/paypalRestApi'
import { pageSelectors } from 'publisher/store'
import usePage from '../usePage'
import usePaymentSubmit from './usePaymentSubmit'

export const usePaypalRestPaymentHandler = (
  entity: OldEntityInterface | PaymentButtonInterface,
) => {
  const { errors, isLoading, submit } = usePaymentSubmit(entity)
  const [orderHash, setOrderHash] = useState('')
  const pageId = usePage(pageSelectors.getPageId)

  const handleSubmit = async () => {
    return await submit<string>(async body => {
      const {
        data: { redirect },
      } = await buyMainOffer(pageId, {
        payment_form: body,
      })
      const res = await continuePurchase<
        CreateOrderResponse | CreateSubscriptionResponse
      >(redirect)
      if (res) {
        setOrderHash(res.data.options.hash)
        // TODO: fix submit return type to avoid using any
        if ('paypalOrderId' in res.data.options) {
          return res.data.options.paypalOrderId as any
        }
        return res.data.options.paypalSubscriptionId
      }
    })
  }

  return {
    errors,
    isLoading,
    handleSubmit,
    orderHash,
  }
}

export interface PayPalApprovedData {
  billingToken?: string | null
  facilitatorAccessToken?: string
  orderID: string
  payerID?: string | null
  paymentID?: string | null
  subscriptionID?: string | null
  authCode?: string | null
}

interface BumpPurchaseResponse {
  options: {
    paypalLink: string
  }
}

export const handleCaptureOrder = async (
  body: PayPalApprovedData,
  hash: string,
  isBumpChecked?: boolean,
) => {
  const { data } = await captureOrder({
    paypalOrderId: body.orderID,
    paypalPaymentId: body.paymentID,
    facilitatorAccessToken: body.facilitatorAccessToken,
    paypalPayerId: body.payerID,
    paymentSource: 'paypal',
    hash,
  })

  if (isBumpChecked) {
    const res = await continuePurchase<BumpPurchaseResponse>(data.redirectLink)
    if (res) {
      window.location.assign(res.data.options.paypalLink)
      return
    }
  }

  if (data.redirectLink) {
    window.location.assign(data.redirectLink)
  }
}

export const handleValidateSubscription = async (
  body: PayPalApprovedData,
  hash: string,
  isBumpChecked?: boolean,
) => {
  if (body.subscriptionID) {
    const { data } = await validateSubscription({
      paypalSubscriptionId: body.subscriptionID,
      hash,
    })

    if (isBumpChecked) {
      const res = await continuePurchase<BumpPurchaseResponse>(
        data.redirectLink,
      )
      if (res) {
        window.location.assign(res.data.options.paypalLink)
        return
      }
    }

    if (data.redirectLink) {
      window.location.assign(data.redirectLink)
    }
  }
}
