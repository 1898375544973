import { CalculatePriceData } from 'common/types/shippingType'
import { transformPricingDto } from 'common/utils/productUtils'
import { calculatePrice } from 'client/api/shippingApi'

export const fetchOfferPricing = async (offers: CalculatePriceData[]) => {
  const { data } = await calculatePrice(offers)

  const offerPricings = data.map(offerPricing =>
    transformPricingDto(offerPricing),
  )

  return offerPricings
}
