import styled from 'styled-components'

type ActiveColorBoxUiProps = {
  backgroundColor: string
}
const ActiveColorBoxUi = styled.div<ActiveColorBoxUiProps>`
  position: absolute;
  inset: 0px;
  border-radius: 2px;
  box-shadow:
    inset 0 0 0 1px rgba(0, 0, 0, 0.15),
    inset 0 0 4px rgba(0, 0, 0, 0.25);
  background: ${p => p.backgroundColor};
`

export default ActiveColorBoxUi
