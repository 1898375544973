import { EntityTypeEnum } from 'common/enums/entityTypeEnum'
import EntityInterface from 'common/types/entities/EntityInterface'
import { copyEntityWithSource } from './cloneStructure'
import { generateHtmlAttrId, generateId } from './createStructureUtils'

export const copyEntity = (entity: EntityInterface) => {
  if (entity.type in EntityTypeEnum) {
    return {
      ...entity,
      oldId: entity.id,
      id: generateId(),
      htmlAttrId: generateHtmlAttrId(entity.type),
    }
  } else {
    return copyEntityWithSource(entity)
  }
}

export const removeMasterBlockProperties = (entity: EntityInterface) => {
  const {
    masterBlockId,
    isMasterBlockRoot,
    ...entityWithoutMasterBlockProperties
  } = entity

  return entityWithoutMasterBlockProperties
}
