import Draft, {
  EditorState,
  convertFromRaw,
  RichUtils,
  SelectionState,
  convertToRaw,
  DraftInlineStyle,
  ContentState,
  Modifier,
  CharacterMetadata,
  ContentBlock,
} from 'draft-js'
import * as colorUtils from 'common/utils/colorUtils'

export function createStateFromRaw(
  rawContentState: string,
  // @ts-ignore
  decorator?: Draft.Model.Decorators.DraftDecoratorType,
): EditorState {
  return EditorState.createWithContent(
    convertFromRaw(JSON.parse(rawContentState)),
    decorator,
  )
}

export const filterBlockTypes = (
  allowList: readonly string[],
  content: ContentState,
) => {
  const blockMap = content.getBlockMap()

  const changedBlocks = blockMap
    .filter(block => (block ? !allowList.includes(block.getType()) : false))
    .map(
      block =>
        block?.merge({
          type: 'unstyled',
          depth: 0,
        }) as ContentBlock,
    )

  return changedBlocks.size === 0
    ? content
    : (content.merge({
        blockMap: blockMap.merge(changedBlocks),
      }) as ContentState)
}

export const filterInlineStyles = (
  allowList: readonly string[],
  content: ContentState,
) => {
  const blockMap = content.getBlockMap()
  const blocks = blockMap.map(block => {
    let altered = false

    const hasStyle = (style: string) =>
      allowList.some(allowedStyle => RegExp(allowedStyle).test(style))

    const chars = block?.getCharacterList().map(char => {
      let newChar = char as CharacterMetadata

      char
        ?.getStyle()
        .filter(style => !hasStyle(style as string))
        .forEach(type => {
          altered = true
          newChar = CharacterMetadata.removeStyle(newChar, type as string)
        })

      return newChar
    })

    return (
      altered ? block?.set('characterList', chars) : block
    ) as ContentBlock
  })

  return content.merge({
    blockMap: blockMap.merge(blocks),
  }) as ContentState
}

export function getRawContentState(editorState: EditorState) {
  return JSON.stringify(convertToRaw(editorState.getCurrentContent()))
}

export function createLinkAndGetEntityKey(editorState: EditorState) {
  const contentState = editorState.getCurrentContent()
  const data = { url: '' }
  const contentStateWithEntity = contentState.createEntity(
    'LINK',
    'MUTABLE',
    data,
  )

  return contentStateWithEntity.getLastCreatedEntityKey()
}

export function getEntityData(editorState: EditorState, key: string) {
  return editorState.getCurrentContent().getEntity(key).getData()
}

export function createLink(targetEditorState: EditorState) {
  const linkKey = createLinkAndGetEntityKey(targetEditorState)

  const editorState = RichUtils.toggleLink(
    targetEditorState,
    targetEditorState.getSelection(),
    linkKey,
  )

  return { linkKey, editorState }
}

export function removeLink(
  editorState: EditorState,
  linkEntityKey: string,
): EditorState | null {
  const selection = editorState.getSelection()
  const anchorKey = selection.getAnchorKey()
  const blockWithLinkAtBeginning = editorState
    .getCurrentContent()
    .getBlockForKey(anchorKey)

  let updatedEditorState: EditorState | null = null

  blockWithLinkAtBeginning.findEntityRanges(
    character => character.getEntity() === linkEntityKey,
    (start, end) => {
      // removing link from editorState
      const editorStateWithSelectionAndWithoutLink = RichUtils.toggleLink(
        editorState,
        selection.merge({
          anchorOffset: start,
          focusOffset: end,
        }),
        null,
      )

      updatedEditorState = EditorState.forceSelection(
        editorStateWithSelectionAndWithoutLink,
        selection, // old selection
      )
    },
  )

  return updatedEditorState
}

export function selectCurrentWord(editorState: EditorState) {
  const contentState = editorState.getCurrentContent()
  const selection = editorState.getSelection()
  const startOffset = selection.getStartOffset()
  const endOffset = selection.getEndOffset()
  const blockKey = selection.getStartKey()
  const block = contentState.getBlockForKey(blockKey)

  if (startOffset === endOffset) {
    const word = getWordByPosition(block.getText(), startOffset)
    const anchorOffset = block.getText().indexOf(word)
    const newSelectionState = SelectionState.createEmpty(blockKey)
      .set('focusOffset', anchorOffset + word.length)
      .set('anchorOffset', anchorOffset) as SelectionState

    return EditorState.forceSelection(editorState, newSelectionState)
  }

  return editorState
}

export function getCurrentColor(editorState: EditorState) {
  return editorState
    .getCurrentInlineStyle()
    .filter(value => (value ? colorUtils.isRgba(value) : false))
    .first()
}

export function applyCustomStyle(inlineStyle: DraftInlineStyle) {
  const color = inlineStyle
    .filter(value => (value ? colorUtils.isRgba(value) : false))
    .first()

  return { color }
}

function getWordByPosition(text: string, position: number) {
  let char
  let forthWordPart = ''
  let forthPosition = position

  const charTrueAndNotWhiteSpace = (char: string) => char && char !== ' '

  do {
    char = text.charAt(forthPosition)
    forthPosition++
    if (charTrueAndNotWhiteSpace(char)) {
      forthWordPart = forthWordPart + char
    }
  } while (charTrueAndNotWhiteSpace(char))

  let backPosition = position
  let backWordPart = ''
  do {
    --backPosition
    char = text.charAt(backPosition)
    if (charTrueAndNotWhiteSpace(char)) {
      backWordPart = backWordPart + char
    }
  } while (charTrueAndNotWhiteSpace(char))

  return backWordPart.split('').reverse().join('') + forthWordPart
}

export function getSelectionEntityKeyOrNull(editorState: EditorState) {
  const selection = editorState.getSelection()
  const anchorOffset = selection.getAnchorOffset()
  const anchorKey = selection.getAnchorKey()
  const contentState = editorState.getCurrentContent()
  const blockWithLinkAtBeginning = contentState.getBlockForKey(anchorKey)
  return blockWithLinkAtBeginning.getEntityAt(anchorOffset)
}

export function selectAnchorWord(editorState: EditorState) {
  const contentState = editorState.getCurrentContent()
  const selection = editorState.getSelection()
  const anchorOffset = selection.getAnchorOffset()
  const focusOffset = selection.getFocusOffset()
  const blockKey = selection.getStartKey()
  const block = contentState.getBlockForKey(blockKey)
  let newAnchorOffset = anchorOffset
  let newFocusOffset = focusOffset
  block.findEntityRanges(
    () => {
      return true
    },
    (start, end) => {
      if (anchorOffset >= start && anchorOffset <= end) {
        newAnchorOffset = start
        newFocusOffset = end
      }
    },
  )
  const newSelectionState = SelectionState.createEmpty(blockKey)
    .set('focusOffset', newFocusOffset)
    .set('anchorOffset', newAnchorOffset) as SelectionState

  return EditorState.forceSelection(editorState, newSelectionState)
}

type FilterInlineStyle = (value: string) => boolean

export function toggleInlineStyle(
  editorState: EditorState,
  newInlineStyle: string,
  filterFn: FilterInlineStyle,
) {
  const inlineStyles = editorState
    .getCurrentContent()
    .getBlockMap()
    .toArray()
    // @ts-ignore
    .flatMap(block =>
      block
        .getCharacterList()
        .toArray()
        // @ts-ignore
        .flatMap(c => c.getStyle().toArray().filter(filterFn)),
    )
  const selection = editorState.getSelection()

  // Let's just allow one color at a time. Turn off all active colors.
  const nextContentState = inlineStyles.reduce(
    (contentState: ContentState, fontSize: string) =>
      Modifier.removeInlineStyle(contentState, selection, fontSize),
    editorState.getCurrentContent(),
  )

  let nextEditorState = EditorState.push(
    editorState,
    nextContentState,
    'change-inline-style',
  )

  const currentStyle = editorState.getCurrentInlineStyle()

  // Unset style override for current color.
  if (selection.isCollapsed()) {
    nextEditorState = currentStyle
      .toArray()
      .reduce(RichUtils.toggleInlineStyle, nextEditorState)
  }

  // If the color is being toggled on, apply it.
  if (!currentStyle.has(newInlineStyle)) {
    nextEditorState = RichUtils.toggleInlineStyle(
      nextEditorState,
      newInlineStyle,
    )
  }

  return nextEditorState
}

export function toggleParentDraggableAttribute(val: boolean) {
  const draggableNodeList = document.querySelectorAll('[draggable]')
  for (let i = 0; i < draggableNodeList.length; i++) {
    const node = draggableNodeList[i]
    node.setAttribute('draggable', String(val))
  }
}
