import styled, { css } from 'styled-components'

const MenuButtonUi = styled.button`
  margin: 2px;
  background-color: ${p => (p.active ? 'rgba(0, 0, 0, 0.1)' : 'transparent')};
  color: ${p => (p.active ? '#a4d7ff' : '#fff')};
  padding: 5px;
  font-size: 14px;
  font-weight: bold;
  display: inline-block;
  border: none;
  cursor: pointer;
  outline: none;
  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
  &:disabled {
    opacity: 0.5;
    cursor: default;
    pointer-events: none;
  }
  ${p =>
    p.marginLeft &&
    css`
      margin-left: ${p.marginLeft};
    `}
`

export default MenuButtonUi
