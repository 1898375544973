import { SectionInterface } from 'common/components/entities/Section/types/SectionInterface'
import { CommonWidthEnum } from 'common/constants/commonWidth'
import EntityTypeEnum from 'common/enums/entityTypeEnum'
import { MasterBlockId } from 'common/types/entities/EntityInterface'
import { generateBaseEntity } from 'client/utils/createStructureUtils'

export const createSection = (
  parentId: string,
  masterBlockId?: MasterBlockId,
) => {
  const section: SectionInterface = {
    ...generateBaseEntity(EntityTypeEnum.Section, parentId, masterBlockId),
    type: EntityTypeEnum.Section,
    background: { backgroundRepeat: 'no-repeat' },
    padding: {
      paddingTop: 40,
      paddingBottom: 40,
      paddingLeft: 40,
      paddingRight: 40,
    },
    mobilePadding: {
      paddingTop: 5,
      paddingBottom: 5,
      paddingLeft: 5,
      paddingRight: 5,
    },
    contentWidth: CommonWidthEnum.fullPage,
    childIds: [],
  }

  return section
}
