import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { BadRequest, InternalError, NetworkError } from 'common/errors'
import { UpsellButtonInterface } from 'common/types/entities/button-interface'
import { optInFail } from 'publisher/actions/optInActions'
import { buyUpsell } from 'publisher/api/paypalRestApi'
import useUpsellSubmit from 'publisher/pages/UpsellPage/hooks/useUpsellSubmit'
import { pageSelectors } from 'publisher/store'
import { usePayment } from 'publisher/store'
import paymentSelectors from 'publisher/store/payment/paymentSelectors'
import usePage from '../usePage'

export const usePaypalUpsellRestHandler = (entity: UpsellButtonInterface) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { errors, setErrors, isLoading, submit } = useUpsellSubmit(entity)
  const [orderHash, setOrderHash] = useState('')
  const pageId = usePage(pageSelectors.getPageId)
  const purchaseProcessId = usePayment(paymentSelectors.getPurchaseProcessId)
  const [isUpsellLoading, setIsUpsellLoading] = useState(false)

  const handleSubmit = async () => {
    return await submit(async (body: { offerPricePlan: number }) => {
      try {
        setIsUpsellLoading(true)
        const { data } = await buyUpsell({
          pageId,
          purchaseProcessId,
          offerPricePlan: body.offerPricePlan,
        })
        setOrderHash(data.options.hash)
        if (data.options.paypalOrderId) {
          return data.options.paypalOrderId
        }
        return data.options.paypalSubscriptionId
      } catch (error) {
        if (error instanceof BadRequest) {
          setErrors(error.response.data.errors.common)
          dispatch(optInFail({ fields: error.response.data.errors.fields }))
        } else if (error instanceof NetworkError) {
          setErrors([t('core.errors.no_connection')])
        } else if (error instanceof InternalError) {
          setErrors([t('core.error.title')])
        }
      }
    })
  }

  return {
    errors,
    isLoading: isLoading || isUpsellLoading,
    handleSubmit,
    orderHash,
  }
}
