import { modes } from 'client/components/core/BaseEntity/ui/BaseEntityUi'

export const getDeviceWarning = (
  appearance = { mobile: true, desktop: true },
  isMobile,
) => {
  let deviceWarning = ''
  if (!appearance.desktop && isMobile) {
    deviceWarning = 'mobile_only'
  }

  if (!appearance.mobile && !isMobile) {
    deviceWarning = 'desktop_only'
  }

  return deviceWarning
}

export const getMode = (editing, showMenu, highlighted, deviceWarning) => {
  if (editing) {
    return modes.editing
  }

  if (showMenu) {
    return modes.hover
  }

  if (highlighted) {
    return modes.highlighted
  }

  if (deviceWarning) {
    return modes.warning
  }

  return null
}

export function getEmulatedEntity(
  entity,
  isMobile,
  emulationStyles,
  emulationOptions,
) {
  const { options, styles, mobileStyles } = entity
  const desktopEmulationStyles =
    options.appearance && !options.appearance.desktop ? { display: 'none' } : {}

  const emulationMobileAppearanceStyles =
    options.appearance && !options.appearance.mobile ? { display: 'none' } : {}

  return isMobile
    ? {
        ...entity,
        styles: {
          ...styles,
          ...emulationStyles,
          ...emulationMobileAppearanceStyles,
          ...mobileStyles,
        },
        options: {
          ...entity.options,
          ...emulationOptions,
          ...entity.mobileOptions,
        },
      }
    : {
        ...entity,
        styles: {
          ...styles,
          ...desktopEmulationStyles,
        },
      }
}

export function notContainActionButtons(event, actionsBoxRef) {
  return (
    actionsBoxRef.current &&
    (!actionsBoxRef.current.copyButton ||
      !actionsBoxRef.current.copyButton.contains(event.target)) &&
    (!actionsBoxRef.current.removeButton ||
      !actionsBoxRef.current.removeButton.contains(event.target)) &&
    (!actionsBoxRef.current.saveButton ||
      !actionsBoxRef.current.saveButton.contains(event.target))
  )
}

export function notContainsSettingsButton(event, actionsBoxRef) {
  return (
    actionsBoxRef.current &&
    !actionsBoxRef.current.settingsButton.contains(event.target)
  )
}

export function relatedTargetNotContainsActionButtons(event, actionsBoxRef) {
  return (
    actionsBoxRef.current &&
    actionsBoxRef.current.box &&
    !actionsBoxRef.current.box.contains(event.relatedTarget)
  )
}
