import styled, { css } from 'styled-components'

const DialogContainer = styled.div`
  align-items: center;
  bottom: 0;
  display: flex;
  height: 100vh;
  left: 0;
  margin: 0 auto;
  opacity: 0;
  outline: 0;
  overflow-y: auto;
  padding: 12px;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateY(10%);
  transition:
    opacity 0.3s linear,
    transform 0.5s ease-in-out;
  width: ${p => p.$width}px;
  z-index: 110;
  ${props =>
    props.$show &&
    css`
      transform: translateY(0);
      opacity: 1;
    `}
`

export default DialogContainer
