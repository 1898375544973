import React, { useEffect, useState } from 'react'
import Button from 'common/components/entities/Button/button'
import { PaymentMethodEnum } from 'common/enums/PaymentMethodEnum'
import { PaymentButtonInterface } from 'common/types/entities/PaymentButtonInterface'
import StyleWrapper from 'publisher/components/core/StyleWrapper'
import CashOnDeliveryPaymentButton from 'publisher/components/entities/PaymentButtonNew/CashOnDeliveryPaymentButton'
import DLocalBoletoAndPixPaymentButton from 'publisher/components/entities/PaymentButtonNew/DLocalBoletoAndPixPaymentButton'
import DLocalPaymentButton from 'publisher/components/entities/PaymentButtonNew/DLocalPaymentButton'
import FlutterwavePaymentButton from 'publisher/components/entities/PaymentButtonNew/FlutterwavePaymentButton'
import GocardlessPaymentButton from 'publisher/components/entities/PaymentButtonNew/GocardlessPaymentButton'
import MercadoPagoCreditCardPaymentButton from 'publisher/components/entities/PaymentButtonNew/MercadoPagoCreditCardPaymentButton'
import MercadoPagoPixAndBoletoPaymentButton from 'publisher/components/entities/PaymentButtonNew/MercadoPagoPixAndBoletoPaymentButton'
import MolliePaymentButton from 'publisher/components/entities/PaymentButtonNew/MolliePaymentButton'
import PaypalPaymentButton from 'publisher/components/entities/PaymentButtonNew/PaypalPaymentButton'
import PaystackPaymentButton from 'publisher/components/entities/PaymentButtonNew/PaystackPaymentButton'
import RazorpayPaymentButton from 'publisher/components/entities/PaymentButtonNew/RazorpayPaymentButton'
import SecureAndPayPaymentButton from 'publisher/components/entities/PaymentButtonNew/SecureAndPayPaymentButton'
import StripeBancontactPaymentButton from 'publisher/components/entities/PaymentButtonNew/StripeBancontactPaymentButton'
import StripeCardPaymentButton from 'publisher/components/entities/PaymentButtonNew/StripeCardPaymentButton'
import StripeIdealPaymentButton from 'publisher/components/entities/PaymentButtonNew/StripeIdealPaymentButton'
import XenditCardPaymentButton from 'publisher/components/entities/PaymentButtonNew/XenditCardPaymentButton'
import { PaypalRestPaymentButton } from 'publisher/components/entities/PaymentButtonNew/paypal-rest-button/index'
import useManagement, { selectors } from 'publisher/hooks/useManagement'
import usePayment, {
  selectors as paymentSelectors,
} from 'publisher/hooks/usePayment'

export interface PaymentButtonNewProps {
  entity: PaymentButtonInterface
}

const PaymentButtonNew = ({ entity }: PaymentButtonNewProps) => {
  const isScreenshotMode = useManagement(selectors.isScreenshotMode)
  const paymentMethod = usePayment(paymentSelectors.getActivePaymentMethod)
  const isPreview = useManagement(selectors.isPreviewMode)
  const [isClient, setIsClient] = useState(false)

  useEffect(() => {
    setIsClient(true)
  }, [])

  if (isScreenshotMode || isPreview) {
    return (
      <StyleWrapper
        flex
        flexColumn
        margin={entity.margin}
        mobileMargin={entity.mobileMargin}
        appearance={entity.appearance}
      >
        <Button
          onClick={() => null}
          disabled={false}
          attrId={entity.htmlAttrId}
          text={entity.text}
          subText={entity.subText}
          width={entity.width}
          mobileWidth={entity.mobileWidth}
          padding={entity.padding}
          mobilePadding={entity.mobilePadding}
          border={entity.border}
          mobileBorder={entity.mobileBorder}
          alignSelf={entity.alignSelf}
          mobileAlignSelf={entity.mobileAlignSelf}
          textColor={entity.textColor}
          mobileTextColor={entity.mobileTextColor}
          textFontSize={entity.textFontSize}
          mobileTextFontSize={entity.mobileTextFontSize}
          lineHeight={entity.lineHeight}
          mobileLineHeight={entity.mobileLineHeight}
          textFontFamily={entity.textFontFamily}
          textFontStyle={entity.textFontStyle}
          textFontWeight={entity.textFontWeight}
          subTextFontSize={entity.subTextFontSize}
          mobileSubTextFontSize={entity.mobileSubTextFontSize}
          subTextColor={entity.subTextColor}
          mobileSubTextColor={entity.mobileSubTextColor}
          subTextFontFamily={entity.subTextFontFamily}
          subTextFontStyle={entity.subTextFontStyle}
          subTextFontWeight={entity.subTextFontWeight}
          boxShadow={entity.boxShadow}
          mobileBoxShadow={entity.mobileBoxShadow}
          hover={entity.hover}
          mobileHover={entity.mobileHover}
          background={entity.background ?? entity.backgroundColor}
          mobileBackground={
            entity.mobileBackground ?? entity.mobileBackgroundColor
          }
          iconClassNameBefore={entity.iconClassNameBefore}
          iconClassNameAfter={entity.iconClassNameAfter}
        />
      </StyleWrapper>
    )
  }

  if (!isClient) return <></>

  switch (paymentMethod) {
    case PaymentMethodEnum.PaypalSoap:
      return <PaypalPaymentButton entity={entity} />
    case PaymentMethodEnum.PaypalRest:
      return (
        <PaypalRestPaymentButton
          entity={entity}
          borderRadius={entity.border}
          backgroundColor={entity.paypal?.backgroundColor}
        />
      )
    case PaymentMethodEnum.StripeCard:
      return <StripeCardPaymentButton entity={entity} />
    case PaymentMethodEnum.StripeIDeal:
      return <StripeIdealPaymentButton entity={entity} />
    case PaymentMethodEnum.StripeBancontact:
      return <StripeBancontactPaymentButton entity={entity} />
    case PaymentMethodEnum.DlocalCard:
      return <DLocalPaymentButton entity={entity} />
    case PaymentMethodEnum.DlocalBoleto:
    case PaymentMethodEnum.DlocalPix:
      return <DLocalBoletoAndPixPaymentButton entity={entity} />
    case PaymentMethodEnum.MercadoPagoCard:
    case PaymentMethodEnum.MercadoPagoBrCard:
    case PaymentMethodEnum.MercadoPagoCoCard:
    case PaymentMethodEnum.MercadoPagoMxCard:
    case PaymentMethodEnum.MercadoPagoArCard:
    case PaymentMethodEnum.MercadoPagoClCard:
      return <MercadoPagoCreditCardPaymentButton entity={entity} />
    case PaymentMethodEnum.MercadoPagoPix:
    case PaymentMethodEnum.MercadoPagoBoleto:
    case PaymentMethodEnum.MercadoPagoBrPix:
    case PaymentMethodEnum.MercadoPagoBrBoleto:
      return <MercadoPagoPixAndBoletoPaymentButton entity={entity} />
    case PaymentMethodEnum.RazorpayCard:
      return <RazorpayPaymentButton entity={entity} />
    case PaymentMethodEnum.Flutterwave:
      return <FlutterwavePaymentButton entity={entity} />
    case PaymentMethodEnum.Mollie:
      return <MolliePaymentButton entity={entity} />
    case PaymentMethodEnum.Gocardless:
      return <GocardlessPaymentButton entity={entity} />
    case PaymentMethodEnum.CashOnDelivery:
      return <CashOnDeliveryPaymentButton entity={entity} />
    case PaymentMethodEnum.XenditIdCard:
    case PaymentMethodEnum.XenditPhCard:
      return <XenditCardPaymentButton entity={entity} />
    case PaymentMethodEnum.PaystackGhCard:
    case PaymentMethodEnum.PaystackKeCard:
    case PaymentMethodEnum.PaystackNgCard:
    case PaymentMethodEnum.PaystackZaCard:
    case PaymentMethodEnum.PaystackEgCard:
    case PaymentMethodEnum.PaystackCiCard:
      return <PaystackPaymentButton entity={entity} />
    case PaymentMethodEnum.SecureAndPay:
      return <SecureAndPayPaymentButton entity={entity} />
    default:
      return <div />
  }
}

export default PaymentButtonNew
