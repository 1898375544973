import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import DeviceWarningUi from './ui/DeviceWarningUi'

type DeviceWarningProps = {
  deviceWarning: string
}

function DeviceWarning({ deviceWarning }: DeviceWarningProps) {
  const { t } = useTranslation()

  return (
    <DeviceWarningUi>{t(`entity.warning.${deviceWarning}`)}</DeviceWarningUi>
  )
}

DeviceWarning.propTypes = {
  deviceWarning: PropTypes.string.isRequired,
}

export default DeviceWarning
