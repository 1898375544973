import React, { useEffect, useRef } from 'react'
import ReactPlayer from 'react-player'
import VideoUi from 'common/components/entities/Video/ui/VideoUi'
import { BorderType } from 'common/types/styleTypes'
import VideoPlaceholderInner from './ui/VideoPlaceholderInnerUi'
import VideoPlaceholder from './ui/VideoPlaceholderUi'

type BasePlayerProps = {
  attrId: string
  url: string
  posterUrl?: string
  controls?: boolean
  border?: Partial<BorderType>
  mobileBorder?: Partial<BorderType>
  boxShadow?: string
  mobileBoxShadow?: string
  disabledPointerEvents?: boolean
}

function BasePlayer({
  url,
  controls,
  border,
  mobileBorder,
  boxShadow,
  mobileBoxShadow,
  disabledPointerEvents = false,
  posterUrl,
}: BasePlayerProps) {
  const video = useRef<ReactPlayer>(null)
  useEffect(() => {
    if (video.current) {
      const player = video.current.getInternalPlayer()
      //The React-player config is memorized, we need to change the poster along with the posterUrl
      if (player) {
        if (posterUrl) player.setAttribute('poster', posterUrl)
        else player.removeAttribute('poster')
      }
    }
  }, [posterUrl])

  if (!url) {
    return (
      <VideoPlaceholder
        border={border}
        mobileBorder={mobileBorder}
        boxShadow={boxShadow}
        mobileBoxShadow={mobileBoxShadow}
      >
        <VideoPlaceholderInner />
      </VideoPlaceholder>
    )
  }

  return (
    <VideoUi
      border={border}
      mobileBorder={mobileBorder}
      boxShadow={boxShadow}
      mobileBoxShadow={mobileBoxShadow}
      disabledPointerEvents={disabledPointerEvents}
    >
      <ReactPlayer
        ref={video}
        width="100%"
        height="100%"
        url={url}
        className="react-player"
        controls={controls}
        config={{
          file: {
            attributes: {
              controlsList: 'nodownload',
              poster: posterUrl,
            },
          },
        }}
      />
    </VideoUi>
  )
}

export default BasePlayer
