import { PayPalScriptProvider } from '@paypal/react-paypal-js'
import React from 'react'
import CommonButton from 'common/components/entities/Button/button'
import {
  CommonPaypalRestButton,
  MAX_PAYPAL_BUTTON_HEIGHT,
  MAX_PAYPAL_BUTTON_WIDTH,
} from 'common/components/entities/paypal-rest-button'
import { borderTypeKeys } from 'common/constants/settings'
import EntityTypeEnum from 'common/enums/entityTypeEnum'
import { MasterBlockId } from 'common/types/entities/EntityInterface'
import { UpsellButtonInterface } from 'common/types/entities/button-interface'
import BaseEntityNew from 'client/components/core/BaseEntity/BaseEntityNew'
import { isPaypalSettingsOpened } from 'client/reducers/managementReducer'
import { useManagement } from 'client/store'
import { generateHtmlAttrId, generateId } from 'client/utils/createStructureNew'
import { EntityProps } from '../../../types'

function UpsellButton({
  entity,
  isMoveDownAllowed,
  isMoveUpAllowed,
}: EntityProps<UpsellButtonInterface>) {
  const showPaypalSettings = useManagement(isPaypalSettingsOpened)

  return (
    <BaseEntityNew
      entity={entity}
      isMoveDownAllowed={isMoveDownAllowed}
      isMoveUpAllowed={isMoveUpAllowed}
      flex
      flexColumn
    >
      {entity.type === EntityTypeEnum.UpsellAgreeButton &&
      showPaypalSettings ? (
        <div style={{ marginTop: 10 }}>
          <PayPalScriptProvider options={{ clientId: 'test' }}>
            <CommonPaypalRestButton
              backgroundColor={entity.paypal?.backgroundColor}
              borderRadius={entity.border}
              disabled
              height={
                entity.width === 'auto' ? MAX_PAYPAL_BUTTON_HEIGHT : undefined
              }
              width={
                entity.width === 'auto' ? MAX_PAYPAL_BUTTON_WIDTH : entity.width
              }
            />
          </PayPalScriptProvider>
        </div>
      ) : (
        <CommonButton
          iconClassNameBefore={entity.iconClassNameBefore}
          iconClassNameAfter={entity.iconClassNameAfter}
          text={entity.text}
          border={entity.border}
          mobileBorder={entity.mobileBorder}
          subText={entity.subText}
          padding={entity.padding}
          mobilePadding={entity.mobilePadding}
          alignSelf={entity.alignSelf}
          background={entity.background ?? entity.backgroundColor}
          mobileBackground={
            entity.mobileBackground ?? entity.mobileBackgroundColor
          }
          attrId={entity.htmlAttrId}
          width={entity.width}
          mobileWidth={entity.mobileWidth}
          textColor={entity.textColor}
          mobileTextColor={entity.mobileTextColor}
          mobileSubTextColor={entity.mobileSubTextColor}
          subTextColor={entity.subTextColor}
          textFontSize={entity.textFontSize}
          mobileTextFontSize={entity.mobileTextFontSize}
          subTextFontSize={entity.subTextFontSize}
          mobileSubTextFontSize={entity.mobileSubTextFontSize}
          textFontFamily={entity.textFontFamily}
          textFontWeight={entity.textFontWeight}
          textFontStyle={entity.textFontStyle}
          subTextFontFamily={entity.subTextFontFamily}
          subTextFontWeight={entity.subTextFontWeight}
          subTextFontStyle={entity.subTextFontStyle}
          mobileTextFontFamily={entity.mobileTextFontFamily}
          mobileTextFontWeight={entity.mobileTextFontWeight}
          mobileTextFontStyle={entity.mobileTextFontStyle}
          mobileSubTextFontFamily={entity.mobileSubTextFontFamily}
          mobileSubTextFontWeight={entity.mobileSubTextFontWeight}
          mobileSubTextFontStyle={entity.mobileSubTextFontStyle}
          boxShadow={entity.boxShadow}
          mobileBoxShadow={entity.mobileBoxShadow}
          lineHeight={entity.lineHeight}
          mobileLineHeight={entity.mobileLineHeight}
          hover={entity.hover}
          mobileHover={entity.mobileHover}
        />
      )}
    </BaseEntityNew>
  )
}

export function createUpsellAgreeButton(
  parentId: string,
  masterBlockId?: MasterBlockId,
): UpsellButtonInterface {
  return {
    id: generateId(),
    type: EntityTypeEnum.UpsellAgreeButton,
    parentId: parentId,
    masterBlockId: masterBlockId,
    text: '',
    textFontSize: 23,
    subTextFontSize: 16,
    lineHeight: 23,
    textColor: 'rgba(255, 255, 255, 1)',
    subTextColor: 'rgba(255,255,255,0.8)',
    background: 'rgba(23, 169, 67, 1)',
    boxShadow: '0px 1px 5px 0px rgba(0, 0, 0, 0.2)',
    mobileBoxShadow: 'none',
    width: 'auto',
    mobileWidth: '100%',
    alignSelf: 'center',
    border: {
      width: 3,
      style: 'solid',
      radius: {
        borderTopLeftRadius: 3,
        borderTopRightRadius: 3,
        borderBottomRightRadius: 3,
        borderBottomLeftRadius: 3,
      },
      color: 'rgba(22, 144, 59, 1)',
      type: borderTypeKeys.bottomOnly,
    },
    margin: {
      marginTop: 10,
      marginRight: 0,
      marginBottom: 0,
      marginLeft: 0,
    },
    mobileMargin: {
      marginTop: 0,
      marginRight: 0,
      marginBottom: 0,
      marginLeft: 0,
    },
    padding: {
      paddingTop: 15,
      paddingRight: 92,
      paddingBottom: 15,
      paddingLeft: 92,
    },
    mobilePadding: {
      paddingTop: 14,
      paddingRight: 5,
      paddingBottom: 14,
      paddingLeft: 5,
    },
    appearance: {
      desktop: true,
      mobile: true,
    },
    htmlAttrId: generateHtmlAttrId(EntityTypeEnum.UpsellAgreeButton),
    childIds: [],
  }
}

export function createUpsellDisagreeButton(
  parentId: string,
  masterBlockId?: MasterBlockId,
): UpsellButtonInterface {
  return {
    id: generateId(),
    type: EntityTypeEnum.UpsellDisagreeButton,
    parentId: parentId,
    masterBlockId: masterBlockId,
    text: '',
    textFontSize: 20,
    lineHeight: 23,
    subTextFontSize: 14,
    textColor: 'rgba(74, 74, 74, 1)',
    subTextColor: 'rgba(141,139,139,1)',
    background: 'rgba(229, 229, 229, 1)',
    boxShadow: '0px 1px 5px 0px rgba(0, 0, 0, 0.2)',
    mobileBoxShadow: 'none',
    width: 'auto',
    mobileWidth: '100%',
    alignSelf: 'center',
    border: {
      width: 3,
      style: 'solid',
      radius: {
        borderTopLeftRadius: 3,
        borderTopRightRadius: 3,
        borderBottomRightRadius: 3,
        borderBottomLeftRadius: 3,
      },
      color: 'rgba(198, 198, 198, 1)',
      type: borderTypeKeys.bottomOnly,
    },
    margin: {
      marginTop: 10,
      marginRight: 0,
      marginBottom: 10,
      marginLeft: 0,
    },
    mobileMargin: {
      marginTop: 5,
      marginRight: 0,
      marginBottom: 5,
      marginLeft: 0,
    },
    padding: {
      paddingTop: 15,
      paddingRight: 86,
      paddingBottom: 15,
      paddingLeft: 86,
    },
    mobilePadding: {
      paddingTop: 5,
      paddingRight: 5,
      paddingBottom: 5,
      paddingLeft: 5,
    },
    appearance: {
      desktop: true,
      mobile: true,
    },
    htmlAttrId: generateHtmlAttrId(EntityTypeEnum.UpsellDisagreeButton),
    childIds: [],
  }
}

export default UpsellButton
