import styled from 'styled-components'

const ActionsBoxItemUi = styled.div`
  padding: 6px 8.2px;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  color: #fff;
  font-family: var(--font-family);
  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
  &:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
  }
`

export default ActionsBoxItemUi
