import styled from 'styled-components'

const DialogFooter = styled.div`
  padding: 0 36px;
  background-color: #f2f4f7;
  border-top: 1px solid rgba(0, 0, 0, 0.01);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 83px;
  width: 100%;
`
export default DialogFooter
