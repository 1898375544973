import React from 'react'
import { Translation } from 'react-i18next'
import ErrorUi from './ui/ErrorUi'

const Error = () => (
  <ErrorUi>
    <Translation ns={['client']}>
      {t => (
        <React.Fragment>
          <p>{t('core.error.title')}</p>
          <sup>{t('core.error.description')}</sup>
        </React.Fragment>
      )}
    </Translation>
  </ErrorUi>
)

export default Error
