import React from 'react'
import { useTranslation } from 'react-i18next'
import CurrencyBadgeUi from 'common/components/entities/OfferPriceNew/ui/CurrencyBadgeUi'
import RadioInputUi from 'common/components/entities/ShippingFees/ui/RadioInputUi'
import ShippingConditionLabel from 'common/components/entities/ShippingFees/ui/ShippingConditionLabel'
import ShippingItem from 'common/components/entities/ShippingFees/ui/ShippingItem'
import ShippingItemDescription from 'common/components/entities/ShippingFees/ui/ShippingItemDescription'
import ShippingItemInfo from 'common/components/entities/ShippingFees/ui/ShippingItemInfo'
import ShippingItemName from 'common/components/entities/ShippingFees/ui/ShippingItemName'
import ShippingItemPrice from 'common/components/entities/ShippingFees/ui/ShippingItemPrice'
import { getConditionLabel } from 'common/components/entities/ShippingFees/utils'
import { SelectedShipping } from 'common/types/entities/ShippingFeesInterface'
import {
  ShippingCondition as ShippingConditionType,
  ShippingRule,
  ShippingService,
} from 'common/types/shippingType'
import { centsToEuro, getReadablePrice } from 'common/utils/priceCalculator'

export interface ShippingConditionProps {
  onChange: (selectedShipping: SelectedShipping) => void
  service: ShippingService
  rule: ShippingRule
  condition: ShippingConditionType
  language: string
  isSelected: boolean
  isLoading?: boolean
}

const ShippingCondition = ({
  service,
  rule,
  condition,
  onChange,
  language,
  isLoading = false,
  isSelected,
}: ShippingConditionProps) => {
  const { t } = useTranslation('common')

  return (
    <ShippingItem
      htmlFor={`conditionition-${condition.id}`}
      isActive={isSelected}
      isLoading={isLoading}
    >
      <ShippingItemInfo>
        <RadioInputUi
          disabled={isLoading}
          id={`conditionition-${condition.id}`}
          onChange={() =>
            onChange({
              ruleId: rule.id,
              currency: rule.currency,
              conditionId: condition.id,
              price: condition.price,
            })
          }
          checked={isSelected}
          type="radio"
        />
        <ShippingItemDescription>
          <ShippingItemName>
            <span>{service.name}</span>
            <span>{rule.name}</span>
          </ShippingItemName>
          <ShippingConditionLabel>
            {getConditionLabel(condition, rule, t, language)}
          </ShippingConditionLabel>
        </ShippingItemDescription>
      </ShippingItemInfo>
      <ShippingItemPrice>
        <CurrencyBadgeUi>{rule.currency}</CurrencyBadgeUi>
        <strong>
          {getReadablePrice(
            centsToEuro(condition.price),
            rule.currency,
            language,
          )}
        </strong>
      </ShippingItemPrice>
    </ShippingItem>
  )
}

export default ShippingCondition
