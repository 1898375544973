import styled, { css } from 'styled-components'
import { BorderType } from 'common/types/styleTypes'
import {
  borderCss,
  boxShadowCss,
  mobileBorderCss,
  mobileBoxShadowCss,
} from 'common/utils/styleUtilsNew'

type VideoPlaceholderUiProps = {
  border?: Partial<BorderType>
  mobileBorder?: Partial<BorderType>
  boxShadow?: string
  mobileBoxShadow?: string
}

const VideoPlaceholder = styled.div<VideoPlaceholderUiProps>`
  position: relative;
  padding-bottom: 56.25% !important;
  padding-top: 25px !important;
  overflow: hidden;
  ${borderCss};
  ${boxShadowCss};
  ${p => p.theme.phone} {
    ${mobileBorderCss};
    ${mobileBoxShadowCss};
  }
  ${p =>
    p.theme.isEditorMobileMode &&
    css`
      ${mobileBorderCss};
      ${mobileBoxShadowCss};
    `}
`

export default VideoPlaceholder
