import styled, { css } from 'styled-components'

const VideoUi = styled.div.attrs(p => ({
  id: p.attrId,
}))`
  'video::-internal-media-controls-download-button' {
    display: none;
  }
  'video::-webkit-media-controls-enclosure' {
    overflow: hidden;
  }
  'video::-webkit-media-controls-panel' {
    width: calc(100% + 30px);
  }
  pointer-events: none;
  position: relative;
  padding-top: 56.25%;
  .react-player {
    position: absolute;
    top: 0;
    left: 0;
    border-top-left-radius: ${p => p.styles.borderTopLeftRadius};
    border-top-right-radius: ${p => p.styles.borderTopRightRadius};
    border-bottom-left-radius: ${p => p.styles.borderBottomRightRadius};
    border-bottom-right-radius: ${p => p.styles.borderBottomLeftRadius};
    overflow: hidden;
  }
  ${props =>
    props.mobile === false &&
    css`
      ${p => p.theme.phone} {
        display: none;
      }
    `}
  ${props =>
    props.desktop === false &&
    css`
      ${p => p.theme.desktop} {
        display: none;
      }
    `}
  ${p => p.theme.phone} {
    ${({ mobileStyles = {} }) => mobileStyles}
  }
  ${({ styles = {} }) => styles}
`

export default VideoUi
