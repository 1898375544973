import styled from 'styled-components'

const PaymentMethodDetailsUi = styled.div`
  padding: 20px 55px;
  display: ${p => (p.show ? 'block' : 'none')};
  height: ${p => (p.show ? 'auto' : 0)};
  transition: height ease 250ms;
  border-bottom: 1px solid var(--payment-method-border-color);
  &:last-child {
    border-bottom: none;
  }
  ${p => p.theme.phone} {
    padding: 20px;
  }
`

export default PaymentMethodDetailsUi
