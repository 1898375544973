import styled from 'styled-components'

interface AlignControlsContainerUiInterface {
  top: number
  left: number
  show: boolean
}

const AlignControlsContainerUi = styled.div<AlignControlsContainerUiInterface>`
  position: absolute;
  display: flex;
  flex-direction: row;
  top: ${({ top }) => `${top}px`};
  left: ${({ left }) => `${left}px`};
  visibility: ${({ show }) => (show ? 'visible' : 'hidden')};
  z-index: 11;
  flex-basis: 10px;
  font-size: 14px;
  background-color: #149efc;
  height: 26px;
`

export default AlignControlsContainerUi
