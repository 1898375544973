import styled from 'styled-components'

const FileUploadContainerUi = styled.div`
  height: 400px;
  border: 1px dashed #459df5;
  border-radius: 3px;
  display: ${({ show }) => (show ? 'flex' : 'none')};
  flex-direction: column;
`

export default FileUploadContainerUi
