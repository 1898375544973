import { PaymentButtonInterface } from 'common/types/entities/PaymentButtonInterface'
import { buyMainOffer } from 'publisher/api/paypalRestApi'
import { pageSelectors } from 'publisher/store'
import { OldEntityInterface } from '../../../common/types/entities/OldEntityInterface'
import usePage from '../usePage'
import usePaymentSubmit from './usePaymentSubmit'

const usePaypalRestPaymentHandler = (
  entity: OldEntityInterface | PaymentButtonInterface,
) => {
  const { errors, isLoading, submit } = usePaymentSubmit(entity)
  const pageId = usePage(pageSelectors.getPageId)

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    submit(async body => {
      const { data } = await buyMainOffer(pageId, {
        payment_form: body,
      })

      if (data.redirect) {
        window.location.assign(data.redirect)
        return
      }
    })
  }

  return { errors, isLoading, handleSubmit }
}

export default usePaypalRestPaymentHandler
