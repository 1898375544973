import Dialog from './Dialog'
import DialogHeader from './DialogHeader'
import DialogBody from './DialogBody'
import DialogFooter from './DialogFooter'
import DialogCancel from './DialogCancel'
import DialogSubmit from './DialogSubmit'

export {
  DialogHeader,
  DialogBody,
  DialogFooter,
  Dialog,
  DialogCancel,
  DialogSubmit,
}
