import React from 'react'
import { useTranslation } from 'react-i18next'
import CommonBlogPostDate from 'common/components/entities/BlogPostDate/BlogPostDateNew'
import EntityTypeEnum from 'common/enums/entityTypeEnum'
import { BlogPostDateInterface } from 'common/types/entities/BlogPostDateInterface'
import { MasterBlockId } from 'common/types/entities/EntityInterface'
import Badge from 'client/components/core/Badge'
import BaseEntityNew from 'client/components/core/BaseEntity/BaseEntityNew'
import { typedPageSelectors, usePage } from 'client/store'
import {
  generateHtmlAttrId,
  generateId,
} from 'client/utils/createStructureUtils'
import { EntityProps } from '../../../types'

const date = new Date()

function BlogPostDateNew({
  entity,
  isMoveDownAllowed,
  isMoveUpAllowed,
}: EntityProps<BlogPostDateInterface>) {
  const locale = usePage(typedPageSelectors.getPageLocale)
  const { t } = useTranslation()

  return (
    <BaseEntityNew
      entity={entity}
      isMoveUpAllowed={isMoveUpAllowed}
      isMoveDownAllowed={isMoveDownAllowed}
    >
      <>
        <CommonBlogPostDate
          date={date}
          locale={locale}
          attrId={entity.htmlAttrId}
          fontSize={entity.fontSize}
          fontFamily={entity.fontFamily}
          fontStyle={entity.fontStyle}
          fontWeight={entity.fontWeight}
          mobileFontSize={entity.mobileFontSize}
          mobileFontFamily={entity.mobileFontFamily}
          mobileFontStyle={entity.mobileFontStyle}
          mobileFontWeight={entity.mobileFontWeight}
          lineHeight={entity.lineHeight}
          mobileLineHeight={entity.mobileLineHeight}
          color={entity.color}
          mobileColor={entity.mobileColor}
          alignSelf={entity.alignSelf}
          mobileAlignSelf={entity.mobileAlignSelf}
        />
        <Badge wide>
          {t('entities.blog_post_date.warnings.do_not_remove')}
        </Badge>
      </>
    </BaseEntityNew>
  )
}

export function createBlogPostDate(
  parentId: string,
  masterBlockId?: MasterBlockId,
) {
  const blogPostDate: BlogPostDateInterface = {
    id: generateId(),
    type: EntityTypeEnum.BlogPostDate,
    parentId: parentId,
    fontSize: 16,
    color: 'rgba(0, 0, 0, 1)',
    mobileFontSize: 16,
    alignSelf: 'left',
    margin: {
      marginTop: 0,
      marginRight: 0,
      marginBottom: 0,
      marginLeft: 0,
    },
    mobileMargin: {
      marginTop: 0,
      marginRight: 0,
      marginBottom: 0,
      marginLeft: 0,
    },
    appearance: {
      desktop: true,
      mobile: true,
    },
    htmlAttrId: generateHtmlAttrId(EntityTypeEnum.BlogPostDate),
  }

  if (masterBlockId) {
    blogPostDate.masterBlockId = masterBlockId
  }

  return blogPostDate
}

export default BlogPostDateNew
