import React from 'react'
import { useTranslation } from 'react-i18next'
import CurrencyBadgeUi from 'common/components/entities/OfferPriceNew/ui/CurrencyBadgeUi'
import { Coupon as CouponType } from 'common/types/CouponType'
import { PricePlan as PricePlanType } from 'common/types/OfferInterface'
import { ProductInterface } from 'common/types/entities/ProductInterface'
import { OfferPricing } from 'common/types/pricingTypes'
import { centsToEuro, getReadablePrice } from 'common/utils/priceCalculator'
import DividerUi from '../ui/DividerUi'
import PricePlanAmountUi from '../ui/PricePlanAmountUi'
import PricePlanItemUi from '../ui/PricePlanItemUi'
import PricePlanUi from '../ui/PricePlanUi'
import PricePlanNameUi from '../ui/PricePlanNameUi'

interface ProductBumpTotalProps {
  activePricePlan?: PricePlanType
  quantity?: number
  coupon?: CouponType
  productBump: ProductInterface
  activePlanPricing?: OfferPricing
  mainOfferPricing?: OfferPricing
  bumpOfferPricing?: OfferPricing
  isFinalSummary?: boolean
  locale: string
  amountFontFamily?: string
  mobileAmountFontFamily?: string
  amountFontSize: number
  mobileAmountFontSize?: number
  amountFontStyle?: string
  mobileAmountFontStyle?: string
  amountFontWeight?: string
  mobileAmountFontWeight?: string
  amountLineHeight: number
  mobileAmountLineHeight?: number
  amountColor: string
  mobileAmountColor?: string
  fontFamily?: string
  mobileFontFamily?: string
  fontSize: number
  mobileFontSize?: number
  fontStyle?: string
  mobileFontStyle?: string
  fontWeight?: string
  mobileFontWeight?: string
  lineHeight: number
  mobileLineHeight?: number
  color: string
  mobileColor?: string
}

const ProductBumpTotal = ({
  activePricePlan,
  activePlanPricing,
  mainOfferPricing,
  bumpOfferPricing,
  productBump,
  isFinalSummary = true,
  locale,
  amountFontSize,
  mobileAmountFontSize,
  amountFontStyle,
  mobileAmountFontStyle,
  amountFontWeight,
  mobileAmountFontWeight,
  amountFontFamily,
  mobileAmountFontFamily,
  amountLineHeight,
  mobileAmountLineHeight,
  amountColor,
  mobileAmountColor,
  fontSize,
  mobileFontSize,
  fontStyle,
  mobileFontStyle,
  fontWeight,
  mobileFontWeight,
  fontFamily,
  mobileFontFamily,
  lineHeight,
  mobileLineHeight,
  color,
  mobileColor,
}: ProductBumpTotalProps) => {
  const { t } = useTranslation(['common', 'publisher'])

  return (
    <>
      <DividerUi />
      <PricePlanUi>
        <PricePlanItemUi skipLeftOffset={!activePricePlan}>
          <PricePlanNameUi
            fontSize={fontSize}
            mobileFontSize={mobileFontSize}
            fontStyle={fontStyle}
            mobileFontStyle={mobileFontStyle}
            fontWeight={fontWeight}
            mobileFontWeight={mobileFontWeight}
            fontFamily={fontFamily}
            mobileFontFamily={mobileFontFamily}
            lineHeight={lineHeight}
            mobileLineHeight={mobileLineHeight}
            color={color}
            mobileColor={mobileColor}
            data-test-id={`offer-price-product-bump-total-${productBump.name}`}
          >
            {isFinalSummary
              ? t(
                  'components.offer_price.one_shots_summary.total_amount_with_tax',
                )
              : t(
                  'components.offer_price.one_shots_summary.subtotal_amount_with_tax',
                )}
          </PricePlanNameUi>
          {activePricePlan ? (
            <PricePlanAmountUi
              fontSize={amountFontSize}
              mobileFontSize={mobileAmountFontSize}
              fontStyle={amountFontStyle}
              mobileFontStyle={mobileAmountFontStyle}
              fontWeight={amountFontWeight}
              mobileFontWeight={mobileAmountFontWeight}
              fontFamily={amountFontFamily}
              mobileFontFamily={mobileAmountFontFamily}
              lineHeight={amountLineHeight}
              mobileLineHeight={mobileAmountLineHeight}
              color={amountColor}
              mobileColor={mobileAmountColor}
            >
              <CurrencyBadgeUi
                color={amountColor}
                mobileColor={mobileAmountColor}
              >
                {activePricePlan.currency}
              </CurrencyBadgeUi>
              {getReadablePrice(
                centsToEuro(activePlanPricing?.grossAmount ?? 0) +
                  centsToEuro(bumpOfferPricing?.grossAmount ?? 0),
                activePricePlan.currency,
                locale,
              )}
            </PricePlanAmountUi>
          ) : (
            <PricePlanAmountUi
              fontSize={amountFontSize}
              mobileFontSize={mobileAmountFontSize}
              fontStyle={amountFontStyle}
              mobileFontStyle={mobileAmountFontStyle}
              fontWeight={amountFontWeight}
              mobileFontWeight={mobileAmountFontWeight}
              fontFamily={amountFontFamily}
              mobileFontFamily={mobileAmountFontFamily}
              lineHeight={amountLineHeight}
              mobileLineHeight={mobileAmountLineHeight}
              color={amountColor}
              mobileColor={mobileAmountColor}
            >
              <CurrencyBadgeUi
                color={amountColor}
                mobileColor={mobileAmountColor}
              >
                {productBump.currency}
              </CurrencyBadgeUi>
              {getReadablePrice(
                centsToEuro(mainOfferPricing?.grossAmount ?? 0) +
                  centsToEuro(bumpOfferPricing?.grossAmount ?? 0),
                productBump.currency,
                locale,
              )}
            </PricePlanAmountUi>
          )}
        </PricePlanItemUi>
      </PricePlanUi>
    </>
  )
}

export default ProductBumpTotal
