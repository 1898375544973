import styled, { css } from 'styled-components'

type MenuButtonUiProps = {
  active?: boolean
  marginLeft?: string
}

const MenuButtonUi = styled.button<MenuButtonUiProps>`
  margin: 2px;
  padding: 5px 8px;
  background-color: transparent;
  font-family: var(--font-family);
  color: #fff;
  font-size: 14px;
  border: none;
  cursor: pointer;
  outline: none;
  height: 25px;
  min-width: 25px;
  display: inline-flex;
  align-items: center;
  ${p =>
    p.marginLeft &&
    css`
      margin-left: ${p.marginLeft};
    `};
  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
  &:first-of-type {
    border-top-left-radius: 5px;
  }
  ${props =>
    props.active &&
    css`
      background-color: rgba(0, 0, 0, 0.1);
      &:after {
        height: 3px;
      }
    `}
`

export default MenuButtonUi
