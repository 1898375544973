import styled from 'styled-components'

export const FileUploadDropZoneUi = styled.div`
  justify-content: center;
  align-items: center;
  flex-direction: column;
  display: flex;
  flex-basis: 360px;
  width: 100%;
  transition: background-color 0.2s linear;
  background-color: ${({ canDrop }) => (canDrop ? '#DEECF8' : 'transparent')};
`

export default FileUploadDropZoneUi
