import React from 'react'
import PropTypes from 'prop-types'
import StyleControls from './StyleControls'

const INLINE_STYLES = [
  { label: '<i class="fa-solid fa-bold" />', style: 'BOLD' },
  { label: '<i class="fa-regular fa-italic" />', style: 'ITALIC' },
  { label: '<i class="fa-regular fa-underline" />', style: 'UNDERLINE' },
  { label: '<i class="fa-regular fa-strikethrough" />', style: 'STRIKETHROUGH' },
]

const InlineStyleControls = ({ currentStyle, onToggle }) => (
  <StyleControls
    checkActive={style => currentStyle.has(style)}
    controls={INLINE_STYLES}
    onToggle={onToggle}
  />
)

InlineStyleControls.propTypes = {
  currentStyle: PropTypes.objectOf(PropTypes.any).isRequired,
  onToggle: PropTypes.func.isRequired,
}

export default InlineStyleControls
