import { ContentBoxInterface } from 'common/types/entities/ContentBoxInterface'

export const getContentBoxFontProperties = (entity: ContentBoxInterface) => {
  const fontProperties = entity.header.fontFamily
    ? [
        {
          fontFamily: entity.header.fontFamily,
          fontStyle: entity.header.fontStyle,
          fontWeight: entity.header.fontWeight,
        },
      ]
    : []

  if (entity.header.mobileFontFamily) {
    fontProperties.push({
      fontFamily: entity.header.mobileFontFamily,
      fontStyle: entity.header.mobileFontStyle,
      fontWeight: entity.header.mobileFontWeight,
    })
  }

  return fontProperties
}

export function getContentBoxCustomFontProperties(entity: ContentBoxInterface) {
  return entity.header.fontFamily
    ? [
        {
          fontFamily: entity.header.fontFamily,
          fontFileId: entity.header.fontFileId,
        },
      ]
    : []
}
