import { InternalError, NetworkError } from 'common/errors'
import * as fileApi from 'client/api/fileApi'
import {
  UPLOAD_FILE,
  FETCH_USER_FILES,
  REMOVE_FILE,
  SUCCESS,
  SET_IS_ALL_FILES_FETCHED,
  ADD_FILE_TO_PAGE_FILES,
} from '../actionTypes'
import {
  FETCH_SEARCH_USER_FILES,
  FETCH_COMMON_FILES,
} from '../store/files/filesActionTypes'

export const uploadFileSuccess = file => ({
  type: UPLOAD_FILE + SUCCESS,
  payload: file,
})

export const removeFile = fileId => async dispatch => {
  try {
    await fileApi.removeFile(fileId)
    dispatch({
      type: REMOVE_FILE,
      payload: Number(fileId),
    })
  } catch (e) {
    if (e instanceof NetworkError) {
      // do nothing
    } else if (e instanceof InternalError) {
      window.Rollbar.error(`Remove file ${fileId} failed`, e)
    }
  }
}

export const fetchUserFiles =
  (type, lastId = null, limit = 30, searchValue = '', cancelToken = '') =>
  async dispatch => {
    const { data } = await fileApi.fetchUserFiles(
      type,
      lastId,
      limit,
      searchValue,
      cancelToken,
    )
    if (searchValue) {
      dispatch({
        type: FETCH_SEARCH_USER_FILES,
        payload: data.files,
      })
    } else {
      dispatch({
        type: FETCH_USER_FILES,
        payload: data.files,
      })
    }
  }

export const fetchCommonFiles =
  (type, lastId = null, limit = 30, searchValue = '', cancelToken = '') =>
  async dispatch => {
    const { data } = await fileApi.fetchCommonFiles(
      type,
      lastId,
      limit,
      searchValue,
      cancelToken,
    )
    dispatch({
      type: FETCH_COMMON_FILES,
      payload: data.files,
    })
  }

export const setIsAllFilesFetched = value => async dispatch => {
  dispatch({
    type: SET_IS_ALL_FILES_FETCHED,
    payload: value,
  })
}

export const addFileToPageFiles = file => ({
  type: ADD_FILE_TO_PAGE_FILES,
  payload: file,
})
