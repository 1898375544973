import { useEffect, useRef, useState } from 'react'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'
import { MenuPlacement } from './ui/MenuUi'

const NARROW_WIDTH = 203
export const DEFAULT_WIDTH = 385
const WIDE_WIDTH = 431

const BORDERS_WIDTH = 4

// defined in base.css (--layout-header-height)
const LAYOUT_HEADER_HEIGHT = 44

function useExtendedMenu(
  extended: boolean,
) {
  const { isMobile } = useDeviceModeContext()
  const ref = useRef<HTMLDivElement>(null)
  const [width, setWidth] = useState(
    extended ? WIDE_WIDTH : NARROW_WIDTH,
  )
  const [placement, setPlacement] = useState<MenuPlacement>({direction: 'top', offset: -30})

  // offset relative to viewport
  const menuTopOffset = ref.current?.getBoundingClientRect().top

  useEffect(() => {
    if (ref.current && menuTopOffset) {
      setPlacement({
        // header hides menu => show the options in the bottom
        direction: menuTopOffset < LAYOUT_HEADER_HEIGHT ? 'bottom' : 'top',
        offset: -ref.current.offsetHeight,
      })
    }
  }, [menuTopOffset])

  useEffect(() => {
    if (!ref.current || !ref.current.parentElement) {
      return
    }

    const parentWidth = ref.current.parentElement.offsetWidth

    setWidth(parentWidth + BORDERS_WIDTH)
  }, [isMobile])

  return { width, ref, placement }
}
export default useExtendedMenu
