import React, { memo } from 'react'
import CommonContentBox from 'common/components/entities/ContentBox'
import { ContentBoxInterface } from 'common/types/entities/ContentBoxInterface'
import BaseEntityNew from 'client/components/core/BaseEntity/BaseEntityNew'
import ChildrenEntities from 'client/components/core/ChildrenEntities'
import { EntityProps } from 'client/types'

function ContentBox({
  entity,
  isMoveDownAllowed,
  isMoveUpAllowed,
}: EntityProps<ContentBoxInterface>) {
  return (
    <BaseEntityNew
      entity={entity}
      isMoveUpAllowed={isMoveUpAllowed}
      isMoveDownAllowed={isMoveDownAllowed}
    >
      <CommonContentBox entity={entity}>
        <ChildrenEntities
          id={entity.id}
          childIds={entity.childIds}
          type={entity.type}
          isReadOnly={entity.isReadOnly}
        />
      </CommonContentBox>
    </BaseEntityNew>
  )
}
export default memo(ContentBox)
