import { CustomLoginModeEnum } from '../../pages/custom-login/entities/CustomLogin'
import { CHANGE_CUSTOM_LOGIN_MODE } from './customLogicActionTypes'
import { CustomLoginActions } from './customLoginActions'

export interface CustomLoginState {
  customLoginMode: CustomLoginModeEnum
}

export default function customLoginReducer(
  state: CustomLoginState = { customLoginMode: CustomLoginModeEnum.Login },
  action: CustomLoginActions,
) {
  switch (action.type) {
    case CHANGE_CUSTOM_LOGIN_MODE:
      return {
        ...state,
        customLoginMode: action.payload,
      }
    default:
      return state
  }
}

function getCustomLoginMode(state: CustomLoginState) {
  return state.customLoginMode
}

export const selectors = {
  getCustomLoginMode,
}
