import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import CommonBody from 'common/components/entities/BodyOld'
import * as types from 'common/types'
import Badge from 'client/components/core/Badge'
import BaseEntity from 'client/components/core/BaseEntity'
import ChildrenEntities from '../../core/ChildrenEntities'
import BlogContentPlaceholderUi from './ui/BlogContentPlaceholderUi'

function BlogContentPlaceholder({
  entity,
  isMoveDownAllowed,
  isMoveUpAllowed,
}) {
  const { t } = useTranslation()

  const renderChildren = () => {
    return entity.childIds ? (
      <ChildrenEntities
        id={entity.id}
        isReadOnly={entity.isReadOnly}
        childIds={entity.childIds}
        type={entity.type}
      />
    ) : (
      <React.Fragment>
        <BlogContentPlaceholderUi>
          {t('entities.blog_content_placeholder.placeholder')}
        </BlogContentPlaceholderUi>
        <Badge wide>
          {t('entities.blog_content_placeholder.warnings.do_not_remove')}
        </Badge>
      </React.Fragment>
    )
  }

  return (
    <BaseEntity
      entity={entity}
      isMoveUpAllowed={isMoveUpAllowed}
      isMoveDownAllowed={isMoveDownAllowed}
    >
      <CommonBody
        styles={entity.styles}
        mobileStyles={entity.mobileStyles}
        options={entity.options}
        mobileOptions={entity.mobileOptions}
      >
        {renderChildren()}
      </CommonBody>
    </BaseEntity>
  )
}

BlogContentPlaceholder.propTypes = {
  entity: types.entity.isRequired,
  isMoveUpAllowed: PropTypes.bool.isRequired,
  isMoveDownAllowed: PropTypes.bool.isRequired,
}

export default BlogContentPlaceholder
