import { CardPayment, initMercadoPago } from '@mercadopago/sdk-react'
import {
  ICardPaymentBrickPayer,
  ICardPaymentFormData,
} from '@mercadopago/sdk-react/bricks/cardPayment/type'
import React, { memo, useEffect } from 'react'
import { usePage, usePayment } from 'publisher/store'
import pageSelectors from 'publisher/store/page/pageSelectors'
import { getMercadoPagoPublicKey } from 'publisher/store/payment/paymentSelectors'
import { SupportedIdentificationTypeEnum } from '../../enums/SupportedIdentificationTypeEnum'

function filterSupportedIdentificationType() {
  const cardPaymentBrickSelect = document.querySelector(
    '#cardPaymentBrick_container form select',
  )
  if (cardPaymentBrickSelect) {
    const options = cardPaymentBrickSelect.getElementsByTagName('option')
    for (let i = 0; i < options.length; i++) {
      const optionValue = options[i].value

      if (
        !Object.values(SupportedIdentificationTypeEnum).includes(
          optionValue as SupportedIdentificationTypeEnum,
        )
      ) {
        options[i].style.display = 'none'
      }
    }
  }
}

export interface PaymentCardPropsInterface {
  amount: number
  processPayment: (
    cardFormData: ICardPaymentFormData<ICardPaymentBrickPayer>,
  ) => Promise<void>
}

function PaymentCard({ amount, processPayment }: PaymentCardPropsInterface) {
  const pagoPublicKey = usePayment(getMercadoPagoPublicKey)
  const pageLocale = usePage(pageSelectors.getLocale)

  useEffect(() => {
    if (pagoPublicKey) {
      initMercadoPago(pagoPublicKey, {
        // @ts-ignore
        locale: pageLocale,
      })
    }
  }, [pagoPublicKey, pageLocale])

  return (
    <CardPayment
      initialization={{ amount }}
      onSubmit={processPayment}
      onReady={filterSupportedIdentificationType}
    />
  )
}

export default memo(PaymentCard)
