import { useCallback, useRef } from 'react'

export default function useDropRef(connectDrop, onDragOver) {
  const ref = useRef(null)
  const setRef = useCallback(
    node => {
      if (ref.current) {
        // Make sure to cleanup any events/references added to the last instance
        ref.current.removeEventListener('dragover', onDragOver, false)
      }

      if (node) {
        // Check if a node is actually passed. Otherwise node would be null.
        // You can now do what you need to, addEventListeners, measure, etc.
        connectDrop(node)
        node.addEventListener('dragover', onDragOver, false)
      }

      // Save a reference to the node
      ref.current = node
    },
    [connectDrop, onDragOver],
  )

  return [setRef]
}
