import PropTypes from 'prop-types'
import React, { useCallback, memo } from 'react'
import { useSelector } from 'react-redux'
import CommonImage from 'common/components/entities/Image'
import { splitMarginsAndRestStyles } from 'common/utils/styleUtils'
import BaseEntity from 'client/components/core/BaseEntity'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'
import { getFileById } from 'client/reducers/filesReducer'
import { getBlockFile } from '../../../reducers/blocksReducer'

const demoImageSrc = `${process.env.EDITOR_ASSETS_HOST}/assets/images/image_placeholder.png`

function ImageNew({ entity, isMoveDownAllowed, isMoveUpAllowed }) {
  const { isMobile } = useDeviceModeContext()
  let file = useSelector(state => {
    return (
      getFileById(state.files, entity.options.srcFileId) ||
      getBlockFile(state.blocks, entity.options.srcFileId)
    )
  })
  const filePath = file && file.path
  const { width, ...restStyles } = entity.styles
  const { width: mobileWidth, ...restMobileStyles } = entity.mobileStyles
  let styles = isMobile ? restStyles : entity.styles
  let mobileStyles = isMobile ? restMobileStyles : entity.mobileStyles
  const [, imageStylesWithoutMargins] = splitMarginsAndRestStyles(entity.styles) //margins in BaseEntity
  const [, imageMobileStylesWithoutMargins] = splitMarginsAndRestStyles(
    entity.mobileStyles,
  ) //margins in BaseEntity

  const setRef = useCallback(node => {
    if (node !== null) {
      // we need to emulate mobile image width
      // width of image could be > 355px and in mobile emulation possible overflow
      const maxWidth =
        node.parentNode &&
        node.parentNode.parentNode &&
        node.parentNode.parentNode.parentNode &&
        node.parentNode.parentNode.parentNode.getBoundingClientRect().width

      if (maxWidth) {
        styles = parseInt(width, 0) > maxWidth ? restStyles : entity.styles
        mobileStyles =
          parseInt(width, 0) > maxWidth ? restMobileStyles : entity.mobileStyles
      }
    }
  }, [])

  return (
    <BaseEntity
      entity={entity}
      isSaveAllowed={Boolean(filePath)}
      isMoveUpAllowed={isMoveUpAllowed}
      isMoveDownAllowed={isMoveDownAllowed}
      styles={{
        display: 'flex',
        justifyContent: isMobile
          ? entity.mobileStyles.justifyContent || entity.styles.justifyContent
          : entity.styles.justifyContent,
      }}
    >
      {emulatedEntity => (
        <CommonImage
          ref={setRef}
          src={
            filePath || emulatedEntity.styles.backgroundImage || demoImageSrc
          }
          styles={imageStylesWithoutMargins}
          mobileStyles={imageMobileStylesWithoutMargins}
          options={entity.options}
        />
      )}
    </BaseEntity>
  )
}

ImageNew.propTypes = {
  entity: PropTypes.shape({
    id: PropTypes.string.isRequired,
    options: PropTypes.objectOf(PropTypes.any).isRequired,
    styles: PropTypes.objectOf(PropTypes.any).isRequired,
    mobileStyles: PropTypes.objectOf(PropTypes.any).isRequired,
    childIds: PropTypes.arrayOf(PropTypes.string),
    type: PropTypes.string.isRequired,
  }).isRequired,
  isMoveUpAllowed: PropTypes.bool.isRequired,
  isMoveDownAllowed: PropTypes.bool.isRequired,
}

export default memo(ImageNew)
