import styled from 'styled-components'

const ModalContentUi = styled.div`
  min-height: 100px;
  max-height: inherit;
  & form {
    padding: 50px !important;
  }
`

export default ModalContentUi
