import React from 'react'
import CommonRawHtml from 'common/components/entities/RawHtmlNew/RawHtml'
import EntityTypeEnum from 'common/enums/entityTypeEnum'
import { MasterBlockId } from 'common/types/entities/EntityInterface'
import { RawHtmlInterface } from 'common/types/entities/RawHtmlInterface'
import { EntityProps } from '../../../types'
import { generateBaseEntity } from '../../../utils/createStructureUtils'
import BaseEntityNew from '../../core/BaseEntity/BaseEntityNew'
import NewRawHtmlWatermarkUi from './ui/NewRawHtmlWatermarkUi'

function NewRawHtml({
  entity,
  isMoveDownAllowed,
  isMoveUpAllowed,
}: EntityProps<RawHtmlInterface>) {
  return (
    <BaseEntityNew
      entity={entity}
      isMoveDownAllowed={isMoveDownAllowed}
      isMoveUpAllowed={isMoveUpAllowed}
    >
      <NewRawHtmlWatermarkUi data-watermark="HTML">
        <CommonRawHtml
          html={entity.html}
          htmlAttrId={entity.htmlAttrId}
          textAlign={entity.textAlign}
          mobileTextAlign={entity.mobileTextAlign}
        />
      </NewRawHtmlWatermarkUi>
    </BaseEntityNew>
  )
}

export default NewRawHtml

export function createRawHtml(
  parentId: string,
  masterBlockId?: MasterBlockId,
): RawHtmlInterface {
  return {
    ...generateBaseEntity(EntityTypeEnum.RawHtml, parentId, masterBlockId),
    type: EntityTypeEnum.RawHtml,
    textAlign: 'center',
    html: '<h2>systeme.io</h2>',
  }
}
