import PropTypes from 'prop-types'
import React from 'react'
import ProgressBarUi from './ui/ProgressBarUi'

const ProgressBar = ({ loaded = 40, total = 100 }) => {
  const progress = Math.round((loaded * 100) / total)
  return (
    <React.Fragment>
      <ProgressBarUi
        isComplete={progress === 100}
        value={progress}
        max="100"
        data-label={`${progress} % complete`}
      >
        {progress} %
      </ProgressBarUi>
    </React.Fragment>
  )
}

ProgressBar.propTypes = {
  loaded: PropTypes.number,
  total: PropTypes.number,
}

export default ProgressBar
