/* eslint-disable no-nested-ternary */
import React from 'react'
import PropTypes from 'prop-types'
import LimitedSubscription from 'common/components/entities/OfferPrice/LimitedSubscription'
import UnlimitedSubscription from 'common/components/entities/OfferPrice/UnlimitedSubscription'
import OneShot from 'common/components/entities/OfferPrice/OneShot'
import { offerPricePlan } from 'common/types/index'

const PricePlanValue = ({
  plan,
  tax = 0,
  translate,
  language,
  quantity = 1,
  coupon = null,
}) =>
  plan.subscriptionPlan ? (
    plan.isLimited ? (
      <LimitedSubscription
        plan={plan}
        translate={translate}
        language={language}
        tax={tax}
        quantity={quantity}
        coupon={coupon}
      />
    ) : (
      <UnlimitedSubscription
        plan={plan}
        translate={translate}
        language={language}
        tax={tax}
        quantity={quantity}
        coupon={coupon}
      />
    )
  ) : (
    <OneShot
      plan={plan}
      translate={translate}
      language={language}
      tax={tax}
      quantity={quantity}
      coupon={coupon}
    />
  )

PricePlanValue.propTypes = {
  coupon: PropTypes.shape({
    id: PropTypes.number.isRequired,
    discountAmount: PropTypes.string.isRequired,
    discountType: PropTypes.string.isRequired,
  }),
  plan: offerPricePlan.isRequired,
  translate: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired,
  tax: PropTypes.number,
  quantity: PropTypes.number,
}

export default PricePlanValue
