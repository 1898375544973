import styled from 'styled-components'
import { color } from 'client/constants/color'

interface SplitterUiInterface {
  left: number
  show: boolean
}

const SplitterUi = styled.div<SplitterUiInterface>`
  width: 2px;
  position: absolute;
  top: 0;
  left: ${({ left }) => `${left}px`};
  height: 100%;
  cursor: col-resize;
  border: 1px solid ${color.blue};
  z-index: 1;
  visibility: ${({ show }) => (show ? 'visible' : 'hidden')};
  &::after {
    content: '';
    position: absolute;
    top: calc(50% - 10px);
    left: -3px;
    width: 6px;
    height: 20px;
    border-radius: 1px;
    background-color: ${color.blue};
  }
`
export default SplitterUi
