import React from 'react'
import PropTypes from 'prop-types'
import { Trans } from 'react-i18next'
import BlueButton from 'client/components/core/BlueButton'
import PopupEditButtonUi from './ui/PopupEditButtonUi'

const PopupEditButton = ({ clickHandler }) => (
  <PopupEditButtonUi>
    <BlueButton onClick={clickHandler}>
      <span className="fas fa-cog" />{'  '}
      <Trans parent="span">entities.popup.edit_button_label</Trans>
    </BlueButton>
  </PopupEditButtonUi>
)

PopupEditButton.propTypes = {
  clickHandler: PropTypes.func.isRequired,
}

export default PopupEditButton

