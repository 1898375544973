export const faqListIcons = [
  'fas fa-arrow-circle-up',
  'fas fa-chevron-up',
  'fas fa-chevron-circle-up',
  'fas fa-caret-up',
  'fas fa-caret-circle-down',
  'fas fa-angle-up',
  'fad fa-angle-double-down',
  'fas fa-angle-double-down',
  'fas fa-arrow-square-down',
]

export const bulletListIcons = [
  'fas fa-check',
  'fad fa-check',
  'fad fa-check-double',
  'fal fa-check-square',
  'fas fa-check-square',
  'far fa-check-circle',
  'fas fa-circle',
  'fas fa-arrow-circle-right',
  'fas fa-check-circle',
  'far fa-lightbulb',
  'fal fa-lightbulb-on',
  'fal fa-badge-check',
  'far fa-chevron-right',
  'far fa-chevron-right',
  'fad fa-chevron-right',
  'fad fa-chevron-double-right',
  'fas fa-times',
  'fad fa-times',
  'fas fa-times-circle',
]

export enum faqListIconsKeys {
  arrowCircle = 'arrow-circle',
  chevron = 'chevron',
  chevronCircle = 'chevron-circle',
  caret = 'caret',
  caretCircle = 'caret-circle',
  angle = 'angle',
  angleDoubleDuotone = 'angle-double-duotone',
  angleDouble = 'angle-double',
  arrowSquare = 'arrow-square',
}

export const faqListIconsOld = {
  [faqListIconsKeys.arrowCircle]: 'fas fa-arrow-circle-up', ///arrow-circle-up-solid
  [faqListIconsKeys.chevron]: 'fas fa-chevron-up', // chevron-up-solid
  [faqListIconsKeys.chevronCircle]: 'fas fa-chevron-circle-up', //chevron-circle-up-solid
  [faqListIconsKeys.caret]: 'fas fa-caret-up', //caret-up-solid
  [faqListIconsKeys.caretCircle]: 'fas fa-caret-circle-down', //caret-circle-down-solid
  [faqListIconsKeys.angle]: 'fas fa-angle-up', //angle-up-solid
  [faqListIconsKeys.angleDoubleDuotone]: 'fad fa-angle-double-down', //angle-double-down-duotone
  [faqListIconsKeys.angleDouble]: 'fas fa-angle-double-down', //angle-double-down-solid
  [faqListIconsKeys.arrowSquare]: 'fas fa-arrow-square-down', //arrow-square-down-solid
}
export enum bulletListIconsKeys {
  check = 'check',
  checkDuotone = 'check-duotone',
  checkDoubleDuotone = 'check-double-duotone',
  checkSquare = 'check-square',
  checkSquareDark = 'check-square-dark',
  checkCircle = 'check-circle',
  circle = 'circle',
  arrowCircle = 'arrow-circle',
  checkCircleDark = 'check-circle-dark',
  lightbulb = 'lightbulb',
  lightbulbOn = 'lightbulb-on',
  badgeCheck = 'badge-check',
  badgeCheckDark = 'badge-check-dark',
  chevronRight = 'chevron-right',
  chevronRightDuotone = 'chevron-right-duotone',
  chevronDoubleRightDuotone = 'chevron-double-right',
  times = 'times',
  timesDuotone = 'times-duotone',
  timesCircle = 'times-circle',
}
export const bulletListIconsOld = {
  [bulletListIconsKeys.check]: 'fas fa-check', //check-solid
  [bulletListIconsKeys.checkDuotone]: 'fad fa-check',
  [bulletListIconsKeys.checkDoubleDuotone]: 'fad fa-check-double',
  [bulletListIconsKeys.checkSquare]: 'fal fa-check-square', //check-square-light
  [bulletListIconsKeys.checkSquareDark]: 'fas fa-check-square', //check-square-solid
  [bulletListIconsKeys.checkCircle]: 'far fa-check-circle', //check-circle-regular
  [bulletListIconsKeys.circle]: 'fas fa-circle', //circle-solid
  [bulletListIconsKeys.arrowCircle]: 'fas fa-arrow-circle-right', //arrow-circle-right-solid
  [bulletListIconsKeys.checkCircleDark]: 'fas fa-check-circle', //check-circle-solid
  [bulletListIconsKeys.lightbulb]: 'far fa-lightbulb', //lightbulb-regular
  [bulletListIconsKeys.lightbulbOn]: 'fal fa-lightbulb-on', // net
  [bulletListIconsKeys.badgeCheck]: 'fal fa-badge-check', //badge-check-light
  [bulletListIconsKeys.badgeCheckDark]: 'fas fa-badge-check', //badge-check-solid
  [bulletListIconsKeys.chevronRight]: 'far fa-chevron-right', //chevron-right-regular
  [bulletListIconsKeys.chevronRightDuotone]: 'fad fa-chevron-right', //same
  [bulletListIconsKeys.chevronDoubleRightDuotone]:
    'fad fa-chevron-double-right', //chevron-double-right-duotone
  [bulletListIconsKeys.times]: 'fas fa-times', //net
  [bulletListIconsKeys.timesDuotone]: 'fad fa-times', //net
  [bulletListIconsKeys.timesCircle]: 'fas fa-times-circle', //times-circle-solid
}
export const defaultColumnCount = 7

export const defaultColumnWidth = 40

export const defaultRowHeight = 40

export const defaultGridWidth = 315

export const getRowCount = (iconsCount: number, columnCount: number) =>
  Math.ceil(iconsCount / columnCount)

export const getGridHeight = (iconsCount: number, columnCount: number) =>
  Math.ceil(iconsCount / columnCount) * 45

export const getIconIndex = (
  columnIndex: number,
  rowIndex: number,
  columnCount: number,
) => columnIndex + rowIndex * columnCount
