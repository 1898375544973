import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useState,
} from 'react'

// export function createCtx<A>(defaultValue: A) {
//   type UpdateType = React.Dispatch<React.SetStateAction<typeof defaultValue>>
//   const defaultUpdate: UpdateType = () => defaultValue
//   const ctx = React.createContext({
//     state: defaultValue,
//     update: defaultUpdate,
//   })
//   function Provider(props: React.PropsWithChildren<{}>) {
//     const [state, update] = React.useState(defaultValue)
//     return <ctx.Provider value={{ state, update }} {...props} />
//   }
//   return [ctx, Provider] as const // alternatively, [typeof ctx, typeof Provider]
// }
//
export enum DeviceModeEnum {
  Mobile = 'Mobile',
  Desktop = 'Desktop',
}

type UpdateDeviceModeType = React.Dispatch<React.SetStateAction<DeviceModeEnum>>
// const setDeviceModeDefault: UpdateDeviceModeType = () => typeof DeviceModeEnum

type DeviceModeContextType = {
  deviceMode: DeviceModeEnum
  isDesktop: boolean
  isMobile: boolean
  setDeviceMode: (deviceMode: DeviceModeEnum) => void
}

export const DeviceModeContext = createContext<DeviceModeContextType>({
  deviceMode: DeviceModeEnum.Desktop,
  isDesktop: true,
  isMobile: false,
  setDeviceMode: (deviceMode: DeviceModeEnum) =>
    console.warn(`no device mode provided ${deviceMode}`),
})

export const useDeviceModeContext = () => useContext(DeviceModeContext)

export function DeviceModeProvider(props: PropsWithChildren) {
  const [deviceMode, setDeviceMode] = useState<DeviceModeEnum>(
    DeviceModeEnum.Desktop,
  )

  return (
    <DeviceModeContext.Provider
      value={{
        deviceMode,
        isDesktop: deviceMode === DeviceModeEnum.Desktop,
        isMobile: deviceMode === DeviceModeEnum.Mobile,
        setDeviceMode,
      }}
      {...props}
    />
  )
}
