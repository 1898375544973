import { v4 as uuid } from 'uuid'
import { copyEntity, removeMasterBlockProperties } from './copy-structure-utils'
import { generateHtmlAttrId } from './createStructureNew'

export const generateId = () => uuid()

// todo rename globally parentId to ascendantId
const buildResult = (parentId, structure) => ({
  parentId,
  structure,
})

export const copyEntityWithSource = entity => {
  const newEntity = {
    ...entity,
    id: generateId(),
    styles: { ...entity.styles },
    options: {
      ...entity.options,
      attrId: generateHtmlAttrId(entity.type),
    },
    mobileStyles: { ...entity.mobileStyles },
    mobileOptions: { ...entity.mobileOptions },
    oldId: entity.id,
  }

  if (entity.childIds) {
    newEntity.childIds = [...entity.childIds]
  }

  return newEntity
}

export const cloneStructure = (sourceEntity, descendants) => {
  const copySourceEntity = copyEntity(sourceEntity)

  const copyEntities = {}
  copyEntities[copySourceEntity.oldId] = copySourceEntity

  descendants.forEach(descendant => {
    const copyDescendant = copyEntity(descendant)

    // remove masterblock properties if copied entity is not inside of a masterblock
    copyEntities[copyDescendant.oldId] = sourceEntity.masterBlockId
      ? copyDescendant
      : removeMasterBlockProperties(copyDescendant)
  })

  // update childIds with new ids
  Object.keys(copyEntities).forEach(index => {
    const copy = copyEntities[index]

    if (!copy.childIds) {
      return
    }

    // go through old child ids and replace with new
    copy.childIds = copy.childIds
      .filter(childId => Boolean(copyEntities[childId]))
      .map(childId => copyEntities[childId].id)
  })

  const findNewParent = (entities, oldParentId) =>
    Object.values(entities).find(e => e.oldId === oldParentId)

  const copyEntitiesWithNewParentIds = Object.values(copyEntities).reduce(
    (prev, cur) => {
      const { oldId, ...curWithoutOldId } = cur
      const newParent = findNewParent(copyEntities, cur.parentId)
      return {
        ...prev,
        [cur.id]: {
          ...curWithoutOldId,
          parentId: (newParent && newParent.id) || cur.parentId,
        },
      }
    },
    {},
  )

  return buildResult(copySourceEntity.id, copyEntitiesWithNewParentIds)
}
