import React, { PropsWithChildren } from 'react'
import styled, { css } from 'styled-components'

const beforeAfter = css`
  position: absolute;
  left: 14px;
  content: ' ';
  height: 30px;
  width: 2px;
  background-color: #333;
  box-shadow: 0px 0px 1px #fff;
`

const CloseButtonUi = styled.a`
  position: absolute;
  cursor: pointer;
  right: 15px;
  top: 15px;
  width: 30px;
  height: 30px;
  z-index: 1;
  opacity: 0.3;
  text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.7);
  transition: opacity 250ms ease-in-out;
  &:hover {
    opacity: 1;
  }
  &:before {
    ${beforeAfter};
    transform: rotate(45deg);
  }
  &:after {
    ${beforeAfter};
    transform: rotate(-45deg);
  }
`

interface DialogHeaderUiProps {
  $grayHeader?: boolean
  $center?: boolean
}

const DialogHeaderUi = styled.div<DialogHeaderUiProps>`
  ${p =>
    p.$grayHeader &&
    css`
      background-color: #f2f4f7;
      border-bottom: 1px solid rgba(0, 0, 0, 0.01);
    `}
  display: block;
  padding: 36px 36px 0;
  position: relative;
  min-height: 45px;
  ${p =>
    p.$center &&
    css`
      text-align: center;
    `}
`

interface DialogHeaderProps {
  close?: () => void
  center?: boolean
  grayHeader?: boolean
}

const DialogHeader = ({
  children,
  close,
  center,
  grayHeader = true,
}: PropsWithChildren<DialogHeaderProps>) => {
  return (
    <DialogHeaderUi $center={center} $grayHeader={grayHeader}>
      {close && <CloseButtonUi onClick={close} />}
      {children}
    </DialogHeaderUi>
  )
}

export default DialogHeader
