import { useEffect, useState } from 'react'
import { UserPlansResponseInterface } from 'common/components/entities/price-element/price-element-interface'
import { getUserPlans } from 'client/api/price-element-api'

export const usePricePlans = () => {
  const [pricePlans, setPricePlans] = useState<
    UserPlansResponseInterface[] | null
  >(null)
  const [error, setError] = useState<unknown>(null)
  const [isFetching, setIsFetching] = useState(false)

  const fetch = async () => {
    setIsFetching(true)
    try {
      const data = await getUserPlans()
      setPricePlans(data.data)
    } catch (error) {
      setError(error)
    } finally {
      setIsFetching(false)
    }
  }

  useEffect(() => {
    fetch()
  }, [])

  return { pricePlans, isFetching, error, fetch }
}
