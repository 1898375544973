export const getPaddingLeft = node =>
  parseInt(getComputedStyle(node).paddingLeft, 0)

export const denyResize = (currentItemSize, nextItemSize, step) =>
  (currentItemSize === 1 && step > 0) || (nextItemSize === 1 && step < 0)

export const calculateLeftPosition = (left, size, index, gap, minWidth) => {
  return left + size * minWidth + (gap * size - gap / 2) + index * (gap / 2)
}

export const calculateColumnControlsLeftPosition = (left, index) => {
  if (index === 0) {
    return 0
  }

  return left
}

export const getMinColumnWidth = (rowWidth, columnSize, gap) =>
  (rowWidth - (columnSize - 1) * gap) / columnSize

export const getResizeData = entities =>
  entities.reduce(
    (prev, entity) => ({
      ...prev,
      [entity.id]: {
        size: entity.options.size,
        isResizing: false,
      },
    }),
    {},
  )

export const generateSplitters = (entities, prefix) =>
  entities.reduce(
    (prev, entity) => ({
      ...prev,
      [prefix + entity.id]: {
        isResizing: false,
      },
    }),
    {},
  )

export const getWidthWithoutPadding = node => {
  const computedStyle = getComputedStyle(node)
  let width = node.clientWidth
  width -=
    parseFloat(computedStyle.paddingLeft) +
    parseFloat(computedStyle.paddingRight)

  return width
}

export const getRowWidthAndLeftPadding = row => {
  const rowWidth = getWidthWithoutPadding(row)
  const rowPaddingLeft = getPaddingLeft(row)

  return { rowWidth, rowPaddingLeft }
}
