import styled from 'styled-components'

const ColorsWrapperUi = styled.div`
  margin-left: -10px;
  margin-right: -10px;
  padding-top: 10px;
  padding-left: 10px;
  border-top: 1px solid #eee;
  display: flex;
  flex-wrap: wrap;
  position: relative;
`
export default ColorsWrapperUi
