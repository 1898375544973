import React from 'react'
import { PaymentMethodEnum } from '../../../enums/PaymentMethodEnum'
import { PaymentMethodSwitcherCreditCardUi } from '../PaymentMethods'
import PaymentMethodSwitcherItemImageUi from './ui/PaymentMethodSwitcherItemImageUi'
import { creditCardMethodTypes } from './utils/creditCardsUtils'
import getImageSrcAndDimensions from './utils/getImageSrcAndDimensions'

interface PaymentMethodSwitcherImageProps {
  method: PaymentMethodEnum
}

const commonImgSrc = `${process.env.CLOUDFRONT_PUBLISHER_ASSETS_HOST}/assets/images/payment`

function PaymentMethodSwitcherImage({
  method,
}: PaymentMethodSwitcherImageProps) {
  if (method === PaymentMethodEnum.MercadoPagoBrCard) {
    return (
      <PaymentMethodSwitcherItemImageUi
        src={`${commonImgSrc}/credit_cards_mercado_pago_br.png`}
        width={110}
        height={40}
      />
    )
  }

  if (creditCardMethodTypes.includes(method))
    return (
      <PaymentMethodSwitcherCreditCardUi
        cardImageUrl={`${commonImgSrc}/credit_cards.png`}
        mobileCardImageUrl={`${commonImgSrc}/credit_cards_mobile.svg`}
      />
    )

  const srcAndDimensions = getImageSrcAndDimensions(method)
  if (!srcAndDimensions) {
    return null
  }
  const { src, width, height } = srcAndDimensions
  return (
    <PaymentMethodSwitcherItemImageUi src={src} width={width} height={height} />
  )
}

export default PaymentMethodSwitcherImage
