import React, { useState, useEffect } from 'react'
// @ts-ignore
import { Checkboard } from 'react-color/lib/components/common'
import SwatchUi from './ui/SwatchUi'

type SwatchPropsType = {
  color: string
  onClick: (color: string, e: React.MouseEvent<HTMLElement>) => void
  title: string
  activeColorIndex: number | null
  index: number
  activeColor: string
  userPallete: string[]
  setUserPallete: (value: string[]) => void
}

const Swatch = ({
  color,
  onClick,
  title = color,
  activeColorIndex,
  index,
  activeColor,
  userPallete,
  setUserPallete,
}: SwatchPropsType) => {
  const transparent: boolean = color === 'transparent'
  const [isActive, setIsActive] = useState(false)

  const handleClick = (e: React.MouseEvent<HTMLElement>) => onClick(color, e)

  useEffect(() => {
    if (activeColorIndex === index) {
      const newPallete = [...userPallete]
      activeColor === 'rgba(0, 0, 0, 0)'
        ? (newPallete[activeColorIndex] = 'transparent')
        : (newPallete[activeColorIndex] = activeColor)
      setUserPallete(newPallete)
      setIsActive(true)
    } else {
      setIsActive(false)
    }
  }, [activeColorIndex, index, activeColor])

  return (
    <SwatchUi
      onClick={handleClick}
      title={title}
      tabIndex={0}
      color={color}
      isActive={isActive}
    >
      {transparent && (
        <Checkboard
          // @ts-ignore
          borderRadius="3px"
          boxShadow="inset 0 0 0 1px rgba(0,0,0,0.1)"
        />
      )}
    </SwatchUi>
  )
}

export default Swatch
