import React from 'react'
import { useTranslation } from 'react-i18next'
import CommonBlogPostTitle from 'common/components/entities/BlogPostTitle/BlogPostTItleNew'
import EntityTypeEnum from 'common/enums/entityTypeEnum'
import { BlogPostTitleInterface } from 'common/types/entities/BlogPostTitleInterface'
import { MasterBlockId } from 'common/types/entities/EntityInterface'
import Badge from 'client/components/core/Badge'
import BaseEntityNew from 'client/components/core/BaseEntity/BaseEntityNew'
import {
  generateHtmlAttrId,
  generateId,
} from 'client/utils/createStructureUtils'
import { EntityProps } from '../../../types'

function BlogPostTitleNew({
  entity,
  isMoveDownAllowed,
  isMoveUpAllowed,
}: EntityProps<BlogPostTitleInterface>) {
  const { t } = useTranslation()
  return (
    <BaseEntityNew
      entity={entity}
      isMoveUpAllowed={isMoveUpAllowed}
      isMoveDownAllowed={isMoveDownAllowed}
    >
      <>
        <CommonBlogPostTitle
          title={t('entities.blog_post_title.placeholder')}
          attrId={entity.htmlAttrId}
          fontSize={entity.fontSize}
          fontFamily={entity.fontFamily}
          fontStyle={entity.fontStyle}
          fontWeight={entity.fontWeight}
          mobileFontSize={entity.mobileFontSize}
          mobileFontFamily={entity.mobileFontFamily}
          mobileFontStyle={entity.mobileFontStyle}
          mobileFontWeight={entity.mobileFontWeight}
          lineHeight={entity.lineHeight}
          mobileLineHeight={entity.mobileLineHeight}
          color={entity.color}
          mobileColor={entity.mobileColor}
          alignSelf={entity.alignSelf}
          mobileAlignSelf={entity.mobileAlignSelf}
        />
        <Badge wide>
          {t('entities.blog_post_title.warnings.do_not_remove')}
        </Badge>
      </>
    </BaseEntityNew>
  )
}

export function createBlogPostTitle(
  parentId: string,
  masterBlockId?: MasterBlockId,
) {
  const blogPostTitle: BlogPostTitleInterface = {
    id: generateId(),
    type: EntityTypeEnum.BlogPostTitle,
    parentId: parentId,
    fontSize: 27,
    color: 'rgba(0, 0, 0, 1)',
    mobileFontSize: 20,
    alignSelf: 'center',
    margin: {
      marginTop: 0,
      marginRight: 0,
      marginBottom: 0,
      marginLeft: 0,
    },
    mobileMargin: {
      marginTop: 0,
      marginRight: 0,
      marginBottom: 0,
      marginLeft: 0,
    },
    appearance: {
      desktop: true,
      mobile: true,
    },
    htmlAttrId: generateHtmlAttrId(EntityTypeEnum.BlogPostTitle),
  }

  if (masterBlockId) {
    blogPostTitle.masterBlockId = masterBlockId
  }

  return blogPostTitle
}

export default BlogPostTitleNew
