import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { localeValues } from 'common/constants/localeTypes'
import commonAr from 'common/translations/ar.json'
import commonCs from 'common/translations/cs.json'
import commonDa from 'common/translations/da.json'
import commonDe from 'common/translations/de.json'
import commonEl from 'common/translations/el.json'
import commonEn from 'common/translations/en.json'
import commonEs from 'common/translations/es.json'
import commonFr from 'common/translations/fr.json'
import commonHi from 'common/translations/hi.json'
import commonHu from 'common/translations/hu.json'
import commonId from 'common/translations/id.json'
import commonIt from 'common/translations/it.json'
import commonJa from 'common/translations/ja.json'
import commonNl from 'common/translations/nl.json'
import commonNo from 'common/translations/no.json'
import commonPl from 'common/translations/pl.json'
import commonPt from 'common/translations/pt.json'
import commonRo from 'common/translations/ro.json'
import commonRu from 'common/translations/ru.json'
import commonSk from 'common/translations/sk.json'
import commonSl from 'common/translations/sl.json'
import commonSq from 'common/translations/sq.json'
import commonSr from 'common/translations/sr.json'
import commonSv from 'common/translations/sv.json'
import commonTh from 'common/translations/th.json'
import commonTr from 'common/translations/tr.json'
import commonUk from 'common/translations/uk.json'
import commonZh from 'common/translations/zh.json'
import clientAr from './translations/ar.json'
import clientCs from './translations/cs.json'
import clientDa from './translations/da.json'
import clientDe from './translations/de.json'
import clientEl from './translations/el.json'
import clientEn from './translations/en.json'
import clientEs from './translations/es.json'
import clientFr from './translations/fr.json'
import clientHi from './translations/hi.json'
import clientHu from './translations/hu.json'
import clientId from './translations/id.json'
import clientIt from './translations/it.json'
import clientJa from './translations/ja.json'
import clientNl from './translations/nl.json'
import clientNo from './translations/no.json'
import clientPl from './translations/pl.json'
import clientPt from './translations/pt.json'
import clientRo from './translations/ro.json'
import clientRu from './translations/ru.json'
import clientSk from './translations/sk.json'
import clientSl from './translations/sl.json'
import clientSq from './translations/sq.json'
import clientSr from './translations/sr.json'
import clientSv from './translations/sv.json'
import clientTh from './translations/th.json'
import clientTr from './translations/tr.json'
import clientUk from './translations/uk.json'
import clientZh from './translations/zh.json'

export const locales = Object.keys(localeValues)

const resources = {
  fr: {
    common: commonFr,
    client: clientFr,
  },
  en: {
    client: clientEn,
    common: commonEn,
  },
  es: {
    client: clientEs,
    common: commonEs,
  },
  it: {
    client: clientIt,
    common: commonIt,
  },
  pt: {
    client: clientPt,
    common: commonPt,
  },
  de: {
    client: clientDe,
    common: commonDe,
  },
  nl: {
    client: clientNl,
    common: commonNl,
  },
  ru: {
    client: clientRu,
    common: commonRu,
  },
  ja: {
    client: clientJa,
    common: commonJa,
  },
  jp: {
    client: clientJa,
    common: commonJa,
  },
  ar: {
    common: commonAr,
    client: clientAr,
  },
  tr: {
    common: commonTr,
    client: clientTr,
  },
  zh: {
    common: commonZh,
    client: clientZh,
  },
  sv: {
    common: commonSv,
    client: clientSv,
  },
  ro: {
    common: commonRo,
    client: clientRo,
  },
  cs: {
    common: commonCs,
    client: clientCs,
  },
  hu: {
    common: commonHu,
    client: clientHu,
  },
  sk: {
    common: commonSk,
    client: clientSk,
  },

  da: {
    common: commonDa,
    client: clientDa,
  },
  id: {
    common: commonId,
    client: clientId,
  },
  pl: {
    common: commonPl,
    client: clientPl,
  },
  el: {
    common: commonEl,
    client: clientEl,
  },
  sr: {
    common: commonSr,
    client: clientSr,
  },

  no: {
    common: commonNo,
    client: clientNo,
  },
  th: {
    common: commonTh,
    client: clientTh,
  },
  sl: {
    common: commonSl,
    client: clientSl,
  },
  uk: {
    common: commonUk,
    client: clientUk,
  },
  sq: {
    common: commonSq,
    client: clientSq,
  },
  hi: {
    common: commonHi,
    client: clientHi,
  },
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    ns: ['common', 'client'],
    defaultNS: 'client',
    debug: false,
    fallbackLng: {
      ua: ['uk'],
      dk: ['da'],
      default: ['en'],
    },
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })

export default i18n
