import React from 'react'
import { OldEntityInterface } from 'common/types/entities/OldEntityInterface'
import { PaymentButtonInterface } from 'common/types/entities/PaymentButtonInterface'
import { buyMainOffer } from 'publisher/api/dLocalApi'
import usePaymentSubmit from 'publisher/hooks/payment/usePaymentSubmit'
import { usePage, pageSelectors } from 'publisher/store'
import { usePayment } from 'publisher/store'
import paymentSelectors from 'publisher/store/payment/paymentSelectors'

export default function useDLocalBoletoAndPixPaymentHandler(
  entity: OldEntityInterface | PaymentButtonInterface,
) {
  const { errors, isLoading, submit } = usePaymentSubmit(entity)
  const purchaseProcessId = usePayment(paymentSelectors.getPurchaseProcessId)
  const pageId = usePage(pageSelectors.getPageId)

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    submit(async body => {
      const { data } = await buyMainOffer(pageId, purchaseProcessId, {
        payment_form: body,
      })

      if (data.redirect) {
        window.location.assign(data.redirect)
        return
      }
    })
  }

  return { errors, isLoading, handleSubmit }
}
