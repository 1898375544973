import styled from 'styled-components'

const ProgressBarUi = styled.progress`
  width: 100%;
  height: 40px;
  text-align: center;
  &:before {
    content: attr(data-label);
    font-size: 12px;
    vertical-align: -8px;
  }
  display: ${({ isComplete }) => (isComplete ? 'none' : 'block')};
`

export default ProgressBarUi
