import { ContentState, convertToRaw } from 'draft-js'
import React from 'react'
import { calculateHeadlineFontFamily } from 'common/components/entities/TextNew/ui/TextUi'
import { HeadingTypeEnum } from 'common/enums/HeadingTypeEnum'
import { EntityTypeEnum } from 'common/enums/entityTypeEnum'
import { MasterBlockId } from 'common/types/entities/EntityInterface'
import { HeadlineInterface } from 'common/types/entities/HeadlineInterface'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'
import { useAppDispatch } from 'client/hooks/use-dispatch'
import { useManagement, usePage } from 'client/store'
import managementSelectors from 'client/store/management/managementSelectors'
import { updateEntity } from 'client/store/page/pageActions'
import pageSelectors from 'client/store/page/pageSelectors'
import { generateBaseEntity } from 'client/utils/createStructureUtils'
import BaseEntity from '../../core/BaseEntity/BaseEntityNew'
import TextEditor, { TextEditorUpdatePayload } from '../../core/TextEditorNew'
import {
  headingFontSize,
  mobileHeadingFontSize,
} from '../../core/TextEditorNew/Menu/HeadingControls'

// import { TiptapEditor } from 'client/components/core/tiptap-editor'

type HeadlineProps = {
  entity: HeadlineInterface
  isMoveUpAllowed: boolean
  isMoveDownAllowed: boolean
}

function Headline({
  entity,
  isMoveUpAllowed,
  isMoveDownAllowed,
}: HeadlineProps) {
  const dispatch = useAppDispatch()
  const idEditing = useManagement(m =>
    managementSelectors.isEditing(m, entity.id),
  )
  const { isMobile } = useDeviceModeContext()
  const linkColor = usePage(pageSelectors.getGlobalLinkColor)
  const globalFontSize = usePage(pageSelectors.getGlobalTextFontSize)
  const globalMobileFontSize = usePage(
    pageSelectors.getGlobalMobileTextFontSize,
  )
  const globalLineHeight = usePage(pageSelectors.getGlobalTextLineHeight)
  const globalMobileLineHeight = usePage(
    pageSelectors.getGlobalMobileTextLineHeight,
  )
  const headingColor = usePage(pageSelectors.getGlobalHeadingColor)
  const fontProperties = usePage(pageSelectors.getGlobalFontProperties)

  const globalTextAlign = usePage(pageSelectors.getGlobalHeadingAlign)
  const globalMobileTextAlign = usePage(
    pageSelectors.getGlobalMobileHeadingAlign,
  )

  function update(payload: TextEditorUpdatePayload) {
    dispatch(
      updateEntity({
        ...entity,
        ...payload,
      }),
    )
  }

  const fontFamily = calculateHeadlineFontFamily(
    entity.fontFamily,
    fontProperties.headingFontFamily,
    fontProperties.fontFamily,
  )

  const mobileFontFamily = calculateHeadlineFontFamily(
    entity.mobileFontFamily || entity.fontFamily,
    fontProperties.headingFontFamily,
    fontProperties.fontFamily,
  )

  return (
    <BaseEntity
      entity={entity}
      isMoveUpAllowed={isMoveUpAllowed}
      isMoveDownAllowed={isMoveDownAllowed}
    >
      <TextEditor
        attrId={entity.htmlAttrId}
        isEditing={idEditing}
        rawContentState={entity.rawContentState}
        update={update}
        padding={entity.padding}
        textAlign={entity.textAlign || globalTextAlign}
        editorTextAlign={
          isMobile
            ? entity.mobileTextAlign ||
              entity.textAlign ||
              globalMobileTextAlign
            : entity.textAlign || globalTextAlign
        }
        textColor={headingColor}
        linkColor={linkColor}
        fontSize={entity.fontSize || globalFontSize}
        fontStyle={
          entity.fontStyle ||
          fontProperties.headingFontStyle ||
          fontProperties.fontStyle
        }
        fontFamily={fontFamily}
        fontWeight={
          entity.fontWeight ||
          fontProperties.headingFontWeight ||
          fontProperties.fontWeight
        }
        lineHeight={entity.lineHeight || globalLineHeight}
        mobilePadding={entity.mobilePadding}
        mobileFontSize={entity.mobileFontSize || globalMobileFontSize}
        mobileLineHeight={entity.mobileLineHeight || globalMobileLineHeight}
        mobileFontStyle={entity.mobileFontStyle}
        mobileFontFamily={mobileFontFamily}
        mobileFontWeight={entity.mobileFontWeight}
        backgroundColor={entity.backgroundColor}
        mobileBackgroundColor={entity.mobileBackgroundColor}
        letterSpacing={entity.letterSpacing}
        mobileLetterSpacing={entity.mobileLetterSpacing}
        enableHeadings
      />
      {/* UNCOMMENT FOR THE TEST */}
      {/* <TiptapEditor
        isEditing={idEditing}
        update={update}
        attrId={entity.htmlAttrId}
        padding={entity.padding}
        textAlign={entity.textAlign}
        textColor={headingColor}
        linkColor={linkColor}
        fontFamily={fontFamily}
        fontSize={entity.fontSize || globalFontSize}
        fontStyle={entity.fontStyle}
        fontWeight={
          entity.fontWeight ||
          fontProperties.headingFontWeight ||
          fontProperties.fontWeight
        }
        lineHeight={entity.lineHeight || globalLineHeight}
        mobilePadding={entity.mobilePadding}
        mobileFontSize={entity.mobileFontSize || globalMobileFontSize}
        mobileLineHeight={entity.mobileLineHeight || globalMobileLineHeight}
        mobileFontStyle={entity.mobileFontStyle}
        mobileFontFamily={mobileFontFamily}
        mobileFontWeight={entity.mobileFontWeight}
        backgroundColor={entity.backgroundColor}
        mobileBackgroundColor={entity.mobileBackgroundColor}
        letterSpacing={entity.letterSpacing}
        mobileLetterSpacing={entity.mobileLetterSpacing}
        content={entity.rawContentState}
        enableHeadings
      /> */}
    </BaseEntity>
  )
}

export function createHeadline(
  parentId: string,
  text: string,
  masterBlockId?: MasterBlockId,
): HeadlineInterface {
  return {
    ...generateBaseEntity(EntityTypeEnum.Headline, parentId, masterBlockId),
    type: EntityTypeEnum.Headline,
    rawContentState: JSON.stringify(
      convertToRaw(ContentState.createFromText(text)),
    ),
    ...headingFontSize[HeadingTypeEnum.HeaderTwo],
    mobileFontSize: mobileHeadingFontSize[HeadingTypeEnum.HeaderTwo].fontSize,
    mobileLineHeight:
      mobileHeadingFontSize[HeadingTypeEnum.HeaderTwo].lineHeight,
    margin: {
      marginTop: 15,
      marginRight: 0,
      marginBottom: 15,
      marginLeft: 0,
    },
    padding: {
      paddingTop: 0,
      paddingRight: 0,
      paddingBottom: 0,
      paddingLeft: 0,
    },
    mobilePadding: {
      paddingTop: 0,
      paddingRight: 0,
      paddingBottom: 0,
      paddingLeft: 0,
    },
  }
}

export default Headline
