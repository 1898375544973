import styled, { css } from 'styled-components'
import { MarginType } from 'common/types/styleTypes'

type BaseEntityProps = {
  stateColor: string | null
  isSomethingDragging: boolean
  isEditing: boolean
  margin: MarginType
  mobileMargin: Partial<MarginType>
  appearance: {
    desktop: boolean
    mobile: boolean
  }
  flex?: boolean
  flexColumn?: boolean
}

const BaseEntityNewUi = styled.div<BaseEntityProps>`
  position: relative;
  ${p => p.flex && `display: flex;`};
  ${p => p.flexColumn && `flex-direction: column;`}
  ${p => p.appearance.desktop === false && `display: none`};
  ${p =>
    p.stateColor &&
    css`
      outline: 1px solid ${p.stateColor};
    `};
  ${p =>
    p.isSomethingDragging &&
    css`
      outline: 1px dashed #ccc;
    `}
  ${p =>
    p.isEditing &&
    css`
      box-shadow: 0 0 0 2px var(--default-blue-color);
    `};
  margin-top: ${p => p.margin.marginTop}px;
  margin-right: ${p => p.margin.marginRight}px;
  margin-bottom: ${p => p.margin.marginBottom}px;
  margin-left: ${p => p.margin.marginLeft}px;
  ${p =>
    p.theme.isEditorMobileMode &&
    css`
      margin-top: ${p.mobileMargin.marginTop}px;
      margin-right: ${p.mobileMargin.marginRight}px;
      margin-bottom: ${p.mobileMargin.marginBottom}px;
      margin-left: ${p.mobileMargin.marginLeft}px;
      display: ${p.appearance.mobile ? (p.flex ? 'flex' : 'block') : 'none'};
    `}
`

export default BaseEntityNewUi
