import { v4 as uuid } from 'uuid'
import defaultScheme from 'common/constants/defaultScheme'
import mapToolBoxToStructure, {
  getContainerEntityStructure,
  getMovableEntityStructure,
} from 'common/constants/mapToolBoxToStructure'
import structureTypes from 'common/constants/structureTypes'
import EntityTypeEnum from 'common/enums/entityTypeEnum'
import { createText } from 'client/components/entities/Text/Text'
import {
  defaultMobileOptions,
  defaultMobileStyles,
  defaultOptions,
  defaultStyles,
  draftOptions,
  translatableOptionKeys,
} from 'client/constants/editorSettings'
import i18next from 'client/i18n'
import { createColumn } from '../components/core/Sidebar/components/Settings/entities/RowSettings/utils/create-column'
import { createRow } from '../components/core/Sidebar/components/Settings/entities/RowSettings/utils/create-row'
import { createCarousel } from '../components/entities/Carousel'
import { createSection } from '../components/entities/Section/utils/createSection'
import { createOfferPrice } from '../pages/offer/entities/OfferPrice'

export const generateId = () => uuid()

export const generateHtmlAttrId = entityType =>
  `${entityType.toLowerCase().replace('_', '-')}-${uuid().slice(0, 8)}`

const getDefaultStyles = type =>
  defaultStyles[type] !== undefined ? defaultStyles[type] : {}

const getMobileDefaultStyles = type =>
  defaultMobileStyles[type] !== undefined ? defaultMobileStyles[type] : {}

const translateOptions = (locale, options) =>
  Object.keys(options).forEach(optionKey => {
    const t = i18next.getFixedT(locale)
    if (translatableOptionKeys.includes(optionKey)) {
      options[optionKey] = t(options[optionKey])
    }

    if (draftOptions.includes(optionKey)) {
      const draftEntity = JSON.parse(options[optionKey])
      draftEntity.blocks.forEach(block =>
        Object.keys(block).forEach(blockKey => {
          if (blockKey === 'text') {
            block[blockKey] = t(block[blockKey]) || block[blockKey]
          }
        }),
      )

      options[optionKey] = JSON.stringify(draftEntity)
    }
  })

const getDefaultOptions = type =>
  defaultOptions[type] !== undefined ? defaultOptions[type] : {}

const getDefaultMobileOptions = type =>
  defaultMobileOptions[type] !== undefined ? defaultMobileOptions[type] : {}

const createDefaultComponent = (
  locale,
  type,
  parentId,
  styles,
  mobileStyles,
  options,
  mobileOptions,
  masterBlockId,
) => {
  // do not mutate default options
  const calculatedOptions = {
    ...getDefaultOptions(type),
    ...options,
    attrId: generateHtmlAttrId(type),
  }
  const calculatedMobileOptions = {
    ...getDefaultMobileOptions(type),
    ...mobileOptions,
  }
  translateOptions(locale, calculatedOptions)
  translateOptions(locale, calculatedMobileOptions)

  const newEntity = {
    id: generateId(),
    type,
    styles: {
      ...getDefaultStyles(type),
      ...styles,
    },
    mobileStyles: {
      ...getMobileDefaultStyles(type),
      ...mobileStyles,
    },
    options: calculatedOptions,
    mobileOptions: calculatedMobileOptions,
  }

  if (masterBlockId) {
    newEntity['masterBlockId'] = masterBlockId
  }

  if (parentId) {
    newEntity.parentId = parentId
  }

  return newEntity
}

const createComplexComponent = (
  locale,
  type,
  parentId,
  styles,
  mobileStyles,
  options,
  mobileOptions,
  masterBlockId,
) => ({
  ...createDefaultComponent(
    locale,
    type,
    parentId,
    styles,
    mobileStyles,
    options,
    mobileOptions,
    masterBlockId,
  ),
  childIds: [],
})

const getColumnSize = type => {
  switch (type) {
    case structureTypes.COLUMN_12:
      return 12
    case structureTypes.COLUMN_8:
      return 8
    case structureTypes.COLUMN_6:
      return 6
    case structureTypes.COLUMN_4:
      return 4
    case structureTypes.COLUMN_3:
      return 3
    default:
      return 12
  }
}

export const createColumnEntity = (locale, type, parentId, masterBlockId) => {
  const column = createComplexComponent(
    locale,
    structureTypes.COLUMN,
    parentId,
    null,
    null,
    null,
    null,
    masterBlockId,
  )
  column.options.size = getColumnSize(type)

  return column
}

export const createSurveyComponent = (
  locale,
  type,
  parentId,
  masterBlockId,
) => {
  const t = i18next.getFixedT(locale)
  return {
    id: generateId(),
    type: structureTypes.SURVEY,
    childIds: [],
    parentId,
    masterBlockId,
    attrId: generateHtmlAttrId(type),
    options: {
      answerBackgroundColor: 'rgba(240,244,248, 1)',
      answerOutlineColor: 'rgba(20,158,252,1)',
      answerColor: 'rgba(93 , 100, 110, 1)',
      questions: [
        {
          id: uuid(),
          title: t('entities.survey.default_question_title_text'),
          subTitle: t('entities.survey.default_question_sub_title_text'),
          answers: [
            {
              id: uuid(),
              title: t('entities.survey.default_answer_title_text'),
            },
            {
              id: uuid(),
              title: t('entities.survey.default_answer_title_text'),
            },
            {
              id: uuid(),
              title: t('entities.survey.default_answer_title_text'),
            },
          ],
        },
      ],
      answerBorderRadius: {
        borderTopLeftRadius: 25,
        borderTopRightRadius: 25,
        borderBottomRightRadius: 25,
        borderBottomLeftRadius: 25,
      },
      questionTitleFontSize: '30px',
      questionSubTitleFontSize: '16px',
      answerFontSize: '18px',
      questionTitleColor: 'rgba(20, 45, 99, 1)',
      questionSubTitleColor: 'rgba(139, 146, 156, 1)',
      action: 'nothing',
      attrId: 'survey-35ff82e0',
    },
    mobileOptions: {
      questionTitleFontSize: '26px',
      answerBorderRadius: {},
    },
    styles: {
      marginTop: '0px',
      marginBottom: '0px',
      marginLeft: '0px',
      marginRight: '0px',
      padding: '15px 100px 15px 100px',
    },
    mobileStyles: {
      marginTop: '0px',
      marginBottom: '0px',
      marginLeft: '0px',
      marginRight: '0px',
      padding: '15px 15px 15px 15px',
    },
  }
}

const createEntity = (
  locale,
  type,
  parentId,
  styles,
  mobileStyles,
  options,
  mobileOptions,
  masterBlockId = null,
) => {
  switch (type) {
    case EntityTypeEnum.Text:
      return createText(parentId, masterBlockId)
    case structureTypes.SURVEY:
      return createSurveyComponent(locale, type, parentId, masterBlockId)
    case structureTypes.BODY:
    case EntityTypeEnum.Body:
    case structureTypes.BLOG_POST_BODY:
    case structureTypes.BLOG_PAGE_BODY:
    case EntityTypeEnum.BlogPageBody:
    case structureTypes.BLOG_POST_LAYOUT_BODY:
    case EntityTypeEnum.BlogPostLayoutBody:
    case structureTypes.SECTION:
    case structureTypes.ROW:
    case structureTypes.CONTENT_BOX:
    case EntityTypeEnum.ContentBox:
    case structureTypes.ORDER_BUMP:
    case structureTypes.POPUP:
    case structureTypes.REMOTE_POPUP:
    case EntityTypeEnum.RemotePopupBody:
    case structureTypes.INLINE:
    case EntityTypeEnum.InlineBody:
    case structureTypes.COLUMN:
    case structureTypes.TWO_STEP_PAYMENT_FORM:
    case structureTypes.TWO_STEP_PAYMENT_FORM_STEP_OPT_IN:
    case structureTypes.TWO_STEP_PAYMENT_FORM_STEP_PAYMENT:
      return createComplexComponent(
        locale,
        type,
        parentId,
        styles,
        mobileStyles,
        options,
        mobileOptions,
        masterBlockId,
      )
    case EntityTypeEnum.Section:
      return createSection(parentId, masterBlockId)
    case structureTypes.COLUMN_12:
    case structureTypes.COLUMN_8:
    case structureTypes.COLUMN_6:
    case structureTypes.COLUMN_4:
    case structureTypes.COLUMN_3:
      return createColumnEntity(locale, type, parentId, masterBlockId)
    case EntityTypeEnum.Carousel:
      return createCarousel(parentId, masterBlockId)
    case EntityTypeEnum.Row:
      return createRow(parentId, masterBlockId, options)
    case EntityTypeEnum.Column:
      return createColumn({ parentId, masterBlockId, options })
    case EntityTypeEnum.OfferPrice:
      return createOfferPrice(parentId, masterBlockId)
    default:
      return createDefaultComponent(
        locale,
        type,
        parentId,
        styles,
        mobileStyles,
        options,
        mobileOptions,
        masterBlockId,
      )
  }
}

const insertChild = (childIds, id, position) => [
  ...childIds.slice(0, position),
  id,
  ...childIds.slice(position),
]

const buildStructureByScheme = (
  locale,
  scheme,
  result = {},
  parentId,
  initialParentId,
  masterBlockId = null,
) => {
  if (!scheme.children) {
    return result
  }

  return scheme.children.reduce(
    ({ structure: parentStructure = {} }, current, index, array) => {
      const currentType = Object.keys(current)[0]
      const schemeObject = current[currentType]
      const entity = createEntity(
        locale,
        currentType,
        parentId || initialParentId,
        schemeObject.styles,
        schemeObject.mobileStyles,
        schemeObject.options,
        schemeObject.mobileOptions,
        masterBlockId,
      )

      const newResult = {
        ascendantId: result.ascendantId || parentId || entity.id,
        structure: {
          ...(result ? result.structure : null),
          ...parentStructure,
          [entity.id]: entity,
        },
      }

      if (parentId) {
        let childIds
        if (parentStructure[parentId]) {
          childIds = [...parentStructure[parentId].childIds, entity.id]
        } else if (result.structure[parentId]) {
          childIds = [...result.structure[parentId].childIds, entity.id]
        } else {
          childIds = [entity.id]
        }

        newResult.structure[parentId] = {
          ...result.structure[parentId],
          childIds,
        }
      }

      return {
        ...newResult,
        ...(index + 1 <= array.length
          ? buildStructureByScheme(
              locale,
              current[currentType],
              newResult,
              entity.id,
              null,
              masterBlockId,
            )
          : null),
      }
    },
    {},
  )
}

const replaceChildScheme = ({ children }, addScheme) => {
  const addSchemeRootType = Object.keys(addScheme)[0]

  if (!children || children.length === 0) {
    return { children: [addScheme] }
  }

  return children.reduce((prev, child) => {
    const childType = Object.keys(child)[0]
    if (childType === addSchemeRootType) {
      return {
        ...prev,
        children: [addScheme],
      }
    }

    return {
      ...prev,
      children: [
        {
          [childType]: { ...replaceChildScheme(child[childType], addScheme) },
        },
      ],
    }
  }, {})
}

const createStructureNew = (
  locale,
  targetType,
  containerEntity = null,
  position = null,
  masterBlockId = null,
) => {
  const containerNeedScheme = containerEntity
    ? defaultScheme[containerEntity.type]
    : {}

  const targetScheme = mapToolBoxToStructure[targetType]
  const resultScheme = replaceChildScheme(containerNeedScheme, targetScheme)
  const { ascendantId, structure } = buildStructureByScheme(
    locale,
    resultScheme,
    {},
    null,
    containerEntity && containerEntity.id,
    masterBlockId,
  )

  if (!containerEntity) {
    return { ascendantId, structure }
  }

  const parentEntity = {
    ...containerEntity,
    childIds: insertChild(containerEntity.childIds, ascendantId, position),
  }

  return {
    ascendantId,
    structure: {
      ...structure,
      [parentEntity.id]: parentEntity,
    },
  }
}

function replaceEntitiesIds(entities) {
  const oldNewIds = {}
  entities.forEach(entity => {
    oldNewIds[entity.id] = uuid()
  })
  // assume first is an ascendant
  return entities.reduce(
    (prev, entity) => [
      ...prev,
      {
        ...entity,
        id: oldNewIds[entity.id],
        parentId: oldNewIds[entity.parentId],
        ...(entity.childIds
          ? {
              childIds: entity.childIds
                .filter(id => Boolean(oldNewIds[id]))
                .map(id => oldNewIds[id]),
            }
          : {}),
      },
    ],
    [],
  )
}

function getAncestor(entities) {
  return entities.find((entity, index, array) => {
    const parent = array.find(e => e.id === entity.parentId)
    if (parent) {
      return false
    }

    return entity
  })
}

export const addBlockEntitiesTo = (
  blockEntities,
  containerEntity,
  locale,
  position = null,
  isMaster = false,
) => {
  const preparedBlockEntities = isMaster
    ? blockEntities
    : replaceEntitiesIds(blockEntities)
  const blockAncestor = getAncestor(preparedBlockEntities)
  const movableReverseStructure = getMovableEntityStructure(blockAncestor.type)
  const containerStructure = getContainerEntityStructure(containerEntity.type)
  const buildMap = movableReverseStructure.filter(value =>
    containerStructure.includes(value),
  )

  if (!buildMap.length) {
    return {
      [containerEntity.id]: {
        ...containerEntity,
        childIds: insertChild(
          containerEntity.childIds,
          blockAncestor.id,
          position,
        ),
      },
      ...preparedBlockEntities.reduce(
        (acc, entity) => ({
          ...acc,
          [entity.id]: {
            ...(entity.id === blockAncestor.id
              ? { ...entity, parentId: containerEntity.id }
              : entity),
          },
        }),
        {},
      ),
    }
  }
  const structure = {}
  let ancestorParentId = containerEntity.id

  buildMap.reduce((prevEntity, entityType, index, array) => {
    const entity = createEntity(
      locale,
      entityType,
      (prevEntity && prevEntity.id) || ancestorParentId,
    )
    structure[entity.id] = entity
    if (prevEntity) {
      structure[prevEntity.id].childIds.push(entity.id)
    }

    // override parentId
    if (index + 1 === array.length) {
      ancestorParentId = entity.id
    }

    if (index === 0) {
      // set as a child of container entity
      structure[containerEntity.id] = {
        ...containerEntity,
        childIds: insertChild(containerEntity.childIds, entity.id, position),
      }
    }

    return entity
  }, null)

  structure[ancestorParentId].childIds.push(blockAncestor.id)

  return preparedBlockEntities.reduce(
    (acc, entity) => ({
      ...acc,
      [entity.id]: {
        ...(entity.id === blockAncestor.id
          ? { ...entity, parentId: ancestorParentId }
          : entity),
      },
    }),
    structure,
  )
}

export const createDestinationStructure = (
  locale,
  movableEntity,
  parentEntity,
  position,
  masterBlockId,
) => {
  const movableReverseStructure = getMovableEntityStructure(movableEntity.type)
  const containerStructure = getContainerEntityStructure(parentEntity.type)
  const buildMap = movableReverseStructure.filter(value =>
    containerStructure.includes(value),
  )

  const structure = {}
  let parentId = parentEntity.id
  let ascendantId = null
  let calculatedPosition = position

  buildMap.reduce((prevEntity, buildType, index, array) => {
    const entity = createEntity(
      locale,
      buildType,
      (prevEntity && prevEntity.id) || parentId,
    )
    if (masterBlockId) {
      entity.masterBlockId = masterBlockId
    }
    structure[entity.id] = entity
    if (prevEntity) {
      structure[prevEntity.id].childIds.push(entity.id)
    }

    if (index === 0) {
      ascendantId = entity.id
      calculatedPosition = 0
    }

    // override parentId
    if (index + 1 === array.length) {
      parentId = entity.id
    }

    return entity
  }, null)

  return { parentId, structure, ascendantId, calculatedPosition }
}

export {
  createStructureNew as default,
  createEntity,
  buildStructureByScheme,
  createStructureNew as createStructure,
}
