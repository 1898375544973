import React from 'react'
import CommonInlineBody from 'common/components/entities/inline-body'
import { InlineBodyInterface } from 'common/types/inline-body-interface'
import ChildrenEntities from 'client/components/core/ChildrenEntities'
import { useBackgroundImage } from 'client/hooks/useBackgroundImage'
import usePresentPage from 'client/hooks/usePresentPage'
import {
  getGlobalFontFamily,
  getGlobalTextFontSize,
  getGlobalMobileTextFontSize,
  getGlobalTextColor,
} from 'client/reducers/pageReducer'

interface InlineBodyProps {
  entity: InlineBodyInterface
}
function InlineBody({ entity }: InlineBodyProps) {
  const { id, type, childIds } = entity

  const {
    background,
    backgroundColor,
    mobileBackgroundColor,
    boxShadow,
    maxWidth,
    mobilePadding,
    mobileWidth,
    padding,
    width,
    blur,
    border,
    mobileBorder,
    mobileBoxShadow,
    mobileBackground,
  } = entity

  const textColor = usePresentPage(getGlobalTextColor)
  const fontFamily = usePresentPage(getGlobalFontFamily)
  const fontSize = usePresentPage(getGlobalTextFontSize)
  const mobileFontSize = usePresentPage(getGlobalMobileTextFontSize)

  const { backgroundImage, mobileBackgroundImage } = useBackgroundImage({
    backgroundFileId: entity.backgroundFileId,
    mobileBackgroundFileId: entity.mobileBackgroundFileId,
  })

  return (
    <CommonInlineBody
      width={width}
      mobileWidth={mobileWidth}
      maxWidth={maxWidth}
      padding={padding}
      mobilePadding={mobilePadding}
      background={background}
      backgroundColor={backgroundColor}
      mobileBackgroundColor={mobileBackgroundColor}
      backgroundImage={backgroundImage}
      mobileBackgroundImage={mobileBackgroundImage}
      mobileBackground={mobileBackground}
      border={border}
      mobileBorder={mobileBorder}
      boxShadow={boxShadow}
      mobileBoxShadow={mobileBoxShadow}
      blur={blur}
      color={textColor}
      fontFamily={fontFamily}
      fontSize={fontSize}
      mobileFontSize={mobileFontSize}
    >
      <ChildrenEntities id={id} type={type} childIds={childIds} />
    </CommonInlineBody>
  )
}

export default InlineBody
