import { RichUtils } from 'draft-js'
import PropTypes from 'prop-types'
import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import { bulletListIconsOld } from 'common/constants/iconList'
import { useActions } from 'common/hooks/useActions'
import { update } from 'client/actions/entityActions'
import BaseEntity from 'client/components/core/BaseEntity'
import TextEditor from 'client/components/core/TextEditor'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'
import usePresentPage from 'client/hooks/usePresentPage'
import { getIsEditing } from 'client/reducers/managementReducer'
import {
  getGlobalFontFamily,
  getGlobalTextFontSize,
  getGlobalLinkColor,
  getGlobalMobileTextFontSize,
  getGlobalTextLineHeight,
  getGlobalMobileTextLineHeight,
  getGlobalTextColor,
} from 'client/reducers/pageReducer'
import ListItem from './ListItem'

function BulletList({ entity, isMoveDownAllowed, isMoveUpAllowed }) {
  const updateAction = useActions(update)
  const isEditing = useSelector(state =>
    getIsEditing(state.management, entity.id),
  )
  const { isMobile } = useDeviceModeContext()
  const linkColor = usePresentPage(getGlobalLinkColor)
  const textColor = usePresentPage(getGlobalTextColor)
  const fontFamily = usePresentPage(getGlobalFontFamily)
  const fontSize = usePresentPage(getGlobalTextFontSize)
  const mobileFontSize = usePresentPage(getGlobalMobileTextFontSize)
  const lineHeight = usePresentPage(getGlobalTextLineHeight)
  const mobileLineHeight = usePresentPage(getGlobalMobileTextLineHeight)
  const calculatedListItemIconColor = isMobile
    ? entity.mobileOptions.listItemIconColor || entity.options.listItemIconColor
    : entity.options.listItemIconColor
  const calculatedVerticalSpacing = isMobile
    ? entity.mobileOptions.verticalSpacing
    : entity.options.verticalSpacing

  const handleUpdateText = rawContentState => {
    updateAction({
      ...entity,
      options: {
        ...entity.options,
        rawContentState,
      },
    })
  }

  const handleUpdateStyles = styles => {
    updateAction({
      ...entity,
      styles,
    })
  }

  const handleReturn = editorState => {
    const currentBlockType = RichUtils.getCurrentBlockType(editorState)
    if (currentBlockType !== 'unordered-list-item') {
      return RichUtils.toggleBlockType(editorState, 'unordered-list-item')
    }
  }

  function listItemBlockRenderer(contentBlock) {
    const type = contentBlock.getType()
    if (type === 'unordered-list-item') {
      return {
        component: ListItem,
        editable: true,
        props: {
          iconClassName: iconClassName,
        },
      }
    }
  }

  //check if it's old className
  const iconClassName =
    entity.options.listItemClassName.indexOf('fa') < 0
      ? bulletListIconsOld[entity.options.listItemClassName]
      : entity.options.listItemClassName

  return (
    <BaseEntity
      entity={entity}
      mobileEmulationStyles={{
        fontSize: mobileFontSize,
        lineHeight: mobileLineHeight,
      }}
      isMoveUpAllowed={isMoveUpAllowed}
      isMoveDownAllowed={isMoveDownAllowed}
    >
      {(emulatedEntity, addToggleableNode) => (
        <TextEditor
          rawContentState={entity.options.rawContentState}
          update={handleUpdateText}
          updateStyles={handleUpdateStyles}
          isEditing={isEditing}
          styles={emulatedEntity.styles}
          addToggleableNode={addToggleableNode}
          textWrapperProps={{
            listItemIconColor: calculatedListItemIconColor,
            listItemIconSize: entity.options.listItemIconSize,
            listItemClassName: iconClassName,
            verticalSpacing: calculatedVerticalSpacing,
            linkColor,
            fontFamily,
            fontSize,
            lineHeight,
            textColor,
          }}
          handleReturn={handleReturn}
          blockRendererFn={listItemBlockRenderer}
        />
      )}
    </BaseEntity>
  )
}

BulletList.propTypes = {
  entity: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    options: PropTypes.object,
    mobileOptions: PropTypes.object,
    styles: PropTypes.object,
  }).isRequired,
  isMoveUpAllowed: PropTypes.bool.isRequired,
  isMoveDownAllowed: PropTypes.bool.isRequired,
}

export default memo(BulletList)
