import { toZonedTime, fromZonedTime } from 'date-fns-tz'

export const setDateWithTimezone = ({
  date,
  timeZone,
  subtract,
}: {
  date: Date
  timeZone: string
  subtract?: boolean
}) => {
  return subtract ? fromZonedTime(date, timeZone) : toZonedTime(date, timeZone)
}
