import React from 'react'
import PropTypes from 'prop-types'
import * as types from 'common/types/index'
import PricePlanItemUi from './ui/PricePlanItem'
import PricePlanValue from './PricePlanValue'

const Tariff = ({
  plan,
  description = '',
  translate,
  language,
  toggle = null,
  active = false,
  quantity = 1,
  coupon,
}) => (
  <PricePlanItemUi
    name={plan.name}
    toggle={toggle}
    description={description || translate('components.offer_price.price')}
    active={active}
  >
    <PricePlanValue
      plan={plan}
      translate={translate}
      language={language}
      quantity={quantity}
      coupon={active && coupon}
    />
  </PricePlanItemUi>
)

Tariff.propTypes = {
  coupon: PropTypes.shape({
    id: PropTypes.number.isRequired,
    discountAmount: PropTypes.string.isRequired,
    discountType: PropTypes.string.isRequired,
  }),
  active: PropTypes.bool,
  plan: types.offerPricePlan.isRequired,
  description: PropTypes.string,
  translate: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired,
  toggle: PropTypes.func,
  quantity: PropTypes.number,
}

export default Tariff
