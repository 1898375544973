import PropTypes from 'prop-types'
import React from 'react'
import ReactPlayer from 'react-player/lazy'
import VideoPlaceholderInner from './ui/VideoPlaceholderInnerUi'
import VideoPlaceholder from './ui/VideoPlaceholderUi'
import VideoUi from './ui/VideoUi'

function BasePlayer({ url = null, controls = false, styles, mobileStyles }) {
  if (!url) {
    return (
      <VideoPlaceholder styles={styles} mobileStyles={mobileStyles}>
        <VideoPlaceholderInner />
      </VideoPlaceholder>
    )
  }

  return (
    <VideoUi styles={styles} mobileStyles={mobileStyles}>
      <ReactPlayer
        width="100%"
        height="100%"
        url={url}
        className="react-player"
        controls={controls}
        config={{
          file: {
            attributes: {
              controlsList: 'nodownload',
            },
          },
        }}
      />
    </VideoUi>
  )
}

BasePlayer.propTypes = {
  controls: PropTypes.bool,
  url: PropTypes.string,
  styles: PropTypes.objectOf(PropTypes.any).isRequired,
  mobileStyles: PropTypes.objectOf(PropTypes.any).isRequired,
}

export default BasePlayer
