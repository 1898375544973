//@ts-ignore
import sortBy from 'lodash.sortby'
import { InputTypesState, SlugProperties } from './inputTypesReducer'

export const getTypeNameBySlug = (state: InputTypesState, slug?: string) => {
  return slug && state[slug] && state[slug].name
}

export const getIdNameListWithEmailAndNameFirst = (state: InputTypesState) => {
  return sortBy({ ...state }, ['slug']) as SlugProperties[]
}

const inputTypesSelectors = {
  getTypeNameBySlug,
  getIdNameListWithEmailAndNameFirst,
}

export default inputTypesSelectors
