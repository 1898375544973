import styled, { css } from 'styled-components'

const beforeAfter = css`
  position: absolute;
  left: 14px;
  content: ' ';
  height: 30px;
  width: 2px;
  background-color: #333;
  box-shadow: 0 0 1px #fff;
`

const ModalCloseUi = styled.a`
  position: absolute;
  right: 15px;
  top: 15px;
  width: 30px;
  height: 30px;
  z-index: 1;
  color: #ccc;
  text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.7);
  transition: opacity 250ms ease-in-out;
  &:hover {
    opacity: 0.5;
  }
  &:before {
    ${beforeAfter};
    transform: rotate(45deg);
  }
  &:after {
    ${beforeAfter};
    transform: rotate(-45deg);
  }
`

export default ModalCloseUi
