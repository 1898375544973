import React from 'react'
import { useTranslation } from 'react-i18next'
import { usePage } from 'client/store'
import pageSelectors from 'client/store/page/pageSelectors'
import MenuSelectUi from '../ui/MenuSelectUi'
import MenuPopupOptionUi from './ui/MenuPopupOptionUi'

type LinkSourcePopupProps = {
  popupId?: string
  update: (popupId: string) => void
}

export default function LinkSourcePopup({
  popupId,
  update,
}: LinkSourcePopupProps) {
  const { t } = useTranslation()
  const popups = usePage(pageSelectors.getPopupEntities)

  function handlePopupChange(e: React.ChangeEvent<HTMLSelectElement>) {
    update(e.target.value)
  }

  return (
    <MenuSelectUi onChange={handlePopupChange} defaultValue={popupId}>
      <MenuPopupOptionUi value="">
        {t('components.core.text_editor.select_popup')}
      </MenuPopupOptionUi>
      {popups.map(popup => (
        <MenuPopupOptionUi key={popup.id} value={popup.id}>
          {popup.options.title}
        </MenuPopupOptionUi>
      ))}
    </MenuSelectUi>
  )
}
