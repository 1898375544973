import styled from 'styled-components'

type DropBoxWithTextUiProps = {
  canDrop: boolean
}

const DropBoxWithTextUi = styled.div<DropBoxWithTextUiProps>`
  display: flex;
  min-height: 50px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 199px;
  border-radius: 4px;
  background-color: rgba(20, 158, 252, ${p => (p.canDrop ? 0.29 : 0.09)});
  transition: background-color 0.3s ease, color 0.3s ease;
  border: dashed 1px #149efc;
  color: ${p => (p.canDrop ? '#006CB8' : '#149efc')};
  font-size: 14px;
  font-family: var(--font-family);
`

export default DropBoxWithTextUi
