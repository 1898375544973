import PropTypes from 'prop-types'
import React, { Component } from 'react'
import styled from 'styled-components'
import { entityBorderColors } from '../constants/editorSettings'

const { blue } = entityBorderColors

const Div = styled.div`
  width: 2px;
  position: absolute;
  top: 0;
  left: ${({ left }) => `${left}px`};
  height: 100%;
  cursor: col-resize;
  border: 1px solid ${blue};
  z-index: 1;
  visibility: ${({ show }) => (show ? 'visible' : 'hidden')};
  &::after {
    content: '';
    position: absolute;
    top: calc(50% - 10px);
    left: -3px;
    width: 6px;
    height: 20px;
    border-radius: 1px;
    background-color: ${blue};
  }
`

class Splitter extends Component {
  state = {
    start: 0,
    compensation: 0,
  }

  componentDidMount() {
    window.addEventListener('mousemove', this.onMouseMove, false)
    window.addEventListener('mouseup', this.onMouseUp, false)
  }
  componentWillUnmount() {
    window.removeEventListener('mousemove', this.onMouseMove, false)
    window.removeEventListener('mouseup', this.onMouseUp, false)
  }

  onMouseDown = e => {
    e.stopPropagation()
    this.setState({ start: e.clientX, compensation: 0 })
    this.props.updateStateResizing(true)
  }

  onMouseMove = e => {
    const { clientX } = e
    const { minColumnWidth, isResizing, resize } = this.props
    if (isResizing) {
      this.clearSelections()
      if (this.state.compensation === clientX) {
        this.moveStartToCompensation()
      }

      // console.log('--clientX--', clientX);
      const movement = this.state.start - clientX
      const isMovementPositive = movement > 0
      // console.log('--compensation--', this.state.compensation ? Math.abs(clientX - this.state.compensation) : this.state.compensation);

      if (this.isPassCompensation(isMovementPositive, clientX)) {
        const crossOverWidth = Math.round(minColumnWidth / 2)
        // console.log('--movement--', movement);
        if (Math.abs(movement) > crossOverWidth) {
          const resizeValue = isMovementPositive ? 1 : -1
          if (resize(resizeValue)) {
            // console.log('--resize set start--', clientX);
            this.setState({
              start: clientX,
              // eslint-disable-next-line max-len
              compensation: isMovementPositive
                ? clientX - crossOverWidth
                : clientX + crossOverWidth,
            })
          }
        }
      }
    }
  }

  onMouseUp = () => {
    if (this.props.isResizing) {
      this.props.updateStateResizing(false)
    }
  }

  isPassCompensation = (isPositive, x) => {
    if (this.state.compensation === 0) {
      return true
    }

    return (
      (isPositive && x < this.state.compensation) ||
      (!isPositive && x > this.state.compensation)
    )
  }

  moveStartToCompensation = () => {
    this.setState(({ compensation }) => ({
      start: compensation,
      compensation: 0,
    }))
  }

  clearSelections = () => {
    window.getSelection().removeAllRanges()
  }

  render() {
    return (
      <Div
        show={this.props.show}
        onMouseDown={this.onMouseDown}
        left={this.props.left}
      />
    )
  }
}

Splitter.propTypes = {
  show: PropTypes.bool.isRequired,
  isResizing: PropTypes.bool.isRequired,
  resize: PropTypes.func.isRequired,
  updateStateResizing: PropTypes.func.isRequired,
  minColumnWidth: PropTypes.number.isRequired,
  left: PropTypes.number.isRequired,
}

export default Splitter
