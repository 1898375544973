import styled from 'styled-components'

const EmbedPlayerUi = styled.div.attrs(p => ({
  id: p.attrId,
}))`
  pointer-events: none;
  position: relative;
  padding-top: 0;
  //height: 0;
  height: auto; // allow to use vertical video
  overflow: hidden;

  :has(>iframe) {
    padding-bottom: 56.5%;
  }

  & iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-width: 0;
  }
  ${p => p.theme.phone} {
    ${({ mobileStyles = {} }) => mobileStyles}
  }
  ${({ styles = {} }) => styles}
`

export default EmbedPlayerUi
