import styled from 'styled-components'

const PopupEditButtonUi = styled.div`
  background: none;
  position: absolute;
  z-index: 10;
  top: -35px;
  left: 50%;
  transform: translateX(-50%);
`

export default PopupEditButtonUi

