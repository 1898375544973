import styled from 'styled-components'

const DialogSubmit = styled.button`
  background-color: var(--color-blue);
  position: relative;
  color: #f2f4f7;
  text-transform: uppercase;
  font-size: 12px;
  padding: 10px 15px;
  margin-left: 10px;
  border-radius: 3px;
  border: none;
  outline: none;
  cursor: pointer;
  &:disabled {
    opacity: 0.5;
    cursor: default;
    pointer-events: none;
  }
`

export default DialogSubmit
