import React from 'react'
import { useTranslation } from 'react-i18next'
import StyleWrapper from 'common/components/StyleWrapper'
import { wrapperStyles } from 'common/components/entities/Button'
import CommonButton from 'common/components/entities/Button/button'
import { BadRequest, InternalError, NetworkError } from 'common/errors'
import * as api from 'publisher/api/dLocalApi'
import FieldErrors from 'publisher/components/FieldErrors'
import usePage, { selectors as pageSelectors } from 'publisher/hooks/usePage'
import usePayment, {
  selectors as paymentSelectors,
} from 'publisher/hooks/usePayment'
import useUpsellSubmit from '../hooks/useUpsellSubmit'
import { UpsellPaymentButtonPropsType } from './UpsellPaymentButton'

function DLocalCreditCardUpsellButton({
  entity,
}: UpsellPaymentButtonPropsType) {
  const { t } = useTranslation()
  const purchaseProcessId = usePayment(paymentSelectors.getPurchaseProcessId)
  const pageId = usePage(pageSelectors.getPageId)
  const { errors, setErrors, isLoading, submit } = useUpsellSubmit(entity)

  const handleSubmit = async () => {
    await submit(async (body: any) => {
      try {
        const { data } = await api.buyUpsellOffer(pageId, purchaseProcessId, {
          upsell_payment_form: body,
        })
        const { redirect } = data

        window.location.assign(redirect)
      } catch (error) {
        if (error instanceof BadRequest) {
          setErrors(error.response.data.errors.common)
        } else if (error instanceof NetworkError) {
          setErrors([t('core.errors.no_connection')])
        } else if (error instanceof InternalError) {
          console.log('InternalError', error)
          setErrors([t('core.error.title')])
        }
      }
    })
  }

  return (
    <>
      <StyleWrapper
        styles={{ ...entity.margin, ...wrapperStyles }}
        mobileStyles={entity.mobileMargin}
        desktop={entity.appearance.desktop}
        mobile={entity.appearance.mobile}
      >
        <CommonButton
          onClick={handleSubmit}
          disabled={isLoading}
          text={entity.text}
          border={entity.border}
          mobileBorder={entity.mobileBorder}
          subText={entity.subText}
          padding={entity.padding}
          mobilePadding={entity.mobilePadding}
          alignSelf={entity.alignSelf}
          background={entity.background ?? entity.backgroundColor}
          mobileBackground={
            entity.mobileBackground ?? entity.mobileBackgroundColor
          }
          attrId={entity.htmlAttrId}
          width={entity.width}
          mobileWidth={entity.mobileWidth}
          textColor={entity.textColor}
          subTextColor={entity.subTextColor}
          mobileTextColor={entity.mobileTextColor}
          mobileSubTextColor={entity.mobileSubTextColor}
          textFontSize={entity.textFontSize}
          mobileTextFontSize={entity.mobileTextFontSize}
          subTextFontSize={entity.subTextFontSize}
          mobileSubTextFontSize={entity.mobileSubTextFontSize}
          textFontFamily={entity.textFontFamily}
          textFontWeight={entity.textFontWeight}
          textFontStyle={entity.textFontStyle}
          subTextFontFamily={entity.subTextFontFamily}
          subTextFontWeight={entity.subTextFontWeight}
          subTextFontStyle={entity.subTextFontStyle}
          mobileTextFontFamily={entity.mobileTextFontFamily}
          mobileTextFontWeight={entity.mobileTextFontWeight}
          mobileTextFontStyle={entity.mobileTextFontStyle}
          mobileSubTextFontFamily={entity.mobileSubTextFontFamily}
          mobileSubTextFontWeight={entity.mobileSubTextFontWeight}
          mobileSubTextFontStyle={entity.mobileSubTextFontStyle}
          boxShadow={entity.boxShadow}
          mobileBoxShadow={entity.mobileBoxShadow}
          lineHeight={entity.lineHeight}
          mobileLineHeight={entity.mobileLineHeight}
          iconClassNameBefore={entity.iconClassNameBefore}
          iconClassNameAfter={entity.iconClassNameAfter}
          hover={entity.hover}
          mobileHover={entity.mobileHover}
        />
      </StyleWrapper>
      <FieldErrors errors={errors} align="center" />
    </>
  )
}

export default DLocalCreditCardUpsellButton
