import styled from 'styled-components'

const ColorUi = styled.div`
  width: 24px;
  height: 24px;
  position: relative;
  margin-top: 4px;
  margin-left: 4px;
  border-radius: 3px;
`

export default ColorUi
