import React from 'react'
import PropTypes from 'prop-types'
import * as types from 'common/types/index'
import PricePlanItemUi from './ui/PricePlanItem'
import PricePlanValue from './PricePlanValue'

const Total = ({
  plan,
  tax = 0,
  quantity = 1,
  translate,
  coupon,
  language,
}) => (
  <PricePlanItemUi description={translate('components.offer_price.total')}>
    <PricePlanValue
      coupon={coupon}
      plan={plan}
      tax={tax}
      quantity={quantity}
      translate={translate}
      language={language}
    />
  </PricePlanItemUi>
)

Total.propTypes = {
  plan: types.offerPricePlan.isRequired,
  tax: PropTypes.number,
  translate: PropTypes.func.isRequired,
  quantity: PropTypes.number,
  language: PropTypes.string.isRequired,
}

export default Total
