import React from 'react'
import CommonTwitterTweetButton from 'common/components/entities/TwitterTweetButton'
import { TwitterTweetButtonInterface } from 'common/types/entities/TwitterTweetButtonInterface'
import BaseEntityNew, {
  BaseEntityProps,
} from 'client/components/core/BaseEntity/BaseEntityNew'

function TwitterTweetButton({
  entity,
  isMoveDownAllowed,
  isMoveUpAllowed,
}: { entity: TwitterTweetButtonInterface } & Pick<
  BaseEntityProps,
  'isMoveDownAllowed' | 'isMoveUpAllowed'
>) {
  return (
    <BaseEntityNew
      entity={entity}
      isMoveUpAllowed={isMoveUpAllowed}
      isMoveDownAllowed={isMoveDownAllowed}
    >
      <CommonTwitterTweetButton
        text={entity.text}
        alignSelf={entity.alignSelf}
        mobileAlignSelf={entity.mobileAlignSelf}
      />
    </BaseEntityNew>
  )
}

export default TwitterTweetButton
