import React from 'react'
import { PaddingType } from 'common/types/styleTypes'
import BodyUi from './ui/BodyUi'

type BodyNewProps = {
  htmlAttrId: string
  padding: PaddingType
  mobilePadding: PaddingType
  children: React.ReactChild
}

function BodyNew({
  htmlAttrId,
  padding,
  mobilePadding,
  children,
}: BodyNewProps) {
  return (
    <BodyUi id={htmlAttrId} padding={padding} mobilePadding={mobilePadding}>
      {children}
    </BodyUi>
  )
}

export default BodyNew
