import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { OldEntityInterface } from 'common/types/entities/OldEntityInterface'
import { PaymentButtonInterface } from 'common/types/entities/PaymentButtonInterface'
import { buyMainOffer } from 'publisher/api/xenditApi'
import { useXendit } from 'publisher/context/XenditContext'
import { XenditCreditCardStatusEnum } from 'publisher/enums/XenditCreditCardStatusEnum'
import { XenditFieldTypeEnum } from 'publisher/enums/XenditFieldTypeEnum'
import usePaymentSubmit from 'publisher/hooks/payment/usePaymentSubmit'
import usePage, { selectors as pageSelectors } from 'publisher/hooks/usePage'
import { usePayment } from 'publisher/store'
import {
  getActivePricePlan,
  getProduct,
} from 'publisher/store/payment/paymentSelectors'
import { FieldSlugEnum } from 'common/enums/FieldSlugEnum'
import {
  getFieldErrorsBySlug,
  getFieldValueBySlug,
} from 'publisher/store/optIn/optInSelectors'
import { useOptIn } from 'publisher/store'
import { useRollbar } from '@rollbar/react'

interface CreditCardTokenInterface {
  id: string
  payer_authentication_url: string
  status: XenditCreditCardStatusEnum
  credit_card_token_id: string
}

export default function useXenditPaymentHandler(
  entity: OldEntityInterface | PaymentButtonInterface,
) {
  const { t } = useTranslation()
  const rollbar = useRollbar()
  const { errors, setErrors, isLoading, submit } = usePaymentSubmit(entity)
  const [isXenditOperationsLoading, setIsXenditOperationsLoading] =
    useState(false)

  const product = usePayment(getProduct)
  const pricePlan = usePayment(getActivePricePlan)

  const pageId = usePage(pageSelectors.getPageId)
  const {
    fields,
    handleXenditVerification,
    setIsSecureModalVisible,
    setXenditOtpUrl,
  } = useXendit()

  const phoneNumber = useOptIn(optIn =>
    getFieldValueBySlug(optIn, FieldSlugEnum.PhoneNumber),
  )
  const phoneNumberErrors = useOptIn(optIn =>
    getFieldErrorsBySlug(optIn, FieldSlugEnum.PhoneNumber),
  )

  const email = useOptIn(optIn =>
    getFieldValueBySlug(optIn, FieldSlugEnum.Email),
  )
  const emailErrors = useOptIn(optIn =>
    getFieldErrorsBySlug(optIn, FieldSlugEnum.Email),
  )

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    if (window.Xendit) {
      const cardNumberValidationResult = handleXenditVerification(
        XenditFieldTypeEnum.card_number,
        fields.cardNumber,
      )
      const cardExpiryDateValidationResult = handleXenditVerification(
        XenditFieldTypeEnum.expiry_date,
        fields.month,
        fields.year,
      )

      const cardCvvValidationResult = handleXenditVerification(
        XenditFieldTypeEnum.cvn_number,
        fields.cvv,
      )

      const cardHolderFirstNameValidationResult = handleXenditVerification(
        XenditFieldTypeEnum.card_holder_first_name,
        fields.cardHolderFirstName,
      )

      const cardHolderLastNameValidationResult = handleXenditVerification(
        XenditFieldTypeEnum.card_holder_last_name,
        fields.cardHolderLastName,
      )

      setIsXenditOperationsLoading(true)
      if (
        !cardNumberValidationResult ||
        !cardExpiryDateValidationResult ||
        !cardCvvValidationResult ||
        !cardHolderFirstNameValidationResult ||
        !cardHolderLastNameValidationResult ||
        !!emailErrors?.length ||
        !!phoneNumberErrors?.length
      ) {
        setIsXenditOperationsLoading(false)
        return
      }
      window.Xendit.card.createToken(
        {
          amount: fields.amount,
          card_number: fields.cardNumber,
          card_exp_month: fields.month,
          card_exp_year: fields.year,
          card_cvn: fields.cvv,
          card_holder_first_name: fields.cardHolderName,
          card_holder_last_name: fields.cardHolderName,
          card_holder_email: email,
          card_holder_phone_number: phoneNumber ? `+${phoneNumber}` : '',
          is_multiple_use: true,
          should_authenticate: true,
          currency: product?.currency ?? pricePlan?.currency,
        },
        xenditTokenResponseHandler,
      )
    }

    function xenditTokenResponseHandler(
      err: {
        error_code: string
        message: string
      },
      creditCardCharge: CreditCardTokenInterface,
    ) {
      if (err) {
        setErrors([t('core.error.title')])
        rollbar.error(err)
        return
      }

      if (creditCardCharge.status === XenditCreditCardStatusEnum.verified) {
        window.Xendit.card.createAuthentication(
          getAuth(creditCardCharge),
          xenditAuthenticationResponseHandler,
        )
      } else if (
        creditCardCharge.status === XenditCreditCardStatusEnum.in_review
      ) {
        // opens 3ds window for confirmation if there is no use_multiple config
        setXenditOtpUrl(creditCardCharge.payer_authentication_url)
        setIsSecureModalVisible(true)
      } else if (
        creditCardCharge.status === XenditCreditCardStatusEnum.failed
      ) {
        setIsXenditOperationsLoading(false)
        setErrors([t('core.error.title')])
      }
    }
  }

  function xenditAuthenticationResponseHandler(
    err: {
      error_code: string
      message: string
    },
    creditCardToken: CreditCardTokenInterface,
  ) {
    if (err) {
      setErrors([t('core.error.title')])
      rollbar.error(err)
      return
    } else if (
      creditCardToken.status === XenditCreditCardStatusEnum.in_review
    ) {
      setXenditOtpUrl(creditCardToken.payer_authentication_url)
      setIsSecureModalVisible(true)
    } else if (creditCardToken.status === XenditCreditCardStatusEnum.verified) {
      setTimeout(() => {
        setIsSecureModalVisible(false)
        setXenditOtpUrl('')
      }, 1000)
      setIsXenditOperationsLoading(false)
      submit(
        async body => {
          const { data } = await buyMainOffer(pageId, {
            payment_form: {
              ...body,
              options: {
                token: creditCardToken.credit_card_token_id,
              },
            },
          })
          if (data.redirect) {
            window.location.assign(data.redirect)
            return
          }
        },
        () => setIsXenditOperationsLoading(false),
      )
    } else {
      setIsXenditOperationsLoading(false)
      setErrors([t('core.error.title')])
    }
  }

  function getAuth(creditCardToken: any) {
    return {
      amount: fields.amount,
      token_id: creditCardToken.id,
    }
  }

  return {
    errors,
    isLoading: isXenditOperationsLoading ? true : isLoading,
    handleSubmit,
  }
}
