import { useRollbar } from '@rollbar/react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import StyleWrapper from 'common/components/StyleWrapper'
import { wrapperStyles } from 'common/components/entities/Button'
import CommonButton from 'common/components/entities/Button/button'
import { BadRequest, InternalError, NetworkError } from 'common/errors'
import FieldErrors from 'publisher/components/FieldErrors'
import { usePage, usePayment } from 'publisher/store'
import { getPageId } from 'publisher/store/page/pageSelectors'
import {
  getActivePricePlan,
  getPurchaseProcessId,
} from 'publisher/store/payment/paymentSelectors'
import paymentSelectors from 'publisher/store/payment/paymentSelectors'
import { buyUpsell } from '../../../api/razorpayApi'
import useRazorpay from '../../../hooks/payment/useRazorpay'
import useUpsellSubmit from '../hooks/useUpsellSubmit'
import { UpsellPaymentButtonPropsType } from './UpsellPaymentButton'

function RazorpayUpsellButton({ entity }: UpsellPaymentButtonPropsType) {
  const rollbar = useRollbar()
  const { t } = useTranslation()
  const purchaseProcessId = usePayment(getPurchaseProcessId)
  const pageId = usePage(getPageId)
  const { errors, setErrors, isLoading, submit } = useUpsellSubmit(entity)
  const { Razorpay, isRazorpayFetching, isRazorpayReady, generateOptions } =
    useRazorpay({ setErrors })
  const pricePlan = usePayment(getActivePricePlan)
  const product = usePayment(paymentSelectors.getProduct)

  const handleSubmit = async () => {
    if (!pricePlan && !product?.id) {
      throw Error('price plan should be defined')
    }

    await submit(async (body: any) => {
      try {
        const { data } = await buyUpsell(pageId, purchaseProcessId, {
          upsell_payment_form: body,
        })

        if (isRazorpayReady) {
          const razorpay = new Razorpay(
            generateOptions(data, pricePlan, false, product),
          )
          razorpay.open()
        } else {
          throw new Error('form submitted before razorpay was ready')
        }
      } catch (error) {
        if (error instanceof BadRequest) {
          setErrors(error.response.data.errors.common)
        } else if (error instanceof NetworkError) {
          setErrors([t('core.errors.no_connection')])
        } else if (error instanceof InternalError) {
          setErrors([t('core.error.title')])
        } else {
          rollbar.error(`Razorpay upsell failed`, error as Error)
        }
      }
    })
  }

  return (
    <>
      <StyleWrapper
        styles={{ ...entity.margin, ...wrapperStyles }}
        mobileStyles={entity.mobileMargin}
        desktop={entity.appearance.desktop}
        mobile={entity.appearance.mobile}
      >
        <CommonButton
          onClick={handleSubmit}
          disabled={isLoading || isRazorpayFetching || !isRazorpayReady}
          text={entity.text}
          border={entity.border}
          mobileBorder={entity.mobileBorder}
          subText={entity.subText}
          padding={entity.padding}
          mobilePadding={entity.mobilePadding}
          alignSelf={entity.alignSelf}
          background={entity.background ?? entity.backgroundColor}
          mobileBackground={
            entity.mobileBackground ?? entity.mobileBackgroundColor
          }
          attrId={entity.htmlAttrId}
          width={entity.width}
          mobileWidth={entity.mobileWidth}
          textColor={entity.textColor}
          subTextColor={entity.subTextColor}
          mobileTextColor={entity.mobileTextColor}
          mobileSubTextColor={entity.mobileSubTextColor}
          textFontSize={entity.textFontSize}
          mobileTextFontSize={entity.mobileTextFontSize}
          subTextFontSize={entity.subTextFontSize}
          mobileSubTextFontSize={entity.mobileSubTextFontSize}
          textFontFamily={entity.textFontFamily}
          textFontWeight={entity.textFontWeight}
          textFontStyle={entity.textFontStyle}
          subTextFontFamily={entity.subTextFontFamily}
          subTextFontWeight={entity.subTextFontWeight}
          subTextFontStyle={entity.subTextFontStyle}
          mobileTextFontFamily={entity.mobileTextFontFamily}
          mobileTextFontWeight={entity.mobileTextFontWeight}
          mobileTextFontStyle={entity.mobileTextFontStyle}
          mobileSubTextFontFamily={entity.mobileSubTextFontFamily}
          mobileSubTextFontWeight={entity.mobileSubTextFontWeight}
          mobileSubTextFontStyle={entity.mobileSubTextFontStyle}
          boxShadow={entity.boxShadow}
          mobileBoxShadow={entity.mobileBoxShadow}
          lineHeight={entity.lineHeight}
          mobileLineHeight={entity.mobileLineHeight}
          iconClassNameBefore={entity.iconClassNameBefore}
          iconClassNameAfter={entity.iconClassNameAfter}
          hover={entity.hover}
          mobileHover={entity.mobileHover}
        />
      </StyleWrapper>
      <FieldErrors errors={errors} align="center" />
    </>
  )
}

export default RazorpayUpsellButton
