import styled from 'styled-components'

const MenuInputGroupUi = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 3px;
  padding: 2px 0;
  margin-right: 5px;
  height: 26px;
  display: flex;
  align-items: center;
`

export default MenuInputGroupUi
