import React from 'react'
import { useTranslation } from 'react-i18next'
import CommonBlogPostCategories from 'common/components/entities/BlogPostCategories/BlogPostCategoriesNew'
import EntityTypeEnum from 'common/enums/entityTypeEnum'
import { BlogPostCategoriesInterface } from 'common/types/entities/BlogPostCategoriesInterface'
import { MasterBlockId } from 'common/types/entities/EntityInterface'
import Badge from 'client/components/core/Badge'
import BaseEntityNew from 'client/components/core/BaseEntity/BaseEntityNew'
import { generateBaseEntity } from 'client/utils/createStructureUtils'
import { EntityProps } from '../../../types'

const categories = ['Lorem', 'Ipsum']

function BlogPostCategoriesNew({
  entity,
  isMoveDownAllowed,
  isMoveUpAllowed,
}: EntityProps<BlogPostCategoriesInterface>) {
  const { t } = useTranslation()

  return (
    <BaseEntityNew
      entity={entity}
      isMoveUpAllowed={isMoveUpAllowed}
      isMoveDownAllowed={isMoveDownAllowed}
    >
      <>
        <CommonBlogPostCategories
          categories={categories}
          attrId={entity.htmlAttrId}
          fontSize={entity.fontSize}
          fontFamily={entity.fontFamily}
          fontStyle={entity.fontStyle}
          fontWeight={entity.fontWeight}
          mobileFontSize={entity.mobileFontSize}
          mobileFontFamily={entity.mobileFontFamily}
          mobileFontStyle={entity.mobileFontStyle}
          mobileFontWeight={entity.mobileFontWeight}
          lineHeight={entity.lineHeight}
          mobileLineHeight={entity.mobileLineHeight}
          color={entity.color}
          mobileColor={entity.mobileColor}
          alignSelf={entity.alignSelf}
          mobileAlignSelf={entity.mobileAlignSelf}
        />
        <Badge wide>
          {t('entities.blog_post_date.warnings.do_not_remove')}
        </Badge>
      </>
    </BaseEntityNew>
  )
}

export function createBlogPostCategories(
  parentId: string,
  masterBlockId?: MasterBlockId,
) {
  return {
    ...generateBaseEntity(
      EntityTypeEnum.BlogPostCategories,
      parentId,
      masterBlockId,
    ),
    type: EntityTypeEnum.BlogPostCategories,
    parentId: parentId,
    fontSize: 12,
    color: 'rgba(0, 116, 199, 1)',
    mobileFontSize: 12,
    alignSelf: 'left',
  } as BlogPostCategoriesInterface
}

export default BlogPostCategoriesNew
