import Rollbar from 'rollbar'
import { TFunction } from 'i18next'
import { formOptions } from 'publisher/context/mercadoPago/config/formOptions'
import { MercadoPageFormError } from 'publisher/context/mercadoPago/types/MercadoPagoFormErrorInterface'
import {
  MercadoPagoCardFormInterface,
  MercadoPagoField,
  ValidityChangeResponseInterface,
} from 'publisher/context/mercadoPago/types/MercadoPagoInterface'
import { getFieldError } from 'publisher/context/mercadoPago/utils/getFieldError'
import {
  MeracdoErrorCause,
  MercadoPago,
  MercadoPagoServerError,
} from 'publisher/context/mercadoPagoContext'

export const isMercadoError = (
  error?: unknown,
): error is MercadoPagoServerError =>
  !!error && typeof error === 'object' && 'cause' in error

export const isMercadoErrorCausesArray = (
  error?: unknown,
): error is MercadoPagoServerError['cause'] =>
  !!error &&
  Array.isArray(error) &&
  error.every(error => 'code' in error && 'message' in error)

export const getProcessedMercadoErrorCauses = (
  causes: MeracdoErrorCause[],
  t: TFunction,
) => {
  const cardFormErrors: string[] = []

  causes.forEach(cause => {
    const errorMessage = getFieldError(cause.code)?.message

    if (errorMessage) {
      cardFormErrors.push(t(errorMessage))
    }
  })

  return cardFormErrors
}

export const initCardForm = (
  mercadoPago: MercadoPago,
  pricePlanAmount: number,
  addError: (field: MercadoPagoField, message: string) => void,
  resetError: (field: MercadoPagoField) => void,
  t: TFunction,
  rollbar: Rollbar,
): MercadoPagoCardFormInterface => {
  return mercadoPago.cardForm({
    amount: `${pricePlanAmount}`,
    form: formOptions,
    callbacks: {
      onFormMounted: (error: any) => {
        if (error) {
          rollbar.error(`Mercadopago payment mount failed`, error)
        }
      },
      onValidityChange: (
        errors?: ValidityChangeResponseInterface[],
        field?: MercadoPagoField,
      ) => {
        // attention, unstable responses
        if (errors && errors.length > 0 && field) {
          errors.forEach(error => {
            const fieldError = getFieldError(error.code)
            if (fieldError) {
              addError(fieldError.field, t(fieldError.message))
            }
          })
        } else if (field) {
          resetError(field)
        }
      },
      onCardTokenReceived: (
        errors?: MercadoPagoServerError | MercadoPageFormError[] | string,
      ) => {
        if (typeof errors === 'string') {
          rollbar.error('onCardTokenReceived error', errors)
        } else if (isMercadoError(errors)) {
          errors.cause.forEach(cause => {
            const error = getFieldError(cause.code)
            if (error) {
              const { field, message } = error
              addError(field, t(message))
            }
          })
        } else if (Array.isArray(errors)) {
          Object.values(errors).forEach(error => {
            const fieldError = getFieldError(error.code)
            if (fieldError) {
              const { field, message } = fieldError
              addError(field, t(message))
            }
          })
        }
      },
      onSubmit: async (event: any) => {
        event.preventDefault()
      },
    },
  })
}
