import EntityTypeEnum from 'common/enums/entityTypeEnum'
import { MasterBlockId } from 'common/types/entities/EntityInterface'
import { FacebookCommentsInterface } from 'common/types/entities/FacebookCommentsInterface'
import { generateBaseEntity } from 'client/utils/createStructureUtils'

export const createFacebookComments = (
  parentId: string,
  masterBlockId?: MasterBlockId,
): FacebookCommentsInterface => {
  return {
    ...generateBaseEntity(
      EntityTypeEnum.FacebookComments,
      parentId,
      masterBlockId,
    ),
    type: EntityTypeEnum.FacebookComments,
    delay: 0,
    href: '',
    numPosts: 5,
  }
}
