import structureTypes from 'common/constants/structureTypes'
import EntityTypeEnum, {
  getReadableRootEntityTypeByPageType,
} from 'common/enums/entityTypeEnum'
import { BaseEntityInterface } from 'common/types/entities/EntityInterface'
import {
  OldEntityInterface,
  OldTextEntityInterface,
} from 'common/types/entities/OldEntityInterface'
import * as entityUtils from 'common/utils/entityUtils'
import { getSortedPopupList } from 'client/utils/getSortedPopupList'
import { EntityType } from '../../types'
import { Entities, PresentPage } from '../index'

function getEntitiesByType(pageState: PresentPage, type: EntityType) {
  return Object.values(pageState.entities).filter(
    entity => entity.type === type,
  )
}

function getOrderedByPositionHeadings(
  pageState: PresentPage,
  isMobile: boolean,
) {
  return entityUtils.getOrderedByPositionHeadings(
    pageState.entities,
    isMobile,
    pageState.type,
  ) as OldTextEntityInterface[]
}

export function getFirstEntityByType(
  pageState: PresentPage,
  type: EntityTypeEnum | keyof typeof structureTypes,
) {
  return Object.values(pageState.entities).find(entity => entity.type === type)
}

function getEntityParents(
  entities: Entities,
  targetEntity: BaseEntityInterface | OldEntityInterface,
  parentIds: string[] = [],
): Entities {
  return Object.values(entities)
    .filter(entity => entity.parentId && entity.id === targetEntity.id)
    .reduce(
      (acc, cur) => ({
        ...acc,
        [cur.id]: cur,
        ...(cur.parentId && entities[cur.parentId]
          ? getEntityParents(entities, entities[cur.parentId], parentIds)
          : null),
      }),
      {} as Entities,
    )
}

function getGlobalFontProperties({
  globalSettings,
  parentGlobalSettings,
}: PresentPage) {
  if (!parentGlobalSettings) {
    return {
      fontFamily: globalSettings.fontFamily,
      fontStyle: globalSettings.fontStyle,
      fontWeight: globalSettings.fontWeight,
      fontFileId: globalSettings.fontFileId,
      headingFontFamily: globalSettings.headingFontFamily,
      headingFontStyle: globalSettings.headingFontStyle,
      headingFontWeight: globalSettings.headingFontWeight,
      headingFontFileId: globalSettings.headingFontFileId,
    }
  }

  return {
    fontFamily: globalSettings.fontFamily || parentGlobalSettings.fontFamily,
    fontStyle: globalSettings.fontStyle || parentGlobalSettings.fontStyle,
    fontWeight: globalSettings.fontWeight || parentGlobalSettings.fontWeight,
    fontFileId: globalSettings.fontFileId || parentGlobalSettings.fontFileId,
    headingFontFamily:
      globalSettings.headingFontFamily ||
      parentGlobalSettings.headingFontFamily,
    headingFontStyle:
      globalSettings.headingFontStyle || parentGlobalSettings.headingFontStyle,
    headingFontWeight:
      globalSettings.headingFontWeight ||
      parentGlobalSettings.headingFontWeight,
    headingFontFileId:
      globalSettings.headingFontFileId ||
      parentGlobalSettings.headingFontFileId,
  }
}

function getSeo(pageState: PresentPage) {
  return pageState.seo
}

function getGlobalFontFamily(pageState: PresentPage) {
  return (
    pageState.globalSettings.fontFamily ||
    (pageState.parentGlobalSettings &&
      pageState.parentGlobalSettings.fontFamily)
  )
}

export function getEntitiesByTypes(pageState: PresentPage, types: string[]) {
  return Object.values(pageState.entities).filter(entity =>
    types.includes(entity.type),
  )
}

export function getPageType(pageState: PresentPage) {
  return pageState.type
}

function getPageId(pageState: PresentPage) {
  return pageState.id
}

export function getReadableRootEntity(pageState: PresentPage) {
  return Object.values(pageState.entities).find(entity =>
    getReadableRootEntityTypeByPageType(pageState.type).includes(entity.type),
  )
}

function getPopupEntities(pageState: PresentPage) {
  const popupEntities = Object.values(pageState.entities).filter(
    entity => entity.type === structureTypes.POPUP,
  ) as OldEntityInterface[]
  return getSortedPopupList(popupEntities)
}

function getGlobalLinkColor(state: PresentPage) {
  return (
    state.globalSettings.linkColor ||
    (state.parentGlobalSettings && state.parentGlobalSettings.linkColor)
  )
}

function getGlobalTextColor(state: PresentPage) {
  return (
    state.globalSettings.textColor ||
    (state.parentGlobalSettings && state.parentGlobalSettings.textColor)
  )
}

function getGlobalHeadingColor(state: PresentPage) {
  return (
    state.globalSettings.headingColor ||
    (state.parentGlobalSettings && state.parentGlobalSettings.headingColor)
  )
}

function getGlobalTextAlign(state: PresentPage) {
  return (
    state.globalSettings.textAlign ||
    (state.parentGlobalSettings && state.parentGlobalSettings.textAlign) ||
    'center'
  )
}

function getGlobalMobileTextAlign(state: PresentPage) {
  return (
    state.globalSettings.mobileTextAlign ||
    (state.parentGlobalSettings &&
      state.parentGlobalSettings.mobileTextAlign) ||
    getGlobalTextAlign(state)
  )
}

function getGlobalHeadingAlign(state: PresentPage) {
  return (
    state.globalSettings.headingAlign ||
    (state.parentGlobalSettings && state.parentGlobalSettings.headingAlign) ||
    'center'
  )
}

function getGlobalMobileHeadingAlign(state: PresentPage) {
  return (
    state.globalSettings.mobileHeadingAlign ||
    (state.parentGlobalSettings &&
      state.parentGlobalSettings.mobileHeadingAlign) ||
    getGlobalHeadingAlign(state)
  )
}

function getGlobalTextFontSize(state: PresentPage) {
  if (state.globalSettings.textFontSize) {
    return parseInt(state.globalSettings.textFontSize)
  }
  if (state.parentGlobalSettings && state.parentGlobalSettings.textFontSize) {
    return parseInt(state.parentGlobalSettings.textFontSize)
  }
}

function getGlobalMobileTextFontSize(state: PresentPage) {
  if (state.globalSettings.mobileTextFontSize) {
    return parseInt(state.globalSettings.mobileTextFontSize)
  }
  if (
    state.parentGlobalSettings &&
    state.parentGlobalSettings.mobileTextFontSize
  ) {
    return parseInt(state.parentGlobalSettings.mobileTextFontSize)
  }
}

function getGlobalTextLineHeight(state: PresentPage) {
  if (state.globalSettings.textLineHeight) {
    return parseInt(state.globalSettings.textLineHeight)
  }
  if (state.parentGlobalSettings && state.parentGlobalSettings.textLineHeight) {
    return parseInt(state.parentGlobalSettings.textLineHeight)
  }
}

function getGlobalMobileTextLineHeight(state: PresentPage) {
  if (state.globalSettings.mobileTextLineHeight) {
    return parseInt(state.globalSettings.mobileTextLineHeight)
  }
  if (
    state.parentGlobalSettings &&
    state.parentGlobalSettings.mobileTextLineHeight
  ) {
    return parseInt(state.parentGlobalSettings.mobileTextLineHeight)
  }
}

function isPageTemplate(state: PresentPage) {
  return state.isTemplate
}

function getBreadcrumbs(state: PresentPage, entityId?: string) {
  return entityId && state.entities[entityId]
    ? getEntityParents(state.entities, state.entities[entityId])
    : {}
}

function getPageLocale(state: PresentPage) {
  return state.locale
}

function getDescendantIds(state: PresentPage, entityId: string) {
  return entityUtils.getDescendantIds(state.entities, entityId)
}

function getEntityById(state: PresentPage, entityId: string | null) {
  if (!entityId) {
    return null
  }
  return state.entities[entityId] || null
}

function getEntityDescendantsContactUsElements(
  state: PresentPage,
  entityId: string,
) {
  const contactUsFields = entityUtils.getAllDescendantsByEntityType(
    state.entities,
    entityId,
    EntityTypeEnum.ContactUsField,
  )
  const oldContactUsRecaptcha = entityUtils.getAllDescendantsByEntityType(
    state.entities,
    entityId,
    EntityTypeEnum.Recaptcha,
  )
  const contactUsRecaptcha = entityUtils.getAllDescendantsByEntityType(
    state.entities,
    entityId,
    EntityTypeEnum.ContactUsRecaptcha,
  )
  const contactUsAttachments = entityUtils.getAllDescendantsByEntityType(
    state.entities,
    entityId,
    EntityTypeEnum.Attachments,
  )
  const contactUsButton = entityUtils.getAllDescendantsByEntityType(
    state.entities,
    entityId,
    EntityTypeEnum.Button,
  )
  return contactUsFields.concat(
    oldContactUsRecaptcha,
    contactUsRecaptcha,
    contactUsAttachments,
    contactUsButton,
  )
}

function getGlobalHeadingFontFamily(pageState: PresentPage) {
  return (
    pageState.globalSettings.headingFontFamily ||
    (pageState.parentGlobalSettings &&
      pageState.parentGlobalSettings.headingFontFamily)
  )
}

function getCaptchaEntities(pageState: PresentPage) {
  return Object.values(pageState.entities).filter(
    entity =>
      entity.type === EntityTypeEnum.Recaptcha ||
      entity.type === EntityTypeEnum.ContactUsRecaptcha,
  )
}

function getPopups(pageState: PresentPage) {
  return Object.values(pageState.entities).filter(
    entity => entity.type === structureTypes.POPUP,
  )
}

function getGlobalSettings(pageState: PresentPage) {
  return pageState.globalSettings
}

function getParentGlobalSettings(pageState: PresentPage) {
  return pageState.parentGlobalSettings
}

function getDoubleOptIn(pageState: PresentPage) {
  return pageState.doubleOptIn
}
export const pageSelectors = {
  getGlobalTextAlign,
  getGlobalMobileTextAlign,
  getGlobalHeadingAlign,
  getGlobalMobileHeadingAlign,
  getReadableRootEntity,
  getGlobalLinkColor,
  getGlobalTextColor,
  getEntitiesByType,
  getBreadcrumbs,
  getPageLocale,
  isPageTemplate,
  getFirstEntityByType,
  getGlobalFontProperties,
  getGlobalFontFamily,
  getEntitiesByTypes,
  getPageType,
  getPageId,
  getPopupEntities,
  getGlobalTextFontSize,
  getGlobalMobileTextFontSize,
  getGlobalTextLineHeight,
  getGlobalMobileTextLineHeight,
  getOrderedByPositionHeadings,
  getDescendantIds,
  getEntityById,
  getGlobalHeadingColor,
  getGlobalHeadingFontFamily,
  getCaptchaEntities,
  getPopups,
  getSeo,
  getGlobalSettings,
  getParentGlobalSettings,
  getDoubleOptIn,
  getEntityDescendantsContactUsElements,
}

export default pageSelectors
