// @ts-nocheck
import React, { useState } from 'react'
import {
  Saturation,
  Hue,
  Alpha,
  Checkboard, //@ts-ignore
} from 'react-color/lib/components/common'
import ColorWrap from 'react-color/lib/components/common/ColorWrap'
//@ts-ignore
import SketchFields from 'react-color/lib/components/sketch/SketchFields'
//@ts-ignore
import SketchPresetColors from 'react-color/lib/components/sketch/SketchPresetColors'
//@ts-ignore
import { useTranslation } from 'react-i18next'
import { getColorAsString } from 'common/utils/colorHelpers'
import CustomPresetColors from './CustomPresetColors'
import ActiveColorBoxUi from './ui/ActiveColorBoxUi'
import AlphaUi from './ui/AlphaUi'
import ColorPickerUi from './ui/ColorPickerUi'
import ColorUi from './ui/ColorUi'
import ControlsUi from './ui/ControlsUi'
import HueUi from './ui/HueUi'
import PresetLabelUi from './ui/PresetLabelUi'
import SaturationUi from './ui/SaturationUi'
import SlidersUi from './ui/SlidersUi'

const presetColors = [
  '#D0021B',
  '#F5A623',
  '#F8E71C',
  '#8B572A',
  '#7ED321',
  '#417505',
  '#BD10E0',
  '#9013FE',
  '#4A90E2',
  '#50E3C2',
  '#B8E986',
  '#000000',
  '#4A4A4A',
  '#9B9B9B',
  '#FFFFFF',
  'transparent',
]

type NewColorPickerPropsType = {
  rgb: RGBColorType
  hex: string
  hsv: HSVColorType
  hsl: HSLColorType
  onChange: (
    color: string | HSLColorType | RGBColorType | HSVColorType,
    event: React.MouseEvent<HTMLElement>,
  ) => void
}

export type HSLColorType = {
  a?: number | undefined
  h: number
  l: number
  s: number
}

export type HSVColorType = {
  a?: number | undefined
  h: number
  v: number
  s: number
}

export type RGBColorType = {
  a?: number | undefined
  b: number
  g: number
  r: number
}

function NewColorPickerPopup({
  rgb,
  hex,
  hsv,
  hsl,
  onChange,
}: NewColorPickerPropsType) {
  const activeColor = getColorAsString(rgb)
  const { t } = useTranslation()

  return (
    <ColorPickerUi>
      <SaturationUi>
        <Saturation hsl={hsl} hsv={hsv} onChange={onChange} />
      </SaturationUi>
      <ControlsUi className="flexbox-fix">
        <SlidersUi>
          <HueUi>
            <Hue hsl={hsl} onChange={onChange} />
          </HueUi>
          <AlphaUi>
            <Alpha rgb={rgb} hsl={hsl} onChange={onChange} />
          </AlphaUi>
        </SlidersUi>
        <ColorUi>
          <Checkboard />
          <ActiveColorBoxUi
            className="box-color"
            backgroundColor={activeColor}
          />
        </ColorUi>
      </ControlsUi>
      <SketchFields rgb={rgb} hsl={hsl} hex={hex} onChange={onChange} />
      <PresetLabelUi>
        {t('entity_options.color_picker.default_colors')}
      </PresetLabelUi>
      <SketchPresetColors colors={presetColors} onClick={onChange} />
      <PresetLabelUi>
        {t('entity_options.color_picker.user_colors')}
      </PresetLabelUi>
      <CustomPresetColors onClick={onChange} activeColor={activeColor} />
    </ColorPickerUi>
  )
}

export { NewColorPickerPopup }

export default ColorWrap(NewColorPickerPopup)
