import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { offerPricePlan } from 'common/types/index'
import centsToEuro from 'common/utils/centsToEuro'
import { getNetPrice, getReadablePrice } from 'common/utils/priceCalculator'

export const PaymentDetails = ({
  amount,
  intervalCount,
  interval,
  limitOfPayments,
}) => {
  const { t } = useTranslation('common')
  return t('components.offer_price.price_plans.limited', {
    amount,
    interval: t(`price_plan.interval.${interval}`, {
      count: intervalCount,
    }),
    intervalWithLimitOfPayments: t(`price_plan.interval.${interval}`, {
      count: limitOfPayments * intervalCount,
    }),
  })
}

const LimitedSubscription = props => {
  const {
    translate,
    language,
    plan: { subscriptionPlan: plan, currency },
    tax = 0,
    quantity = 1,
    coupon = null,
  } = props
  const netPrice = getNetPrice(plan.amount, quantity, coupon)
  const interval = translate(`price_plan.trial_period.${plan.trialInterval}`, {
    count: plan.trialPeriod,
  })
  return (
    <React.Fragment>
      {plan.trialPeriod
        ? translate('components.offer_price.price_plans.trial_period_new', {
            trialPeriodPrice: translate(
              'components.offer_price.price_plans.trial_period_price',
            ),
            trialInterval: interval,
          })
        : ''}{' '}
      <PaymentDetails
        interval={plan.interval}
        intervalCount={plan.intervalCount}
        limitOfPayments={plan.limitOfPayments}
        amount={getReadablePrice(
          centsToEuro(netPrice + tax),
          currency,
          language,
        )}
      />
    </React.Fragment>
  )
}

LimitedSubscription.propTypes = {
  plan: offerPricePlan.isRequired,
  translate: PropTypes.func.isRequired,
  tax: PropTypes.number,
  quantity: PropTypes.number,
  coupon: PropTypes.shape({
    id: PropTypes.number.isRequired,
    discountAmount: PropTypes.string.isRequired,
    discountType: PropTypes.string.isRequired,
  }),
  language: PropTypes.string.isRequired,
}

export default LimitedSubscription
