import PropTypes from 'prop-types'
import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'
import * as types from 'common/types'
import CommonWebinarSessionDateTime from 'common/components/entities/WebinarSessionDateTime'
import BaseEntity from 'client/components/core/BaseEntity'
import usePresentPage from 'client/hooks/usePresentPage'
import { getPageLocale } from 'client/reducers/pageReducer'

function WebinarSessionDateTime({
  entity,
  isMoveDownAllowed,
  isMoveUpAllowed,
}) {
  const locale = usePresentPage(getPageLocale)
  const { i18n } = useTranslation()
  const fixedT = i18n.getFixedT(locale)
  return (
    <BaseEntity
      entity={entity}
      isMoveUpAllowed={isMoveUpAllowed}
      isMoveDownAllowed={isMoveDownAllowed}
    >
      {emulatedEntity => (
        <CommonWebinarSessionDateTime
          dateTimeString={fixedT(
            'entities.webinar_session_date_time.example_date_time_string',
          )}
          options={emulatedEntity.options}
          styles={emulatedEntity.styles}
          mobileStyles={emulatedEntity.mobileStyles}
        />
      )}
    </BaseEntity>
  )
}

WebinarSessionDateTime.propTypes = {
  entity: types.entity.isRequired,
  isMoveUpAllowed: PropTypes.bool.isRequired,
  isMoveDownAllowed: PropTypes.bool.isRequired,
}

export default memo(WebinarSessionDateTime)
