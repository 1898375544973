import styled from 'styled-components'

const ProductCarouselSlideUi = styled.div`
  position: relative;
  top: 0;
  min-width: 100%;
  margin: auto;
  scroll-snap-align: start;
  min-height: 1px;
  display: flex;
  gap: 5px;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
`
export default ProductCarouselSlideUi
