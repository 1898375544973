import { BaseEntityInterface } from 'common/types/entities/EntityInterface'
import { Entities } from 'client/store'
import { generateId } from './createStructureNew'

function resolveEntityIdConflicts(
  entity: BaseEntityInterface,
  oldNewIdsMap: OldNewIdsMap,
) {
  const newEntity = { ...entity }
  if (newEntity.parentId && oldNewIdsMap[newEntity.parentId]) {
    newEntity.parentId = oldNewIdsMap[newEntity.parentId]
  }

  if (newEntity.childIds) {
    newEntity.childIds = newEntity.childIds.map(id => oldNewIdsMap[id] || id)
  }

  if (oldNewIdsMap[newEntity.id]) {
    newEntity.id = oldNewIdsMap[newEntity.id]
  }

  return newEntity
}

type OldNewIdsMap = Record<BaseEntityInterface['id'], BaseEntityInterface['id']>

export function getEntitiesWithResolvedIdConflicts(
  newEntities: BaseEntityInterface[],
  entities: Entities,
) {
  // check that entities ids don't intersect with existing entities
  const blockEntityIds = newEntities.map(entity => entity.id)
  const presentEntityIds = Object.keys(entities)
  const intersectedIds = blockEntityIds.filter(id =>
    presentEntityIds.includes(id),
  )
  const oldNewIdsMap = intersectedIds.reduce((acc, id) => {
    acc[id] = generateId()
    return acc
  }, {} as OldNewIdsMap)
  // replace block entities ids with new ones if they intersect
  return newEntities.map(entity =>
    resolveEntityIdConflicts(entity, oldNewIdsMap),
  )
}
