import styled from 'styled-components'

type SwatchUiProps = {
  color: string
  isActive: boolean
}
const SwatchUi = styled.div<SwatchUiProps>`
  background: ${p => p.color};
  height: 100%;
  width: 100%;
  cursor: pointer;
  position: relative;
  outline: none;
  border-radius: 3px;
  transition: all 0.2s linear;
  transform: ${p => (p.isActive ? 'scale(1.32)' : 'scale(1)')};
  &:hover {
    transform: scale(1.25);
  }
  & > div {
    pointer-events: none;
  }
`

export default SwatchUi
