import React from 'react'
import { PaymentMethodEnum } from 'common/enums/PaymentMethodEnum'
import CashOnDelivery from 'publisher/pages/offer-page/components/PaymentMethod/PaymentMethodDetails/methods/CashOnDelivery'
import DLocalCreditCard from 'publisher/pages/offer-page/components/PaymentMethod/PaymentMethodDetails/methods/dlocal/CreditCard'
import MercadoPageCreditCard from 'publisher/pages/offer-page/components/PaymentMethod/PaymentMethodDetails/methods/mercadoPago/CreditCard'
import StripeCreditCard from 'publisher/pages/offer-page/components/PaymentMethod/PaymentMethodDetails/methods/stripe/CreditCard'
import IDeal from 'publisher/pages/offer-page/components/PaymentMethod/PaymentMethodDetails/methods/stripe/IDeal'
import XenditCreditCard from 'publisher/pages/offer-page/components/PaymentMethod/PaymentMethodDetails/methods/xendit/CreditCard'
import TaxNumber from 'publisher/pages/offer-page/components/PaymentMethod/PaymentMethodDetails/shared/components/TaxNumber'
import { usePayment } from 'publisher/store'
import { getPaymentMethods } from 'publisher/store/payment/paymentSelectors'

export const usePaymentMethodDetails = (
  paymentMethod: PaymentMethodEnum,
): React.ReactNode => {
  const paymentMethods = usePayment(getPaymentMethods)

  switch (paymentMethod) {
    // StripeApplepay should only be rendered when StripeCard is disabled
    case PaymentMethodEnum.StripeCard:
    case PaymentMethodEnum.StripeApplepay:
      return (
        <StripeCreditCard
          isApplePayEnabled={paymentMethods.includes(
            PaymentMethodEnum.StripeApplepay,
          )}
          isCardPaymentEnabled={paymentMethod === PaymentMethodEnum.StripeCard}
        />
      )
    case PaymentMethodEnum.StripeIDeal:
      return <IDeal />
    case PaymentMethodEnum.DlocalCard:
      return <DLocalCreditCard />
    case PaymentMethodEnum.MercadoPagoCard:
    case PaymentMethodEnum.MercadoPagoBrCard:
    case PaymentMethodEnum.MercadoPagoCoCard:
    case PaymentMethodEnum.MercadoPagoMxCard:
    case PaymentMethodEnum.MercadoPagoArCard:
    case PaymentMethodEnum.MercadoPagoClCard:
      return <MercadoPageCreditCard type={paymentMethod} />
    case PaymentMethodEnum.MercadoPagoPix:
    case PaymentMethodEnum.MercadoPagoBoleto:
    case PaymentMethodEnum.DlocalPix:
    case PaymentMethodEnum.DlocalBoleto:
      return <TaxNumber type={paymentMethod} />
    case PaymentMethodEnum.XenditIdCard:
    case PaymentMethodEnum.XenditPhCard:
      return <XenditCreditCard />
    case PaymentMethodEnum.CashOnDelivery:
      return <CashOnDelivery />
    default:
      return null
  }
}
