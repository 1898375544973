import React from 'react'
import { useTranslation } from 'react-i18next'
import CurrencyBadgeUi from 'common/components/entities/OfferPriceNew/ui/CurrencyBadgeUi'
import DividerUi from 'common/components/entities/OfferPriceNew/ui/DividerUi'
import PricePlanAmountUi from 'common/components/entities/OfferPriceNew/ui/PricePlanAmountUi'
import PricePlanItemWithoutLeftOffsetUi from 'common/components/entities/OfferPriceNew/ui/PricePlanItemWithoutLeftOffsetUi'
import PricePlanUi from 'common/components/entities/OfferPriceNew/ui/PricePlanUi'
import { SelectedShipping } from 'common/types/entities/ShippingFeesInterface'
import { centsToEuro, getReadablePrice } from 'common/utils/priceCalculator'
import { OfferPricing } from 'common/types/pricingTypes'
import PricePlanNameUi from '../ui/PricePlanNameUi'

interface ShippingSummaryProps {
  productPricing?: OfferPricing
  bumpOfferPricing?: OfferPricing
  selectedShipping: SelectedShipping
  amountFontFamily?: string
  locale: string
  mobileAmountFontFamily?: string
  amountFontSize: number
  mobileAmountFontSize?: number
  amountFontStyle?: string
  mobileAmountFontStyle?: string
  amountFontWeight?: string
  mobileAmountFontWeight?: string
  amountLineHeight: number
  mobileAmountLineHeight?: number
  amountColor: string
  mobileAmountColor?: string
  fontFamily?: string
  mobileFontFamily?: string
  fontSize: number
  mobileFontSize?: number
  fontStyle?: string
  mobileFontStyle?: string
  fontWeight?: string
  mobileFontWeight?: string
  lineHeight: number
  mobileLineHeight?: number
  color: string
  mobileColor?: string
}

const ShippingSummary = ({
  productPricing,
  selectedShipping,
  bumpOfferPricing,
  locale,
  amountFontSize,
  mobileAmountFontSize,
  amountFontStyle,
  mobileAmountFontStyle,
  amountFontWeight,
  mobileAmountFontWeight,
  amountFontFamily,
  mobileAmountFontFamily,
  amountLineHeight,
  mobileAmountLineHeight,
  amountColor,
  mobileAmountColor,
  fontSize,
  mobileFontSize,
  fontStyle,
  mobileFontStyle,
  fontWeight,
  mobileFontWeight,
  fontFamily,
  mobileFontFamily,
  lineHeight,
  mobileLineHeight,
  color,
  mobileColor,
}: ShippingSummaryProps) => {
  const { t } = useTranslation(['common', 'publisher'])

  const shippingTax =
    selectedShipping.price * ((productPricing?.taxPercent ?? 0) / 100)

  return (
    <>
      <DividerUi />
      <PricePlanUi>
        <PricePlanItemWithoutLeftOffsetUi>
          <PricePlanNameUi
            fontSize={fontSize}
            mobileFontSize={mobileFontSize}
            fontStyle={fontStyle}
            mobileFontStyle={mobileFontStyle}
            fontWeight={fontWeight}
            mobileFontWeight={mobileFontWeight}
            fontFamily={fontFamily}
            mobileFontFamily={mobileFontFamily}
            lineHeight={lineHeight}
            mobileLineHeight={mobileLineHeight}
            color={color}
            mobileColor={mobileColor}
            data-test-id={`offer-price-shipping-summary-net-${selectedShipping.conditionId}`}
          >
            {t('components.offer_price.shipping')}
          </PricePlanNameUi>
          <PricePlanAmountUi
            fontSize={amountFontSize}
            mobileFontSize={mobileAmountFontSize}
            fontStyle={amountFontStyle}
            mobileFontStyle={mobileAmountFontStyle}
            fontWeight={amountFontWeight}
            mobileFontWeight={mobileAmountFontWeight}
            fontFamily={amountFontFamily}
            mobileFontFamily={mobileAmountFontFamily}
            lineHeight={amountLineHeight}
            mobileLineHeight={mobileAmountLineHeight}
            color={amountColor}
            mobileColor={mobileAmountColor}
          >
            <>
              <CurrencyBadgeUi
                color={amountColor}
                mobileColor={mobileAmountColor}
              >
                {selectedShipping.currency}
              </CurrencyBadgeUi>

              {getReadablePrice(
                centsToEuro(selectedShipping.price),
                selectedShipping.currency,
                locale,
              )}
            </>
          </PricePlanAmountUi>
        </PricePlanItemWithoutLeftOffsetUi>
        {shippingTax > 0 && (
          <PricePlanItemWithoutLeftOffsetUi>
            <PricePlanNameUi
              fontSize={fontSize}
              mobileFontSize={mobileFontSize}
              fontStyle={fontStyle}
              mobileFontStyle={mobileFontStyle}
              fontWeight={fontWeight}
              mobileFontWeight={mobileFontWeight}
              fontFamily={fontFamily}
              mobileFontFamily={mobileFontFamily}
              lineHeight={lineHeight}
              mobileLineHeight={mobileLineHeight}
              color={color}
              mobileColor={mobileColor}
              data-test-id={`offer-price-shipping-summary-tax-${selectedShipping.conditionId}`}
            >
              {`${t('components.offer_price.vat')} (${
                productPricing?.taxPercent
              }%)`}
            </PricePlanNameUi>
            <PricePlanAmountUi
              fontSize={amountFontSize}
              mobileFontSize={mobileAmountFontSize}
              fontStyle={amountFontStyle}
              mobileFontStyle={mobileAmountFontStyle}
              fontWeight={amountFontWeight}
              mobileFontWeight={mobileAmountFontWeight}
              fontFamily={amountFontFamily}
              mobileFontFamily={mobileAmountFontFamily}
              lineHeight={amountLineHeight}
              mobileLineHeight={mobileAmountLineHeight}
              color={amountColor}
              mobileColor={mobileAmountColor}
            >
              <>
                <CurrencyBadgeUi
                  color={amountColor}
                  mobileColor={mobileAmountColor}
                >
                  {selectedShipping.currency}
                </CurrencyBadgeUi>

                {getReadablePrice(
                  centsToEuro(shippingTax),
                  selectedShipping.currency,
                  locale,
                )}
              </>
            </PricePlanAmountUi>
          </PricePlanItemWithoutLeftOffsetUi>
        )}
        <DividerUi />
        <PricePlanItemWithoutLeftOffsetUi>
          <PricePlanNameUi
            fontSize={fontSize}
            mobileFontSize={mobileFontSize}
            fontStyle={fontStyle}
            mobileFontStyle={mobileFontStyle}
            fontWeight={fontWeight}
            mobileFontWeight={mobileFontWeight}
            fontFamily={fontFamily}
            mobileFontFamily={mobileFontFamily}
            lineHeight={lineHeight}
            mobileLineHeight={mobileLineHeight}
            color={color}
            mobileColor={mobileColor}
            data-test-id={`offer-price-shipping-summary-total-${selectedShipping.conditionId}`}
          >
            {t(
              'components.offer_price.one_shots_summary.total_amount_with_tax_and_shipping',
            )}
          </PricePlanNameUi>
          <PricePlanAmountUi
            fontSize={amountFontSize}
            mobileFontSize={mobileAmountFontSize}
            fontStyle={amountFontStyle}
            mobileFontStyle={mobileAmountFontStyle}
            fontWeight={amountFontWeight}
            mobileFontWeight={mobileAmountFontWeight}
            fontFamily={amountFontFamily}
            mobileFontFamily={mobileAmountFontFamily}
            lineHeight={amountLineHeight}
            mobileLineHeight={mobileAmountLineHeight}
            color={amountColor}
            mobileColor={mobileAmountColor}
          >
            <>
              <CurrencyBadgeUi
                color={amountColor}
                mobileColor={mobileAmountColor}
              >
                {selectedShipping.currency}
              </CurrencyBadgeUi>

              {getReadablePrice(
                centsToEuro(
                  selectedShipping.price +
                    shippingTax +
                    (productPricing?.grossAmount ?? 0) +
                    (bumpOfferPricing?.grossAmount ?? 0),
                ),
                selectedShipping.currency,
                locale,
              )}
            </>
          </PricePlanAmountUi>
        </PricePlanItemWithoutLeftOffsetUi>
      </PricePlanUi>
    </>
  )
}

export default ShippingSummary
