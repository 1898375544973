import styled, { css } from 'styled-components'

const ActionsBoxItemTooltipUi = styled.span`
  visibility: hidden;
  width: 100px;
  color: #fff;
  text-align: center;
  padding: 3px 0;
  border-radius: 6px;
  font-family: var(--font-family);
  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.5s;

  /* Position the tooltip text */
  position: absolute;
  z-index: 1;
  margin-left: -50px;
  background-color: ${({ backgroundColor }) =>
    backgroundColor || 'transparent'};
  &::after {
    content: '';
    position: absolute;
    margin-left: -5px;
    border: 5px solid;
    border-color: ${({ backgroundColor }) =>
      `${backgroundColor} transparent transparent transparent`};
  }
  ${p =>
    p.column
      ? css`
          bottom: 0;
          right: 125%;
          &::after {
            top: 35%;
            right: -9px;
            transform: rotate(270deg);
          }
        `
      : css`
          bottom: 125%;
          left: 50%;
          &::after {
            top: 100%;
            left: 50%;
          }
        `};
`

export default ActionsBoxItemTooltipUi
