import { ContentBlock, EditorState, RichUtils } from 'draft-js'
import React, { memo, useCallback } from 'react'
import CommonBulletList from 'common/components/entities/BulletList'
import { bulletListIconsOld } from 'common/constants/iconList'
import { BulletListInterface } from 'common/types/entities/BulletListInterface'
import BaseEntityNew from 'client/components/core/BaseEntity/BaseEntityNew'
import TextEditor, {
  TextEditorUpdatePayload,
} from 'client/components/core/TextEditorNew'
import { useManagement, usePage } from 'client/store'
import managementSelectors from 'client/store/management/managementSelectors'
import { updateEntity } from 'client/store/page/pageActions'
import pageSelectors from 'client/store/page/pageSelectors'
import { EntityProps } from 'client/types'
import ListItem from './ListItem'
import { useAppDispatch } from 'client/hooks/use-dispatch'

function NewBulletList({
  entity,
  isMoveDownAllowed,
  isMoveUpAllowed,
}: EntityProps<BulletListInterface>) {
  const dispatch = useAppDispatch()
  const isEditing = useManagement(m =>
    managementSelectors.isEditing(m, entity.id),
  )

  const linkColor = usePage(pageSelectors.getGlobalLinkColor)
  const globalFontSize = usePage(pageSelectors.getGlobalTextFontSize)
  const globalMobileFontSize = usePage(
    pageSelectors.getGlobalMobileTextFontSize,
  )
  const globalLineHeight = usePage(pageSelectors.getGlobalTextLineHeight)
  const globalMobileLineHeight = usePage(
    pageSelectors.getGlobalMobileTextLineHeight,
  )
  const textColor = usePage(pageSelectors.getGlobalTextColor)

  const updateText = ({ rawContentState }: TextEditorUpdatePayload) => {
    dispatch(
      updateEntity({
        ...entity,
        rawContentState,
      }),
    )
  }

  //check if it's old className
  const iconClassName =
    entity.listItemClassName.indexOf('fa') < 0
      ? bulletListIconsOld[
          entity.listItemClassName as keyof typeof bulletListIconsOld
        ]
      : entity.listItemClassName

  const listItemBlockRenderer = useCallback(
    (contentBlock: ContentBlock) => {
      const type = contentBlock.getType()
      if (type === 'unordered-list-item') {
        return {
          component: ListItem,
          editable: true,
          props: {
            iconClassName: iconClassName,
          },
        }
      }
    },
    [iconClassName],
  )

  const handleReturn = (
    e: React.KeyboardEvent<HTMLElement>,
    editorState: EditorState,
  ): EditorState | undefined => {
    const currentBlockType = RichUtils.getCurrentBlockType(editorState)
    if (currentBlockType !== 'unordered-list-item') {
      return RichUtils.toggleBlockType(editorState, 'unordered-list-item')
    }
  }

  return (
    <BaseEntityNew
      entity={entity}
      isMoveUpAllowed={isMoveUpAllowed}
      isMoveDownAllowed={isMoveDownAllowed}
    >
      <CommonBulletList
        isEditing={isEditing}
        verticalSpacing={entity.verticalSpacing}
        mobileVerticalSpacing={entity.mobileVerticalSpacing}
        listItemIconColor={entity.listItemIconColor}
        mobileListItemIconColor={entity.mobileListItemIconColor}
        listItemIconSize={entity.listItemIconSize}
      >
        <TextEditor
          rawContentState={entity.rawContentState}
          textAlign={entity.textAlign}
          attrId={entity.htmlAttrId}
          isEditing={isEditing}
          update={updateText}
          padding={entity.padding}
          textColor={textColor}
          linkColor={linkColor}
          fontSize={entity.fontSize || globalFontSize}
          fontStyle={entity.fontStyle}
          fontFamily={entity.fontFamily}
          mobileFontFamily={entity.mobileFontFamily}
          fontWeight={entity.fontWeight}
          lineHeight={entity.lineHeight || globalLineHeight}
          mobilePadding={entity.mobilePadding}
          mobileFontSize={entity.mobileFontSize || globalMobileFontSize}
          mobileLineHeight={entity.mobileLineHeight || globalMobileLineHeight}
          mobileFontStyle={entity.mobileFontStyle}
          mobileFontWeight={entity.mobileFontWeight}
          backgroundColor={entity.backgroundColor}
          mobileBackgroundColor={entity.mobileBackgroundColor}
          handleReturn={handleReturn}
          blockRendererFn={listItemBlockRenderer}
        />
      </CommonBulletList>
    </BaseEntityNew>
  )
}

export default memo(NewBulletList)
