export enum AutomationRuleActionsEnum {
  add_tag = 'add_tag',
  remove_tag = 'remove_tag',
  subscribe_to_campaign = 'subscribe_to_campaign',
  unsubscribe_from_campaign = 'unsubscribe_from_campaign',
  send_email = 'send_email',
  send_email_to_specific_address = 'send_email_to_specific_address',
  enroll_in_course = 'enroll_in_course',
  revoke_access_to_course = 'revoke_access_to_course',
  enroll_in_course_bundle = 'enroll_in_course_bundle',
  revoke_access_to_course_bundle = 'revoke_access_to_course_bundle',
  send_webhook = 'send_webhook',
  grant_access_to_community = 'grant_access_to_community',
  revoke_access_to_community = 'revoke_access_to_community',
  create_user = 'create_user',
}

export enum AutomationRuleTagActionsEnum {
  add_tag = 'add_tag',
}

export enum AutomationRuleActionsTitleEnum {
  subscribe_to_campaign = 'entity_settings.automation_rule.action.add_campaign',
  unsubscribe_from_campaign = 'entity_settings.automation_rule.action.remove_campaign_from_contact',
  add_tag = 'entity_settings.automation_rule.action.add_tag',
  remove_tag = 'entity_settings.automation_rule.action.remove_tag',
  send_email = 'entity_settings.automation_rule.action.send_email',
  send_email_to_specific_address = 'entity_settings.automation_rule.action.send_email_to_specific_address',
  enroll_in_course = 'entity_settings.automation_rule.action.enroll_in_course',
  revoke_access_to_course = 'entity_settings.automation_rule.action.revoke_access_to_course',
  enroll_in_course_bundle = 'entity_settings.automation_rule.action.enroll_in_course_bundle',
  revoke_access_to_course_bundle = 'entity_settings.automation_rule.action.revoke_access_to_course_bundle',
  create_user = 'entity_settings.automation_rule.action.create_user',
  send_webhook = 'entity_settings.automation_rule.action.send_webhook',
  grant_access_to_community = 'entity_settings.automation_rule.action.grant_access_to_community',
  revoke_access_to_community = 'entity_settings.automation_rule.action.revoke_access_to_community',
}

export enum AutomationRuleActionsDescriptionEnum {
  subscribe_to_campaign = 'entity_settings.automation_rule.action.description.add_campaign',
  unsubscribe_from_campaign = 'entity_settings.automation_rule.action.description.remove_campaign_from_contact',
  create_user = 'entity_settings.automation_rule.action.description.create_user',
  add_tag = 'entity_settings.automation_rule.action.description.add_tag',
  remove_tag = 'entity_settings.automation_rule.action.description.remove_tag',
  send_email = 'entity_settings.automation_rule.action.description.send_email',
  send_email_to_specific_address = 'entity_settings.automation_rule.action.description.send_email_to_specific_address',
  enroll_in_course = 'entity_settings.automation_rule.action.description.enroll_in_course',
  revoke_access_to_course = 'entity_settings.automation_rule.action.description.revoke_access_to_course',
  enroll_in_course_bundle = 'entity_settings.automation_rule.action.description.enroll_in_course_bundle',
  revoke_access_to_course_bundle = 'entity_settings.automation_rule.action.description.revoke_access_to_course_bundle',
  send_webhook = 'entity_settings.automation_rule.action.description.send_webhook',
  grant_access_to_community = 'entity_settings.automation_rule.action.description.grant_access_to_community',
  revoke_access_to_community = 'entity_settings.automation_rule.action.description.revoke_access_to_community',
}

export enum AutomationRuleTriggersEnum {
  form_subscribed = 'form_subscribed',
  blog_page_form_subscribed = 'blog_page_form_subscribed',
}
