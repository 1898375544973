import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const StyledSection = styled.section`
  display: flex;
  margin: 10px 0;
  color: #000000;
`

const StyledFlex40Div = styled.div`
  flex-basis: ${p => (p.wide ? '70%' : '40%')};
  text-align: ${({ textAlign }) => textAlign || 'left'};
`

const StyledFlex20Div = styled.div`
  flex-basis: 20%;
  text-align: right;
`

const PricePlanItem = ({
  name = '',
  active = false,
  toggle = null,
  children,
  description = '',
  forwardedRef = () => {},
}) => (
  <StyledSection ref={forwardedRef}>
    <StyledFlex40Div ref={forwardedRef}>
      {toggle ? (
        <label>
          <input type="radio" checked={active} onChange={toggle} /> {name}
        </label>
      ) : (
        name
      )}
    </StyledFlex40Div>
    <StyledFlex20Div ref={forwardedRef}>{description}</StyledFlex20Div>
    <StyledFlex40Div
      ref={forwardedRef}
      textAlign="right"
      wide={!name && !description}
    >
      {children}
    </StyledFlex40Div>
  </StyledSection>
)

PricePlanItem.propTypes = {
  name: PropTypes.string,
  children: PropTypes.node.isRequired,
  toggle: PropTypes.func,
  active: PropTypes.bool,
  description: PropTypes.string,
  forwardedRef: PropTypes.func,
}

export default PricePlanItem
