import styled from 'styled-components'

const OrderBumpCheckBoxUi = styled.input`
  font-size: 20px;
  margin-right: 10px;
  min-height: 13px;
  min-width: 13px;
`

export default OrderBumpCheckBoxUi
