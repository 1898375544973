import request from 'client/utils/request'
import {
  EventInterface,
  MyEventInterface,
} from 'common/components/entities/Calendar/calendar-interface'

export function getMyEvents() {
  return request.get<MyEventInterface[]>(`/booking-calendar/event/my-events`, {
    baseURL: `${process.env.SYSTEME_IO_HOST}/api/dashboard`,
  })
}

export function getEvent(eventId: EventInterface['id']) {
  return request.get<EventInterface>(`/booking-calendar/event/${eventId}`, {
    baseURL: `${process.env.SYSTEME_IO_HOST}/api/dashboard`,
  })
}

interface GetTimeSlotsArgs {
  ownerId: number
  eventId: EventInterface['id']
}

export function getTimeSlots({ ownerId, eventId }: GetTimeSlotsArgs) {
  return request.get<EventInterface>(
    `/booking-calendar/event/timeSlots?ownerId=${ownerId}&eventId=${eventId}`,
    {
      baseURL: `${process.env.SYSTEME_IO_HOST}/api/dashboard`,
    },
  )
}
