import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import StyleWrapper from 'common/components/StyleWrapper'
import OrderBump from 'common/components/entities/OrderBump'
import * as types from 'common/types'
import { toggleCheckedBump } from 'publisher/actions/paymentActions'
import BaseNodeChildren from 'publisher/components/ChildrenEntities'
import { getOfferBumps } from 'publisher/reducers/paymentReducer'
import { getCheckedBumpId } from 'publisher/store/payment/paymentSelectors'

class OrderBumpContainer extends PureComponent {
  toggleCheckedBump = id => this.props.toggleCheckedBump(id)

  render() {
    const {
      entity: { styles, mobileStyles, options, mobileOptions, id },
      bumps,
      checkedBumpsId = null,
    } = this.props
    const {
      appearance: { desktop, mobile },
    } = options

    if (!bumps) {
      return <div />
    }

    return (
      <StyleWrapper desktop={desktop} mobile={mobile}>
        <OrderBump
          id={id}
          options={options}
          mobileOptions={mobileOptions}
          checkedBumpsIds={checkedBumpsId ? [checkedBumpsId] : []}
          bumps={bumps}
          styles={styles}
          mobileStyles={mobileStyles}
          onChangeHandler={this.toggleCheckedBump}
        >
          <BaseNodeChildren childIds={this.props.entity.childIds} />
        </OrderBump>
      </StyleWrapper>
    )
  }
}

OrderBumpContainer.propTypes = {
  entity: types.entity.isRequired,
  bumps: PropTypes.arrayOf(PropTypes.any).isRequired,
  toggleCheckedBump: PropTypes.func.isRequired,
  checkedBumpsId: PropTypes.arrayOf(PropTypes.number),
  productBump: PropTypes.any,
}

const mapStateToProps = ({ payment }, files, entity) => ({
  bumps: getOfferBumps(payment),
  checkedBumpsId: getCheckedBumpId(payment),
})

const mapDispatchToProps = { toggleCheckedBump }

export default connect(mapStateToProps, mapDispatchToProps)(OrderBumpContainer)
