import React, { ReactNode, useState, useRef } from 'react'
import {
  TooltipWrapperUi,
  TooltipWrapperUiProps,
} from './ui/tooltip-wrapper-ui'
import {
  TooltipMessageUi,
  TooltipMessageUiProps,
} from './ui/tooltip-message-ui'
import { TooltipOverlayUi } from './ui/tooltip-overlay-ui'
import { TooltipArrowUi } from './ui/tooltip-arrow-ui'
import { TooltipIconUi } from './ui/tooltip-icon-ui'

interface TooltipProps extends TooltipWrapperUiProps {
  children?: ReactNode
  message: string
  isArrowCenter?: boolean
}

interface TooltipStateInterface extends TooltipMessageUiProps {
  arrowLeft: string
}

const defaultMessageMaxWidth = 350
const defaultMinLeft = 5

export const Tooltip = ({
  children,
  isFlex,
  message,
  isArrowCenter,
}: TooltipProps) => {
  const tooltipIcon = useRef<HTMLDivElement>(null)
  const tooltipMessage = useRef<HTMLDivElement>(null)
  const [state, setState] = useState<TooltipStateInterface>({
    isVisible: false,
    top: 0,
    left: 0,
    arrowLeft: '50%',
  })
  const handleHideMessage = () => setState({ ...state, isVisible: false })

  const handleShowMessage = () => {
    if (!tooltipIcon?.current || !tooltipMessage?.current) return false
    const {
      y,
      x,
      width: iconWidth,
      height: iconHeight,
    } = tooltipIcon.current.getBoundingClientRect()
    const { height } = tooltipMessage.current.getBoundingClientRect()
    const top = y - height - iconHeight / 2
    const left =
      x < defaultMessageMaxWidth / 2 + iconWidth / 2
        ? defaultMinLeft
        : defaultMessageMaxWidth / 2 + iconWidth / 2

    setState({
      isVisible: true,
      top,
      left,
      arrowLeft: isArrowCenter ? `${defaultMessageMaxWidth / 2}px` : `${x}px`, // @TODO: fix and remove hack
    })
  }

  return (
    <TooltipWrapperUi isFlex={isFlex}>
      {children}
      <TooltipMessageUi
        ref={tooltipMessage}
        top={state.top}
        left={state.left}
        isVisible={state.isVisible}
        maxWidth={defaultMessageMaxWidth}
      >
        {message}
        <TooltipArrowUi left={state.arrowLeft} />
      </TooltipMessageUi>
      <TooltipIconUi
        ref={tooltipIcon}
        className="fa fa-question-circle"
        onClick={handleShowMessage}
      />
      {state.isVisible && <TooltipOverlayUi onClick={handleHideMessage} />}
    </TooltipWrapperUi>
  )
}
