import {
  FETCH_AGREEMENT,
  FETCH_PAYMENT_DATA_ERROR,
  FETCH_PAYMENT_DATA_SUCCESS,
  SUCCESS,
} from 'client/actionTypes'

const defaultState = {
  offerBumps: [],
  offerBump: undefined,
  offer: {
    pricePlans: [],
  },
  offerPricing: [],
  paymentMethods: [],
  isFetching: true,
  agreement: '',
  isFetched: false,
  coupon: {},
  product: null,
}

export default function (state = defaultState, action) {
  const { type, payload } = action
  switch (type) {
    case FETCH_PAYMENT_DATA_SUCCESS:
      return {
        ...state,
        offerBumps: payload.offerBumps || defaultState.offerBumps,
        offerBump: payload.offerBumps.length > 0 && payload.offerBumps[0],
        offer: payload.offer || defaultState.offer,
        paymentMethods: payload.paymentMethods || defaultState.paymentMethods,
        product: payload.product,
        isFetching: false,
        isFetched: true,
      }
    case FETCH_PAYMENT_DATA_ERROR:
      return {
        ...state,
        isFetching: false,
      }
    case FETCH_AGREEMENT + SUCCESS:
      return {
        ...state,
        agreement: payload,
      }
    default:
      return state
  }
}

export const getPricePlans = state => {
  return state.offer.pricePlans || []
}

export const getOfferBumps = ({ offerBumps }) => offerBumps

export const getPaymentMethods = ({ paymentMethods = [] }) => paymentMethods

export const getIsFetching = ({ isFetching }) => isFetching

export const getAgreement = ({ agreement }) => agreement

export const getIsFetched = ({ isFetched }) => isFetched

export const getProduct = ({ product }) => product

export const getCoupon = ({ coupon }) => coupon

export const selectors = {
  getPaymentMethods,
}
