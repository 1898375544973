export const getHumanReadableSize = (bytes, decimals) => {
  if (bytes === 0) {
    return '0 Bytes'
  }

  const k = 1024
  const dm = decimals + 1 || 2
  const sizes = ['Bytes', 'Kb', 'Mb', 'Gb']
  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

export const toBase64 = (file, stripDataUrl = false) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () =>
      resolve(stripDataUrl ? reader.result.split(',')[1] : reader.result)
    reader.onerror = error => reject(error)
  })
