import React, { Component, ErrorInfo, ReactNode } from 'react'
import { TextInterface } from 'common/types/entities/TextInterface'

interface Props {
  children: ReactNode
  entity: TextInterface
}

interface State {
  hasError: boolean
}

class TextErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  }

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI

    return { hasError: true }
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    const searchParams = new URLSearchParams(document.location.search)
    const isDebug = searchParams.get('debug')
    const entity = this.props.entity

    if (isDebug) {
      ;(window as any).Rollbar.info(
        `KARIM debug error in text editor ${entity.id}`,
        { entity: JSON.stringify(this.props.entity), error, errorInfo },
      )
    }
  }

  public render() {
    if (this.state.hasError) {
      return <span>Sorry.. there was an error, please try again</span>
    }

    return this.props.children
  }
}

export default TextErrorBoundary
