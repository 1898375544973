import { ContentState, convertToRaw } from 'draft-js'
import EntityTypeEnum from 'common/enums/entityTypeEnum'
import { BulletListInterface } from 'common/types/entities/BulletListInterface'
import { MasterBlockId } from 'common/types/entities/EntityInterface'
import { generateBaseEntity } from 'client/utils/createStructureUtils'

export const createBulletList = (
  parentId: string,
  masterBlockId?: MasterBlockId,
  text?: string,
): BulletListInterface => {
  const entity: BulletListInterface = {
    ...generateBaseEntity(EntityTypeEnum.BulletList, parentId, masterBlockId),
    type: EntityTypeEnum.BulletList,
    rawContentState:
      '{"entityMap":{},"blocks":[{"key":"7gjsu","text":"Lorem ipsum dolor sit amet","type":"unordered-list-item","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"a8v7j","text":"Lorem ipsum dolor sit amet","type":"unordered-list-item","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"9cjsa","text":"Lorem ipsum dolor sit amet","type":"unordered-list-item","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}]}',

    textAlign: 'left',
    padding: {
      paddingTop: 5,
      paddingRight: 5,
      paddingBottom: 5,
      paddingLeft: 5,
    },
    margin: {
      marginTop: 0,
      marginBottom: 0,
      marginLeft: 30,
      marginRight: 0,
    },
    mobileMargin: {
      marginTop: 0,
      marginBottom: 0,
      marginLeft: 10,
      marginRight: 0,
    },
    fontSize: 20,
    mobileFontSize: 18,
    lineHeight: 34,
    mobileLineHeight: 28,

    listItemIconColor: 'rgba(20, 158, 252, 1)',
    listItemIconSize: 20,
    listItemClassName: 'fas fa-check',
  }

  if (text) {
    entity.rawContentState = JSON.stringify(
      convertToRaw(ContentState.createFromText(text)),
    )
  }

  return entity
}
