import styled from 'styled-components'
import { PaddingType } from 'common/types/styleTypes'
import { mobilePaddingsCss, paddingsCss } from 'common/utils/styleUtilsNew'

type BodyUiProps = {
  padding: PaddingType
  mobilePadding: PaddingType
}
const BodyUi = styled.div<BodyUiProps>`
  position: relative;
  width: 100%;
  ${paddingsCss}
  ${p => p.theme.isEditorMobileMode && mobilePaddingsCss};
  ${p => p.theme.phone} {
    ${mobilePaddingsCss}
  }
`

export default BodyUi
