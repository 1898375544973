import styled, { css } from 'styled-components'
import * as mixins from 'common/utils/mixins'

const TextEditorUi = styled.div`
  ${p =>
    p.isEditing
      ? css`
          cursor: text;
          position: relative;
          z-index: 11;
        `
      : css`
          &::before {
            position: absolute;
            content: ' ';
            z-index: 10;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
          }
          cursor: pointer;
          z-index: 10;
        `};
  word-break: break-word;
  & ul {
    ${mixins.textUl()}
  }
  & li {
    ${mixins.textLi()}
    ${({ verticalSpacing }) =>
      verticalSpacing &&
      css`
        margin-bottom: ${verticalSpacing}px;
      `}
  }
  & li:last-of-type {
    margin-bottom: 0;
  }
  & li div {
    display: inline;
    position: relative;
  }

  & li > i {
    ${({ listItemIconColor }) =>
      listItemIconColor &&
      css`
        color: ${listItemIconColor};
        margin-left: -25px;
        margin-right: 10px;
      `}
    ${({ listItemIconSize }) =>
      listItemIconSize &&
      css`
        font-size: ${listItemIconSize};
      `}
  }
  ${p => p.theme.phone} {
    ${({ mobileStyles = {} }) => mobileStyles}
  }
  ${p => p.linkColor && mixins.textLinkColor(p.linkColor)}
  ${p => p.fontFamily && mixins.textFontFamily(p.fontFamily)}
  ${p => p.fontSize && mixins.textFontSize(p.fontSize)}
  ${p => p.fontStyle && mixins.textFontStyle(p.fontStyle)}
  ${p => p.lineHeight && mixins.textLineHeight(p.lineHeight)}
  ${p => p.styles.fontSize && mixins.headingFontSize(p.styles.fontSize)}
  ${p => p.styles.fontWeight && mixins.headingFontWeight(p.styles.fontWeight)}
  ${p => p.styles.fontStyle && mixins.headingFontStyle(p.styles.fontStyle)}
  ${p => p.textColor && mixins.textColor(p.textColor)};
  ${mixins.headingMargins()}
  ${p => p.styles.linkColor && mixins.textLinkColor(p.styles.linkColor)}
  ${p => p.flexBasis && mixins.textFlexBasis(p.flexBasis)}
    ${({ styles = {} }) => styles}
`

export default TextEditorUi
