import { Files, DataFile, FilesState } from './filesReducer'

export const FETCH_PAGE_FILES = 'FETCH_PAGE_FILES'
export const FETCH_USER_FILES = 'FETCH_USER_FILES'
export const ADD_FILE = 'ADD_FILE'
export const ADD_FILES = 'ADD_FILES'
export const REMOVE_FILE = 'REMOVE_FILE'
export const FETCH_SEARCH_USER_FILES = 'FETCH_SEARCH_USER_FILES'
export const SET_FILES = 'SET_FILES'
export const FETCH_COMMON_FILES = `FETCH_COMMON_FILES`

export type SetFiles = {
  type: typeof SET_FILES
  payload: FilesState
}

export type FetchPageFiles = {
  type: typeof FETCH_PAGE_FILES
  payload: Files
}

export type FetchUserFiles = {
  type: typeof FETCH_USER_FILES
  payload: Files
}

export type RemoveFile = {
  type: typeof REMOVE_FILE
  payload: number
}

export type AddFile = {
  type: typeof ADD_FILE
  payload: DataFile
}

export type AddFiles = {
  type: typeof ADD_FILES
  payload: Files
}

export type FetchSearchUserFiles = {
  type: typeof FETCH_SEARCH_USER_FILES
  payload: Files
}

export interface FetchCommonFiles {
  type: typeof FETCH_COMMON_FILES
  payload: Files
}

export type FileActionTypes =
  | FetchPageFiles
  | FetchUserFiles
  | RemoveFile
  | AddFile
  | AddFiles
  | FetchSearchUserFiles
  | SetFiles
  | FetchCommonFiles
