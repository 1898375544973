import React from 'react'
import { FacebookCommentsInterface } from 'common/types/entities/FacebookCommentsInterface'
import BaseEntityNew from 'client/components/core/BaseEntity/BaseEntityNew'
import FacebookCommentsUi from './ui/FacebookCommentsUi'

const FacebookCommentsPlaceholder = FacebookCommentsUi

interface FacebookCommentsProps {
  entity: FacebookCommentsInterface
  isMoveDownAllowed: boolean
  isMoveUpAllowed: boolean
}

function FacebookComments({
  entity,
  isMoveDownAllowed,
  isMoveUpAllowed,
}: FacebookCommentsProps) {
  return (
    <BaseEntityNew
      entity={entity}
      isMoveUpAllowed={isMoveUpAllowed}
      isMoveDownAllowed={isMoveDownAllowed}
    >
      <FacebookCommentsPlaceholder
        src="/assets/images/fb_comments.png"
        alt=""
        width="100%"
      />
    </BaseEntityNew>
  )
}

export default FacebookComments
