import React from 'react'
//@ts-ignore
import { EditorBlock } from 'draft-js'

function ListItem(props: any) {
  return (
    <>
      <i className={props.blockProps.iconClassName} />
      <EditorBlock {...props} />
    </>
  )
}
export default ListItem
