import React from 'react'
import MenuButtonUi from './ui/MenuButtonUi'

type Control = {
  label: string
  style: string
}

type StyleControlsProps = {
  controls: Control[]
  checkActive: (value: string) => boolean
  handleClick: (value: string) => void
}

const StyleControls = ({
  controls,
  checkActive,
  handleClick,
}: StyleControlsProps) => (
  <>
    {controls.map(type => (
      <MenuButtonUi
        key={type.label}
        active={checkActive(type.style)}
        onMouseDown={e => {
          e.preventDefault()
          handleClick(type.style)
        }}
        dangerouslySetInnerHTML={{ __html: type.label }}
      />
    ))}
  </>
)

export default StyleControls
