import React from 'react'
import { ColumnInterface } from 'common/components/entities/Column/types/ColumnInterface'
import { useUpdateProp } from 'client/components/core/Sidebar/components/Settings/hooks/useUpdateProps'
import ColumnAlignControls, {
  OptionalRefInterface,
} from 'client/components/entities/Row/components/ColumnAlignControls'

interface ColumnAlignControlsWrapperProps {
  entity: ColumnInterface
  left: number
  show: boolean
  externalActionsRef: React.MutableRefObject<OptionalRefInterface | null>
  withoutTop: boolean
  columnSize: number
  isLastChild: boolean
}
const ColumnAlignControlsWrapper = ({
  entity,
  left,
  columnSize,
  isLastChild,
  externalActionsRef,
  withoutTop,
  show,
}: ColumnAlignControlsWrapperProps) => {
  const updateProp = useUpdateProp(entity)
  const activeMode = entity.alignSelf
  return (
    <ColumnAlignControls
      left={left}
      show={show}
      update={updateProp('alignSelf')}
      activeMode={activeMode}
      externalActionsRef={externalActionsRef}
      withoutTop={withoutTop}
      columnSize={columnSize}
      isLastChild={isLastChild}
    />
  )
}

export default ColumnAlignControlsWrapper
