import React from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import DialogContainer from './DialogContainer'
import DialogOverlay from './DialogOverlay'

const StyledDialog = styled.div`
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  display: inline-block;
  flex: ${p => `0 1 ${p.$small ? 570 : p.$width}px`};
  margin: auto;
  padding: 0;
  font-size: 16px;
`

/**
 * Function component for rendering a dialog overlay with customizable options.
 * @param {Object} props - The properties for the dialog component.
 * @param {boolean} props.show - Flag to control the visibility of the dialog.
 * @param {React.ReactNode} props.children - The content to be displayed within the dialog.
 * @param {function|undefined} [props.close] - Function to close the dialog when clicked outside.
 * @param {boolean} [props.small] - Flag to indicate if the dialog should be small.
 * @param {number} props.width - The width of the dialog.
 * @returns {React.ReactNode} The rendered dialog component.
 */
const Dialog = ({
  show = false,
  children,
  close,
  small = false,
  width = 810,
}) => {
  const onClick = ({ target, currentTarget }) => {
    if (target === currentTarget && close) {
      close()
    }
  }

  return (
    show &&
    ReactDOM.createPortal(
      <DialogOverlay $show={show} onClick={onClick}>
        <DialogContainer $show={show} onClick={onClick} $width={width}>
          <StyledDialog $small={small} $width={width}>
            {children}
          </StyledDialog>
        </DialogContainer>
      </DialogOverlay>,
      document.body,
    )
  )
}

export default Dialog
