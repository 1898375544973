import styled, { CSSObject } from 'styled-components'

/**
 * Represents a styled div element for a tooltip message UI component.
 *
 * The TooltipMessageUi component is used to display tooltip messages with custom styling.
 * It allows setting the min-width, max-width, position, padding, top and left positions, background color,
 * text transformation, box shadow, and visibility based on certain props.
 *
 * @property maxWidth - The maximum width of the tooltip message in pixels
 * @property top - The top position of the tooltip message
 * @property left - The left position of the tooltip message
 * @property isVisible- Boolean flag to show or hide the tooltip message
 */

export interface TooltipMessageUiProps {
  maxWidth?: number
  isVisible?: boolean
  top?: number
  left?: number
  styles?: CSSObject
}

export const TooltipMessageUi = styled.div<TooltipMessageUiProps>`
  min-width: 100px;
  max-width: ${p => p.maxWidth ? `${p.maxWidth}px` : '350px'};
  position: fixed;
  z-index: 11; // more than TooltipOverlayUi
  padding: 10px;
  top: ${p => p.top ? `${p.top}px` : 'auto'};
  left: ${p => p.left ? `${p.left}px` : 'auto'};
  background: white;
  text-transform: none;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.3);
  visibility: ${p => (p.isVisible ? 'visible' : 'hidden')};
  ${p => p.styles && p.styles}
`