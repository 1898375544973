import React from 'react'
import OrderSummary from 'common/components/entities/OrderSummaryNew'
import { PageLocaleEnum } from 'common/enums/PageLocaleEnum'
import EntityTypeEnum from 'common/enums/entityTypeEnum'
import EntityInterface, {
  MasterBlockId,
} from 'common/types/entities/EntityInterface'
import { getCurrencySignByLocale } from 'common/utils/currencyUtils'
import BaseEntityNew from 'client/components/core/BaseEntity/BaseEntityNew'
import { pageSelectors } from 'client/store/page/pageSelectors'
import i18next from '../../i18n'
import { usePage } from '../../store'
import {
  generateHtmlAttrId,
  generateId,
} from '../../utils/createStructureUtils'

type OfferThankYouOrderSummaryProps = {
  entity: EntityInterface
  isMoveDownAllowed: boolean
  isMoveUpAllowed: boolean
}

function OfferThankYouOrderSummary({
  entity,
  isMoveDownAllowed,
  isMoveUpAllowed,
}: OfferThankYouOrderSummaryProps) {
  const pageLocale = usePage(pageSelectors.getPageLocale)
  const fixedT = i18next.getFixedT(pageLocale)

  const upsellPricePlansExample = [
    {
      id: 3,
      name: fixedT('entities.offer_upsell_price.stub.name'),
      price: '1400 ' + getCurrencySignByLocale(pageLocale as PageLocaleEnum),
    },
  ]

  return (
    <BaseEntityNew
      entity={entity}
      isMoveUpAllowed={isMoveUpAllowed}
      isMoveDownAllowed={isMoveDownAllowed}
    >
      <OrderSummary
        attrId={entity.htmlAttrId}
        pricePlans={upsellPricePlansExample}
      />
    </BaseEntityNew>
  )
}

export function createOrderSummary(
  parentId: string,
  masterBlockId?: MasterBlockId,
): EntityInterface {
  return {
    id: generateId(),
    type: EntityTypeEnum.OrderSummary,
    parentId: parentId,
    masterBlockId: masterBlockId,
    margin: {
      marginTop: 0,
      marginRight: 0,
      marginBottom: 0,
      marginLeft: 0,
    },
    mobileMargin: {
      marginTop: 0,
      marginRight: 0,
      marginBottom: 0,
      marginLeft: 0,
    },
    appearance: {
      desktop: true,
      mobile: true,
    },
    htmlAttrId: generateHtmlAttrId(EntityTypeEnum.OrderSummary),
  }
}

export default OfferThankYouOrderSummary
