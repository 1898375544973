import React from 'react'
import { FieldInterface } from 'common/types/entities/field-interface'
import BaseFieldNew from 'publisher/components/entities/field-new/base-field'
import { usePayment } from 'publisher/store'
import paymentSelectors from 'publisher/store/payment/paymentSelectors'

type CompanyNameFieldProps = {
  entity: FieldInterface
}

function CompanyNameFieldNew({ entity }: CompanyNameFieldProps) {
  const isPersonal = usePayment(paymentSelectors.isCustomerPersonal)

  return isPersonal ? (
    <div />
  ) : (
    <BaseFieldNew entity={entity} needInitialise={false} />
  )
}

export default CompanyNameFieldNew
