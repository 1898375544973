import { TFunction } from 'i18next'
import { ShippingCondition, ShippingRule } from 'common/types/shippingType'
import { centsToEuro, getReadablePrice } from 'common/utils/priceCalculator'

export const getConditionLabel = (
  condition: ShippingCondition,
  rule: ShippingRule,
  t: TFunction,
  language: string,
) => {
  switch (rule.type) {
    case 'price':
      if (condition.max) {
        return t('components.shipping.condition_label.price', {
          min: getReadablePrice(
            centsToEuro(condition.min),
            rule.currency,
            language,
          ),
          max: getReadablePrice(
            centsToEuro(condition.max),
            rule.currency,
            language,
          ),
          currency: '',
        })
      }

      return t('components.shipping.condition_label.price_min', {
        min: getReadablePrice(
          centsToEuro(condition.min),
          rule.currency,
          language,
        ),
        currency: '',
      })
    case 'quantity':
      if (condition.max) {
        return t('components.shipping.condition_label.quantity', {
          min: condition.min,
          max: condition.max,
        })
      }

      return t('components.shipping.condition_label.quantity_min', {
        min: condition.min,
      })
    case 'weight':
      if (condition.max) {
        return t('components.shipping.condition_label.weight', {
          min: condition.min,
          max: condition.max,
        })
      }

      return t('components.shipping.condition_label.weight_min', {
        min: condition.min,
      })
    default:
      return ''
  }
}

export const getShippingUnavailableMessage = (customerCountry?: string) => {
  if (!customerCountry) {
    return 'components.shipping.country_not_selected'
  }

  return 'components.shipping.not_available'
}
