import styled from 'styled-components'

const positions = {
  top: 'top',
  outsideTop: 'outsideTop',
  right: 'right',
  outsideRight: 'outsideRight',
  left: 'left',
}

const defaultTopBottom = 0
const defaultLeftRight = 0 // prevent scrolls
const outsideAbsValue = -26
const defaultBorderRadius = 3

const positionsStyles = {
  top: {
    top: defaultTopBottom,
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
  },
  outsideTop: {
    top: outsideAbsValue,
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
    borderTopRightRadius: defaultBorderRadius,
  },
  right: {
    right: defaultLeftRight,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  outsideRight: {
    right: outsideAbsValue,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  bottom: {
    bottom: defaultTopBottom,
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
  },
  left: {
    left: defaultLeftRight,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
}

const getStyles = position => {
  switch (position) {
    case positions.top:
      return positionsStyles[positions.top]
    case positions.outsideTop:
      return positionsStyles[positions.outsideTop]
    case positions.right:
      return positionsStyles[positions.right]
    case positions.outsideRight:
      return positionsStyles[positions.outsideRight]
    case positions.left:
      return positionsStyles[positions.left]
    default:
      return {}
  }
}

const ActionsBoxUi = styled.div`
  ${({
    top = false,
    right,
    outsideTop,
    outsideRight,
    bottom,
    left,
    backgroundColor,
    column,
  }) => {
    let resStyles = {}

    if (top) {
      resStyles = { ...resStyles, ...getStyles(positions.top) }
    }

    if (right) {
      resStyles = { ...resStyles, ...getStyles(positions.right) }
    }

    if (outsideTop) {
      resStyles = { ...resStyles, ...getStyles(positions.outsideTop) }
    }

    if (outsideRight) {
      resStyles = { ...resStyles, ...getStyles(positions.outsideRight) }
    }

    if (bottom) {
      resStyles = { ...resStyles, bottom: positionsStyles.bottom }
    }

    if (left) {
      resStyles = { ...resStyles, ...getStyles(positions.left) }
    }

    if (backgroundColor) {
      resStyles = { ...resStyles, backgroundColor }
    }

    if (column) {
      resStyles = { ...resStyles, flexDirection: 'column' }
    }

    return resStyles
  }};
  position: absolute;
  height: 26px;
  display: flex;
  z-index: 11;
  flex-basis: 10px;
  border-radius: ${defaultBorderRadius};
  font-size: 14px;
`

export default ActionsBoxUi
