import styled from 'styled-components'

const MenuLinkUi = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  padding: 0 5px;
  flex: 1;
  margin-right: 15px;
`

export default MenuLinkUi
