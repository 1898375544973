import PropTypes from 'prop-types'
import React from 'react'
import { HeadingTypeEnum } from 'common/enums/HeadingTypeEnum'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'
import StyleControls from './StyleControls'

const BLOCK_TYPES = [
  { label: '<i class="fa-regular fa-h1" />', style: HeadingTypeEnum.HeaderOne },
  { label: '<i class="fa-regular fa-h2" />', style: HeadingTypeEnum.HeaderTwo },
  { label: '<i class="fa-regular fa-h3" />', style: HeadingTypeEnum.HeaderThree },
  { label: '<i class="fa-regular fa-h4" />', style: HeadingTypeEnum.HeaderFour },
  { label: '<i class="fa-regular fa-h5" />', style: HeadingTypeEnum.HeaderFive },
  { label: '<i class="fa-regular fa-h6" />', style: HeadingTypeEnum.HeaderSix },
]

const headerFontSize = {
  'header-one': { fontSize: '40px', lineHeight: '48px' },
  'header-two': { fontSize: '32px', lineHeight: '38.4px' },
  'header-three': { fontSize: '28px', lineHeight: '33.6px' },
  'header-four': { fontSize: '24px', lineHeight: '28.8px' },
  'header-five': { fontSize: '20px', lineHeight: '24px' },
  'header-six': { fontSize: '16px', lineHeight: '19.2px' },
}

const mobileHeaderFontSize = {
  'header-one': { fontSize: '30px', lineHeight: '36px' },
  'header-two': { fontSize: '24px', lineHeight: '28.8px' },
  'header-three': { fontSize: '22px', lineHeight: '26.4px' },
  'header-four': { fontSize: '20px', lineHeight: '24px' },
  'header-five': { fontSize: '16px', lineHeight: '19.2px' },
  'header-six': { fontSize: '14px', lineHeight: '16.8px' },
}

const HeadersControls = ({ currentType, onToggle }) => {
  const handleToggle = type =>
    onToggle(headerFontSize[type], mobileHeaderFontSize[type], type)

  const { isMobile } = useDeviceModeContext()
  return (
    <StyleControls
      checkActive={style => currentType === style}
      controls={BLOCK_TYPES}
      onToggle={handleToggle}
      small={isMobile}
    />
  )
}

HeadersControls.propTypes = {
  currentType: PropTypes.string.isRequired,
  onToggle: PropTypes.func.isRequired,
}

export default HeadersControls
