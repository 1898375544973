export const generateDate = ({ time, date }: { time: string; date: Date }) => {
  if (time.includes('PM')) {
    const hours = time.split(':')
    if (time.includes('12')) {
      hours[0] = '00'
    }
    date.setHours(parseInt(hours[0]) + 12, parseInt(hours[1]))
  } else if (time.includes('AM')) {
    const hours = time.split(':')
    if (time.includes('12')) {
      hours[0] = '00'
    }
    date.setHours(parseInt(hours[0]), parseInt(hours[1]))
  } else {
    const hours = time.split(':')
    date.setHours(parseInt(hours[0]), parseInt(hours[1]))
  }
  return date
}

export const getDateIn10Days = () => {
  const currentDate = new Date()
  const in10DaysDate = new Date(
    currentDate.getTime() + 10 * 24 * 60 * 60 * 1000,
  )
  return in10DaysDate.toISOString()
}

export const getDateFromTime = ({
  hours,
  minutes,
  seconds,
}: {
  hours: number
  minutes: number
  seconds: number
}) => {
  const date = new Date()
  date.setHours(hours)
  date.setMinutes(minutes)
  date.setSeconds(seconds)
  date.setMilliseconds(0)

  return date
}
