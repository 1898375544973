import React from 'react'
import { OldEntityInterface } from 'common/types/entities/OldEntityInterface'
import { PaymentButtonInterface } from 'common/types/entities/PaymentButtonInterface'
import { buyMainOffer } from 'publisher/api/dLocalApi'
import { useDLocal } from 'publisher/context/DlocalContext'
import usePaymentSubmit from 'publisher/hooks/payment/usePaymentSubmit'
import { usePage, pageSelectors } from 'publisher/store'
import { usePayment } from 'publisher/store'
import paymentSelectors from 'publisher/store/payment/paymentSelectors'

export default function useDLocalPaymentHandler(
  entity: OldEntityInterface | PaymentButtonInterface,
) {
  const { errors, setErrors, isLoading, submit } = usePaymentSubmit(entity)
  const purchaseProcessId = usePayment(paymentSelectors.getPurchaseProcessId)
  const pageId = usePage(pageSelectors.getPageId)
  const dlocal = useDLocal()

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    submit(async body => {
      if (!dlocal) {
        return
      }

      const { token, error } = await dlocal.createToken()
      const { data } = await buyMainOffer(pageId, purchaseProcessId, {
        payment_form: {
          ...body,
          paymentMethodId: token,
        },
      })

      if (error) {
        setErrors([error])
        return
      }
      if (data.redirect) {
        window.location.assign(data.redirect)
        return
      }
    })
  }

  return { errors, isLoading, handleSubmit }
}
