import React, { useEffect, useState } from 'react'
import { ColumnInterface } from 'common/components/entities/Column/types/ColumnInterface'
import { useUpdateProp } from 'client/components/core/Sidebar/components/Settings/hooks/useUpdateProps'
import SplitterUi from 'client/components/entities/Row/ui/SplitterUi'
import { useSetRowResizing } from 'client/context/ResizingRowContext'
import { denyResize } from '../utils/columnResizeUtils'

interface SplitterProps {
  leftChild: ColumnInterface
  rightChild: ColumnInterface
  left: number
  minColumnWidth: number
  show: boolean
}
const Splitter = ({
  leftChild,
  rightChild,
  left,
  show,
  minColumnWidth,
}: SplitterProps) => {
  const setRowResizing = useSetRowResizing()
  const updateLeftProp = useUpdateProp(leftChild)
  const updateRightProp = useUpdateProp(rightChild)
  const [isResizing, setIsResizing] = useState(false)

  const [start, setStart] = useState(0)
  const [compensation, setCompensation] = useState(0)

  const updateResizeData = (newIsResizing: boolean) => {
    setIsResizing(newIsResizing)

    setTimeout(() => {
      setRowResizing(newIsResizing)
    }, 100)
  }

  useEffect(() => {
    const resize = (resizeStep: number) => {
      if (denyResize(leftChild.size, rightChild.size, resizeStep)) {
        return false
      }
      updateLeftProp('size')(leftChild.size - resizeStep)
      updateRightProp('size')(rightChild.size + resizeStep)
      return true
    }
    const onMouseMove = (e: MouseEvent) => {
      const { clientX } = e
      const isPassCompensation = (isPositive: boolean, x: number) => {
        if (compensation === 0) {
          return true
        }

        return (
          (isPositive && x < compensation) || (!isPositive && x > compensation)
        )
      }
      if (isResizing) {
        window?.getSelection()?.removeAllRanges()
        if (compensation === clientX) {
          setStart(compensation)
          setCompensation(0)
        }

        const movement = start - clientX
        const isMovementPositive = movement > 0

        if (isPassCompensation(isMovementPositive, clientX)) {
          const crossOverWidth = Math.round(minColumnWidth / 2)
          if (Math.abs(movement) > crossOverWidth) {
            const resizeValue = isMovementPositive ? 1 : -1
            if (resize(resizeValue)) {
              setStart(clientX)
              setCompensation(
                isMovementPositive
                  ? clientX - crossOverWidth
                  : clientX + crossOverWidth,
              )
            }
          }
        }
      }
    }
    const onMouseUp = () => {
      if (isResizing) {
        updateResizeData(false)
      }
    }
    window.addEventListener('mousemove', onMouseMove, false)
    window.addEventListener('mouseup', onMouseUp, false)
    return () => {
      window.removeEventListener('mousemove', onMouseMove, false)
      window.removeEventListener('mouseup', onMouseUp, false)
    }
  }, [
    compensation,
    isResizing,
    leftChild.size,
    minColumnWidth,
    rightChild.size,
    start,
    updateLeftProp,
    updateResizeData,
    updateRightProp,
  ])

  const onMouseDown = (e: React.MouseEvent) => {
    e.stopPropagation()
    setStart(e.clientX)
    setCompensation(0)
    updateResizeData(true)
  }

  return <SplitterUi onMouseDown={onMouseDown} left={left} show={show} />
}

export default Splitter
