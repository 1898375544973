import React, { createContext, useContext } from 'react'
import CommonContactUs from 'common/components/entities/ContactUs'
import { EntityTypeEnum } from 'common/enums/entityTypeEnum'
import { ContactUsInterface } from 'common/types/entities/ContactUsInterface'
import { MasterBlockId } from 'common/types/entities/EntityInterface'
import BaseEntityNew from 'client/components/core/BaseEntity/BaseEntityNew'
import ChildrenEntities from 'client/components/core/ChildrenEntities'
import { usePage } from 'client/store'
import pageSelectors from 'client/store/page/pageSelectors'
import { EntityProps } from 'client/types'
import {
  generateHtmlAttrId,
  generateId,
} from 'client/utils/createStructureUtils'

const ContactUsDescendantIdsContext = createContext([] as string[])

type ContactUsDescendantIdsProviderProps = {
  children: React.ReactElement
  entityId: string
}

export const useContactUsDescendantIds = () =>
  useContext(ContactUsDescendantIdsContext)

function ContactUsDescendantIdsProvider({
  children,
  entityId,
}: ContactUsDescendantIdsProviderProps) {
  const descendantIds = usePage(page =>
    pageSelectors.getDescendantIds(page, entityId),
  ).concat([entityId])
  return (
    <ContactUsDescendantIdsContext.Provider value={descendantIds}>
      {children}
    </ContactUsDescendantIdsContext.Provider>
  )
}

function ContactUs({
  entity,
  isMoveDownAllowed,
  isMoveUpAllowed,
}: EntityProps<ContactUsInterface>) {
  return (
    <BaseEntityNew
      entity={entity}
      isMoveDownAllowed={isMoveDownAllowed}
      isMoveUpAllowed={isMoveUpAllowed}
    >
      <ContactUsDescendantIdsProvider entityId={entity.id}>
        <CommonContactUs
          padding={entity.padding}
          mobilePadding={entity.mobilePadding}
          id={entity.htmlAttrId}
          border={entity.border}
          mobileBorder={entity.mobileBorder}
          backgroundColor={entity.backgroundColor}
          mobileBackgroundColor={entity.mobileBackgroundColor}
          boxShadow={entity.boxShadow}
          mobileBoxShadow={entity.mobileBoxShadow}
        >
          <ChildrenEntities
            id={entity.id}
            childIds={entity.childIds}
            type={entity.type}
          />
        </CommonContactUs>
      </ContactUsDescendantIdsProvider>
    </BaseEntityNew>
  )
}

export function createContactUs(
  parentId: string,
  masterBlockId?: MasterBlockId,
): ContactUsInterface {
  return {
    id: generateId(),
    type: EntityTypeEnum.ContactUs,
    parentId: parentId,
    masterBlockId: masterBlockId,
    padding: {
      paddingTop: 30,
      paddingRight: 70,
      paddingBottom: 30,
      paddingLeft: 70,
    },
    margin: {
      marginTop: 0,
      marginRight: 0,
      marginBottom: 0,
      marginLeft: 0,
    },
    mobileMargin: {
      marginTop: 0,
      marginRight: 0,
      marginBottom: 0,
      marginLeft: 0,
    },
    mobilePadding: {
      paddingTop: 10,
      paddingRight: 20,
      paddingBottom: 10,
      paddingLeft: 20,
    },
    childIds: [],
    appearance: {
      desktop: true,
      mobile: true,
    },

    htmlAttrId: generateHtmlAttrId(EntityTypeEnum.ContactUs),
  }
}

export default ContactUs
