import { MiddlewareState, Strategy, Placement } from '@floating-ui/core'
import { FlipOptions } from '@floating-ui/dom'
import {
  useFloating,
  autoUpdate,
  offset,
  flip,
  shift,
  useDismiss,
  useClick,
  useInteractions,
  detectOverflow,
} from '@floating-ui/react'
import React, { useState } from 'react'
import { WORK_AREA_ID } from 'client/constants/editorIds'

const overflowMiddleWare = {
  name: 'overflowMiddleWare',
  async fn(state: MiddlewareState) {
    const overflow = await detectOverflow(state, {
      boundary: document.querySelector(`#${WORK_AREA_ID}`) || undefined,
    })
    if (overflow.top > 0) {
      return {
        y: state.y + overflow.top,
      }
    }
    return {}
  },
}

interface usePopoverPropertiesProps {
  offsetValue: number
  flipOptions: FlipOptions
  strategyValue: Strategy
  placement: Placement
}

function usePopoverProperties({
  offsetValue,
  flipOptions,
  strategyValue,
  placement,
}: usePopoverPropertiesProps) {
  const [isOpen, setIsOpen] = useState(false)

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: placement,
    middleware: [
      offset(offsetValue),
      flip(flipOptions),
      shift(),
      overflowMiddleWare,
    ],
    strategy: strategyValue,
    whileElementsMounted: autoUpdate,
  })
  const click = useClick(context)
  const dismiss = useDismiss(context)

  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    dismiss,
  ])

  return React.useMemo(
    () => ({
      refs,
      floatingStyles: { ...floatingStyles, zIndex: 100 },
      getReferenceProps,
      getFloatingProps,
      isOpen,
      context,
    }),
    [
      refs,
      floatingStyles,
      getReferenceProps,
      getFloatingProps,
      isOpen,
      context,
    ],
  )
}

export default usePopoverProperties
