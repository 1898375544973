import styled from 'styled-components'

const NewRawHtmlWatermarkUi = styled.div`
  position: relative;
  overflow: hidden;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAoCAYAAAC8cqlMAAAFEElEQVRoQ+2XbUhbZxTHz41asSBxS7EmVW/M6sIsuF4Q5godVqeLbYiZs2FBKRUsQpHhhFEGHUj3VtZVN0vn9sFNirT1JbFJiS7RbegGcwVLKHZZk1kl9TVxuXkxH+JMznggghS73quJleL9ltxzz///u+fcw3koeE4u6jnhgF2QnVbJ3YrsViROb+CZtBYidno8npdFItGRWHE9ExC73V6bm5vb4XQ6H9A0/UosYLYVBBFzKIqaIsYHBwffUygUX83MzExmZWXlrodBRNHq6mp7f3+/VqPRhLmAbhsIIv4ciUTeMJlM76hUKgMxZzQa65VKZfvCwsK0RCKRkf8QUYiID1mWRZlMVu/z+XQ7CsTr9d4RCoUFkUgETSaTVqVS9RCDt2/frj1x4kSHy+WaSU5OzhcKhXa/359I0/RFn893GQB2XEVyIpGIY2FhYVEsFosHBgZOKZXKLgJjMBiqlUrlNYqiqEAg4JNKpRdZliUQq1yqQWK2rbWImNvtvjs7O5uYlpaWlJ2dLTebzWfKy8s7yL3+/n5NYWFhW35+fpvb7f4SAFa4QsQVBBGvLC8vT6Smpn63Zoh87OFw2KFWq99sa2u7IpVKD1kslrMKheLbaEwm4QWAEB+IuIEgYiMAtBKBUCgU9Pl8F/bv3/8F+b20tDT+6NGjRIZhXnU4HHcPHjx4uKOjo6auru46X/Pr4+PSWoiY4HK5nPv27cuw2WwP5HJ5LiKusix7KT09/QdSlePHjxdbLJZRg8FwXaPR/BYKhb7ZMSCIeM7j8TAikejdnp6ehKNHj06lp6dL2tvbz9I0rSorKysVCARIJtHExISDYZjDWzEfl4og4vsAcLm1tdXY1NRUDwCLZJjMzs4+zMjIyI6O3JGbN29+rVKpVF6v1y+RSIoB4M9YwMSktRCxAQDarl69ampoaPgUAMbWm3M6nY7MzMyX1o3cF/fs2VO0srJi5DNi/w94yyCIeAYAyGSirFbrHYZhXttIcHp62kZGbnd3t1ar1XbHogoxay1EPA0A33d2dg4lJSXNVVdXn7bb7X/I5fLCjYyOjIz8qNVqf5qbm7u0o0D8fn+T0Wgsr6mp+QwAfhkeHr5QUlJyfnJykozVgieYTeC6dvCB3VRrIaKMoqiHUaEXAIBdEzWbzefLysouTE1NTchksnw+ZrYSyxsEEQWIGJifn585cOCAfCNxi8VyrrS09HOn02mjafrQVgxyfZY3CEm80fr9uODAwEBjcXHxxaKiosaxsbG1FYSrL95xmwIhKuvX79HR0ZwnHIBINTwAMM/bGc8HOIEgohQAnBRFRdbyI+LriPgrRVECt9s9f//+ffrYsWOc126ePp8azgkkHA67vV7vSl9fn7S+vv5fRCxAxN/Hx8f/6urqMrW0tHzAsqxLp9Nlk/tPVY1DACcQnU73tlqt7mVZ9p+9e/dWpKSkjFqt1r8ZhvkEAK7r9fqKiooKncvlmheLxaR6nE51seThBEIEe3t7lZWVlbcEAkGCzWZz5OXlEYhra2b0er3i3r17p5qbmz8CgMlYmuSSizMISdbX1/eWWq02BQKBwNDQUJZGo1l+TITkI9vttl+8QIi7GzduFJ08eXIoGAwu6/X6nNraWu+2u95AkDdIFOZIVVXVSDAY9KelpUk2czSNNfymQKIwBYuLix82NjZ+DADWWBvjm2/TIFGhZ/ZNPA66VRC+Ly5u8bsgcXu1m0z83FTkPzkRL0dFN2ToAAAAAElFTkSuQmCC);
  min-height: 1px;
`

export default NewRawHtmlWatermarkUi
