import {
  COLUMN_GAP,
  COLUMN_SIZE,
} from 'client/components/entities/Row/constants/column-options'

export const getPaddingLeft = (node: Element): number =>
  parseInt(getComputedStyle(node).paddingLeft, 0)

export const denyResize = (
  currentItemSize: number,
  nextItemSize: number,
  step: number,
) => (currentItemSize === 1 && step > 0) || (nextItemSize === 1 && step < 0)

export const calculateLeftPosition = (
  left: number,
  size: number,
  index: number,
  minWidth: number,
) => {
  return (
    left +
    size * minWidth +
    (COLUMN_GAP * size - COLUMN_GAP / 2) +
    index * (COLUMN_GAP / 2)
  )
}

export const calculateColumnControlsLeftPosition = (
  left: number,
  index: number,
) => {
  if (index === 0) {
    return 0
  }

  return left
}

export const getMinColumnWidth = (rowWidth: number) =>
  (rowWidth - (COLUMN_SIZE - 1) * COLUMN_GAP) / COLUMN_SIZE

export const getWidthWithoutPadding = (node: Element) => {
  const computedStyle = getComputedStyle(node)
  let width = node.clientWidth
  width -=
    parseFloat(computedStyle.paddingLeft) +
    parseFloat(computedStyle.paddingRight)

  return width
}
