import { PayPalScriptProvider } from '@paypal/react-paypal-js'
import React, { memo } from 'react'
import StyleWrapper from 'common/components/StyleWrapper'
import { wrapperStyles } from 'common/components/entities/Button'
import {
  CommonPaypalRestButton,
  MAX_PAYPAL_BUTTON_HEIGHT,
  MAX_PAYPAL_BUTTON_WIDTH,
} from 'common/components/entities/paypal-rest-button'
import { PricePlanTypeEnum } from 'common/enums/PricePlanTypeEnum'
import FieldErrors from 'publisher/components/FieldErrors'
import {
  handleCaptureOrder,
  handleValidateSubscription,
} from 'publisher/hooks/payment/use-paypal-rest-payment-handler'
import { usePaypalUpsellRestHandler } from 'publisher/hooks/payment/use-paypal-upsell-rest-handler'
import useManagement from 'publisher/hooks/useManagement'
import { selectors as managementSelectors } from 'publisher/reducers/managementReducer'
import { usePayment } from 'publisher/store'
import {
  getActivePricePlan,
  getOfferCurrency,
} from 'publisher/store/payment/paymentSelectors'
import { UpsellPaymentButtonPropsType } from './UpsellPaymentButton'

const PaypalUpsellRestButton = ({ entity }: UpsellPaymentButtonPropsType) => {
  const pricePlan = usePayment(getActivePricePlan)

  const isSubscriptionPricePlan =
    pricePlan && pricePlan.type !== PricePlanTypeEnum.OneShot

  if (isSubscriptionPricePlan) {
    return <PaypalUpsellSubscriptionButton entity={entity} />
  }

  return <PaypalUpsellOrderButton entity={entity} />
}

const PaypalUpsellSubscriptionButton = ({
  entity,
}: UpsellPaymentButtonPropsType) => {
  const isPreview = useManagement(managementSelectors.isPreviewMode)
  const currency = usePayment(getOfferCurrency)

  const { handleSubmit, errors, isLoading, orderHash } =
    usePaypalUpsellRestHandler(entity)

  return (
    <PayPalScriptProvider
      options={{
        clientId: `${process.env.PAYPAL_CLIENT_ID}`,
        currency: currency.toLocaleUpperCase(),
        vault: true,
        intent: 'subscription',
      }}
    >
      <StyleWrapper
        styles={{ ...entity.margin, ...wrapperStyles }}
        mobileStyles={entity.mobileMargin}
        desktop={entity.appearance.desktop}
        mobile={entity.appearance.mobile}
      >
        <CommonPaypalRestButton
          createSubscription={async () => await handleSubmit()}
          onApprove={async body =>
            await handleValidateSubscription(body, orderHash)
          }
          borderRadius={entity.border}
          backgroundColor={entity.paypal?.backgroundColor}
          disabled={isLoading || isPreview}
          height={
            entity.width === 'auto' ? MAX_PAYPAL_BUTTON_HEIGHT : undefined
          }
          width={
            entity.width === 'auto' ? MAX_PAYPAL_BUTTON_WIDTH : entity.width
          }
        />
      </StyleWrapper>
      <FieldErrors errors={errors} align="center" />
    </PayPalScriptProvider>
  )
}

const PaypalUpsellOrderButton = ({ entity }: UpsellPaymentButtonPropsType) => {
  const isPreview = useManagement(managementSelectors.isPreviewMode)
  const currency = usePayment(getOfferCurrency)

  const { handleSubmit, errors, isLoading, orderHash } =
    usePaypalUpsellRestHandler(entity)

  return (
    <PayPalScriptProvider
      options={{
        clientId: `${process.env.PAYPAL_CLIENT_ID}`,
        currency: currency.toLocaleUpperCase(),
      }}
    >
      <StyleWrapper
        styles={{ ...entity.margin, ...wrapperStyles }}
        mobileStyles={entity.mobileMargin}
        desktop={entity.appearance.desktop}
        mobile={entity.appearance.mobile}
      >
        <CommonPaypalRestButton
          createOrder={async () => await handleSubmit()}
          onApprove={async body => await handleCaptureOrder(body, orderHash)}
          borderRadius={entity.border}
          backgroundColor={entity.paypal?.backgroundColor}
          disabled={isLoading || isPreview}
          height={
            entity.width === 'auto' ? MAX_PAYPAL_BUTTON_HEIGHT : undefined
          }
          width={
            entity.width === 'auto' ? MAX_PAYPAL_BUTTON_WIDTH : entity.width
          }
        />
      </StyleWrapper>
      <FieldErrors errors={errors} align="center" />
    </PayPalScriptProvider>
  )
}

export default memo(PaypalUpsellRestButton)
