import React from 'react'
import { RowInterface } from 'common/components/entities/Row/types/RowInterface'
import Column from 'client/components/entities/Column'
import ColumnAlignControls, {
  OptionalRefInterface,
} from 'client/components/entities/Row/components/ColumnAlignControls'
import ColumnAlignControlsWrapper from 'client/components/entities/Row/components/ColumnAlignControlsWrapper'
import Splitter from 'client/components/entities/Row/components/Splitter'
import {
  COLUMN_GAP,
  SPLITTER_PREFIX,
} from 'client/components/entities/Row/constants/column-options'
import { useColumnEntities } from 'client/components/entities/Row/hooks/useColumnEntities'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'
import {
  calculateColumnControlsLeftPosition,
  calculateLeftPosition,
} from '../utils/columnResizeUtils'

interface RowChildrenProps {
  entity: RowInterface
  isHovered?: boolean
  minColumnWidth?: number
  paddingLeft?: number
  externalActionsRef?: React.MutableRefObject<OptionalRefInterface | null>
}

type DataType = {
  rendered: JSX.Element[]
  gridColumnStart: number
  left: number
}

const RowChildren = ({
  entity,
  minColumnWidth = 0,
  isHovered = false,
  paddingLeft = 0,
  externalActionsRef,
}: RowChildrenProps) => {
  const { isDesktop } = useDeviceModeContext()
  const columnEntities = useColumnEntities(entity)

  const data: DataType = columnEntities.reduce(
    (acc, child, index) => {
      const size = child.size
      const nextEntity = columnEntities[index + 1]
      const splitterKey = SPLITTER_PREFIX + child.id
      const left = calculateLeftPosition(acc.left, size, index, minColumnWidth)
      const columnControlsLeft = calculateColumnControlsLeftPosition(
        acc.left,
        index,
      )
      const isFirstChild = index === 0
      const isLastChild = index === columnEntities.length - 1
      return {
        left,
        gridColumnStart: acc.gridColumnStart + size,
        rendered: [
          ...acc.rendered,
          <Column
            key={child.id}
            entity={child}
            gridColumnStart={acc.gridColumnStart}
            disableStacking={entity.disableStacking}
          />,
          ...(columnEntities.length > 1 && externalActionsRef
            ? [
                <ColumnAlignControlsWrapper
                  key={`${child.id}-align-controls`}
                  left={columnControlsLeft}
                  show={isDesktop && isHovered}
                  entity={child}
                  externalActionsRef={externalActionsRef}
                  withoutTop={!isLastChild && !isFirstChild}
                  columnSize={size * minColumnWidth + COLUMN_GAP}
                  isLastChild={isLastChild}
                />,
              ]
            : []),
          ...(index !== columnEntities.length - 1 && externalActionsRef
            ? [
                <Splitter
                  left={left}
                  leftChild={child}
                  rightChild={nextEntity}
                  key={splitterKey}
                  show={isDesktop && isHovered}
                  minColumnWidth={minColumnWidth + COLUMN_GAP}
                />,
              ]
            : []),
        ],
      }
    },
    { gridColumnStart: 1, rendered: [], left: paddingLeft } as DataType,
  )

  return <>{data.rendered}</>
}

export default RowChildren
