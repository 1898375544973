import React from 'react'
import { CommonPriceElement } from 'common/components/entities/price-element'
import { PriceElementInterface } from 'common/types/entities/price-element-interface'
import BaseEntityNew from 'client/components/core/BaseEntity/BaseEntityNew'
import usePresentPage, { selectors } from 'client/hooks/usePresentPage'
import { EntityProps } from 'client/types'
import { usePricePlans } from './hooks/usePricePlans'

export const PriceElement = ({
  entity,
  isMoveDownAllowed,
  isMoveUpAllowed,
}: EntityProps<PriceElementInterface>) => {
  const locale = usePresentPage(selectors.getPageLocale)
  const { pricePlans } = usePricePlans()
  return (
    <BaseEntityNew
      entity={entity}
      isMoveUpAllowed={isMoveUpAllowed}
      isMoveDownAllowed={isMoveDownAllowed}
    >
      <CommonPriceElement
        locale={locale}
        pricePlans={pricePlans}
        openPopup={() => {}}
      />
    </BaseEntityNew>
  )
}
