import React, { memo } from 'react'
import { borderTypeValues } from 'common/constants/settings'
import CommonPopup from 'common/components/entities/Popup/RemotePopup'
import ChildrenEntities from 'client/components/core/ChildrenEntities'

const RemotePopup = ({
  entity: { id, childIds, options, styles, mobileStyles, type },
}) => {
  const { borderType } = options
  return (
    <CommonPopup
      onCloseHandler={() => {}}
      styles={{
        ...styles,
        ...borderTypeValues[borderType],
      }}
      mobileStyles={mobileStyles}
      showClose={!!+options.showClose}
      maxHeight={'calc(100vh - 110px)'}
    >
      <ChildrenEntities id={id} type={type} childIds={childIds} />
    </CommonPopup>
  )
}

export default memo(RemotePopup)
