import { match } from 'path-to-regexp'
import React, { useEffect, useState } from 'react'
import StyleWrapper from 'common/components/StyleWrapper'
import * as types from 'common/types'
import CustomLoginInterface from 'common/types/entities/CustomLoginInterface'
import BaseNodeChildren from 'publisher/components/ChildrenEntities'
import useManagement, { selectors } from 'publisher/hooks/useManagement'

type NewCustomLoginProps = {
  entity: CustomLoginInterface
}

function NewCustomLogin({ entity }: NewCustomLoginProps) {
  const isPreview = useManagement(selectors.isPreviewMode)
  const [mode, setMode] = useState(isPreview ? [entity.childIds[0]] : null)
  useEffect(() => {
    const checkPattern = (uri: string) => match(uri)(window.location.pathname)
    // todo check
    if (checkPattern('/dashboard/:locale/login')) {
      setMode([entity.childIds[0]])
    } else if (
      checkPattern('/dashboard/:locale/registration/set-password/:token')
    ) {
      setMode([entity.childIds[1]])
    } else if (checkPattern('/:locale/register/confirm/:token')) {
      setMode([entity.childIds[1]])
    } else if (checkPattern('/dashboard/:locale/resetting/request')) {
      setMode([entity.childIds[2]])
    } else if (checkPattern('/dashboard/:locale/resetting/reset/:token')) {
      setMode([entity.childIds[3]])
    }
  }, [])
  return (
    <StyleWrapper
      desktop={entity.appearance.desktop}
      mobile={entity.appearance.mobile}
    >
      {mode && <BaseNodeChildren childIds={mode} />}
    </StyleWrapper>
  )
}

NewCustomLogin.propTypes = {
  entity: types.entity.isRequired,
}

export default NewCustomLogin
