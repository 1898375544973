import { FETCH_BLOG_DATA, FetchBlogData } from './blogActionTypes'

export type IdName = {
  id: number
  name: string
}

export interface BlogState {
  posts: IdName[]
  pages: IdName[]
  categories: IdName[]
}

const defaultState = {
  posts: [],
  pages: [],
  categories: [],
}

export default function blogReducer(
  state: BlogState = defaultState,
  action: FetchBlogData,
) {
  switch (action.type) {
    case FETCH_BLOG_DATA:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}

export function getBlogPosts(state: BlogState) {
  return state.posts
}

export function getBlogPages(state: BlogState) {
  return state.pages
}

export function getBlogCategories(state: BlogState) {
  return state.categories
}

export const selectors = {
  getBlogPosts,
  getBlogPages,
  getBlogCategories,
}
