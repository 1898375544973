import PropTypes from 'prop-types'
import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import { useActions } from 'common/hooks/useActions'
import * as types from 'common/types'
import { update } from 'client/actions/entityActions'
import BaseEntity from 'client/components/core/BaseEntity'
import TextEditor from 'client/components/core/TextEditor'
import usePresentPage from 'client/hooks/usePresentPage'
import { getIsEditing } from 'client/reducers/managementReducer'
import {
  getGlobalFontFamily,
  getGlobalLinkColor,
  getGlobalTextColor,
  getGlobalMobileTextFontSize,
  getGlobalMobileTextLineHeight,
  getGlobalTextFontSize,
  getGlobalTextLineHeight,
} from 'client/reducers/pageReducer'

function Text({ entity, isMoveUpAllowed, isMoveDownAllowed }) {
  const updateAction = useActions(update)
  const isEditing = useSelector(state =>
    getIsEditing(state.management, entity.id),
  )
  const linkColor = usePresentPage(getGlobalLinkColor)
  const textColor = usePresentPage(getGlobalTextColor)
  const fontFamily = usePresentPage(getGlobalFontFamily)
  const fontSize = usePresentPage(getGlobalTextFontSize)
  const mobileFontSize = usePresentPage(getGlobalMobileTextFontSize)
  const lineHeight = usePresentPage(getGlobalTextLineHeight)
  const mobileLineHeight = usePresentPage(getGlobalMobileTextLineHeight)

  const handleUpdateText = rawContentState => {
    updateAction({
      ...entity,
      options: {
        ...entity.options,
        rawContentState,
      },
    })
  }

  const handleUpdateStyles = styles => {
    updateAction({
      ...entity,
      styles,
    })
  }

  return (
    <BaseEntity
      entity={entity}
      mobileEmulationStyles={{
        fontSize: mobileFontSize,
        lineHeight: mobileLineHeight,
      }}
      isMoveUpAllowed={isMoveUpAllowed}
      isMoveDownAllowed={isMoveDownAllowed}
    >
      {(emulatedEntity, addToggleableNode) => (
        <TextEditor
          textWrapperProps={{
            linkColor,
            textColor,
            lineHeight,
            fontFamily,
            fontSize,
          }}
          rawContentState={entity.options.rawContentState}
          update={handleUpdateText}
          updateStyles={handleUpdateStyles}
          addToggleableNode={addToggleableNode}
          isEditing={isEditing}
          styles={emulatedEntity.styles}
        />
      )}
    </BaseEntity>
  )
}

Text.propTypes = {
  entity: types.entity.isRequired,
  isMoveUpAllowed: PropTypes.bool.isRequired,
  isMoveDownAllowed: PropTypes.bool.isRequired,
}

export function getTextFontProperties(entity) {
  const boldFontProperties = entity.options.rawContentState.includes(
    '"style":"BOLD"',
  )
    ? {
        fontFamily: entity.styles.fontFamily,
        fontStyle: 'normal',
        fontWeight: '700',
      }
    : { fontFamily: entity.styles.fontFamily }

  const italicFontProperties = entity.options.rawContentState.includes(
    '"style":"ITALIC"',
  )
    ? {
        fontFamily: entity.styles.fontFamily,
        fontStyle: 'italic',
        fontWeight: entity.styles.fontWeight,
      }
    : { fontFamily: entity.styles.fontFamily }

  return [
    {
      fontFamily: entity.styles.fontFamily,
      fontStyle: entity.styles.fontStyle,
      fontWeight: entity.styles.fontWeight,
    },
    {
      fontFamily: entity.mobileStyles.fontFamily,
      fontStyle: entity.mobileStyles.fontStyle,
      fontWeight: entity.mobileStyles.fontWeight,
    },
    boldFontProperties,
    italicFontProperties,
  ]
}

export function getTextCustomFontProperties(entity) {
  return [
    {
      fontFamily: entity.styles.fontFamily,
      fontFileId: entity.options.fontFileId,
    },
    {
      fontFamily: entity.mobileStyles.fontFamily,
      fontFileId: entity.mobileOptions.fontFileId,
    },
  ]
}

export default memo(Text)
