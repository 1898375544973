import PropTypes from 'prop-types'
import React, { memo } from 'react'
import * as types from 'common/types'
import ChildrenEntities from 'client/components/core/ChildrenEntities'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'
import { alignSelfVariants } from '../../../constants/alignSelfOptions'
import ColumnOldUi from './ui/ColumnOldUi'

function ColumnOld({
  entity,
  gridColumnStart = 1,
  size = 12,
  disableStacking = false,
}) {
  const { isMobile } = useDeviceModeContext()
  const { id, type, options, childIds } = entity
  const alignSelf = entity.styles.alignSelf || alignSelfVariants.flexStart

  return (
    <ColumnOldUi
      gridColumnStart={gridColumnStart}
      size={size || options.size}
      disableStacking={disableStacking}
      alignSelf={alignSelf}
      isMobile={isMobile}
    >
      <ChildrenEntities
        id={id}
        childIds={childIds}
        type={type}
        isReadOnly={entity.isReadOnly}
      />
    </ColumnOldUi>
  )
}

ColumnOld.propTypes = {
  entity: types.entity.isRequired,
  gridColumnStart: PropTypes.number.isRequired,
  size: PropTypes.number.isRequired,
  disableStacking: PropTypes.bool,
}

export default memo(ColumnOld)
