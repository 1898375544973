import React from 'react'
import PropTypes from 'prop-types'
import useFiles, { selectors as filesSelector } from '../../../hooks/useFiles'
import BasePlayer from './BasePlayer'

function SourcePlayer({ entity }) {
  return (
    <BasePlayer
      url={entity.options.url}
      controls={entity.options.controls}
      styles={entity.styles}
      mobileStyles={entity.mobileStyles}
    />
  )
}

SourcePlayer.propTypes = {
  entity: PropTypes.shape({
    id: PropTypes.string.isRequired,
    options: PropTypes.shape({
      attrId: PropTypes.string,
      srcFileId: PropTypes.number,
      type: PropTypes.string.isRequired,
      autoPlay: PropTypes.bool.isRequired,
      url: PropTypes.string.isRequired,
      controls: PropTypes.bool.isRequired,
    }).isRequired,
    styles: PropTypes.objectOf(PropTypes.any).isRequired,
    mobileStyles: PropTypes.objectOf(PropTypes.any).isRequired,
    type: PropTypes.string.isRequired,
  }),
}

export default SourcePlayer
