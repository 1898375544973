import { PayPalScriptProvider } from '@paypal/react-paypal-js'
import React, { memo } from 'react'
import {
  CommonPaypalRestButton,
  PaypalBackgroundColorType,
} from 'common/components/entities/paypal-rest-button'
import { PaymentButtonInterface } from 'common/types/entities/PaymentButtonInterface'
import { BorderType } from 'common/types/styleTypes'
import FieldErrors from 'publisher/components/FieldErrors'
import StyleWrapper from 'publisher/components/core/StyleWrapper'
import {
  handleCaptureOrder,
  usePaypalRestPaymentHandler,
} from 'publisher/hooks/payment/use-paypal-rest-payment-handler'
import useManagement from 'publisher/hooks/useManagement'
import { selectors as managementSelectors } from 'publisher/reducers/managementReducer'
import { usePayment } from 'publisher/store'
import { isOrderBumpChecked } from 'publisher/store/payment/paymentSelectors'

export interface PaypalRestOrderButtonProps {
  entity: PaymentButtonInterface
  currency: string
  backgroundColor?: PaypalBackgroundColorType
  borderRadius?: Partial<BorderType>
}

const PaypalRestOrderButton = ({
  entity,
  currency,
  backgroundColor,
  borderRadius,
}: PaypalRestOrderButtonProps) => {
  const { handleSubmit, errors, isLoading, orderHash } =
    usePaypalRestPaymentHandler(entity)
  const isBumpChecked = usePayment(isOrderBumpChecked)

  const isPreview = useManagement(managementSelectors.isPreviewMode)

  return (
    <PayPalScriptProvider
      options={{
        clientId: `${process.env.PAYPAL_CLIENT_ID}`,
        currency: currency.toLocaleUpperCase(),
      }}
    >
      <StyleWrapper
        flex
        flexColumn
        margin={entity.margin}
        mobileMargin={entity.mobileMargin}
        appearance={entity.appearance || { desktop: true, mobile: true }}
      >
        <CommonPaypalRestButton
          createOrder={async () => (await handleSubmit()) as string}
          onApprove={async data => {
            await handleCaptureOrder(data, orderHash, isBumpChecked)
          }}
          borderRadius={borderRadius}
          backgroundColor={backgroundColor}
          disabled={isLoading || isPreview}
        />
        <FieldErrors errors={errors} align="center" />
      </StyleWrapper>
    </PayPalScriptProvider>
  )
}

export default memo(PaypalRestOrderButton)
