import React, { memo } from 'react'
import { useDragLayer } from 'react-dnd'
import { CommonBlogPostLayoutBody } from 'common/components/entities/blog-post-layout-body'
import { BlogPostLayoutInterface } from 'common/types/entities/blog-post-layout-body-interface'
import ChildrenEntities from 'client/components/core/ChildrenEntities'

interface BlogPostLayoutBodyProps {
  entity: BlogPostLayoutInterface
}

function BlogPostLayoutBody({ entity }: BlogPostLayoutBodyProps) {
  const { isDragging } = useDragLayer(monitor => ({
    isDragging: monitor.isDragging(),
    itemType: monitor.getItemType(),
  }))

  const { id, type, childIds } = entity

  return (
    <CommonBlogPostLayoutBody>
      <ChildrenEntities
        id={id}
        childIds={childIds as never[]}
        type={type}
        isReadOnly={entity.isReadOnly}
        placeholderText="entities.body.empty_dropbox_text"
        expandedDropZone={isDragging}
      />
    </CommonBlogPostLayoutBody>
  )
}

export default memo(BlogPostLayoutBody)
