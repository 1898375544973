import undoable, { excludeAction } from 'redux-undo'
import {
  ADD_CHILD,
  CREATE_ENTITY,
  FETCH_EXIT_URL,
  FETCH_INPUT_TYPES,
  FETCH_PAGE_FILES,
  FETCH_USER,
  SUCCESS,
  TOGGLE_HIGHLIGHT,
  TOGGLE_SETTINGS,
  SAVE_PAGE,
  MOVE_NODE_NEW,
  CREATE_DESTINATION_STRUCTURE,
  ADD_STRUCTURE_ASCENDANT_AS_CHILD,
  PAGE_VALIDATION_ERROR_RESET,
  PAGE_VALIDATION_ERROR,
} from 'client/actionTypes'
import automationRulesReducer from '../store/automationRules/automationRulesReducer'
import blocks from './blocksReducer'
import filesReducer from './filesReducer'
import inputTypesReducer from './inputTypesReducer'
import managementReducer from './managementReducer'
import { pageReducer } from './pageReducer'
import payment from './paymentReducer'

const excludedActions = [
  FETCH_PAGE_FILES,
  FETCH_INPUT_TYPES + SUCCESS,
  FETCH_USER + SUCCESS,
  FETCH_EXIT_URL,
  TOGGLE_SETTINGS,
  TOGGLE_HIGHLIGHT,
  SAVE_PAGE,
  PAGE_VALIDATION_ERROR,
  PAGE_VALIDATION_ERROR_RESET,
]

const copyActions = [CREATE_ENTITY, ADD_CHILD]
const moveActions = [
  CREATE_DESTINATION_STRUCTURE,
  ADD_STRUCTURE_ASCENDANT_AS_CHILD,
  MOVE_NODE_NEW,
]

export const reducers = {
  page: undoable(pageReducer, {
    filter: excludeAction(excludedActions),
    groupBy: ({ type }) => {
      if (copyActions.includes(type)) {
        return 'copy'
      } else if (moveActions.includes(type)) {
        return 'move'
      }

      return null
    },
    limit: 10,
    debug: process.env.NODE_ENV !== 'production',
  }),
  inputTypes: inputTypesReducer,
  files: filesReducer,
  management: managementReducer,
  payment,
  blocks,
  automationRules: automationRulesReducer,
}
