import { v4 as uuid } from 'uuid'
import React from 'react'
import CommonSurvey from 'common/components/entities/SurveyNew'
import { EntityTypeEnum } from 'common/enums/entityTypeEnum'
import { MasterBlockId } from 'common/types/entities/EntityInterface'
import { SurveyInterface } from 'common/types/entities/SurveyInterface'
import useManagement, { selectors } from 'client/hooks/useManagement'
import { EntityProps } from 'client/types'
import { generateBaseEntity } from 'client/utils/createStructureUtils'
import BaseEntityNew from '../../core/BaseEntity/BaseEntityNew'
import ChildrenEntities from '../../core/ChildrenEntities'

function Survey({
  entity,
  isMoveDownAllowed,
  isMoveUpAllowed,
}: EntityProps<SurveyInterface>) {
  const isSurveyResult = useManagement(selectors.isSurveyResult)

  return (
    <BaseEntityNew
      entity={entity}
      isMoveDownAllowed={isMoveDownAllowed}
      isMoveUpAllowed={isMoveUpAllowed}
    >
      <CommonSurvey
        question={entity.questions[0]}
        questionTitleFontSize={entity.questionTitleFontSize}
        mobileQuestionTitleFontSize={entity.mobileQuestionTitleFontSize}
        questionSubTitleFontSize={entity.questionSubTitleFontSize}
        mobileQuestionSubTitleFontSize={entity.mobileQuestionSubTitleFontSize}
        fontFamily={entity.fontFamily}
        mobileFontFamily={entity.mobileFontFamily}
        fontStyle={entity.fontStyle}
        mobileFontStyle={entity.mobileFontStyle}
        fontWeight={entity.fontWeight}
        mobileFontWeight={entity.mobileFontWeight}
        answerFontSize={entity.answerFontSize}
        mobileAnswerFontSize={entity.mobileAnswerFontSize}
        answerColor={entity.answerColor}
        answerOutlineColor={entity.answerOutlineColor}
        answerBackgroundColor={entity.answerBackgroundColor}
        questionTitleColor={entity.questionTitleColor}
        questionSubTitleColor={entity.questionSubTitleColor}
        answerBorder={entity.answerBorder}
        mobileAnswerBorder={entity.mobileAnswerBorder}
        backgroundColor={entity.backgroundColor}
        mobileBackgroundColor={entity.mobileBackgroundColor}
        border={entity.border}
        mobileBorder={entity.mobileBorder}
        boxShadow={entity.boxShadow}
        mobileBoxShadow={entity.mobileBoxShadow}
        showResult={isSurveyResult}
        padding={entity.padding}
        mobilePadding={entity.mobilePadding}
        choose={() => {}}
        isEdit
      >
        <ChildrenEntities
          id={entity.id}
          type={entity.type}
          childIds={entity.childIds}
          isReadOnly={entity.isReadOnly}
        />
      </CommonSurvey>
    </BaseEntityNew>
  )
}

export function createSurvey(
  parentId: string,
  masterBlockId?: MasterBlockId,
): SurveyInterface {
  return {
    ...generateBaseEntity(EntityTypeEnum.Survey, parentId, masterBlockId),
    type: EntityTypeEnum.Survey,
    childIds: [],
    answerBackgroundColor: 'rgba(240,244,248, 1)',
    answerBorder: {
      radius: 25,
    },
    answerColor: 'rgba(93 , 100, 110, 1)',
    answerFontSize: 18,
    answerOutlineColor: 'rgba(20,158,252,1)',
    mobileQuestionTitleFontSize: 26,
    questionSubTitleColor: 'rgba(139, 146, 156, 1)',
    questionSubTitleFontSize: 16,
    questionTitleColor: 'rgba(20, 45, 99, 1)',
    questionTitleFontSize: 30,
    questions: [
      {
        id: uuid(),
        title: 'Add Your SurveyQuestionType Here',
        subTitle: 'Add a description to your question here',
        answers: [
          {
            id: uuid(),
            title: 'Answer 1',
          },
          {
            id: uuid(),
            title: 'Answer 2',
          },
          {
            id: uuid(),
            title: 'Answer 3',
          },
        ],
      },
    ],
    padding: {
      paddingTop: 15,
      paddingRight: 100,
      paddingBottom: 15,
      paddingLeft: 100,
    },
    mobilePadding: {
      paddingTop: 15,
      paddingRight: 15,
      paddingBottom: 15,
      paddingLeft: 15,
    },
  } as SurveyInterface
}

export function getSurveyFontProperties(entity: SurveyInterface) {
  const fontProperties = entity.fontFamily
    ? [
        {
          fontFamily: entity.fontFamily,
          fontStyle: entity.fontStyle,
          fontWeight: entity.fontWeight,
        },
      ]
    : []

  if (entity.mobileFontFamily) {
    fontProperties.push({
      fontFamily: entity.mobileFontFamily,
      fontStyle: entity.mobileFontStyle,
      fontWeight: entity.mobileFontWeight,
    })
  }

  return fontProperties
}

export function getSurveyCustomFontProperties(entity: SurveyInterface) {
  const fontProperties = entity.fontFamily
    ? [
        {
          fontFamily: entity.fontFamily,
          fontFileId: entity.fontFileId,
        },
      ]
    : []

  if (entity.mobileFontFamily) {
    fontProperties.push({
      fontFamily: entity.mobileFontFamily,
      fontFileId: entity.mobileFontFileId,
    })
  }

  return fontProperties
}
export default Survey
