import PropTypes from 'prop-types'
import React, { memo } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import CommonSection from 'common/components/entities/Section/SectionOld'
import * as types from 'common/types'
import BaseEntity, { color } from 'client/components/core/BaseEntity'
import ChildrenEntities from 'client/components/core/ChildrenEntities'
import { getFileById } from 'client/reducers/filesReducer'
import { getBlockFile } from '../../../reducers/blocksReducer'
import emulationMobileStyles from './emulationMobileStyles'

function SectionOld({ entity, isMoveUpAllowed, isMoveDownAllowed }) {
  const backgroundFile = useSelector(
    state =>
      getFileById(state.files, entity.options.backgroundFileId) ||
      getBlockFile(state.blocks, entity.options.backgroundFileId),
    shallowEqual,
  )
  const mobileBackgroundFile = useSelector(
    state =>
      getFileById(state.files, entity.mobileOptions.backgroundFileId) ||
      getBlockFile(state.blocks, entity.mobileOptions.backgroundFileId),
    shallowEqual,
  )
  const calculatedMobileBackgroundFile =
    typeof entity.mobileOptions.backgroundFileId === 'undefined'
      ? backgroundFile
      : mobileBackgroundFile
  return (
    <BaseEntity
      entity={entity}
      color={color.green}
      hoverColor={color.green}
      mobileEmulationStyles={emulationMobileStyles}
      isMoveUpAllowed={isMoveUpAllowed}
      isMoveDownAllowed={isMoveDownAllowed}
      outline
    >
      {(emulatedEntity, addToggleableNode, isMobile) => (
        <CommonSection
          styles={emulatedEntity.styles}
          mobileStyles={emulatedEntity.mobileStyles}
          options={emulatedEntity.options}
          backgroundFile={
            isMobile ? calculatedMobileBackgroundFile : backgroundFile
          }
          mobileOptions={emulatedEntity.mobileOptions}
        >
          <ChildrenEntities
            id={emulatedEntity.id}
            childIds={emulatedEntity.childIds}
            type={emulatedEntity.type}
            isReadOnly={entity.isReadOnly}
          />
        </CommonSection>
      )}
    </BaseEntity>
  )
}

SectionOld.propTypes = {
  entity: types.entity.isRequired,
  isMoveUpAllowed: PropTypes.bool.isRequired,
  isMoveDownAllowed: PropTypes.bool.isRequired,
}

export default memo(SectionOld)
