import React from 'react'
import CommonWebinarSessionDateTime from 'common/components/entities/WebinarSessionDateTime/WebinarSessionDateTimeNew'
import EntityTypeEnum from 'common/enums/entityTypeEnum'
import { MasterBlockId } from 'common/types/entities/EntityInterface'
import WebinarSessionDateTimeInterface from 'common/types/entities/WebinarSessionDateTimeInterface'
import BaseEntityNew from 'client/components/core/BaseEntity/BaseEntityNew'
import { generateBaseEntity } from 'client/utils/createStructureUtils'
import { EntityProps } from '../../../types'

function WebinarSessionDateTime({
  entity,
  isMoveDownAllowed,
  isMoveUpAllowed,
}: EntityProps<WebinarSessionDateTimeInterface>) {
  return (
    <BaseEntityNew
      entity={entity}
      isMoveDownAllowed={isMoveDownAllowed}
      isMoveUpAllowed={isMoveUpAllowed}
    >
      <CommonWebinarSessionDateTime
        attrId={entity.htmlAttrId}
        padding={entity.padding}
        mobilePadding={entity.mobilePadding}
        border={entity.border}
        mobileBorder={entity.mobileBorder}
        backgroundColor={entity.backgroundColor}
        mobileBackgroundColor={entity.mobileBackgroundColor}
        color={entity.color}
        mobileColor={entity.mobileColor}
        fontSize={entity.fontSize}
        iconFontSize={entity.iconFontSize}
        mobileFontSize={entity.mobileFontSize}
        mobileIconFontSize={entity.mobileIconFontSize}
        boxShadow={entity.boxShadow}
        mobileBoxShadow={entity.mobileBoxShadow}
        fontFamily={entity.fontFamily}
        fontWeight={entity.fontWeight}
        fontStyle={entity.fontStyle}
        mobileFontFamily={entity.mobileFontFamily}
        mobileFontWeight={entity.mobileFontWeight}
        mobileFontStyle={entity.mobileFontStyle}
      />
    </BaseEntityNew>
  )
}

export function createWebinarSessionDateTime(
  parentId: string,
  masterBlockId?: MasterBlockId,
): WebinarSessionDateTimeInterface {
  return {
    ...generateBaseEntity(
      EntityTypeEnum.WebinarSessionDateTime,
      parentId,
      masterBlockId,
    ),
    type: EntityTypeEnum.WebinarSessionDateTime,
    padding: {
      paddingTop: 10,
      paddingBottom: 10,
      paddingLeft: 10,
      paddingRight: 10,
    },
    mobilePadding: {
      paddingTop: 10,
      paddingBottom: 10,
      paddingLeft: 10,
      paddingRight: 10,
    },
    fontSize: 18,
    iconFontSize: 20,
  }
}

export default WebinarSessionDateTime
