import PropTypes from 'prop-types'
import React from 'react'
import { wrapFontFamily } from 'common/components/entities/Text/utils'
import { usePage } from 'publisher/store'
import pageSelectors from 'publisher/store/page/pageSelectors'
import PageUi from './ui/PageUi'
import Meta from '../components/core/Meta'

function CustomLoginPage({ children }) {
  const globalTextFontProperties = usePage(
    pageSelectors.getGlobalFontProperties,
  )
  return (
    <PageUi
      $textFontFamily={wrapFontFamily(globalTextFontProperties.fontFamily)}
      $textFontWeight={globalTextFontProperties.fontWeight}
      $textFontStyle={globalTextFontProperties.fontStyle}
    >
      <Meta />
      {children}
    </PageUi>
  )
}

CustomLoginPage.propTypes = {
  children: PropTypes.node.isRequired,
}

export default CustomLoginPage
