import React, { memo } from 'react'
import CommonButton from 'common/components/entities/Button/button'
import { ButtonInterface } from 'common/types/entities/button-interface'
import BaseEntityNew from 'client/components/core/BaseEntity/BaseEntityNew'
import { EntityProps } from 'client/types'

export interface NewButtonProps {
  entity: ButtonInterface
}

const ButtonNew = ({
  entity,
  isMoveDownAllowed,
  isMoveUpAllowed,
}: EntityProps<ButtonInterface>) => {
  return (
    <BaseEntityNew
      entity={entity}
      flex
      flexColumn
      isMoveUpAllowed={isMoveUpAllowed}
      isMoveDownAllowed={isMoveDownAllowed}
    >
      <CommonButton
        iconClassNameBefore={entity.iconClassNameBefore}
        iconClassNameAfter={entity.iconClassNameAfter}
        text={entity.text}
        border={entity.border}
        mobileBorder={entity.mobileBorder}
        subText={entity.subText}
        padding={entity.padding}
        mobilePadding={entity.mobilePadding}
        alignSelf={entity.alignSelf}
        mobileAlignSelf={entity.mobileAlignSelf}
        background={entity.backgroundColor ?? entity.background}
        mobileBackground={
          entity.mobileBackground ?? entity.mobileBackgroundColor
        }
        attrId={entity.htmlAttrId}
        width={entity.width}
        mobileWidth={entity.mobileWidth}
        textColor={entity.textColor}
        mobileTextColor={entity.mobileTextColor}
        mobileSubTextColor={entity.mobileSubTextColor}
        subTextColor={entity.subTextColor}
        textFontSize={entity.textFontSize}
        mobileTextFontSize={entity.mobileTextFontSize}
        subTextFontSize={entity.subTextFontSize}
        mobileSubTextFontSize={entity.mobileSubTextFontSize}
        textFontFamily={entity.textFontFamily}
        textFontWeight={entity.textFontWeight}
        textFontStyle={entity.textFontStyle}
        lineHeight={entity.lineHeight}
        subTextFontFamily={entity.subTextFontFamily}
        subTextFontWeight={entity.subTextFontWeight}
        subTextFontStyle={entity.subTextFontStyle}
        mobileTextFontFamily={entity.mobileTextFontFamily}
        mobileTextFontWeight={entity.mobileTextFontWeight}
        mobileTextFontStyle={entity.mobileTextFontStyle}
        mobileSubTextFontFamily={entity.mobileSubTextFontFamily}
        mobileSubTextFontWeight={entity.mobileSubTextFontWeight}
        mobileSubTextFontStyle={entity.mobileSubTextFontStyle}
        mobileLineHeight={entity.mobileLineHeight}
        boxShadow={entity.boxShadow}
        mobileBoxShadow={entity.mobileBoxShadow}
        hover={entity.hover}
        mobileHover={entity.mobileHover}
      />
    </BaseEntityNew>
  )
}

export default memo(ButtonNew)
