import React, { useState, createContext, useContext } from 'react'

export const SidebarVisibilityContext = createContext()
export const SidebarVisibilityActionsContext = createContext()

export const useSidebarVisibilityContext = () =>
  useContext(SidebarVisibilityContext)

export const useSidebarVisibilityActionsContext = () =>
  useContext(SidebarVisibilityActionsContext)

function SidebarVisibilityContextProvider(props) {
  const [isVisible, setVisible] = useState(true)
  const showSidebar = () => setVisible(true)
  const toggleSidebar = () => setVisible(!isVisible)

  return (
    <SidebarVisibilityContext.Provider value={isVisible}>
      <SidebarVisibilityActionsContext.Provider
        value={{ showSidebar, toggleSidebar }}
      >
        {props.children}
      </SidebarVisibilityActionsContext.Provider>
    </SidebarVisibilityContext.Provider>
  )
}

export default SidebarVisibilityContextProvider
