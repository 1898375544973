export const mockBooking = {
  selectedDate: getFirst14Days()[2].date,
  availabilitySlots: getFirst14Days(),
  selectedTimeSlot: 54000,
}

function getFirst14Days() {
  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth();

  return Array.from({ length: 14 }, (_, i) => ({
    date: new Date(year, month, i + 1),
    spots: [
        { startTime: 54000 },
        { startTime: 64000 },
        { startTime: 74000 },
        { startTime: 84000 },
    ],
  }))
}
