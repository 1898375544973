import styled, { css } from 'styled-components'

const Tab = styled.li`
  flex: 1 0 auto;
  text-align: center;
  padding: 6px 2px;
  margin: 0 6px;
  position: relative;
  font-size: 12px;
  transition: color 0.5s ease;
  line-height: 2;
  letter-spacing: 1px;
  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 0;
    margin: auto;
    background-color: #459df5;
    transition: height 0.2s linear;
  }
  ${props =>
    props.active &&
    css`
      color: #020020;
      &:after {
        height: 3px;
      }
    `}
`

export default Tab
