import PropTypes from 'prop-types'
import React, { memo } from 'react'
import { Trans } from 'react-i18next'
import { useTranslation } from 'react-i18next'
import CommonBody from 'common/components/entities/BodyOld'
import * as types from 'common/types'
import Badge from 'client/components/core/Badge'
import BaseEntity from 'client/components/core/BaseEntity'
import ChildrenEntities from '../../core/ChildrenEntities'
import BlogContentPlaceholderUi from '../BlogContentPlaceholder/ui/BlogContentPlaceholderUi'

const BlogPostContentPlaceholder = ({
  entity,
  isMoveDownAllowed,
  isMoveUpAllowed,
}) => {
  const { t } = useTranslation()

  const renderChildren = () => {
    return entity.childIds && entity.childIds.length > 0 ? (
      <ChildrenEntities
        id={entity.id}
        isReadOnly={entity.isReadOnly}
        childIds={entity.childIds}
        type={entity.type}
      />
    ) : (
      <>
        <BlogContentPlaceholderUi>
          {t('entities.blog_post_content_placeholder.placeholder')}
        </BlogContentPlaceholderUi>
        <Badge wide>
          <Trans parent="span">
            entities.blog_post_content_placeholder.warnings.do_not_remove
          </Trans>
        </Badge>
      </>
    )
  }

  return (
    <BaseEntity
      entity={entity}
      isMoveUpAllowed={isMoveUpAllowed}
      isMoveDownAllowed={isMoveDownAllowed}
    >
      <CommonBody
        styles={entity.styles}
        mobileStyles={entity.mobileStyles}
        options={entity.options}
        mobileOptions={entity.mobileOptions}
      >
        {renderChildren()}
      </CommonBody>
    </BaseEntity>
  )
}

BlogPostContentPlaceholder.propTypes = {
  entity: types.entity.isRequired,
  isMoveUpAllowed: PropTypes.bool.isRequired,
  isMoveDownAllowed: PropTypes.bool.isRequired,
}

export default memo(BlogPostContentPlaceholder)
