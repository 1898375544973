import PropTypes from 'prop-types'
import React from 'react'
import { HeadingTypeEnum } from 'common/enums/HeadingTypeEnum'
import StyleControls from './StyleControls'

export type HeadingTypeStyles = {
  fontSize: number
  lineHeight: number
}

const BLOCK_TYPES = [
  { label: '<i class="fa-regular fa-h1" />', style: HeadingTypeEnum.HeaderOne },
  { label: '<i class="fa-regular fa-h2" />', style: HeadingTypeEnum.HeaderTwo },
  { label: '<i class="fa-regular fa-h3" />', style: HeadingTypeEnum.HeaderThree },
  { label: '<i class="fa-regular fa-h4" />', style: HeadingTypeEnum.HeaderFour },
  { label: '<i class="fa-regular fa-h5" />', style: HeadingTypeEnum.HeaderFive },
  { label: '<i class="fa-regular fa-h6" />', style: HeadingTypeEnum.HeaderSix },
]

export const headingFontSize = {
  [HeadingTypeEnum.HeaderOne]: { fontSize: 40, lineHeight: 48 },
  [HeadingTypeEnum.HeaderTwo]: { fontSize: 32, lineHeight: 38.4 },
  [HeadingTypeEnum.HeaderThree]: { fontSize: 28, lineHeight: 33.6 },
  [HeadingTypeEnum.HeaderFour]: { fontSize: 24, lineHeight: 28.8 },
  [HeadingTypeEnum.HeaderFive]: { fontSize: 20, lineHeight: 24 },
  [HeadingTypeEnum.HeaderSix]: { fontSize: 16, lineHeight: 19.2 },
}

export const mobileHeadingFontSize = {
  [HeadingTypeEnum.HeaderOne]: { fontSize: 30, lineHeight: 36 },
  [HeadingTypeEnum.HeaderTwo]: { fontSize: 24, lineHeight: 28.8 },
  [HeadingTypeEnum.HeaderThree]: { fontSize: 22, lineHeight: 26.4 },
  [HeadingTypeEnum.HeaderFour]: { fontSize: 20, lineHeight: 24 },
  [HeadingTypeEnum.HeaderFive]: { fontSize: 16, lineHeight: 19.2 },
  [HeadingTypeEnum.HeaderSix]: { fontSize: 14, lineHeight: 16.8 },
}

type HeadingControlsProps = {
  currentType: string
  onToggle: (
    headingStyles: HeadingTypeStyles,
    headingMobileStyles: HeadingTypeStyles,
    type: HeadingTypeEnum,
  ) => void
}

function HeadingControls({ currentType, onToggle }: HeadingControlsProps) {
  const handleToggle = (type: string) =>
    onToggle(
      headingFontSize[type as HeadingTypeEnum],
      mobileHeadingFontSize[type as HeadingTypeEnum],
      type as HeadingTypeEnum,
    )

  return (
    <StyleControls
      checkActive={style => currentType === style}
      controls={BLOCK_TYPES}
      handleClick={handleToggle}
    />
  )
}

HeadingControls.propTypes = {
  currentType: PropTypes.string.isRequired,
  onToggle: PropTypes.func.isRequired,
}

export default HeadingControls
