import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { CompositeDecorator } from 'draft-js'

const LinkUi = styled.a`
  position: relative;
  cursor: pointer;
`

const Link = ({ entityKey, contentState, children, clickHandler }) => {
  const { url } = contentState.getEntity(entityKey).getData()
  return (
    <LinkUi href={url} onClick={clickHandler}>
      {children}
    </LinkUi>
  )
}

Link.propTypes = {
  entityKey: PropTypes.string.isRequired,
  contentState: PropTypes.objectOf(PropTypes.any).isRequired,
  children: PropTypes.arrayOf(PropTypes.any).isRequired,
  clickHandler: PropTypes.func.isRequired,
}

const findLinkEntities = (contentBlock, callback, contentState) => {
  contentBlock.findEntityRanges(character => {
    const entityKey = character.getEntity()

    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === 'LINK'
    )
  }, callback)
}

const createLinkDecorator = clickHandler => {
  return new CompositeDecorator([
    {
      strategy: findLinkEntities,
      component: Link,
      props: {
        clickHandler,
      },
    },
  ])
}

export default createLinkDecorator
