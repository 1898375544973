import styled from 'styled-components'

const FileIconUi = styled.i`
  margin-bottom: 10px;
  transition: all 0.2s linear;
  font-size: ${p => p.fontSize};
  &:hover {
    transform: scale(1.1);
  }
`

export default FileIconUi
