import React, { useEffect, useRef, useState } from 'react'
import { AlignSelfType } from 'common/types/styleTypes'
import AlignControlsButtonUi from 'client/components/entities/Row/ui/AlignControlsButtonUi'
import AlignControlsContainerUi from 'client/components/entities/Row/ui/AlignControlsContainerUi'
import { alignSelfVariants } from 'client/constants/alignSelfOptions'

const MAX_OFFSET = 100
const TOP_OFFSET_UNDER_SETTING_CONTROLS = 26
const TOP_DEFAULT_OFFSET = 0

export interface OptionalRefInterface {
  box: HTMLDivElement
  copyButton: HTMLDivElement
  removeButton: HTMLDivElement
  saveButton: HTMLDivElement
  settingsButton: HTMLDivElement
}
interface ColumnAlignControlsProps {
  left: number
  show: boolean
  update: (type: AlignSelfType) => void
  activeMode?: AlignSelfType
  externalActionsRef: React.MutableRefObject<OptionalRefInterface | null>
  withoutTop: boolean
  columnSize: number
  isLastChild: boolean
}

export default function ColumnAlignControls({
  left,
  show,
  update,
  activeMode = alignSelfVariants.flexStart,
  externalActionsRef,
  withoutTop,
  columnSize,
  isLastChild,
}: ColumnAlignControlsProps) {
  const boxRef = useRef<HTMLDivElement>(null)
  const [outsideTop, setOutsideTop] = useState(true)
  const [isLastWithTopOffset, setIsLastWithTopOffset] = useState(false)

  const handleUpdateUp = (e: React.SyntheticEvent) => {
    e.stopPropagation()
    update(alignSelfVariants.flexStart)
  }
  const handleUpdateCenter = (e: React.SyntheticEvent) => {
    e.stopPropagation()
    update(alignSelfVariants.center)
  }
  const handleUpdateDown = (e: React.SyntheticEvent) => {
    e.stopPropagation()
    update(alignSelfVariants.flexEnd)
  }

  useEffect(() => {
    if (externalActionsRef.current && externalActionsRef.current.box) {
      const { top } = externalActionsRef.current.box.getBoundingClientRect()
      if (top < MAX_OFFSET) {
        setOutsideTop(false)
      } else {
        setOutsideTop(true)
      }
    }
  }, [externalActionsRef.current])

  useEffect(() => {
    if (isLastChild) {
      if (boxRef.current && externalActionsRef.current) {
        const { width: alignControlsWidth } =
          boxRef.current.getBoundingClientRect()

        let combinedControlsWidth = alignControlsWidth

        if (externalActionsRef.current.copyButton) {
          const { width: copyButtonWidth } =
            externalActionsRef.current.copyButton.getBoundingClientRect()
          combinedControlsWidth += copyButtonWidth
        }
        if (externalActionsRef.current.removeButton) {
          const { width: removeButtonWidth } =
            externalActionsRef.current.removeButton.getBoundingClientRect()
          combinedControlsWidth += removeButtonWidth
        }
        if (externalActionsRef.current.saveButton) {
          const { width: saveButtonWidth } =
            externalActionsRef.current.saveButton.getBoundingClientRect()
          combinedControlsWidth += saveButtonWidth
        }
        if (externalActionsRef.current.settingsButton) {
          const { width: settingsButtonWidth } =
            externalActionsRef.current.settingsButton.getBoundingClientRect()
          combinedControlsWidth += settingsButtonWidth
        }

        if (combinedControlsWidth > columnSize && !outsideTop) {
          setIsLastWithTopOffset(true)
        } else {
          setIsLastWithTopOffset(false)
        }
      }
    }
  }, [columnSize, boxRef.current, externalActionsRef.current])

  const getTopOffset = () => {
    if (isLastChild) {
      if (isLastWithTopOffset) {
        return TOP_OFFSET_UNDER_SETTING_CONTROLS
      } else {
        return TOP_DEFAULT_OFFSET
      }
    }
    if (withoutTop || outsideTop) {
      return TOP_DEFAULT_OFFSET
    }

    return TOP_OFFSET_UNDER_SETTING_CONTROLS
  }

  return (
    <AlignControlsContainerUi
      left={left}
      show={show}
      ref={boxRef}
      top={getTopOffset()}
    >
      <AlignControlsButtonUi
        onClick={handleUpdateUp}
        active={activeMode === alignSelfVariants.flexStart}
      >
        <span className={'fa-solid fa-objects-align-top'} />
      </AlignControlsButtonUi>
      <AlignControlsButtonUi
        onClick={handleUpdateCenter}
        active={activeMode === alignSelfVariants.center}
      >
        <span className={'fa-solid fa-objects-align-center-vertical'} />
      </AlignControlsButtonUi>
      <AlignControlsButtonUi
        onClick={handleUpdateDown}
        active={activeMode === alignSelfVariants.flexEnd}
      >
        <span className={'fa-solid fa-objects-align-bottom'} />
      </AlignControlsButtonUi>
    </AlignControlsContainerUi>
  )
}
