import {
  SET_EDITING_ENTITY,
  SetEditingEntity,
  SWITCH_ENTITY_INNER_ITEM_ACTIVE_INDEX,
  SwitchEntityInnerItemActiveIndex,
  SET_USER_COLORS,
  SetUserColors,
  NEED_SCROLL,
  NeedScrollAction,
  TOGGLE_INSTANT_UPLOAD,
  TOGGLE_PAYPAL_SETTINGS,
  TogglePayPalSettings,
  ToggleInstantUpload,
} from './managementActionTypes'

export function switchEntityInnerItemActiveIndex(
  entityId: string,
  entityInnerItemActiveIndex: number,
): SwitchEntityInnerItemActiveIndex {
  return {
    type: SWITCH_ENTITY_INNER_ITEM_ACTIVE_INDEX,
    payload: {
      entityId,
      entityInnerItemActiveIndex,
    },
  }
}

export function needScroll(value: boolean): NeedScrollAction {
  return {
    type: NEED_SCROLL,
    payload: value,
  }
}

export function setEditingEntity(entityId: string): SetEditingEntity {
  return {
    type: SET_EDITING_ENTITY,
    payload: entityId,
  }
}

export function setUserColors(customPallete: string[]): SetUserColors {
  return {
    type: SET_USER_COLORS,
    payload: customPallete,
  }
}

export function disableInstantUpload(): ToggleInstantUpload {
  return {
    type: TOGGLE_INSTANT_UPLOAD,
    payload: false,
  }
}

export function enableInstantUpload(): ToggleInstantUpload {
  return {
    type: TOGGLE_INSTANT_UPLOAD,
    payload: true,
  }
}

export function togglePayPalSettings(value: boolean): TogglePayPalSettings {
  return {
    type: TOGGLE_PAYPAL_SETTINGS,
    payload: value,
  }
}

export type ManagementActions =
  | SwitchEntityInnerItemActiveIndex
  | NeedScrollAction
  | SetEditingEntity
  | SetUserColors
  | ToggleInstantUpload
