import PropTypes from 'prop-types'
import React, { memo } from 'react'
import CommonCoupon from 'common/components/entities/Coupon'
import { borderTypeValues } from 'common/constants/settings'
import * as types from 'common/types'
import BaseEntity from 'client/components/core/BaseEntity'

function Coupon({ entity, isMoveDownAllowed, isMoveUpAllowed }) {
  let fieldStyles = entity.options.fieldStyles
  if (entity.options.fieldOptions.borderType) {
    fieldStyles = {
      ...fieldStyles,
      ...borderTypeValues[entity.options.fieldOptions.borderType],
    }
  }
  let mobileFieldStyles = entity.mobileOptions.fieldStyles
  if (entity.mobileOptions.fieldOptions.borderType) {
    mobileFieldStyles = {
      ...mobileFieldStyles,
      ...borderTypeValues[entity.mobileOptions.fieldOptions.borderType],
    }
  }
  let buttonStyles = entity.options.buttonStyles
  if (entity.options.buttonOptions.borderType) {
    buttonStyles = {
      ...buttonStyles,
      ...borderTypeValues[entity.options.buttonOptions.borderType],
    }
  }
  let mobileButtonStyles = entity.mobileOptions.buttonStyles
  if (entity.mobileOptions.buttonOptions.borderType) {
    mobileButtonStyles = {
      ...mobileButtonStyles,
      ...borderTypeValues[entity.mobileOptions.buttonOptions.borderType],
    }
  }

  return (
    <BaseEntity
      entity={entity}
      isMoveUpAllowed={isMoveUpAllowed}
      isMoveDownAllowed={isMoveDownAllowed}
    >
      {() => (
        <CommonCoupon
          styles={entity.styles}
          mobileStyles={entity.mobileStyles}
          fieldStyles={fieldStyles}
          mobileFieldStyles={mobileFieldStyles}
          placeholder={entity.options.placeholder}
          buttonStyles={buttonStyles}
          mobileButtonStyles={mobileButtonStyles}
          buttonText={entity.options.buttonText}
        />
      )}
    </BaseEntity>
  )
}

Coupon.propTypes = {
  entity: types.entity.isRequired,
  isMoveUpAllowed: PropTypes.bool.isRequired,
  isMoveDownAllowed: PropTypes.bool.isRequired,
}

export default memo(Coupon)
