import styled from 'styled-components'

const FileRemoveIconUi = styled.i`
  position: absolute;
  right: 0;
  top: 0;
  display: none;
  opacity: 0;
  color: red;
  z-index: 1;
  transition: opacity 200ms ease-in-out;
`

export default FileRemoveIconUi
