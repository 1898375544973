import PropTypes from 'prop-types'
import { PayPalScriptProvider } from '@paypal/react-paypal-js'
import React from 'react'
import {
  Button,
  Step,
  StepFooter,
} from 'common/components/entities/TwoStepPaymentForm'
import { CommonPaypalRestButton } from 'common/components/entities/paypal-rest-button'
import { isPaypalSettingsOpened } from 'client/reducers/managementReducer'
import { useManagement } from 'client/store'
import ChildrenEntities from '../../core/ChildrenEntities'

function PaymentStep({ entity, buttonStyles, mobileButtonStyles }) {
  const showPaypalSettings = useManagement(isPaypalSettingsOpened)

  return (
    <Step>
      <ChildrenEntities
        id={entity.id}
        type={entity.type}
        childIds={entity.childIds}
      />
      {showPaypalSettings ? (
        <PayPalScriptProvider options={{ clientId: 'test' }}>
          <CommonPaypalRestButton
            backgroundColor={entity?.options?.paypal?.backgroundColor}
            forceReRender={[entity?.options?.paypal]}
            disabled
          />
        </PayPalScriptProvider>
      ) : (
        <>
          <Button
            styles={buttonStyles}
            mobileStyles={mobileButtonStyles}
            text={entity.options.buttonText}
            subText={entity.options.buttonSubText}
          />
          {entity.options.footerText && (
            <StepFooter>{entity.options.footerText}</StepFooter>
          )}
        </>
      )}
    </Step>
  )
}

PaymentStep.propTypes = {
  entity: PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    options: PropTypes.shape({
      buttonText: PropTypes.string.isRequired,
      buttonSubText: PropTypes.string.isRequired,
      footerText: PropTypes.string.isRequired,
    }).isRequired,
    childIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  buttonStyles: PropTypes.shape({
    buttonBackgroundColor: PropTypes.string.isRequired,
    buttonTextColor: PropTypes.string.isRequired,
  }).isRequired,
  mobileButtonStyles: PropTypes.shape({
    buttonBackgroundColor: PropTypes.string,
    buttonTextColor: PropTypes.string,
  }),
}

export default PaymentStep
