import styled, { css } from 'styled-components'
import { stretchMixin } from 'client/utils/styleUtils'

export const modes = {
  highlighted: 'highlighted',
  hover: 'hover',
  warning: 'warning',
  editing: 'editing',
}

const outlineStylesMixin = css`
  outline: ${p => `2px solid ${p.color}`};
  outline-offset: -2px;
`

const shadowStylesMixin = css`
  box-shadow: ${p => `0px 0px 0px 2px ${p.color}`};
`

const highlightedStylesMixin = css`
  box-shadow: ${p => `0px 0px 0px 2px ${p.color}`};
  transition: box-shadow 0.25s ease-in;
`

const BaseEntityUi = styled.div`
  ${p =>
    !p.isEditing &&
    css`
      cursor: pointer;
    `}
  ${p =>
    p.mode === modes.hover &&
    p.color &&
    (p.outline ? outlineStylesMixin : shadowStylesMixin)};
  ${p =>
    p.mode === modes.highlighted &&
    p.color &&
    (p.outline ? outlineStylesMixin : highlightedStylesMixin)};
  ${p =>
    p.mode === modes.editing &&
    p.color &&
    (p.outline ? outlineStylesMixin : highlightedStylesMixin)};
  ${p =>
    p.mode === modes.warning &&
    p.color &&
    (p.outline ? outlineStylesMixin : highlightedStylesMixin)};
  ${p =>
    p.resrtictPointerEvents &&
    css`
      pointer-events: none;
    `}
  ${stretchMixin};
  position: relative;
  ${p =>
    p.theme.isEditorMobileMode ? { ...p.styles, ...p.mobileStyles } : p.styles}
`

export default BaseEntityUi
