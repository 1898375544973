import {
  CalculatePriceData,
  GetShippingRules,
  ShippingResponse,
} from 'common/types/shippingType'
import request from 'client/utils/request'
import { CalculatedPrice } from 'common/types/pricingTypes'

export const getShippingRules = (
  data: GetShippingRules,
  abortSignal?: AbortSignal,
) =>
  request.post<ShippingResponse>(
    `${process.env.SYSTEME_IO_HOST}/api/payment/shipping-rules`,
    data,
    {
      signal: abortSignal,
    },
  )

export const calculatePrice = (
  data: CalculatePriceData[],
  abortSignal?: AbortSignal,
) =>
  request.post<CalculatedPrice[]>(
    `${process.env.SYSTEME_IO_HOST}/api/payment/calculate-price`,
    data,
    {
      signal: abortSignal,
    },
  )
