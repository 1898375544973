import EntityTypeEnum from 'common/enums/entityTypeEnum'
import { AgreementInterface } from 'common/types/entities/AgreementInterface'
import { MasterBlockId } from 'common/types/entities/EntityInterface'
import { generateBaseEntity } from 'client/utils/createStructureUtils'

export const createAgreement = (
  parentId: string,
  masterBlockId?: MasterBlockId,
): AgreementInterface => {
  return {
    ...generateBaseEntity(EntityTypeEnum.Agreement, parentId, masterBlockId),
    type: EntityTypeEnum.Agreement,
  }
}
