import { EntityWithChildIdsMigrationType } from 'common/types/entities/EntityInterface'
import { createAgreement } from 'client/components/core/Sidebar/components/Settings/entities/NewAgreementSettings/utils/create-agreement'
import {
  createContainerStructure,
  insertChild,
} from 'client/utils/createStructureUtils'

export const createAgreementFromLibrary = (
  container: EntityWithChildIdsMigrationType,
  locale: string,
  position = 0,
) => {
  const { structure, descendantEntity } = createContainerStructure(
    container,
    locale,
    position,
  )

  const agreementEntity = createAgreement(container.id, container.masterBlockId)

  return {
    ...structure,
    [descendantEntity.id]: {
      ...descendantEntity,
      childIds: insertChild(
        descendantEntity.childIds,
        agreementEntity.id,
        position,
      ),
    },
    [agreementEntity.id]: {
      ...agreementEntity,
      parentId: descendantEntity.id,
    },
  }
}
