import { Strategy, Placement } from '@floating-ui/core'
import { FlipOptions } from '@floating-ui/dom'
import { FloatingFocusManager } from '@floating-ui/react'
import React, { ReactElement } from 'react'
import usePopoverProperties from './hooks/usePopoverProperties'

interface PopoverProps {
  offsetValue: number
  flipOptions: FlipOptions
  strategyValue: Strategy
  placement: Placement
  triggerElement: ReactElement
  popoverContent: ReactElement
}

function Popover({
  offsetValue,
  flipOptions,
  strategyValue,
  placement,
  triggerElement,
  popoverContent,
}: PopoverProps) {
  const {
    refs,
    floatingStyles,
    getReferenceProps,
    getFloatingProps,
    isOpen,
    context,
  } = usePopoverProperties({
    offsetValue,
    flipOptions,
    strategyValue,
    placement,
  })

  const triggerElementWithProps = React.cloneElement(triggerElement, {
    ref: refs.setReference,
    ...getReferenceProps(),
  })

  return (
    <>
      {triggerElementWithProps}
      {isOpen && (
        <FloatingFocusManager context={context} modal={false}>
          <div
            ref={refs.setFloating}
            style={{ ...floatingStyles, zIndex: 100 }}
            {...getFloatingProps()}
          >
            {popoverContent}
          </div>
        </FloatingFocusManager>
      )}
    </>
  )
}

export default Popover
