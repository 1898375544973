import styled, { css } from 'styled-components'
import { MarginType } from 'common/types/styleTypes'

type ReadOnlyEntityUiProps = {
  margin: MarginType
  mobileMargin: Partial<MarginType>
  styles?: Record<string, string>
  showInDesktopMode: boolean
  showInMobileMode: boolean
  flex?: boolean
  flexColumn?: boolean
}

const ReadOnlyEntityUi = styled.div<ReadOnlyEntityUiProps>`
  position: relative;
  margin-top: ${p => p.margin.marginTop}px;
  margin-right: ${p => p.margin.marginRight}px;
  margin-bottom: ${p => p.margin.marginBottom}px;
  margin-left: ${p => p.margin.marginLeft}px;
  ${({ styles = {} }) => styles};
  display: ${p =>
    p.showInDesktopMode === false
      ? 'none '
      : p.flex
      ? 'flex'
      : (p.styles && p.styles.display) || 'block'};
  ${p =>
    p.theme.isEditorMobileMode &&
    css`
      margin-top: ${p.mobileMargin.marginTop}px;
      margin-right: ${p.mobileMargin.marginRight}px;
      margin-bottom: ${p.mobileMargin.marginBottom}px;
      margin-left: ${p.mobileMargin.marginLeft}px;
      display: ${p.showInMobileMode === false
        ? 'none '
        : p.flex
        ? 'flex'
        : (p.styles && p.styles.display) || 'block'};
    `};
  ${p => p.flexColumn && `flex-direction: column;`}
`

export default ReadOnlyEntityUi
