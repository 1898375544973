import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import CardFieldUi from '../ui/CardFieldUi'
import CardLabelUi from '../ui/CardLabelUi'
import CardRowUi from '../ui/CardRowUi'
import StripeErrorUi from './ui/StripeErrorUi'

function CreditCard({ CardNumberElement, CardExpiryElement, CardCvcElement }) {
  const { t } = useTranslation('common')
  const [isCardNumberError, setIsCardNumberError] = useState(false)
  const [isCardExpirationError, setIsCardExpirationError] = useState(false)
  const [isCardCvcError, setIsCardCvcError] = useState(false)
  //to prevent zoom on focus in mobile ios
  const baseElementOptions = {
    style: {
      base: {
        fontSize: '16px',
      },
    },
  }

  return (
    <React.Fragment>
      <CardRowUi flexDirectionColumn>
        <CardFieldUi>
          <CardLabelUi htmlFor="card-number">
            {t('components.payment_methods.card_number')}
          </CardLabelUi>
          <CardNumberElement
            options={{
              ...baseElementOptions,
              showIcon: true,
            }}
            className="Card"
            onChange={({ error }) => {
              if (!error) {
                if (isCardNumberError) {
                  setIsCardNumberError(false)
                }
              } else if (error.message) {
                setIsCardNumberError(true)
              }
            }}
            id="card-number"
          />
        </CardFieldUi>
        {isCardNumberError && (
          <StripeErrorUi>
            {t('components.payment_methods.stripe.invalid_card_number')}
          </StripeErrorUi>
        )}
      </CardRowUi>
      <CardRowUi>
        <CardFieldUi width="50%">
          <CardLabelUi htmlFor="card-expiration">
            {t('components.payment_methods.expiration')}
          </CardLabelUi>
          <CardExpiryElement
            options={baseElementOptions}
            id="card-expiration"
            className="Card"
            onChange={({ error }) => {
              if (!error) {
                if (isCardExpirationError) {
                  setIsCardExpirationError(false)
                }
              } else if (error.message) {
                setIsCardExpirationError(true)
              }
            }}
          />
          {isCardExpirationError && (
            <StripeErrorUi>
              {t(
                'components.payment_methods.stripe.invalid_card_expiration_date',
              )}
            </StripeErrorUi>
          )}
        </CardFieldUi>
        <CardFieldUi width="50%">
          <CardLabelUi htmlFor="card-cvc">
            {t('components.payment_methods.cvc')}
          </CardLabelUi>
          <CardCvcElement
            options={baseElementOptions}
            id="card-cvc"
            className="Card"
            onChange={({ error }) => {
              if (!error) {
                if (isCardCvcError) {
                  setIsCardCvcError(false)
                }
              } else if (error.message) {
                // https://systeme-team.slack.com/archives/C6UURE03D/p1668177169203109
                setIsCardCvcError(true)
              }
            }}
          />
          {isCardCvcError && (
            <StripeErrorUi>
              {t(
                'components.payment_methods.stripe.invalid_card_security_code',
              )}
            </StripeErrorUi>
          )}
        </CardFieldUi>
      </CardRowUi>
    </React.Fragment>
  )
}

CreditCard.propTypes = {
  CardNumberElement: React.Component,
  CardExpiryElement: React.Component,
  CardCvcElement: React.Component,
}

export default CreditCard
