import React from 'react'
import { RGBColor, ColorResult } from 'react-color'
import { parseToObject, getColorAsString } from 'common/utils/colorUtils'
import NewColorPickerPopup from 'client/components/core/NewColorPickerPopup'
import Popover from 'client/components/core/Popover'
import ColorMenuButtonUi from './ui/ColorMenuButtonUi'
import ColorPickerUi from './ui/ColorPickerUi'
import ColorPreviewInnerUi from './ui/ColorPreviewInnerUi'
import ColorPreviewUi from './ui/ColorPreviewUi'
import PickerPopupUi from './ui/PickerPopupUi'

type ColorProps = {
  color?: string
  onChange: (color: string) => void
}

const defaultColor = 'rgba(0, 0, 0, 0)'

function Color({ color = defaultColor, onChange }: ColorProps) {
  function getPrevColor(): RGBColor {
    return parseToObject(color || defaultColor)
  }

  function hasRgbChanged(color: RGBColor) {
    const { r, g, b } = getPrevColor()
    return color.r !== r || color.g !== g || color.b !== b
  }

  function handleChangeComplete(color: ColorResult) {
    const rgb = { ...color.rgb }
    const prevA = getPrevColor().a

    if (hasRgbChanged(rgb) && prevA === 0) {
      rgb.a = 1
    }
    onChange(getColorAsString(rgb))
  }

  return (
    <ColorMenuButtonUi>
      <ColorPickerUi>
        <Popover
          offsetValue={5}
          flipOptions={{
            fallbackAxisSideDirection: 'end',
            fallbackPlacements: ['top-start', 'right', 'bottom-start'],
          }}
          strategyValue={'fixed'}
          placement={'right-end'}
          triggerElement={
            <ColorPreviewUi>
              <ColorPreviewInnerUi color={color} />
            </ColorPreviewUi>
          }
          popoverContent={
            <PickerPopupUi>
              {/* @ts-ignore */}
              <NewColorPickerPopup
                color={color}
                onChangeComplete={handleChangeComplete}
              />
            </PickerPopupUi>
          }
        />
      </ColorPickerUi>
    </ColorMenuButtonUi>
  )
}

export default Color
