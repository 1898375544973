import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import CommonPaymentMethod from 'common/components/entities/PaymentMethodNew'
import { PaymentMethodEnum } from 'common/enums/PaymentMethodEnum'
import { PaymentMethodInterface } from 'common/types/entities/PaymentMethodInterface'
import { togglePaymentMethod } from 'publisher/actions/paymentActions'
import StyleWrapper from 'publisher/components/core/StyleWrapper'
import { usePayment } from 'publisher/store'
import { usePage } from 'publisher/store'
import pageSelectors from 'publisher/store/page/pageSelectors'
import paymentSelectors from 'publisher/store/payment/paymentSelectors'
import PaymentMethodDetails from './PaymentMethodDetails'

interface PaymentMethodProps {
  entity: PaymentMethodInterface
}

function PaymentMethod({ entity }: PaymentMethodProps) {
  const dispatch = useDispatch()
  const [isClient, setIsClient] = useState(false)
  const paymentMethods = usePayment(paymentSelectors.getPaymentMethods)
  const activeMethod = usePayment(paymentSelectors.getActivePaymentMethod)
  const textColor = usePage(pageSelectors.getGlobalTextColor)
  const displayablePaymentMethods = paymentMethods.includes(
    PaymentMethodEnum.StripeCard,
  )
    ? paymentMethods.filter(
        paymentMethod => paymentMethod !== PaymentMethodEnum.StripeApplepay,
      )
    : paymentMethods

  function changeMethod(method: PaymentMethodEnum) {
    dispatch(togglePaymentMethod(method))
  }

  useEffect(() => {
    setIsClient(true)
  }, [])

  return (
    isClient && (
      <StyleWrapper
        appearance={entity.appearance}
        margin={entity.margin}
        mobileMargin={entity.mobileMargin}
      >
        <CommonPaymentMethod
          attrId={entity.htmlAttrId}
          fontSize={entity.fontSize}
          mobileFontSize={entity.mobileFontSize}
          lineHeight={entity.lineHeight}
          mobileLineHeight={entity.mobileLineHeight}
          fontFamily={entity.fontFamily}
          fontWeight={entity.fontWeight}
          fontStyle={entity.fontStyle}
          mobileFontStyle={entity.mobileFontStyle}
          mobileFontFamily={entity.mobileFontFamily}
          mobileFontWeight={entity.mobileFontWeight}
          color={entity.color || textColor}
          mobileColor={entity.mobileColor}
          paymentMethods={displayablePaymentMethods}
          activeMethod={activeMethod}
          changeMethod={changeMethod}
          renderDetails={method => (
            <PaymentMethodDetails paymentMethod={method} />
          )}
        />
      </StyleWrapper>
    )
  )
}

export default PaymentMethod
