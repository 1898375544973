import React from 'react'
import { PaypalBackgroundColorType } from 'common/components/entities/paypal-rest-button'
import { PricePlanTypeEnum } from 'common/enums/PricePlanTypeEnum'
import { PaymentButtonInterface } from 'common/types/entities/PaymentButtonInterface'
import { BorderType } from 'common/types/styleTypes'
import { usePayment } from 'publisher/store'
import {
  getActivePricePlan,
  getOfferCurrency,
} from 'publisher/store/payment/paymentSelectors'
import PaypalRestOrderButton from './paypal-rest-order-button'
import PaypalRestSubscriptionButton from './paypal-rest-subscription-button'

export interface PaypalRestPaymentButtonProps {
  entity: PaymentButtonInterface
  backgroundColor?: PaypalBackgroundColorType
  borderRadius?: Partial<BorderType>
}

export const PaypalRestPaymentButton = ({
  entity,
  backgroundColor,
  borderRadius,
}: PaypalRestPaymentButtonProps) => {
  const pricePlan = usePayment(getActivePricePlan)
  const offerCurrency = usePayment(getOfferCurrency)

  const isSubscriptionPricePlan =
    pricePlan && pricePlan.type !== PricePlanTypeEnum.OneShot

  const paypalButtonOptions = {
    entity,
    currency: offerCurrency,
    backgroundColor,
    borderRadius,
  }

  if (isSubscriptionPricePlan) {
    return <PaypalRestSubscriptionButton {...paypalButtonOptions} />
  }

  return <PaypalRestOrderButton {...paypalButtonOptions} />
}
