import React from 'react'
import CommonRow from 'common/components/entities/Row'
import { RowInterface } from 'common/components/entities/Row/types/RowInterface'
import BaseEntityNew from 'client/components/core/BaseEntity/BaseEntityNew'
import RowChildren from 'client/components/entities/Row/components/RowChildren'
import { useBackgroundImage } from 'client/hooks/useBackgroundImage'
import { EntityProps } from 'client/types'

const ReadOnlyRow = ({ entity }: Pick<EntityProps<RowInterface>, 'entity'>) => {
  const { backgroundImage, mobileBackgroundImage } = useBackgroundImage({
    backgroundFileId: entity.backgroundFileId,
    mobileBackgroundFileId: entity.mobileBackgroundFileId,
  })

  return (
    <BaseEntityNew
      entity={entity}
      isMoveDownAllowed={false}
      isMoveUpAllowed={false}
    >
      <CommonRow
        disableColumnGap={entity.childIds.length === 1}
        {...entity}
        backgroundImage={backgroundImage}
        mobileBackgroundImage={mobileBackgroundImage}
      >
        <RowChildren entity={entity} />
      </CommonRow>
    </BaseEntityNew>
  )
}

export default ReadOnlyRow
