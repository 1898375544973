import { CountdownTypesEnum } from 'common/components/entities/Countdown/enums/CountdownTypesEnum'
import { CountdownInterface } from 'common/types/entities/CountdownInterface'
import { setDateWithTimezone } from 'client/utils/date-time-format'

export const getClientTimeFromCountdown = (
  countdown: CountdownInterface,
  userTimeZone: string,
): { days: number; hours: number; minutes: number; seconds: number } => {
  switch (countdown.countdownType) {
    case CountdownTypesEnum.daily: {
      const date = new Date()
      date.setHours(countdown[CountdownTypesEnum.daily].hours)
      date.setMinutes(countdown[CountdownTypesEnum.daily].minutes)
      date.setSeconds(countdown[CountdownTypesEnum.daily].seconds)
      const dateWithTimezone = setDateWithTimezone({
        date,
        timeZone: userTimeZone,
      })
      return {
        days: 0,
        hours: dateWithTimezone.getHours(),
        minutes: dateWithTimezone.getMinutes(),
        seconds: dateWithTimezone.getSeconds(),
      }
    }
    case CountdownTypesEnum.delay: {
      return {
        days: countdown[CountdownTypesEnum.delay].days,
        hours: countdown[CountdownTypesEnum.delay].hours,
        minutes: countdown[CountdownTypesEnum.delay].minutes,
        seconds: countdown[CountdownTypesEnum.delay].seconds,
      }
    }
    case CountdownTypesEnum.fixed: {
      const todayWithTimezone = setDateWithTimezone({
        date: new Date(),
        timeZone: userTimeZone,
      })
      const dateWithTimezone = setDateWithTimezone({
        date: new Date(countdown[CountdownTypesEnum.fixed].date),
        timeZone: userTimeZone,
      })
      const diff = dateWithTimezone.getTime() - todayWithTimezone.getTime()
      if (diff <= 0) {
        return { days: 0, hours: 0, minutes: 0, seconds: 0 }
      }
      const days = Math.floor(diff / (1000 * 60 * 60 * 24))
      const hours = Math.floor((diff / (1000 * 60 * 60)) % 24)
      const minutes = Math.floor((diff / 1000 / 60) % 60)
      const seconds = Math.floor((diff / 1000) % 60)
      return { days, hours, minutes, seconds }
    }
    default: {
      return { days: 0, hours: 0, minutes: 0, seconds: 0 }
    }
  }
}
