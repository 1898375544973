import React, { createContext, useContext, useState } from 'react'

type SetEntityHighlightedContext = (value: string) => void

const HighlightedEntityContext = createContext<string | null>(null)
const SetEntityHighlightedContext = createContext<SetEntityHighlightedContext>(
  {} as SetEntityHighlightedContext,
)

export const useSetEntityHighlighted = () =>
  useContext(SetEntityHighlightedContext)
export const useHighlightedEntityId = () => useContext(HighlightedEntityContext)

export function HighlightedEntityProvider(
  props: React.PropsWithChildren<React.ReactNode>,
) {
  const [highlightedId, setHighlightedId] = useState<string | null>(null)

  return (
    <HighlightedEntityContext.Provider value={highlightedId}>
      <SetEntityHighlightedContext.Provider value={setHighlightedId}>
        {props.children}
      </SetEntityHighlightedContext.Provider>
    </HighlightedEntityContext.Provider>
  )
}
