import React from 'react'
import CommonField from 'common/components/entities/Field/FieldUi'
import { FieldSlugEnum } from 'common/enums/FieldSlugEnum'
import { EntityTypeEnum } from 'common/enums/entityTypeEnum'
import { MasterBlockId } from 'common/types/entities/EntityInterface'
import { FieldInterface } from 'common/types/entities/field-interface'
import BaseEntityNew from 'client/components/core/BaseEntity/BaseEntityNew'
import {
  generateHtmlAttrId,
  generateId,
} from 'client/utils/createStructureUtils'
import { EntityProps } from '../../../types'
import { useContactUsDescendantIds } from './ContactUs'

function ContactUsField({
  entity,
  isMoveDownAllowed,
  isMoveUpAllowed,
}: EntityProps<FieldInterface>) {
  const entityIds = useContactUsDescendantIds()
  return (
    <BaseEntityNew
      entity={entity}
      isMoveDownAllowed={isMoveDownAllowed}
      isMoveUpAllowed={isMoveUpAllowed}
      isRemoveAllowed={false}
      isSaveAllowed={false}
      isCopyAllowed={false}
      dropBoxAllowedEntityIds={entityIds}
    >
      <CommonField
        placeholder={entity.placeholder}
        padding={entity.padding}
        lineHeight={entity.lineHeight}
        mobileLineHeight={entity.mobileLineHeight}
        textAlign={entity.textAlign}
        mobileTextAlign={entity.mobileTextAlign}
        mobilePadding={entity.mobilePadding}
        id={entity.htmlAttrId}
        border={entity.border}
        mobileBorder={entity.mobileBorder}
        backgroundColor={entity.backgroundColor}
        mobileBackgroundColor={entity.mobileBackgroundColor}
        color={entity.color}
        mobileColor={entity.mobileColor}
        fontSize={entity.fontSize}
        mobileFontSize={entity.mobileFontSize}
        boxShadow={entity.boxShadow}
        mobileBoxShadow={entity.mobileBoxShadow}
      />
    </BaseEntityNew>
  )
}

export function createContactUsField(
  parentId: string,
  masterBlockId?: MasterBlockId,
  slug?: FieldSlugEnum,
) {
  const field: FieldInterface = {
    id: generateId(),
    optional: false,
    textAlign: 'center',
    type: EntityTypeEnum.ContactUsField,
    parentId: parentId,
    masterBlockId: masterBlockId,
    fontSize: 18,
    slug: FieldSlugEnum.Email,
    backgroundColor: 'rgba(241, 244, 248, 1)',
    padding: {
      paddingTop: 20,
      paddingRight: 10,
      paddingBottom: 20,
      paddingLeft: 10,
    },
    border: {
      radius: 3,
      style: 'none',
    },
    margin: {
      marginTop: 0,
      marginRight: 0,
      marginBottom: 25,
      marginLeft: 0,
    },
    mobileMargin: {
      marginTop: 0,
      marginRight: 0,
      marginBottom: 10,
      marginLeft: 0,
    },
    appearance: {
      desktop: true,
      mobile: true,
    },
    htmlAttrId: generateHtmlAttrId(EntityTypeEnum.ContactUsField),
  }

  if (slug) {
    field.slug = slug
  }

  return field
}

export default ContactUsField
