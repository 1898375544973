import React, { memo } from 'react'
import { CommonRemotePopupBody } from 'common/components/entities/remote-popup-body'
import { RemotePopupBodyInterface } from 'common/types/remote-popup-body-interface'
import ChildrenEntities from 'client/components/core/ChildrenEntities'
import { useBackgroundImage } from 'client/hooks/useBackgroundImage'

interface RemotePopupBodyProps {
  entity: RemotePopupBodyInterface
}

const RemotePopupBody = ({ entity }: RemotePopupBodyProps) => {
  const { backgroundImage, mobileBackgroundImage } = useBackgroundImage({
    backgroundFileId: entity.backgroundFileId,
    mobileBackgroundFileId: entity.mobileBackgroundFileId,
  })

  return (
    <CommonRemotePopupBody
      showClose={entity.showClose}
      closeButtonColor={entity.closeButtonColor}
      mobileCloseButtonColor={entity.mobileCloseButtonColor}
      maxHeight={'100%'}
      background={entity.background}
      backgroundImage={backgroundImage}
      mobileBackgroundImage={mobileBackgroundImage}
      mobileBackground={entity.mobileBackground}
      backgroundColor={entity.backgroundColor}
      mobileBackgroundColor={entity.mobileBackgroundColor}
      border={entity.border}
      mobileBorder={entity.mobileBorder}
      boxShadow={entity.boxShadow}
      mobileBoxShadow={entity.mobileBoxShadow}
      padding={entity.padding}
      mobilePadding={entity.mobilePadding}
      hideOverflow
      margin={'50px 0'}
    >
      <ChildrenEntities
        id={entity.id}
        type={entity.type}
        childIds={entity.childIds}
      />
    </CommonRemotePopupBody>
  )
}

export default memo(RemotePopupBody)
