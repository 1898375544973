import EntityTypeEnum from 'common/enums/entityTypeEnum'
import { MasterBlockId } from 'common/types/entities/EntityInterface'
import { PriceElementInterface } from 'common/types/entities/price-element-interface'
import { generateBaseEntity } from 'client/utils/createStructureUtils'

export const createPriceElement = (
  parentId: string,
  masterBlockId?: MasterBlockId,
): PriceElementInterface => {
  const entity: PriceElementInterface = {
    ...generateBaseEntity(EntityTypeEnum.PriceElement, parentId, masterBlockId),
    type: EntityTypeEnum.PriceElement,

    popupIdFirstPlan: null,
    popupIdSecondPlan: null,
    popupIdThirdPlan: null,
    popupIdFourthPlan: null,

    fontFamily: 'Caveat',
    fontStyle: 'normal',
    fontWeight: '700'
  }

  return entity
}
