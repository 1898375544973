import React, { memo } from 'react'
import PropTypes from 'prop-types'
import BaseEntity from 'client/components/core/BaseEntity'
import videoTypes from 'common/constants/videoTypes'
import EmbedPlayer from './EmbedPlayer'
import FilePlayer from './FilePlayer'
import SourcePlayer from './SourcePlayer'
import YoutubePlayer from './YoutubePlayer'

function Video({ entity, isMoveDownAllowed, isMoveUpAllowed }) {
  let Player
  switch (entity.options.type) {
    case videoTypes.FILE:
      Player = FilePlayer
      break
    case videoTypes.URL:
      Player = SourcePlayer
      break
    case videoTypes.CUSTOM:
      Player = EmbedPlayer
      break
    case videoTypes.YOUTUBE:
      Player = YoutubePlayer
      break
    default:
      throw new Error(`Unknown video type ${entity.options.type}`)
  }

  return (
    <BaseEntity
      entity={entity}
      isMoveUpAllowed={isMoveUpAllowed}
      isMoveDownAllowed={isMoveDownAllowed}
    >
      {emulatedEntity => <Player entity={emulatedEntity} />}
    </BaseEntity>
  )
}

Video.propTypes = {
  entity: PropTypes.shape({
    id: PropTypes.string.isRequired,
    options: PropTypes.objectOf(PropTypes.any).isRequired,
    styles: PropTypes.objectOf(PropTypes.any).isRequired,
    mobileStyles: PropTypes.objectOf(PropTypes.any).isRequired,
    childIds: PropTypes.arrayOf(PropTypes.string),
    type: PropTypes.string.isRequired,
  }).isRequired,
  isMoveUpAllowed: PropTypes.bool.isRequired,
  isMoveDownAllowed: PropTypes.bool.isRequired,
}

export default memo(Video)
