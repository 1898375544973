/* eslint-disable no-underscore-dangle */
import React from 'react'
import { hydrateRoot } from 'react-dom/client'
import 'common/utils/domNormalizeHack'
import { Query, QueryKeys } from 'publisher/components/core/MatchMedia'
import configureStore from 'publisher/entrypoints/payment/configureStore'
import createEntityElement from 'publisher/entrypoints/payment/createEntityElement'
import createPage from 'publisher/entrypoints/payment/createPage'
import { selectors } from 'publisher/reducers/optInReducer'
import { selectors as pageSelectors } from 'publisher/reducers/pageReducer'
import { normalizeStringBoolean } from 'publisher/store'
import { initialiseAxiosInterceptors } from 'publisher/utils/registerAxiosInterceptors'
import BaseApp from '../base-app'

initialiseAxiosInterceptors()

const rollbarReportLevel = window.__ROLLBAR_LEVEL__
const preloadedState = window.__PRELOADED_STATE__
delete window.__PRELOADED_STATE__
// Allow the passed state to be garbage-collected
const isDesktop = window.matchMedia(Query[QueryKeys.DESKTOP]).matches
preloadedState.management.isDesktop = isDesktop
preloadedState.optIn.fields = selectors.getCustomerFields(preloadedState.optIn)
preloadedState.management.isMobile = normalizeStringBoolean(
  preloadedState.management.isMobile,
)
preloadedState.payment.isOnlyPersonalAvailable = normalizeStringBoolean(
  preloadedState.payment.isOnlyPersonalAvailable,
)
if (pageSelectors.getCountryFieldId(preloadedState.page)) {
  preloadedState.optIn.fields.country = selectors.getCustomerCountry(
    preloadedState.optIn,
  )
}
const store = configureStore(preloadedState)
const rootElement = document.getElementById('app')

hydrateRoot(
  rootElement,
  <BaseApp
    createEntityElement={createEntityElement}
    createPage={createPage}
    store={store}
    rollbarReportLevel={rollbarReportLevel}
  />,
)
