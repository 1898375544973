import styled from 'styled-components'

const FileUploadCancelUi = styled.button`
  background-color: red;
  border-radius: 5px;
  border: none;
  outline: none;
  color: #fff;
  padding: 10px 15px;
  font-size: 14px;
  cursor: pointer;
`

export default FileUploadCancelUi
