import React from 'react'
import PropTypes from 'prop-types'
import { getReadablePrice } from 'common/utils/priceCalculator'
import PricePlanItemUi from './ui/PricePlanItem'
import Coupon from './Coupon'

const OneShotsSummary = ({
  totalTax,
  totalAmount,
  totalAmountWithoutTax,
  translate,
  language,
  currency,
  coupon = null,
  isVatNotChargeable,
}) => (
  <React.Fragment>
    <PricePlanItemUi>
      {/*
      since there are no taxes it could be confusing for customers
      they could wonder if they will end up paying VAT on top of that
      several users raised this issue
      and I think it makes sense
      so if users selected "Not charge VAT on their payment pages"
      then I propose we display:
      Total T.T.C.
      */}
      {translate(
        isVatNotChargeable
          ? 'components.offer_price.one_shots_summary.total_amount_with_tax' // @see why https://systeme-team.slack.com/archives/G61JS7GDC/p1566808055000200
          : 'components.offer_price.one_shots_summary.total_amount',
      )}{' '}
      {getReadablePrice(totalAmountWithoutTax, currency, language)}
    </PricePlanItemUi>
    {coupon && <Coupon coupon={coupon} translate={translate} />}
    {+totalTax > 0 && (
      <React.Fragment>
        <PricePlanItemUi>
          {translate('components.offer_price.one_shots_summary.total_tax')}{' '}
          {getReadablePrice(totalTax, currency, language)}
        </PricePlanItemUi>
        <PricePlanItemUi>
          {translate(
            'components.offer_price.one_shots_summary.total_amount_with_tax',
          )}{' '}
          {getReadablePrice(totalAmount, currency, language)}
        </PricePlanItemUi>
      </React.Fragment>
    )}
    {coupon && +totalTax === 0 && (
      <PricePlanItemUi>
        {translate(
          'components.offer_price.one_shots_summary.total_amount_with_coupon',
        )}{' '}
        {getReadablePrice(totalAmount, currency, language)}
      </PricePlanItemUi>
    )}
  </React.Fragment>
)

OneShotsSummary.propTypes = {
  isVatNotChargeable: PropTypes.bool.isRequired,
  coupon: PropTypes.shape({
    id: PropTypes.number.isRequired,
    discountAmount: PropTypes.string.isRequired,
    discountType: PropTypes.string.isRequired,
  }),
  totalTax: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  totalAmountWithoutTax: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  totalAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  translate: PropTypes.func.isRequired,
  currency: PropTypes.string.isRequired,
  language: PropTypes.string.isRequired,
}

export default OneShotsSummary
