import styled from 'styled-components'

const DialogCancel = styled.button`
  background-color: #f2f4f7;
  color: rgba(107, 124, 147, 0.6);
  text-transform: uppercase;
  font-size: 12px;
  padding: 9px 15px;
  border-radius: 3px;
  border: 1px solid rgba(107, 124, 147, 0.3);
  outline: none;
  cursor: pointer;
`

export default DialogCancel
