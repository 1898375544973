import React, { useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import CommonOfferPrice from 'common/components/entities/OfferPriceNew'
import { FieldSlugEnum } from 'common/enums/FieldSlugEnum'
import { PaymentMethodEnum } from 'common/enums/PaymentMethodEnum'
import { ResourceTypeEnum } from 'common/enums/PricingEnum'
import { BadRequest } from 'common/errors'
import { OfferPriceInterface } from 'common/types/entities/OfferPriceInterface'
import { optInFail } from 'publisher/actions/optInActions'
import StyleWrapper from 'publisher/components/core/StyleWrapper'
import { useSelectedOfferPricing } from 'publisher/pages/offer-page/hooks/useSelectedOfferPricing'
import { calculateOfferPricing } from 'publisher/pages/offer-page/utils/calculateOfferPricing'
import { useOptIn, usePage, usePayment } from 'publisher/store'
import { getFieldValueBySlug } from 'publisher/store/optIn/optInSelectors'
import pageSelectors from 'publisher/store/page/pageSelectors'
import {
  setActivePricePlanId,
  setOfferPricing,
} from 'publisher/store/payment/paymentActions'
import {
  getActivePaymentMethod,
  getCheckedCoupon,
  isOnlyPersonalAvailable,
  getCheckedBump,
  getOfferId,
  getOfferPricing,
  getProductActiveVariant,
  getProduct,
  getProductQuantity,
  getSelectedShipping,
} from 'publisher/store/payment/paymentSelectors'
import { useCalculatedPricePlans } from './useCalculatedPricePlans'

function OfferPrice({ entity }: { entity: OfferPriceInterface }) {
  const dispatch = useDispatch()
  const abortControllerRef = useRef<AbortController>()

  const offerId = usePayment(getOfferId)
  const locale = usePage(pageSelectors.getLocale)
  const coupon = usePayment(getCheckedCoupon)
  const bump = usePayment(getCheckedBump)
  const activePaymentMethod = usePayment(getActivePaymentMethod)

  const { pricePlans, activePlanId } = useCalculatedPricePlans()
  const bumpPlan = bump?.pricePlans[0]

  const taxNumber = useOptIn(state =>
    getFieldValueBySlug(state, FieldSlugEnum.TaxNumber),
  )
  const customerCountry = useOptIn(state =>
    getFieldValueBySlug(state, FieldSlugEnum.Country),
  )
  const isVatNotChargeable =
    usePayment(isOnlyPersonalAvailable) ||
    activePaymentMethod === PaymentMethodEnum.RazorpayCard

  const product = usePayment(getProduct)
  const activeProductVariant = usePayment(getProductActiveVariant)
  const productQuantity = usePayment(getProductQuantity)
  const productBump = bump?.product || null

  const offerPricing = usePayment(getOfferPricing)
  const selectedShipping = usePayment(getSelectedShipping)

  const togglePricePlanId = (pricePlanId: number) => {
    dispatch(setActivePricePlanId(pricePlanId))
  }

  useEffect(() => {
    if (!offerId || (!product?.id && !pricePlans.length)) return

    const fetchPriceData = async () => {
      try {
        abortControllerRef.current = new AbortController()

        const newOfferPricings = await calculateOfferPricing({
          product,
          productBump,
          pricePlans,
          bumpPlan,
          bumpOfferId: bump?.id,
          offerId,
          country: customerCountry,
          activeProductVariant,
          coupon,
          taxNumber,
          productQuantity,
          abortSignal: abortControllerRef.current.signal,
        })
        dispatch(setOfferPricing(newOfferPricings))
      } catch (error) {
        if (error instanceof BadRequest) {
          dispatch(optInFail({ fields: error.response.data.errors.fields }))
        }
      }
    }

    if (abortControllerRef.current?.signal) {
      abortControllerRef.current.abort('new_request_sent')
    }

    fetchPriceData()
  }, [
    product?.id,
    productQuantity,
    bump?.id,
    activeProductVariant,
    coupon,
    customerCountry,
    offerId,
    taxNumber,
  ])

  const mainOfferPricing = useSelectedOfferPricing({
    selectedProduct: product,
    activeProductVariant,
  })

  const bumpOfferPricing = useSelectedOfferPricing({
    selectedProduct: productBump,
  })

  const bumpPlanPricing = useSelectedOfferPricing({
    selectedPricePlan: bumpPlan,
  })

  const pricePlansPricing = offerPricing?.filter(({ entityId, entityType }) =>
    pricePlans.some(
      pricePlan =>
        pricePlan.id === entityId && entityType === ResourceTypeEnum.PricePlan,
    ),
  )

  return (
    <StyleWrapper
      margin={entity.margin}
      appearance={entity.appearance}
      mobileMargin={entity.mobileMargin}
    >
      <CommonOfferPrice
        attrId={entity.htmlAttrId}
        plans={pricePlans}
        activePlanId={activePlanId}
        bumpPlan={bumpPlan}
        coupon={coupon}
        mainOfferPricing={mainOfferPricing}
        bumpOfferPricing={bumpOfferPricing}
        pricePlansPricing={pricePlansPricing}
        bumpPlanPricing={bumpPlanPricing}
        selectedShipping={selectedShipping}
        isVatNotChargeable={isVatNotChargeable}
        togglePricePlan={togglePricePlanId}
        customDescription={entity.customDescription}
        product={product}
        productBump={productBump}
        locale={locale}
        nameFontSize={entity.nameFontSize}
        mobileNameFontSize={entity.mobileNameFontSize}
        nameFontStyle={entity.nameFontStyle}
        mobileNameFontStyle={entity.mobileNameFontStyle}
        nameFontWeight={entity.nameFontWeight}
        mobileNameFontWeight={entity.mobileNameFontWeight}
        nameFontFamily={entity.nameFontFamily}
        mobileNameFontFamily={entity.mobileNameFontFamily}
        nameLineHeight={entity.nameLineHeight}
        mobileNameLineHeight={entity.mobileNameLineHeight}
        nameColor={entity.nameColor}
        mobileNameColor={entity.mobileNameColor}
        amountFontSize={entity.amountFontSize}
        mobileAmountFontSize={entity.mobileAmountFontSize}
        amountFontStyle={entity.amountFontStyle}
        mobileAmountFontStyle={entity.mobileAmountFontStyle}
        amountFontWeight={entity.amountFontWeight}
        mobileAmountFontWeight={entity.mobileAmountFontWeight}
        amountFontFamily={entity.amountFontFamily}
        mobileAmountFontFamily={entity.mobileAmountFontFamily}
        amountLineHeight={entity.amountLineHeight}
        mobileAmountLineHeight={entity.mobileAmountLineHeight}
        amountColor={entity.amountColor}
        mobileAmountColor={entity.mobileAmountColor}
        descriptionFontSize={entity.descriptionFontSize}
        mobileDescriptionFontSize={entity.mobileDescriptionFontSize}
        descriptionFontStyle={entity.descriptionFontStyle}
        mobileDescriptionFontStyle={entity.mobileDescriptionFontStyle}
        descriptionFontWeight={entity.descriptionFontWeight}
        mobileDescriptionFontWeight={entity.mobileDescriptionFontWeight}
        descriptionFontFamily={entity.descriptionFontFamily}
        mobileDescriptionFontFamily={entity.mobileDescriptionFontFamily}
        descriptionLineHeight={entity.descriptionLineHeight}
        mobileDescriptionLineHeight={entity.mobileDescriptionLineHeight}
        descriptionColor={entity.descriptionColor}
        mobileDescriptionColor={entity.mobileDescriptionColor}
      />
    </StyleWrapper>
  )
}

export default OfferPrice
