import React from 'react'
import PropTypes from 'prop-types'
import couponTypes from 'common/components/entities/Coupon/couponTypes'
import centsToEuro from 'common/utils/centsToEuro'
import { getReadablePrice } from '../../../utils/priceCalculator'
import PricePlanItemUi from './ui/PricePlanItem'

const Coupon = ({ coupon, translate, language }) => (
  <PricePlanItemUi
    description={translate('components.offer_price.one_shots_summary.discount')}
  >
    {' '}
    -
    {coupon.discountType === couponTypes.fixed
      ? getReadablePrice(
          centsToEuro(coupon.discountAmount),
          coupon.currency,
          language,
        )
      : `${coupon.discountAmount}%`}
  </PricePlanItemUi>
)

Coupon.propTypes = {
  coupon: PropTypes.shape({
    id: PropTypes.number.isRequired,
    discountAmount: PropTypes.number.isRequired,
    discountType: PropTypes.string.isRequired,
    currency: PropTypes.string.isRequired,
  }).isRequired,
  translate: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired,
}

export default Coupon
