import styled from 'styled-components'

const DialogOverlay = styled.div`
  background-color: rgba(2, 0, 32, 0.9);
  bottom: 0;
  left: 0;
  opacity: ${({ $show }) => ($show ? 1 : 0)};
  position: fixed;
  right: 0;
  top: 0;
  transition: opacity 0.5s ease-in-out;
  z-index: 100;
`

export default DialogOverlay
