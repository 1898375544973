import PropTypes from 'prop-types'
import React, { memo } from 'react'
import CommonButton from 'common/components/entities/Button/index'
import * as types from 'common/types'
import BaseEntity from 'client/components/core/BaseEntity'
import emulationMobileStyles from './emulationMobileStyles'

function WebinarSessionLink({ entity, isMoveDownAllowed, isMoveUpAllowed }) {
  return (
    <BaseEntity
      emulationMobileStyles={emulationMobileStyles}
      styles={{
        display: 'flex',
        justifyContent: 'center',
        position: 'relative',
        flexDirection: 'column',
      }}
      entity={entity}
      isMoveUpAllowed={isMoveUpAllowed}
      isMoveDownAllowed={isMoveDownAllowed}
    >
      {emulatedEntity => (
        <CommonButton
          styles={emulatedEntity.styles}
          mobileStyles={emulatedEntity.mobileStyles}
          options={emulatedEntity.options}
        >
          {entity.options.text}
        </CommonButton>
      )}
    </BaseEntity>
  )
}

WebinarSessionLink.propTypes = {
  entity: types.entity.isRequired,
  isMoveUpAllowed: PropTypes.bool.isRequired,
  isMoveDownAllowed: PropTypes.bool.isRequired,
}

export default memo(WebinarSessionLink)
