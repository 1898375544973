import styled, { css } from 'styled-components'

/**
 * @typedef {Object} PaymentMethodSwitcherCreditCardUiProps
 * @property {string} cardImageUrl
 * @property {string} mobileCardImageUrl
 */

/**
 * @type {React.FC<PaymentMethodSwitcherCreditCardUiProps>}
 */

const PaymentMethodSwitcherCreditCardUi = styled.div`
  height: 36px;
  max-width: 223px;
  width: 100%;
  background-image: url(${p => p.cardImageUrl});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin-left: auto;

  ${p => p.theme.phone} {
    background-image: url(${p => p.mobileCardImageUrl});
    height: 38px;
    width: 60px;
  }

  ${p =>
    p.isMobile &&
    css`
      background-image: url(${p => p.mobileCardImageUrl});
      height: 38px;
      width: 60px;
    `}
`

export default PaymentMethodSwitcherCreditCardUi
