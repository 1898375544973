const defaultState = {
  userBlocks: [],
  masterBlocks: [],
  commonBlocks: [],
  userFiles: {},
  masterFiles: {},
  commonFiles: {},
  isFetched: false,
}

const IS_FETCHED = 'IS_FETCHED'
const FETCH_USER_BLOCKS = 'FETCH_USER_BLOCKS'
const FETCH_COMMON_BLOCKS = 'FETCH_COMMON_BLOCKS'
const FETCH_MASTER_BLOCKS = 'FETCH_MASTER_BLOCKS'
const FETCH_USER_BLOCK_FILES = 'FETCH_USER_BLOCK_FILES'
const FETCH_COMMON_BLOCK_FILES = 'FETCH_COMMON_BLOCK_FILES'
const FETCH_MASTER_BLOCK_FILES = 'FETCH_MASTER_BLOCK_FILES'
const ADD_USER_BLOCK_FILE = 'ADD_USER_BLOCK_FILE'
const ADD_COMMON_BLOCK_FILE = 'ADD_COMMON_BLOCK_FILE'
const ADD_MASTER_BLOCK_FILE = 'ADD_MASTER_BLOCK_FILE'
const ADD_USER_BLOCK = 'ADD_USER_BLOCK'
const ADD_COMMON_BLOCK = 'ADD_COMMON_BLOCK'
const ADD_MASTER_BLOCK = 'ADD_MASTER_BLOCK'
const DELETE_USER_BLOCK = 'DELETE_USER_BLOCK'
const DELETE_COMMON_BLOCK = 'DELETE_COMMON_BLOCK'
const DELETE_MASTER_BLOCK = 'DELETE_MASTER_BLOCK'

export const actionTypes = {
  FETCH_USER_BLOCKS,
  FETCH_COMMON_BLOCKS,
  FETCH_MASTER_BLOCKS,
  FETCH_USER_BLOCK_FILES,
  FETCH_COMMON_BLOCK_FILES,
  FETCH_MASTER_BLOCK_FILES,
  ADD_USER_BLOCK_FILE,
  ADD_COMMON_BLOCK_FILE,
  ADD_MASTER_BLOCK_FILE,
  ADD_USER_BLOCK,
  ADD_COMMON_BLOCK,
  ADD_MASTER_BLOCK,
  DELETE_USER_BLOCK,
  DELETE_COMMON_BLOCK,
  DELETE_MASTER_BLOCK,
  IS_FETCHED,
}

export default function (state = defaultState, action) {
  const { type, payload } = action

  switch (type) {
    case IS_FETCHED:
      return {
        ...state,
        isFetched: payload,
      }
    case FETCH_USER_BLOCK_FILES:
      return {
        ...state,
        userFiles: {
          ...state.userFiles,
          ...payload,
        },
      }
    case FETCH_COMMON_BLOCK_FILES:
      return {
        ...state,
        commonFiles: {
          ...state.commonFiles,
          ...payload,
        },
      }
    case FETCH_MASTER_BLOCK_FILES:
      return {
        ...state,
        masterFiles: {
          ...state.masterFiles,
          ...payload,
        },
      }
    case ADD_USER_BLOCK_FILE:
      return {
        ...state,
        userFiles: {
          ...state.userFiles,
          [payload.id]: payload,
        },
      }
    case ADD_COMMON_BLOCK_FILE:
      return {
        ...state,
        commonFiles: {
          ...state.commonFiles,
          [payload.id]: payload,
        },
      }
    case ADD_MASTER_BLOCK_FILE:
      return {
        ...state,
        masterFiles: {
          ...state.masterFiles,
          [payload.id]: payload,
        },
      }
    case FETCH_USER_BLOCKS:
      return {
        ...state,
        userBlocks: payload,
      }
    case FETCH_COMMON_BLOCKS:
      return {
        ...state,
        commonBlocks: payload,
      }
    case FETCH_MASTER_BLOCKS:
      return {
        ...state,
        masterBlocks: payload,
      }
    case ADD_USER_BLOCK:
      return {
        ...state,
        userBlocks: [payload, ...state.userBlocks],
      }
    case ADD_COMMON_BLOCK:
      return {
        ...state,
        commonBlocks: [payload, ...state.commonBlocks],
      }
    case ADD_MASTER_BLOCK:
      return {
        ...state,
        masterBlocks: [payload, ...state.masterBlocks],
      }
    case DELETE_USER_BLOCK:
      return {
        ...state,
        userBlocks: state.userBlocks.filter(block => block.id !== payload),
      }
    case DELETE_COMMON_BLOCK:
      return {
        ...state,
        commonBlocks: state.commonBlocks.filter(block => block.id !== payload),
      }
    case DELETE_MASTER_BLOCK:
      return {
        ...state,
        masterBlocks: state.masterBlocks.filter(block => block.id !== payload),
      }
    default:
      return state
  }
}

export const getUserAndMasterBlocks = ({ userBlocks, masterBlocks }) => {
  return masterBlocks
    .map(block => ({ ...block, isMaster: true }))
    .concat(userBlocks)
    .sort((a, b) =>
      Date.parse(a.created_at) < Date.parse(b.created_at) ? 1 : -1,
    )
}

export const getUserBlocks = ({ userBlocks }) => userBlocks

export const getMasterBlocks = ({ masterBlocks }) =>
  masterBlocks.map(block => ({ ...block, isMaster: true }))

export const getCommonBlocksByCategories = ({ commonBlocks }) => {
  return commonBlocks.reduce(
    (acc, block) => ({
      ...acc,
      [block.category]: [
        ...(acc[block.category] ? acc[block.category] : []),
        block,
      ],
    }),
    {},
  )
}

export const getBlockFilesByIds = (
  { userFiles, masterFiles, commonFiles },
  ids,
) => {
  const allFiles = { ...userFiles, ...masterFiles, ...commonFiles }

  return ids.reduce(
    (acc, id) => ({
      ...acc,
      ...(allFiles[id] ? { [id]: allFiles[id] } : {}),
    }),
    {},
  )
}

export const getBlockFile = ({ userFiles, commonFiles, masterFiles }, id) => {
  if (!id) {
    return null
  }

  return userFiles[id] || commonFiles[id] || masterFiles[id]
}

export const isFetched = state => state.isFetched

export const selectors = {
  isFetched,
  getUserAndMasterBlocks,
  getCommonBlocksByCategories,
  getUserBlocks,
  getMasterBlocks,
}
