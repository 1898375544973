import {
  CommonBodyBackgroundMobileStyles,
  CommonBodyBackgroundUiStylesInterface,
} from 'common/components/entities/body/ui/body-background-ui'
import { css } from 'styled-components'

export const BackgroundUi = () => css<CommonBodyBackgroundUiStylesInterface>`
  ${p =>
    p.theme.isEditorMobileMode &&
    css<CommonBodyBackgroundUiStylesInterface>`
      max-width: 350px;
      ${p?.mobileBackground?.backgroundAttachment === 'fixed' &&
      css`
        position: fixed;
        z-index: -1;
      `}
      ${CommonBodyBackgroundMobileStyles}
    `}
`
