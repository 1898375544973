import PropTypes from 'prop-types'
import React, { memo } from 'react'
import {
  PaymentMethodUi,
  PaymentMethodSwitcher,
} from 'common/components/entities/PaymentMethods'
import { PaymentMethodEnum } from 'common/enums/PaymentMethodEnum'
import * as types from 'common/types'
import { extractMarginFromOldStyles } from 'common/utils/styleUtilsNew'
import BaseEntity from 'client/components/core/BaseEntity'
import EntityExampleWrapper from 'client/components/core/EntityExampleWrapper'
import usePayment, {
  selectors as paymentSelectors,
} from 'client/hooks/usePayment'
import usePresentPage, {
  selectors as pageSelectors,
} from '../../../hooks/usePresentPage'
import emulationMobileStyles from './emulationMobileStyles'

function PaymentMethods({ entity, isMoveDownAllowed, isMoveUpAllowed }) {
  const paymentMethods = usePayment(paymentSelectors.getPaymentMethods)
  const pageLocale = usePresentPage(pageSelectors.getPageLocale)
  const isExample = paymentMethods.length === 0

  const calculatedMethods = isExample
    ? [PaymentMethodEnum.StripeCard, PaymentMethodEnum.PaypalSoap]
    : paymentMethods.filter(pm => Object.values(PaymentMethodEnum).includes(pm))

  const margin = extractMarginFromOldStyles(entity.styles)
  const mobileMargin = extractMarginFromOldStyles(entity.mobileStyles)

  return (
    <BaseEntity
      emulationMobileStyles={emulationMobileStyles}
      entity={entity}
      isMoveUpAllowed={isMoveUpAllowed}
      isMoveDownAllowed={isMoveDownAllowed}
    >
      {(emulatedEntity, _, isMobile) => (
        <EntityExampleWrapper
          label="entities.payment_methods.example_warning"
          visible={isExample}
        >
          <PaymentMethodUi margin={margin} mobileMargin={mobileMargin}>
            <PaymentMethodSwitcher
              fontSize={entity.styles.fontSize}
              mobileFontSize={entity.mobileStyles.fontSize}
              lineHeight={entity.styles.lineHeight}
              mobileLineHeight={entity.mobileStyles.lineHeight}
              fontFamily={entity.styles.fontFamily}
              fontWeight={entity.styles.fontWeight}
              fontStyle={entity.styles.fontStyle}
              mobileFontStyle={entity.mobileStyles.fontStyle}
              mobileFontFamily={entity.mobileStyles.fontFamily}
              mobileFontWeight={entity.mobileStyles.fontWeight}
              color={entity.styles.color}
              mobileColor={entity.mobileStyles.color}
              locale={pageLocale}
              activeMethod={calculatedMethods[0]} // stripe first
              paymentMethods={calculatedMethods}
              changeMethod={() => {}}
              isMobile={isMobile}
            />
          </PaymentMethodUi>
        </EntityExampleWrapper>
      )}
    </BaseEntity>
  )
}

PaymentMethods.propTypes = {
  entity: types.entity.isRequired,
  isMoveUpAllowed: PropTypes.bool.isRequired,
  isMoveDownAllowed: PropTypes.bool.isRequired,
}

export default memo(PaymentMethods)
