import PropTypes from 'prop-types'
import React from 'react'
import CommonLanguageSwitcher from 'common/components/entities/LanguageSwitcher'
import usePresentPage, { selectors } from '../../hooks/usePresentPage'
import BaseEntity from '../core/BaseEntity'

function LanguageSwitcher({ entity, isMoveDownAllowed, isMoveUpAllowed }) {
  const locale = usePresentPage(selectors.getPageLocale)

  return (
    <BaseEntity
      entity={entity}
      isMoveUpAllowed={isMoveUpAllowed}
      isMoveDownAllowed={isMoveDownAllowed}
    >
      {emulatedEntity => (
        <CommonLanguageSwitcher
          currentLanguage={locale}
          styles={emulatedEntity.styles}
          mobileStyles={emulatedEntity.mobileStyles}
          redirectPath={emulatedEntity.options.redirectPath}
          excludedLanguages={
            entity.options.excludedLanguages
              ? entity.options.excludedLanguages
              : []
          }
        />
      )}
    </BaseEntity>
  )
}

LanguageSwitcher.propTypes = {
  entity: PropTypes.any.isRequired,
  isMoveUpAllowed: PropTypes.bool.isRequired,
  isMoveDownAllowed: PropTypes.bool.isRequired,
}

export default LanguageSwitcher
