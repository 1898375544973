import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PricePlan } from 'common/types/OfferInterface'
import { ProductInterface } from 'common/types/entities/ProductInterface'
import { getPricePlanAmount } from 'common/utils/priceCalculator'
import { RazorpayBuyOfferResponse } from 'publisher/api/razorpayApi'
import { loadScriptAsync } from 'publisher/utils/loadScript'

const RAZORPAY_SCRIPT = 'https://checkout.razorpay.com/v1/checkout.js'

type RazorpaySuccessHandlerArgs = {
  razorpay_signature: string
  razorpay_order_id?: string
  razorpay_subscription_id?: string
  razorpay_payment_id: string
}

interface UseRazorpay {
  setErrors?: (errors: string[]) => void
}

export interface RazorpayOptions {
  key: string
  amount: number
  currency: string
  name: string
  description?: string
  image?: string
  order_id?: string
  subscription_id?: string
  handler: (args: RazorpaySuccessHandlerArgs) => void
  prefill?: Record<string, unknown>
  notes?: Record<string, unknown>
  theme?: Record<string, unknown>
  modal?: Record<string, unknown>
}

interface Constructable<T> {
  new (options: RazorpayOptions): T
}

declare global {
  interface Window {
    Razorpay: Constructable<Razorpay>
  }
}

class Razorpay {
  private readonly options: RazorpayOptions
  private razorpayInstance: any

  constructor(options: RazorpayOptions) {
    this.options = options
    if (typeof window !== 'undefined' && 'Razorpay' in window) {
      this.razorpayInstance = new window.Razorpay(this.options)
    }
  }

  public on(event: string, callback: () => void) {
    this.razorpayInstance.on(event, callback)
  }

  public open() {
    this.razorpayInstance.open()
  }
}

export default function useRazorpay({ setErrors }: UseRazorpay) {
  const { t } = useTranslation()
  const [isRazorpayFetching, setIsRazorpayFetching] = useState(false)
  const [isRazorpayReady, setIsRazorpayReady] = useState(false)

  const generateOptions = function (
    purchaseDetails: RazorpayBuyOfferResponse,
    pricePlan: PricePlan | undefined | null,
    isBump = false,
    product: ProductInterface | null = {} as ProductInterface,
  ) {
    const calculatedAmount = pricePlan
      ? getPricePlanAmount(pricePlan)
      : (product as ProductInterface).price
    const calculatedCurrency = pricePlan
      ? pricePlan?.currency.toUpperCase()
      : (product as ProductInterface).currency.toUpperCase()

    const options: RazorpayOptions = {
      key: purchaseDetails.keyId,
      amount: calculatedAmount,
      currency: calculatedCurrency,
      name: purchaseDetails.vendorName,
      description: pricePlan?.name,
      prefill: {
        name: purchaseDetails.prefill.name,
        email: purchaseDetails.prefill.email,
        contact: purchaseDetails.prefill.phone,
      },
      theme: {
        color: '#3399cc',
      },
      handler: async () => {
        window.location.assign(purchaseDetails.redirectUrl)
      },
    }

    if (isBump) {
      if (purchaseDetails.rpBumpOrderId) {
        options.order_id = purchaseDetails.rpBumpOrderId
      } else if (purchaseDetails.rpBumpSubscriptionId) {
        options.subscription_id = purchaseDetails.rpBumpSubscriptionId
      } else {
        throw Error('RP bump order id and bump subscription id is missing')
      }

      options.order_id = purchaseDetails.rpBumpOrderId
    } else {
      if (purchaseDetails.rpOrderId) {
        options.order_id = purchaseDetails.rpOrderId
      } else if (purchaseDetails.rpSubscriptionId) {
        options.subscription_id = purchaseDetails.rpSubscriptionId
      } else {
        throw Error('RP order id and subscription id is missing')
      }
    }

    return options
  }

  useEffect(() => {
    const fetchRazorpayScript = async () => {
      try {
        setIsRazorpayFetching(true)
        await loadScriptAsync(RAZORPAY_SCRIPT, document)
        setIsRazorpayReady(true)
      } catch (error: any) {
        setErrors && setErrors([t('core.errors.no_connection')])
        throw new Error(error)
      } finally {
        setIsRazorpayFetching(false)
      }
    }

    if (!('Razorpay' in window)) {
      fetchRazorpayScript()
    } else {
      setIsRazorpayReady(true)
    }
  }, [t, setErrors])

  return { Razorpay, generateOptions, isRazorpayReady, isRazorpayFetching }
}
