import { DataFile, FilesState } from './filesReducer'

export const getFileById = (state: FilesState, fileId?: number | null) => {
  if (!fileId) {
    return null
  }

  return state.pageFiles[fileId] || state.userFiles[fileId]
}

export const getFilesByIds = (state: FilesState, fileIds: number[]) => {
  if (!fileIds) {
    return null
  }
  return fileIds.reduce(
    (prev, id) => ({
      ...prev,
      [id]: getFileById(state, id),
    }),
    {} as Record<number, DataFile | null>,
  )
}

const filesSelectors = {
  getFileById,
  getFilesByIds,
}

export default filesSelectors
