import styled from 'styled-components'

export const FileUploadButtonUi = styled.button`
  background-color: #459df5;
  border-radius: 5px;
  border: none;
  outline: none;
  color: #fff;
  padding: 10px 15px;
  font-size: 14px;
  cursor: pointer;
`

export default FileUploadButtonUi