import styled from 'styled-components'

const ChildlessPlaceholderUi = styled.div`
  display: flex;
  min-height: 50px;
  flex-direction: column;
  font-size: 16px;
  color: #d0d7de;
  text-transform: uppercase;
  justify-content: center;
  align-items: center;
  ${p => !p.isCreateBlockActive && `border: 1px dashed #969fa6;`};
  flex: 1;
`

export default ChildlessPlaceholderUi
