import styled from 'styled-components'

const VideoPlaceholderInner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
  box-sizing: border-box;
  background: #2B2B2B url(/assets/images/videoplaceholderbg.png) center center no-repeat;
  border: 1px solid rgba(0,0,0,0.2);
  text-align: center;
  box-shadow: inset 0 0 0 1px rgba(255,255,255,0.3);
`

export default VideoPlaceholderInner
