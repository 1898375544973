import PropTypes from 'prop-types'
import React, { memo } from 'react'
import CommonRawHtml from 'common/components/entities/RawHtml'
import * as types from 'common/types'
import BaseEntity from 'client/components/core/BaseEntity'
import RawHtmlWatermarkUi from './ui/RawHtmlWatermarkUi'

function RawHtml({ entity, isMoveDownAllowed, isMoveUpAllowed }) {
  return (
    <BaseEntity
      entity={entity}
      // they will be overridden by mobileStyles if such present
      mobileEmulationStyles={{
        marginTop: 0,
        marginRight: 0,
        marginBottom: 0,
        marginLeft: 0,
      }}
      isMoveUpAllowed={isMoveUpAllowed}
      isMoveDownAllowed={isMoveDownAllowed}
    >
      {emulatedEntity => (
        <RawHtmlWatermarkUi
          data-watermark="HTML"
          styles={emulatedEntity.styles}
          mobileStyles={emulatedEntity.mobileStyles}
        >
          <CommonRawHtml html={entity.options.html} />
        </RawHtmlWatermarkUi>
      )}
    </BaseEntity>
  )
}

RawHtml.propTypes = {
  entity: types.entity.isRequired,
  isMoveUpAllowed: PropTypes.bool.isRequired,
  isMoveDownAllowed: PropTypes.bool.isRequired,
}

export default memo(RawHtml)
