import PropTypes from 'prop-types'
import React, { memo, useCallback, useRef, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import CommonRow from 'common/components/entities/Row/RowOld'
import { useActions } from 'common/hooks/useActions'
import { update } from 'client/actions/entityActions'
import Splitter from 'client/components/Splitter'
import BaseEntity, { color } from 'client/components/core/BaseEntity'
import Column from 'client/components/entities/Column/ColumnOld'
import ColumnAlignControls from 'client/components/entities/Row/components/ColumnAlignControls'
import * as resizeUtils from 'client/components/entities/Row/resizeUtils'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'
import { useContactUsDescendantIds } from 'client/pages/contact-us/entities/ContactUs'
import { getFileById } from 'client/reducers/filesReducer'
import { getChildrenEntities } from 'client/reducers/pageReducer'
import typedPageSelectors from 'publisher/store/page/pageSelectors'
import EntityTypeEnum from '../../../../common/enums/entityTypeEnum'
import { splitMarginsAndRestStyles } from '../../../../common/utils/styleUtils'
import { useSetRowResizing } from '../../../context/ResizingRowContext'
import { getBlockFile } from '../../../reducers/blocksReducer'
import { usePage } from '../../../store'
import pageSelectors from '../../../store/page/pageSelectors'

const COLUMN_GAP = 20
const SPLITTER_PREFIX = 'after_'
const COLUMN_SIZE = 12

function RowOld({ entity, isMoveUpAllowed, isMoveDownAllowed }) {
  const { isMobile, isDesktop } = useDeviceModeContext()
  const ref = useRef(null)
  const entities = useSelector(
    state => getChildrenEntities(state.page.present, entity.childIds),
    shallowEqual,
  )
  const setRowResizing = useSetRowResizing()
  const updateAction = useActions(update)
  const childContactUsElements = usePage(p =>
    typedPageSelectors.getEntityDescendantsContactUsElements(p, entity.id),
  )
  const dropBoxAllowedContactUsEntityIds = useContactUsDescendantIds()

  // const toggleResizingAction = useActions(toggleResizing)
  const [resizeData, setResizeData] = useState(
    resizeUtils.getResizeData(entities),
  )
  const [splitters, setSplitters] = useState(
    resizeUtils.generateSplitters(entities, SPLITTER_PREFIX),
  )
  const backgroundFile = useSelector(
    state =>
      getFileById(state.files, entity.options.backgroundFileId) ||
      getBlockFile(state.blocks, entity.options.backgroundFileId),
    shallowEqual,
  )
  const mobileBackgroundFile = useSelector(
    state =>
      getFileById(state.files, entity.mobileOptions.backgroundFileId) ||
      getBlockFile(state.blocks, entity.mobileOptions.backgroundFileId),
    shallowEqual,
  )
  const calculatedMobileBackgroundFile =
    typeof entity.mobileOptions.backgroundFileId === 'undefined'
      ? backgroundFile
      : mobileBackgroundFile
  const [width, setWidth] = useState(0)
  const [paddingLeft, setPaddingLeft] = useState(0)
  const [isHovered, setIsHovered] = useState(false)
  const minColumnWidth = resizeUtils.getMinColumnWidth(
    width,
    COLUMN_SIZE,
    COLUMN_GAP,
  )
  const enableHover = () => setIsHovered(true)
  const disableHover = () => setIsHovered(false)
  const externalActionsRef = useRef()
  const setRef = useCallback(node => {
    if (node !== null) {
      setWidth(resizeUtils.getWidthWithoutPadding(node))
      setPaddingLeft(resizeUtils.getPaddingLeft(node))
    }
    // Save a reference to the node
    ref.current = node
  }, [])

  const resize = (prev, next) => resizeStep => {
    const currentItem = resizeData[prev]
    const nextItem = resizeData[next]

    if (resizeUtils.denyResize(currentItem.size, nextItem.size, resizeStep)) {
      return false
    }

    setResizeData({
      ...resizeData,
      [prev]: {
        ...currentItem,
        size: currentItem.size - resizeStep,
      },
      [next]: {
        ...nextItem,
        size: nextItem.size + resizeStep,
      },
    })

    return true
  }

  const updateResizeData = (prev, next) => isResizing => {
    const prevItemData = {
      ...resizeData[prev.id],
      isResizing,
    }
    const nextItemData = {
      ...resizeData[next.id],
      isResizing,
    }
    const splitter = {
      ...splitters[SPLITTER_PREFIX + prev.id],
      isResizing,
    }

    setResizeData({
      ...resizeData,
      [prev.id]: prevItemData,
      [next.id]: nextItemData,
    })

    setSplitters({
      ...splitters,
      [SPLITTER_PREFIX + prev.id]: splitter,
    })

    if (!isResizing) {
      updateAction({
        ...prev,
        options: {
          ...prev.options,
          size: prevItemData.size,
        },
      })
      updateAction({
        ...next,
        options: {
          ...next.options,
          size: nextItemData.size,
        },
      })
    }

    setTimeout(() => {
      setRowResizing(isResizing)
    }, 100)
  }

  const handleUpdateColumnAlignment = entity => style => {
    const entityStyles = entity.styles ? entity.styles : {}
    updateAction({
      ...entity,
      styles: {
        ...entityStyles,
        alignSelf: style,
      },
    })
  }

  const renderChildren = () => {
    const data = entities.reduce(
      (acc, child, index) => {
        const size = resizeData[child.id] ? resizeData[child.id].size : 12
        const nextEntity = entities[index + 1]
        const splitterKey = SPLITTER_PREFIX + child.id
        const left = resizeUtils.calculateLeftPosition(
          acc.left,
          size,
          index,
          COLUMN_GAP,
          minColumnWidth,
        )
        const columnControlsLeft =
          resizeUtils.calculateColumnControlsLeftPosition(acc.left, index)
        const isFirstChild = index === 0
        const isLastChild = index === entities.length - 1
        return {
          left,
          gridColumnStart: acc.gridColumnStart + size,
          rendered: [
            ...acc.rendered,
            <Column
              key={child.id}
              entity={child}
              disableStacking={entity.options.disableStacking}
              gridColumnStart={acc.gridColumnStart}
              size={size}
            />,
            ...(entities.length > 1
              ? [
                  <ColumnAlignControls
                    key={`${child.id}-align-controls`}
                    left={columnControlsLeft}
                    show={isDesktop && isHovered}
                    update={handleUpdateColumnAlignment(child)}
                    activeMode={child.styles.alignSelf}
                    externalActionsRef={externalActionsRef}
                    withoutTop={!isLastChild && !isFirstChild}
                    columnSize={size * minColumnWidth + COLUMN_GAP}
                    isLastChild={isLastChild}
                  />,
                ]
              : []),
            ...(index !== entities.length - 1
              ? [
                  <Splitter
                    left={left}
                    key={splitterKey}
                    show={isDesktop && isHovered}
                    isResizing={
                      splitters[splitterKey]
                        ? splitters[splitterKey].isResizing
                        : false
                    }
                    resize={resize(child.id, nextEntity.id)}
                    updateStateResizing={updateResizeData(child, nextEntity)}
                    minColumnWidth={minColumnWidth + COLUMN_GAP}
                  />,
                ]
              : []),
          ],
        }
      },
      { gridColumnStart: 1, rendered: [], left: paddingLeft },
    )
    return data.rendered
  }

  const parentEntity = usePage(page =>
    pageSelectors.getEntityById(page, entity.parentId),
  )
  // todo: looks dirty, we should remove it
  const isContactUsDescendant =
    parentEntity && parentEntity.type === EntityTypeEnum.ContactUs
  const isDropBoxRestricted =
    isContactUsDescendant && childContactUsElements.length > 0

  return (
    <BaseEntity
      entity={entity}
      color={color.blue}
      hoverColor={color.blue}
      isMoveUpAllowed={isMoveUpAllowed}
      isMoveDownAllowed={isMoveDownAllowed}
      isSaveAllowed={!isContactUsDescendant}
      isRemoveAllowed={!isContactUsDescendant}
      isCopyAllowed={!isContactUsDescendant}
      dropBoxAllowedEntityIds={
        isDropBoxRestricted ? dropBoxAllowedContactUsEntityIds : undefined
      }
      externalActionsRef={externalActionsRef}
    >
      {emulatedEntity => (
        <CommonRow
          onMouseOver={enableHover}
          onMouseOut={disableHover}
          ref={setRef}
          styles={{
            ...emulatedEntity.styles,
            width: '100%',
          }}
          mobileStyles={
            splitMarginsAndRestStyles(emulatedEntity.mobileStyles)[1]
          } // it has margins due to bugs in old BaseEntity emulation calculations
          options={{
            ...emulatedEntity.options,
          }} /* hidden: false - publisher hack to hide elements */
          backgroundFile={
            isMobile ? calculatedMobileBackgroundFile : backgroundFile
          }
          mobileOptions={emulatedEntity.mobileOptions}
          disableColumnGap={entities.length === 1}
        >
          {renderChildren()}
        </CommonRow>
      )}
    </BaseEntity>
  )
}

RowOld.propTypes = {
  entity: PropTypes.shape({
    id: PropTypes.string.isRequired,
    options: PropTypes.objectOf(PropTypes.any).isRequired,
    mobileOptions: PropTypes.objectOf(PropTypes.any).isRequired,
    styles: PropTypes.objectOf(PropTypes.any).isRequired,
    mobileStyles: PropTypes.objectOf(PropTypes.any).isRequired,
    childIds: PropTypes.arrayOf(PropTypes.string),
    type: PropTypes.string.isRequired,
    parentId: PropTypes.string,
  }).isRequired,
  isMoveUpAllowed: PropTypes.bool.isRequired,
  isMoveDownAllowed: PropTypes.bool.isRequired,
}

export default memo(RowOld)
