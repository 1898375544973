import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useDrop } from 'react-dnd'
import { useDispatch } from 'react-redux'
import structureTypes from 'common/constants/structureTypes'
import EntityTypeEnum from 'common/enums/entityTypeEnum'
import LibraryElementEnum from 'client/enums/LibraryElementEnum'
import useDropRef from 'client/hooks/useDropRef'
import useIntersection from 'client/hooks/useIntersection'
import { usePage, pageSelectors } from 'client/store/index'
import { addStructure } from 'client/store/page/pageActions'
import createLibraryElementStructure from 'client/utils/createLibraryElements'
import DropBoxUi from './ui/DropBoxUi'

const isInTopHalfOfRect = ({ clientX, clientY }, rect) =>
  clientX > rect.x &&
  clientX < rect.x + rect.width &&
  clientY > rect.y &&
  clientY < rect.y + rect.height / 2

/*
  Notice, it will re-render TWO times.
  First, changes canDrop, then changes top or bottom highlighting
 */
function DropBoxEntity({
  parentId,
  allowedTypes = [],
  children = null,
  createEntity,
  createBlock,
  position,
  moveEntity,
  expanded = false,
}) {
  const pageLocale = usePage(pageSelectors.getPageLocale)
  const dispatch = useDispatch()
  const parent = usePage(page => pageSelectors.getEntityById(page, parentId))
  const [intersectionRef, entry] = useIntersection({
    threshold: [0, 0.25, 0.5, 0.75, 1],
  })
  const [dropTop, setDropTop] = useState(false)
  const [dropBottom, setDropBottom] = useState(false)
  const [{ canDrop }, connectDrop] = useDrop({
    accept: allowedTypes,
    drop: (item, monitor) => {
      if (monitor.didDrop()) {
        return
      }
      const calculatedPosition = dropTop ? position : position + 1
      if (monitor.getItemType().startsWith('TOOLBOX_')) {
        createEntity(monitor.getItemType(), parentId, calculatedPosition)
      } else if (monitor.getItemType() in LibraryElementEnum) {
        const structure = createLibraryElementStructure(
          monitor.getItemType(),
          parent,
          pageLocale,
          calculatedPosition,
        )
        dispatch(addStructure(structure))
      } else if (monitor.getItemType() === 'BLOCK') {
        createBlock(item.entities, parentId, calculatedPosition, item.isMaster)
      } else {
        moveEntity(item.entity, parentId, calculatedPosition)
      }
    },
    /* @see arePropsEqual(props, otherProps)function inside the options object
    can improve the performance */
    canDrop: draggable => {
      if (draggable.type === 'BLOCK') {
        if (
          draggable.entities.find(
            ent =>
              ent.type === structureTypes.SECTION ||
              ent.type === EntityTypeEnum.Section,
          ) &&
          !allowedTypes.includes(structureTypes.SECTION) &&
          !allowedTypes.includes(EntityTypeEnum.Section)
        ) {
          return false
        }

        return true
      }

      // checking for library elements
      if (
        draggable.type.startsWith('TOOLBOX_') ||
        draggable.type.startsWith('Library')
      ) {
        return true
      }

      // check if draggable item under the child dropBox
      if (draggable.entity.id === parentId) {
        return false
      }
      // checking descendant dropBoxes
      if (
        draggable.descendantIds &&
        draggable.descendantIds.includes(parentId)
      ) {
        return false
      }

      if (
        draggable.allowedEntityIds &&
        !draggable.allowedEntityIds.includes(parentId)
      ) {
        return false
      }

      return true
    },
    collect: monitor => ({
      canDrop: monitor.isOver({ shallow: true }) && monitor.canDrop(),
    }),
  })

  const enableDropTop = () => {
    setDropTop(true)
    setDropBottom(false)
  }

  const enableDropBottom = () => {
    setDropBottom(true)
    setDropTop(false)
  }

  const onDragOver = ({ clientX, clientY, target }) => {
    if (canDrop) {
      const rect = target.getBoundingClientRect()
      if (isInTopHalfOfRect({ clientX, clientY }, rect)) {
        enableDropTop()
      } else {
        enableDropBottom()
      }
    }
  }

  const [setRef] = useDropRef(connectDrop, onDragOver)
  return (
    <div ref={intersectionRef}>
      <DropBoxUi
        ref={setRef}
        canDrop={canDrop}
        dropTop={dropTop}
        dropBottom={dropBottom}
        expanded={expanded && entry.intersectionRatio > 0.7}
      >
        {children}
      </DropBoxUi>
    </div>
  )
}

DropBoxEntity.propTypes = {
  children: PropTypes.node,
  parentId: PropTypes.string.isRequired,
  position: PropTypes.number.isRequired,
  createEntity: PropTypes.func.isRequired,
  moveEntity: PropTypes.func.isRequired,
  createBlock: PropTypes.func.isRequired,
  allowedTypes: PropTypes.arrayOf(PropTypes.string),
  expanded: PropTypes.bool,
}

export default DropBoxEntity
