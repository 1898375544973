import styled from 'styled-components'

const ModalWrapperUi = styled.div`
  position: fixed;
  left: 0; /*ie11 hack*/
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
`

export default ModalWrapperUi
