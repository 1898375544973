import styled from 'styled-components'

interface AlignControlsButtonUiInterface {
  active: boolean
}

const AlignControlsButtonUi = styled.div<AlignControlsButtonUiInterface>`
  padding: 6px 8px;
  height: 100%;
  display: flex;
  background-color: #149efc;
  align-items: center;
  position: relative;
  color: #fff;
  font-family: var(--font-family);
  background-color: ${({ active }) =>
    active ? 'rgba(0, 0, 0, 0.1)' : '#149efc'};
  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
  &:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
  }
`
export default AlignControlsButtonUi
