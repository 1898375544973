import PropTypes from 'prop-types'
import { offerPricePlan } from 'common/types/index'
import { getNetPrice, getReadablePrice } from 'common/utils/priceCalculator'
import centsToEuro from 'common/utils/centsToEuro'

const OneShot = ({
  plan: { directChargeAmount, currency },
  language,
  tax = 0,
  quantity = 1,
  coupon,
}) =>
  getReadablePrice(
    centsToEuro(
      getNetPrice(directChargeAmount, quantity, coupon) + tax * quantity,
    ),
    currency,
    language,
  )

OneShot.propTypes = {
  plan: offerPricePlan.isRequired,
  translate: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired,
  tax: PropTypes.number,
  quantity: PropTypes.number,
}

export default OneShot
