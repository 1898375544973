import React from 'react'
import CommonLanguageSwitcher from 'common/components/entities/NewLanguageSwitcher'
import EntityTypeEnum from 'common/enums/entityTypeEnum'
import { MasterBlockId } from 'common/types/entities/EntityInterface'
import { LanguageSwitcherInterface } from 'common/types/entities/LanguageSwitcherInterface'
import usePresentPage, { selectors } from 'client/hooks/usePresentPage'
import { useManagement } from '../../../store'
import managementSelectors from '../../../store/management/managementSelectors'
import { EntityProps } from '../../../types'
import { generateBaseEntity } from '../../../utils/createStructureUtils'
import BaseEntityNew from '../../core/BaseEntity/BaseEntityNew'

function NewLanguageSwitcher({
  entity,
  isMoveDownAllowed,
  isMoveUpAllowed,
}: EntityProps<LanguageSwitcherInterface>) {
  const locale = usePresentPage(selectors.getPageLocale)

  const isEditing = useManagement(state =>
    managementSelectors.isEditing(state, entity.id),
  )

  return (
    <BaseEntityNew
      entity={entity}
      isMoveDownAllowed={isMoveDownAllowed}
      isMoveUpAllowed={isMoveUpAllowed}
    >
      <CommonLanguageSwitcher
        color={entity.color}
        mobileColor={entity.mobileColor}
        margin={entity.margin}
        mobileMargin={entity.mobileMargin}
        fontSize={entity.fontSize}
        fontStyle={entity.fontStyle}
        fontFamily={entity.fontFamily}
        fontWeight={entity.fontWeight}
        lineHeight={entity.lineHeight}
        mobileFontSize={entity.mobileFontSize}
        mobileLineHeight={entity.mobileLineHeight}
        mobileFontStyle={entity.mobileFontStyle}
        mobileFontFamily={entity.mobileFontFamily}
        mobileFontWeight={entity.mobileFontWeight}
        redirectPath={entity.redirectPath}
        excludedLanguages={
          entity.excludedLanguages ? entity.excludedLanguages : []
        }
        currentLanguage={locale}
        isEditing={isEditing}
      />
    </BaseEntityNew>
  )
}

export default NewLanguageSwitcher

export function createLanguageSwitcher(
  parentId: string,
  masterBlockId?: MasterBlockId,
): LanguageSwitcherInterface {
  return {
    ...generateBaseEntity(EntityTypeEnum.Image, parentId, masterBlockId),
    type: EntityTypeEnum.LanguageSwitcher,
    excludedLanguages: [],
    redirectPath: '',
    fontSize: 16,
    color: 'rgba(149, 164, 186, 1)',
  }
}
