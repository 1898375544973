import React from 'react'
import MenuButton from 'client/components/core/TextEditor/menu/MenuButton'

const StyleControls = ({ controls, checkActive, onToggle, small }) =>
  controls.map(type => (
    <MenuButton
      key={type.label}
      active={checkActive(type.style)}
      onToggle={e => {
        e.preventDefault()
        onToggle(type.style)
      }}
      small={small}
      dangerouslySetInnerHTML={{ __html: type.label }}
    />
  ))

export default StyleControls
