import React, { memo } from 'react'
import { CommonBlogPageBody } from 'common/components/entities/blog-page-body'
import { BlogPageBodyInterface } from 'common/types/entities/blog-page-body-interface'
import ChildrenEntities from 'client/components/core/ChildrenEntities'
import { useBackgroundImage } from 'client/hooks/useBackgroundImage'

interface BlogPageBodyProps {
  entity: BlogPageBodyInterface
}

function BlogPageBody({ entity }: BlogPageBodyProps) {
  const {
    background,
    backgroundColor,
    mobileBackgroundColor,
    blur,
    mobileBackground,
    id,
    type,
    childIds,
  } = entity
  const { backgroundImage, mobileBackgroundImage } = useBackgroundImage({
    backgroundFileId: entity.backgroundFileId,
    mobileBackgroundFileId: entity.mobileBackgroundFileId,
  })

  return (
    <CommonBlogPageBody
      background={background}
      backgroundColor={backgroundColor}
      mobileBackgroundColor={mobileBackgroundColor}
      backgroundImage={backgroundImage}
      mobileBackgroundImage={mobileBackgroundImage}
      mobileBackground={mobileBackground}
      blur={blur}
    >
      <ChildrenEntities
        id={id}
        childIds={childIds as never[]}
        type={type}
        isReadOnly={entity.isReadOnly}
        placeholderText="entities.body.empty_dropbox_text"
      />
    </CommonBlogPageBody>
  )
}

export default memo(BlogPageBody)
