import { loadStripe } from '@stripe/stripe-js'
import PropTypes from 'prop-types'
import { Elements } from '@stripe/react-stripe-js'
import React, { useState } from 'react'
import { wrapFontFamily } from 'common/components/entities/Text/utils'
import { zeroDecimalCurrencies } from 'common/constants/stripe-consts'
import {
  calculateShippingTotal,
  centsToEuro,
} from 'common/utils/priceCalculator'
import { useSelectedOfferPricing } from 'publisher/pages/offer-page/hooks/useSelectedOfferPricing'
import { usePage } from 'publisher/store'
import { usePayment } from 'publisher/store'
import pageSelectors from 'publisher/store/page/pageSelectors'
import {
  getStripeAccountId,
  getProduct,
  getActivePricePlan,
  getCheckedBump,
  getProductActiveVariant,
  getSelectedShipping,
  getOfferCurrency,
} from 'publisher/store/payment/paymentSelectors'
import Meta from '../components/core/Meta'
import { DLocalProvider } from '../context/DlocalContext'
import { XenditProvider } from '../context/XenditContext'
import { MercadoPagoProvider } from '../context/mercadoPagoContext'
import usePhysicalProductsFromQueryString from '../hooks/usePhysicalProductsFromQueryString'
import OnExitPopup from './components/OnExitPopup'
import OnloadPopups from './components/OnloadPopups'
import TriggeredPopup from './components/TriggeredPopup'
import PageUi from './ui/PageUi'

function PaymentPage({ children }) {
  const globalTextFontProperties = usePage(
    pageSelectors.getGlobalFontProperties,
  )
  const pageLocale = usePage(pageSelectors.getLocale)
  const stripeAccount = usePayment(getStripeAccountId)
  usePhysicalProductsFromQueryString()

  const [stripePromise] = useState(() =>
    stripeAccount
      ? loadStripe(process.env.STRIPE_KEY, {
          stripeAccount,
          locale: pageLocale,
        })
      : null,
  )

  const product = usePayment(getProduct)
  const activeProductVariant = usePayment(getProductActiveVariant)
  const pricePlan = usePayment(getActivePricePlan)
  const bump = usePayment(getCheckedBump)
  const bumpPlan = bump && bump.pricePlans ? bump.pricePlans[0] : null
  const productBump = bump && bump.product ? bump.product : null

  const selectedPricing = useSelectedOfferPricing({
    selectedProduct: product,
    selectedPricePlan: pricePlan,
    activeProductVariant,
  })
  const selectedBumpPricing = useSelectedOfferPricing({
    selectedProduct: productBump,
    selectedPricePlan: bumpPlan,
  })
  const selectedShipping = usePayment(getSelectedShipping)
  const shippingTotal = calculateShippingTotal(
    selectedShipping,
    selectedPricing?.taxPercent ?? 0,
  )

  const totalGrossAmount = selectedPricing?.grossAmount
    ? selectedPricing?.grossAmount + (selectedBumpPricing?.grossAmount ?? 0)
    : null

  const offerCurrency = usePayment(getOfferCurrency)

  // temp solution, we need to use backend calculations https://systeme-team.slack.com/archives/C03MZQ0J26N/p1740474129691919
  const stripePaymentAmount = Math.ceil(
    (totalGrossAmount ??
      product?.amount ??
      pricePlan?.directChargeAmount ??
      0) + shippingTotal,
  )

  const stripeOptions = stripePaymentAmount
    ? {
        mode: 'payment',
        // https://docs.stripe.com/currencies#minor-units
        amount: zeroDecimalCurrencies.includes(offerCurrency)
          ? centsToEuro(stripePaymentAmount)
          : stripePaymentAmount,
        currency: offerCurrency,
        paymentMethodCreation: 'manual',
      }
    : {
        mode: 'setup',
        currency: offerCurrency,
        paymentMethodCreation: 'manual',
      }

  return (
    <Elements stripe={stripePromise} options={stripeOptions}>
      <DLocalProvider>
        <form id="form-checkout">
          <MercadoPagoProvider>
            <XenditProvider>
              <PageUi
                $textFontFamily={wrapFontFamily(
                  globalTextFontProperties.fontFamily,
                )}
                $textFontWeight={globalTextFontProperties.fontWeight}
                $textFontStyle={globalTextFontProperties.fontStyle}
              >
                <Meta />
                {children}
                <TriggeredPopup />
                <OnExitPopup />
                <OnloadPopups />
              </PageUi>
            </XenditProvider>
          </MercadoPagoProvider>
        </form>
      </DLocalProvider>
    </Elements>
  )
}

PaymentPage.propTypes = {
  children: PropTypes.node.isRequired,
}

export default PaymentPage
