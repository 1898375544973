// @ts-nocheck
import React from 'react'
import { OldEntityInterface } from 'common/types/entities/OldEntityInterface'
import { buyMainOffer } from 'publisher/api/paystackApi'
import { usePage, pageSelectors } from 'publisher/store'
import usePaymentSubmit from './usePaymentSubmit'

export default function usePaystackPaymentHandler(
  entity: OldEntityInterface | PaymentButtonInterface,
) {
  const { errors, isLoading, submit } = usePaymentSubmit(entity)
  const pageId = usePage(pageSelectors.getPageId)

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    submit(async body => {
      const { data } = await buyMainOffer(pageId, {
        payment_form: {
          ...body,
        },
      })
      if (data.redirect) {
        window.location.assign(data.redirect)
        return
      }
    })
  }

  return { errors, isLoading, handleSubmit }
}
