import {
  PayPalButtons,
  PayPalButtonsComponentProps,
} from '@paypal/react-paypal-js'
import React, { CSSProperties } from 'react'
import { BorderType } from 'common/types/styleTypes'

export type PaypalBackgroundColorType =
  | 'gold'
  | 'blue'
  | 'silver'
  | 'white'
  | 'black'

export const MAX_PAYPAL_BUTTON_HEIGHT = 55
export const MAX_PAYPAL_BUTTON_WIDTH = 300

interface CommonPaypalButtonProps
  extends Omit<PayPalButtonsComponentProps, 'style' | 'message'> {
  backgroundColor?: PaypalBackgroundColorType
  height?: number
  width?: CSSProperties['width']
  borderRadius?: Partial<BorderType>
  disabled?: boolean
}

export const CommonPaypalRestButton = ({
  backgroundColor = 'blue',
  height,
  width,
  borderRadius,
  ...rest
}: CommonPaypalButtonProps) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div style={{ maxWidth: width, width: '100%' }}>
        <PayPalButtons
          {...rest}
          style={{
            layout: 'horizontal',
            label: 'paypal',
            shape: 'rect',
            disableMaxWidth: true,
            color: backgroundColor,
            borderRadius: typeof borderRadius === 'number' ? borderRadius : 0,
            height,
          }}
          forceReRender={[backgroundColor, height, borderRadius]}
          message={{}}
        />
      </div>
    </div>
  )
}
