import styled from 'styled-components'

const PickerPopupUi = styled.div`
  z-index: 110;
  background: rgb(255, 255, 255);
  border-radius: 4px;
  box-shadow: rgb(0 0 0 / 15%) 0px 0px 0px 1px, rgb(0 0 0 / 15%) 0px 8px 16px;
`

export default PickerPopupUi
