import { useState } from 'react'
import { UpsellButtonInterface } from 'common/types/entities/button-interface'
import { usePayment, usePage, useManagement } from 'publisher/store'
import managementSelectors from 'publisher/store/management/managementSelectors'
import pageSelectors from 'publisher/store/page/pageSelectors'
import typedPaymentSelectors from 'publisher/store/payment/paymentSelectors'
import paymentSelectors from 'publisher/store/payment/paymentSelectors'

export default function useUpsellSubmit(entity: UpsellButtonInterface) {
  const [isLoading, setLoading] = useState(false)
  const [errors, setErrors] = useState<string[]>([])
  const checkedPricePlanId = usePayment(paymentSelectors.getCheckedPricePlanId)
  const pageId = usePage(pageSelectors.getPageId)
  const purchaseProcessId = usePayment(paymentSelectors.getPurchaseProcessId)
  const isDesktop = useManagement(managementSelectors.isDesktop)
  const popupId = usePage(p => pageSelectors.getAscendantPopupId(p, entity))

  const mandatoryCheckboxes = usePage(p =>
    pageSelectors.getVisibleMandatoryCheckboxes(p, isDesktop, popupId),
  )
  const confirmedCheckboxesIds = useManagement(
    managementSelectors.getConfirmedCheckboxesIds,
  )
  const product = usePayment(typedPaymentSelectors.getProduct)
  const productQuantity = usePayment(typedPaymentSelectors.getProductQuantity)
  const activeProductVariant = usePayment(
    typedPaymentSelectors.getProductActiveVariant,
  )

  const submit = async (paymentMethodHandler: any) => {
    setErrors([])

    const checkboxesErrors = mandatoryCheckboxes.reduce(
      (errors: string[], checkbox) => {
        return confirmedCheckboxesIds.includes(checkbox.id)
          ? errors
          : [
              ...errors,
              'options' in checkbox
                ? checkbox.options.errorText
                : checkbox.errorText,
            ]
      },
      [],
    )

    if (checkboxesErrors.length > 0) {
      setErrors(checkboxesErrors)
      return
    }

    const calculatedFormProperties = product
      ? {
          product: {
            id: product?.id,
            variant: activeProductVariant?.id,
            quantity: productQuantity,
          },
        }
      : {
          offerPricePlan: checkedPricePlanId,
        }

    setLoading(true)

    try {
      return await paymentMethodHandler(
        calculatedFormProperties,
        pageId,
        purchaseProcessId,
      )
    } finally {
      setLoading(false)
    }
  }

  return { errors, setErrors, isLoading, submit }
}
