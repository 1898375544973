import PropTypes from 'prop-types'
import React, { forwardRef } from 'react'
import ToolTip from './ui/ActionsBoxItemTooltipUi'
import ActionsBoxItemUi from './ui/ActionsBoxItemUi'

const ActionsBoxItem = (
  { backgroundColor, column = false, onClick, className, children },
  ref,
) => {
  return (
    <ActionsBoxItemUi
      ref={ref}
      backgroundColor={backgroundColor}
      onClick={onClick}
    >
      <span className={className} />
      <ToolTip backgroundColor={backgroundColor} column={column}>
        {children}
      </ToolTip>
    </ActionsBoxItemUi>
  )
}

const ForwardedActionsBoxItem = forwardRef(ActionsBoxItem)

ForwardedActionsBoxItem.propTypes = {
  onClick: PropTypes.func.isRequired,
  column: PropTypes.bool,
  className: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    .isRequired,
}

export default ForwardedActionsBoxItem
