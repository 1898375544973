import React from 'react'
import EntityTypeEnum from 'common/enums/entityTypeEnum'
import { MasterBlockId } from 'common/types/entities/EntityInterface'
import { VideoInterface } from 'common/types/entities/VideoInterface'
import { useFiles } from 'client/store'
import selectors from 'client/store/files/filesSelectors'
import { EntityProps } from '../../../types'
import { generateBaseEntity } from '../../../utils/createStructureUtils'
import BaseEntityNew from '../../core/BaseEntity/BaseEntityNew'
import BasePlayer from './BasePlayer'
import EmbedPlayerUi from './ui/EmbedPlayerUi'
import VideoPlaceholderInner from './ui/VideoPlaceholderInnerUi'
import VideoPlaceholder from './ui/VideoPlaceholderUi'

function Video({
  entity,
  isMoveDownAllowed,
  isMoveUpAllowed,
}: EntityProps<VideoInterface>) {
  const file = useFiles(files => selectors.getFileById(files, entity.fileId))
  const posterFile = useFiles(files =>
    selectors.getFileById(files, entity.posterFileId),
  )

  if (entity.embedCode) {
    return (
      <BaseEntityNew
        entity={entity}
        isMoveDownAllowed={isMoveDownAllowed}
        isMoveUpAllowed={isMoveUpAllowed}
      >
        <EmbedPlayerUi
          border={entity.border}
          mobileBorder={entity.mobileBorder}
          boxShadow={entity.boxShadow}
          mobileBoxShadow={entity.mobileBoxShadow}
          id={entity.htmlAttrId}
          dangerouslySetInnerHTML={{ __html: entity.embedCode }}
        />
      </BaseEntityNew>
    )
  }

  let url
  if (file) {
    url = file.path
  } else if (entity.url) {
    url = entity.url
  }

  if (!url) {
    return (
      <BaseEntityNew
        entity={entity}
        isMoveDownAllowed={isMoveDownAllowed}
        isMoveUpAllowed={isMoveUpAllowed}
      >
        <VideoPlaceholder
          border={entity.border}
          mobileBorder={entity.mobileBorder}
          boxShadow={entity.boxShadow}
          mobileBoxShadow={entity.mobileBoxShadow}
        >
          <VideoPlaceholderInner />
        </VideoPlaceholder>
      </BaseEntityNew>
    )
  }

  return (
    <BaseEntityNew
      entity={entity}
      isMoveDownAllowed={isMoveDownAllowed}
      isMoveUpAllowed={isMoveUpAllowed}
    >
      <BasePlayer
        attrId={entity.htmlAttrId}
        url={url}
        posterUrl={posterFile?.path}
        controls={entity.controls}
        border={entity.border}
        mobileBorder={entity.mobileBorder}
        boxShadow={entity.boxShadow}
        mobileBoxShadow={entity.mobileBoxShadow}
        disabledPointerEvents
      />
    </BaseEntityNew>
  )
}

export function createVideo(
  parentId: string,
  masterBlockId?: MasterBlockId,
): VideoInterface {
  return {
    ...generateBaseEntity(EntityTypeEnum.Video, parentId, masterBlockId),
    type: EntityTypeEnum.Video,
    url: 'https://youtu.be/f48bbvvDJFQ',
    border: {
      radius: 8,
    },
  }
}

export default Video
