import React from 'react'
import { createPortal } from 'react-dom'
import Overlay from 'common/components/core/Overlay'
import PaymentCard, { PaymentCardPropsInterface } from '../PaymentCard'
import ModalCloseUi from './ui/ModalCloseUi'
import ModalContentUi from './ui/ModalContentUi'
import ModalUi from './ui/ModalUi'
import ModalWrapperUi from './ui/ModalWrapperUi'

export interface MercadoPagoCardPaymentModalPropsInterface
  extends PaymentCardPropsInterface {
  onClose: () => void
}

export type AsyncMercadoPagoCardPaymentModal = ({
  amount,
  onClose,
  processPayment,
}: MercadoPagoCardPaymentModalPropsInterface) => React.ReactPortal

function PaymentModal({
  amount,
  onClose,
  processPayment,
}: MercadoPagoCardPaymentModalPropsInterface) {
  return createPortal(
    <>
      <ModalWrapperUi>
        <ModalUi>
          <ModalCloseUi onClick={onClose} />
          <ModalContentUi>
            <PaymentCard amount={amount} processPayment={processPayment} />
          </ModalContentUi>
        </ModalUi>
      </ModalWrapperUi>
      <Overlay dark />
    </>,
    document.getElementById('mercado-pago-dialog') as HTMLElement,
  )
}

export default PaymentModal
