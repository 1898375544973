import React from 'react'
import CommonCustomerType from 'common/components/entities/CustomerType/CustomerTypeNew'
import EntityTypeEnum from 'common/enums/entityTypeEnum'
import { CustomerTypeInterface } from 'common/types/entities/CustomerTypeInteface'
import { MasterBlockId } from 'common/types/entities/EntityInterface'
import BaseEntityNew from 'client/components/core/BaseEntity/BaseEntityNew'
import { generateBaseEntity } from 'client/utils/createStructureUtils'

interface CustomerTypeProps {
  entity: CustomerTypeInterface
  isMoveDownAllowed: boolean
  isMoveUpAllowed: boolean
}

function CustomerType({
  entity,
  isMoveDownAllowed,
  isMoveUpAllowed,
}: CustomerTypeProps) {
  return (
    <BaseEntityNew
      entity={entity}
      isMoveUpAllowed={isMoveUpAllowed}
      isMoveDownAllowed={isMoveDownAllowed}
    >
      <CommonCustomerType />
    </BaseEntityNew>
  )
}
export default CustomerType

export function createCustomerType(
  parentId: string,
  masterBlockId?: MasterBlockId,
) {
  return {
    ...generateBaseEntity(EntityTypeEnum.CustomerType, parentId, masterBlockId),
    type: EntityTypeEnum.CustomerType,
  }
}
