import React from 'react'
import CommonBlogPostListing from 'common/components/entities/BlogPostListing/BlogPostListingsNew'
import {
  clientBlogPostTs,
  clientImagePlaceholderSrc,
  rangePosts,
} from 'common/components/entities/BlogPostsNew/utils'
import { PageLocaleEnum } from 'common/enums/PageLocaleEnum'
import EntityTypeEnum from 'common/enums/entityTypeEnum'
import { BlogPostListingInterface } from 'common/types/entities/BlogPostListingInterface'
import { MasterBlockId } from 'common/types/entities/EntityInterface'
import { ImagePlacementVariants } from 'common/types/entities/LatestBlogPostsCarouselInterface'
import { LatestBlogPostsInterface } from 'common/types/entities/LatestBlogPostsInterface'
import BaseEntityNew from 'client/components/core/BaseEntity/BaseEntityNew'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'
import { typedPageSelectors, usePage } from 'client/store'
import { generateBaseEntity } from 'client/utils/createStructureUtils'
import { EntityProps } from '../../../types'

function BlogPostListing({
  entity,
  isMoveDownAllowed,
  isMoveUpAllowed,
}: EntityProps<BlogPostListingInterface>) {
  const locale = usePage(page => typedPageSelectors.getPageLocale(page))
  const { isMobile } = useDeviceModeContext()

  return (
    <BaseEntityNew
      entity={entity}
      isMoveUpAllowed={isMoveUpAllowed}
      isMoveDownAllowed={isMoveDownAllowed}
    >
      <CommonBlogPostListing
        htmlAttrId={entity.htmlAttrId}
        displayHorizontally={isMobile ? false : entity.displayHorizontally}
        isMobile={isMobile}
        imagePlacement={entity.imagePlacement}
        mobileImagePlacement={entity.mobileImagePlacement}
        layoutType={entity.layoutType}
        locale={locale as PageLocaleEnum}
        paginationCurrent={1}
        paginationTotal={2}
        posts={rangePosts(
          entity.maxPosts,
          clientBlogPostTs,
          clientImagePlaceholderSrc,
        )}
        maxPostsPerRow={entity.maxPostsPerRow}
        showDate={entity.showDate}
        padding={entity.padding}
        mobilePadding={entity.mobilePadding}
        imageMargin={entity.imageMargin}
        mobileImageMargin={entity.mobileImageMargin}
        imageBorder={entity.imageBorder}
        mobileImageBorder={entity.mobileImageBorder}
        imageBoxShadow={entity.imageBoxShadow}
        mobileImageBoxShadow={entity.mobileImageBoxShadow}
        titleMargin={entity.titleMargin}
        mobileTitleMargin={entity.mobileTitleMargin}
        titleFontSize={entity.titleFontSize}
        mobileTitleFontSize={entity.mobileTitleFontSize}
        titleLineHeight={entity.titleLineHeight}
        mobileTitleLineHeight={entity.mobileTitleLineHeight}
        titleFontFamily={entity.titleFontFamily}
        titleFontWeight={entity.titleFontWeight}
        titleFontStyle={entity.titleFontStyle}
        mobileTitleFontFamily={entity.mobileTitleFontFamily}
        mobileTitleFontWeight={entity.mobileTitleFontWeight}
        mobileTitleFontStyle={entity.mobileTitleFontStyle}
        titleLetterSpacing={entity.titleLetterSpacing}
        mobileTitleLetterSpacing={entity.mobileTitleLetterSpacing}
        titleAlign={entity.titleAlign}
        mobileTitleAlign={entity.mobileTitleAlign}
        titleColor={entity.titleColor}
        mobileTitleColor={entity.mobileTitleColor}
        descriptionMargin={entity.descriptionMargin}
        mobileDescriptionMargin={entity.mobileDescriptionMargin}
        descriptionFontSize={entity.descriptionFontSize}
        mobileDescriptionFontSize={entity.mobileDescriptionFontSize}
        descriptionLineHeight={entity.descriptionLineHeight}
        mobileDescriptionLineHeight={entity.mobileDescriptionLineHeight}
        descriptionFontFamily={entity.descriptionFontFamily}
        descriptionFontWeight={entity.descriptionFontWeight}
        descriptionFontStyle={entity.descriptionFontStyle}
        mobileDescriptionFontFamily={entity.mobileDescriptionFontFamily}
        mobileDescriptionFontWeight={entity.mobileDescriptionFontWeight}
        mobileDescriptionFontStyle={entity.mobileDescriptionFontStyle}
        descriptionAlign={entity.descriptionAlign}
        mobileDescriptionAlign={entity.mobileDescriptionAlign}
        descriptionColor={entity.descriptionColor}
        mobileDescriptionColor={entity.mobileDescriptionColor}
        categoriesMargin={entity.categoriesMargin}
        mobileCategoriesMargin={entity.mobileCategoriesMargin}
        categoriesFontSize={entity.categoriesFontSize}
        mobileCategoriesFontSize={entity.mobileCategoriesFontSize}
        categoriesFontFamily={entity.categoriesFontFamily}
        categoriesFontWeight={entity.categoriesFontWeight}
        categoriesFontStyle={entity.categoriesFontStyle}
        mobileCategoriesFontFamily={entity.mobileCategoriesFontFamily}
        mobileCategoriesFontWeight={entity.mobileCategoriesFontWeight}
        mobileCategoriesFontStyle={entity.mobileCategoriesFontStyle}
        categoriesAlign={entity.categoriesAlign}
        mobileCategoriesAlign={entity.mobileCategoriesAlign}
        categoriesColor={entity.categoriesColor}
        mobileCategoriesColor={entity.mobileCategoriesColor}
        dateMargin={entity.dateMargin}
        mobileDateMargin={entity.mobileDateMargin}
        dateFontSize={entity.dateFontSize}
        mobileDateFontSize={entity.mobileDateFontSize}
        dateFontFamily={entity.dateFontFamily}
        dateFontWeight={entity.dateFontWeight}
        dateFontStyle={entity.dateFontStyle}
        mobileDateFontFamily={entity.mobileDateFontFamily}
        mobileDateFontWeight={entity.mobileDateFontWeight}
        mobileDateFontStyle={entity.mobileDateFontStyle}
        dateAlign={entity.dateAlign}
        mobileDateAlign={entity.mobileDateAlign}
        dateColor={entity.dateColor}
        mobileDateColor={entity.mobileDateColor}
        paginationActiveColor={entity.paginationActiveColor}
        mobilePaginationActiveColor={entity.mobilePaginationActiveColor}
        paginationActiveBackgroundColor={entity.paginationActiveBackgroundColor}
        mobilePaginationActiveBackgroundColor={
          entity.mobilePaginationActiveBackgroundColor
        }
        paginationInactiveColor={entity.paginationInactiveColor}
        mobilePaginationInactiveColor={entity.mobilePaginationInactiveColor}
        paginationInactiveBackgroundColor={
          entity.paginationInactiveBackgroundColor
        }
        mobilePaginationInactiveBackgroundColor={
          entity.mobilePaginationInactiveBackgroundColor
        }
        rowsGap={entity.rowsGap}
        columnsGap={entity.columnsGap}
        postCard={entity.postCard}
      />
    </BaseEntityNew>
  )
}

export function createBlogPostListing(
  parentId: string,
  masterBlockId?: MasterBlockId,
): BlogPostListingInterface {
  return {
    ...generateBaseEntity(
      EntityTypeEnum.BlogPostListing,
      parentId,
      masterBlockId,
    ),
    type: EntityTypeEnum.BlogPostListing,
    maxPosts: 6,
    maxPostsPerRow: 3,
    rowsGap: 30,
    columnsGap: 30,
    showDate: false,
    displayHorizontally: false,
    titleFontSize: 26,
    mobileTitleFontSize: 22,
    titleColor: 'rgba(20, 45, 99, 1)',
    titleAlign: 'left',
    categoriesFontSize: 18,
    categoriesColor: 'rgba(27, 160, 255, 1)',
    categoriesAlign: 'left',
    descriptionFontSize: 22,
    mobileDescriptionFontSize: 18,
    descriptionColor: 'rgba(100, 101, 126, 1)',
    descriptionAlign: 'left',
    dateFontSize: 18,
    dateColor: 'rgba(20, 45, 99, 1)',
    dateAlign: 'left',
    paginationActiveColor: 'rgba(0, 165, 255, 1)',
    paginationActiveBackgroundColor: 'rgba(255, 255, 255, 1)',
    paginationInactiveColor: 'rgba(85, 95, 110, 1)',
    paginationInactiveBackgroundColor: 'rgba(228, 232, 238, 1)',
    mobileImagePlacement: ImagePlacementVariants.Up,
    imagePlacement: ImagePlacementVariants.Up,
    padding: {
      paddingTop: 0,
      paddingRight: 0,
      paddingBottom: 0,
      paddingLeft: 0,
    },
    mobilePadding: {
      paddingTop: 0,
      paddingRight: 0,
      paddingBottom: 0,
      paddingLeft: 0,
    },
    imageMargin: {
      marginTop: 0,
      marginRight: 0,
      marginBottom: 0,
      marginLeft: 0,
    },
    titleMargin: {
      marginTop: 5,
      marginRight: 0,
      marginBottom: 5,
      marginLeft: 0,
    },
    categoriesMargin: {
      marginTop: 5,
      marginRight: 0,
      marginBottom: 5,
      marginLeft: 0,
    },
    descriptionMargin: {
      marginTop: 5,
      marginRight: 0,
      marginBottom: 5,
      marginLeft: 0,
    },
    dateMargin: {
      marginTop: 10,
      marginRight: 0,
      marginBottom: 10,
      marginLeft: 0,
    },
  } as BlogPostListingInterface
}

export function getNewBlogPostListingFontProperties(
  entity: BlogPostListingInterface | LatestBlogPostsInterface,
) {
  const fontProperties = []

  if (entity.titleFontFamily) {
    fontProperties.push({
      fontFamily: entity.titleFontFamily,
      fontStyle: entity.titleFontStyle,
      fontWeight: entity.titleFontWeight,
    })
  }
  if (entity.mobileTitleFontFamily) {
    fontProperties.push({
      fontFamily: entity.mobileTitleFontFamily,
      fontStyle: entity.mobileTitleFontStyle,
      fontWeight: entity.mobileTitleFontWeight,
    })
  }
  if (entity.categoriesFontFamily) {
    fontProperties.push({
      fontFamily: entity.categoriesFontFamily,
      fontWeight: entity.categoriesFontWeight,
      fontStyle: entity.categoriesFontStyle,
    })
  }
  if (entity.mobileCategoriesFontFamily) {
    fontProperties.push({
      fontFamily: entity.mobileCategoriesFontFamily,
      fontWeight: entity.mobileCategoriesFontWeight,
      fontStyle: entity.mobileCategoriesFontStyle,
    })
  }
  if (entity.descriptionFontFamily) {
    fontProperties.push({
      fontFamily: entity.descriptionFontFamily,
      fontWeight: entity.descriptionFontWeight,
      fontStyle: entity.descriptionFontStyle,
    })
  }
  if (entity.mobileDescriptionFontFamily) {
    fontProperties.push({
      fontFamily: entity.mobileDescriptionFontFamily,
      fontWeight: entity.mobileDescriptionFontWeight,
      fontStyle: entity.mobileDescriptionFontStyle,
    })
  }
  if (entity.dateFontFamily) {
    fontProperties.push({
      fontFamily: entity.dateFontFamily,
      fontWeight: entity.dateFontWeight,
      fontStyle: entity.dateFontStyle,
    })
  }
  if (entity.mobileDateFontFamily) {
    fontProperties.push({
      fontFamily: entity.mobileDateFontFamily,
      fontWeight: entity.mobileDateFontWeight,
      fontStyle: entity.mobileDateFontStyle,
    })
  }
  return fontProperties
}

export function getNewBlogPostListingCustomFontProperties(
  entity: BlogPostListingInterface | LatestBlogPostsInterface,
) {
  const fontProperties = []

  if (entity.titleFontFamily) {
    fontProperties.push({
      fontFamily: entity.titleFontFamily,
      fontFileId: entity.titleFontFileId,
    })
  }
  if (entity.mobileTitleFontFamily) {
    fontProperties.push({
      fontFamily: entity.mobileTitleFontFamily,
      fontFileId: entity.mobileTitleFontFileId,
    })
  }
  if (entity.categoriesFontFamily) {
    fontProperties.push({
      fontFamily: entity.categoriesFontFamily,
      fontFileId: entity.categoriesFontFileId,
    })
  }
  if (entity.mobileCategoriesFontFamily) {
    fontProperties.push({
      fontFamily: entity.mobileCategoriesFontFamily,
      fontFileId: entity.mobileCategoriesFontFileId,
    })
  }
  if (entity.descriptionFontFamily) {
    fontProperties.push({
      fontFamily: entity.descriptionFontFamily,
      fontFileId: entity.descriptionFontFileId,
    })
  }
  if (entity.mobileDescriptionFontFamily) {
    fontProperties.push({
      fontFamily: entity.mobileDescriptionFontFamily,
      fontFileId: entity.mobileDescriptionFontFileId,
    })
  }
  if (entity.dateFontFamily) {
    fontProperties.push({
      fontFamily: entity.dateFontFamily,
      fontFileId: entity.dateFontFileId,
    })
  }
  if (entity.mobileDateFontFamily) {
    fontProperties.push({
      fontFamily: entity.mobileDateFontFamily,
      fontFileId: entity.mobileDateFontFileId,
    })
  }
  return fontProperties
}

export default BlogPostListing
