import React, { createContext, useContext, useState } from 'react'

type SetRowResizingContext = (value: boolean) => void

const ResizingRowContext = createContext<boolean>(false)
const SetRowResizingContext = createContext<SetRowResizingContext>(() => {})

export const useSetRowResizing = () => useContext(SetRowResizingContext)
export const useIsRowResizing = () => useContext(ResizingRowContext)

export function ResizingRowProvider(
  props: React.PropsWithChildren<React.ReactNode>,
) {
  const [isResizing, setIsResizing] = useState(false)

  return (
    <ResizingRowContext.Provider value={isResizing}>
      <SetRowResizingContext.Provider value={setIsResizing}>
        {props.children}
      </SetRowResizingContext.Provider>
    </ResizingRowContext.Provider>
  )
}
