import React, { memo } from 'react'
import CommonSection from 'common/components/entities/Section'
import { SectionInterface } from 'common/components/entities/Section/types/SectionInterface'
import { commonWidthValues } from 'common/constants/commonWidth'
import { color } from 'client/components/core/BaseEntity'
import BaseEntityNew from 'client/components/core/BaseEntity/BaseEntityNew'
import ChildrenEntities from 'client/components/core/ChildrenEntities'
import { useBackgroundImage } from 'client/hooks/useBackgroundImage'

interface SectionProps {
  entity: SectionInterface
  isMoveUpAllowed: boolean
  isMoveDownAllowed: boolean
}

const Section = ({
  entity,
  isMoveUpAllowed,
  isMoveDownAllowed,
}: SectionProps) => {
  const { backgroundImage, mobileBackgroundImage } = useBackgroundImage({
    backgroundFileId: entity.backgroundFileId,
    mobileBackgroundFileId: entity.mobileBackgroundFileId,
  })
  return (
    <BaseEntityNew
      entity={entity}
      highlightedColor={color.green}
      hoverColor={color.green}
      isMoveUpAllowed={isMoveUpAllowed}
      isMoveDownAllowed={isMoveDownAllowed}
    >
      <CommonSection
        backgroundImage={backgroundImage}
        mobileBackgroundImage={mobileBackgroundImage}
        htmlAttrId={entity.htmlAttrId}
        backgroundColor={entity.backgroundColor}
        mobileBackgroundColor={entity.mobileBackgroundColor}
        padding={entity.padding}
        mobilePadding={entity.mobilePadding}
        boxShadow={entity.boxShadow}
        mobileBoxShadow={entity.mobileBoxShadow}
        width={commonWidthValues[entity.contentWidth]}
        containerBackgroundColor={entity.containerBackgroundColor}
        mobileContainerBackgroundColor={entity.mobileContainerBackgroundColor}
        background={entity.background}
      >
        <ChildrenEntities
          id={entity.id}
          childIds={entity.childIds}
          type={entity.type}
          isReadOnly={entity.isReadOnly}
        />
      </CommonSection>
    </BaseEntityNew>
  )
}

export default memo(Section)
