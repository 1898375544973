import React from 'react'
import ProductLabelUi from 'common/components/entities/Product/ui/ProductLabelUi'
import ProductOptionValueContainerUi from 'common/components/entities/Product/ui/ProductOptionValueContainerUi'
import ProductOptionValuesContainerUi from 'common/components/entities/Product/ui/ProductOptionValuesContainerUi'
import ProductOptionsContainerUi from 'common/components/entities/Product/ui/ProductOptionsContainerUi'
import {
  ProductOptionInterface,
  ProductVariantInterface,
} from 'common/types/entities/ProductInterface'

interface ProductOptionsProps {
  options: ProductOptionInterface[]
  activeVariant: ProductVariantInterface
}

function ProductOptions({ options, activeVariant }: ProductOptionsProps) {
  return (
    <>
      {options.map(option => (
        <ProductOptionsContainerUi key={option.id}>
          <ProductLabelUi>{option.name}</ProductLabelUi>
          <ProductOptionValuesContainerUi>
            {option.optionValues.map(optionValue => (
              <ProductOptionValueContainerUi
                key={optionValue.id}
                active={activeVariant.optionValueIds.includes(optionValue.id)}
              >
                {optionValue.value}
              </ProductOptionValueContainerUi>
            ))}
          </ProductOptionValuesContainerUi>
        </ProductOptionsContainerUi>
      ))}
    </>
  )
}

export default ProductOptions
