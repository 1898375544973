import React from 'react'
import PropTypes from 'prop-types'
import * as CommonTwoStepPaymentForm from 'common/components/entities/TwoStepPaymentForm'
import {
  STEP_OPT_IN,
  STEP_PAYMENT,
} from 'common/components/entities/TwoStepPaymentForm'
import BaseEntity from 'client/components/core/BaseEntity'
import usePresentPage from 'client/hooks/usePresentPage'
import { getEntityById } from 'client/reducers/pageReducer'
import useManagement, { selectors } from 'client/hooks/useManagement'
import OptInStep from './OptInStep'
import PaymentStep from './PaymentStep'

function TwoStepPaymentForm({ entity, isMoveDownAllowed, isMoveUpAllowed }) {
  const stepType = useManagement(selectors.getTwoStepPaymentFormStepType)
  const isPaymentStep = stepType === STEP_PAYMENT
  const isOptInStep = stepType === STEP_OPT_IN
  const stepOptIn = usePresentPage(page =>
    getEntityById(page, entity.childIds[0]),
  )
  const stepPayment = usePresentPage(page =>
    getEntityById(page, entity.childIds[1]),
  )

  return (
    <BaseEntity
      entity={entity}
      isMoveUpAllowed={isMoveUpAllowed}
      isMoveDownAllowed={isMoveDownAllowed}
    >
      <CommonTwoStepPaymentForm.Form
        styles={entity.styles}
        mobileStyles={{
          ...entity.styles,
          ...entity.mobileStyles,
        }}
        id={entity.options.attrId}
      >
        <CommonTwoStepPaymentForm.Header>
          <CommonTwoStepPaymentForm.HeaderItem
            number={1}
            styles={{
              backgroundColor: entity.options.headerBackgroundColor,
              color: entity.options.headerTextColor,
            }}
            mobileStyles={{
              backgroundColor: entity.mobileOptions.headerBackgroundColor,
              color: entity.mobileOptions.headerTextColor,
            }}
            headline={stepOptIn.options.headline}
            subHeadline={stepOptIn.options.subHeadline}
            active={isOptInStep}
            headerUnderlineColor={entity.options.headerUnderlineColor}
          />
          <CommonTwoStepPaymentForm.HeaderItem
            number={2}
            styles={{
              backgroundColor: entity.options.headerBackgroundColor,
              color: entity.options.headerTextColor,
            }}
            mobileStyles={{
              backgroundColor: entity.mobileOptions.headerBackgroundColor,
              color: entity.mobileOptions.headerTextColor,
            }}
            headline={stepPayment.options.headline}
            subHeadline={stepPayment.options.subHeadline}
            active={isPaymentStep}
            headerUnderlineColor={entity.options.headerUnderlineColor}
          />
        </CommonTwoStepPaymentForm.Header>
        <CommonTwoStepPaymentForm.Content
          styles={{ padding: entity.options.contentPadding }}
          mobileStyles={{ padding: entity.mobileOptions.contentPadding }}
        >
          {isOptInStep && (
            <OptInStep
              entity={stepOptIn}
              buttonStyles={{
                color: entity.options.buttonTextColor,
                backgroundColor: entity.options.buttonBackgroundColor,
              }}
              mobileButtonStyles={{
                color: entity.mobileOptions.buttonTextColor,
                backgroundColor: entity.mobileOptions.buttonBackgroundColor,
              }}
            />
          )}
          {isPaymentStep && (
            <PaymentStep
              entity={stepPayment}
              buttonStyles={{
                color: entity.options.buttonTextColor,
                backgroundColor: entity.options.buttonBackgroundColor,
              }}
              mobileButtonStyles={{
                color: entity.mobileOptions.buttonTextColor,
                backgroundColor: entity.mobileOptions.buttonBackgroundColor,
              }}
            />
          )}
        </CommonTwoStepPaymentForm.Content>
      </CommonTwoStepPaymentForm.Form>
    </BaseEntity>
  )
}

TwoStepPaymentForm.propTypes = {
  entity: PropTypes.shape({
    styles: PropTypes.shape({
      marginTop: PropTypes.string.isRequired,
      marginRight: PropTypes.string.isRequired,
      marginBottom: PropTypes.string.isRequired,
      marginLeft: PropTypes.string.isRequired,
      borderTopLeftRadius: PropTypes.string.isRequired,
      borderTopRightRadius: PropTypes.string.isRequired,
      borderBottomRightRadius: PropTypes.string.isRequired,
      borderBottomLeftRadius: PropTypes.string.isRequired,
      borderStyle: PropTypes.string.isRequired,
      borderWidth: PropTypes.string.isRequired,
      borderColor: PropTypes.string.isRequired,
      backgroundColor: PropTypes.string.isRequired,
    }),
    mobileStyles: PropTypes.shape({
      marginTop: PropTypes.string,
      marginRight: PropTypes.string,
      marginBottom: PropTypes.string,
      marginLeft: PropTypes.string,
      borderTopLeftRadius: PropTypes.string,
      borderTopRightRadius: PropTypes.string,
      borderBottomRightRadius: PropTypes.string,
      borderBottomLeftRadius: PropTypes.string,
      borderStyle: PropTypes.string,
      borderWidth: PropTypes.string,
      borderColor: PropTypes.string,
      backgroundColor: PropTypes.string,
    }),
    options: PropTypes.shape({
      attrId: PropTypes.string.isRequired,
      headerUnderlineColor: PropTypes.string.isRequired,
      headerBackgroundColor: PropTypes.string.isRequired,
      headerTextColor: PropTypes.string.isRequired,
      buttonBackgroundColor: PropTypes.string.isRequired,
      buttonTextColor: PropTypes.string.isRequired,
      contentPadding: PropTypes.string.isRequired,
    }).isRequired,
    mobileOptions: PropTypes.shape({
      headerBackgroundColor: PropTypes.string,
      headerTextColor: PropTypes.string,
      headerUnderlineColor: PropTypes.string,
      buttonBackgroundColor: PropTypes.string,
      buttonTextColor: PropTypes.string,
      contentPadding: PropTypes.string,
    }),
    childIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  isMoveUpAllowed: PropTypes.bool.isRequired,
  isMoveDownAllowed: PropTypes.bool.isRequired,
}

export default TwoStepPaymentForm
