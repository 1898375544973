import React from 'react'
import { useTranslation } from 'react-i18next'
import Coupon from 'common/components/entities/OfferPriceNew/Coupon'
import CurrencyBadgeUi from 'common/components/entities/OfferPriceNew/ui/CurrencyBadgeUi'
import PricePlanAmountUi from 'common/components/entities/OfferPriceNew/ui/PricePlanAmountUi'
import PricePlanItemUi from 'common/components/entities/OfferPriceNew/ui/PricePlanItemUi'
import PricePlanUi from 'common/components/entities/OfferPriceNew/ui/PricePlanUi'
import { Coupon as CouponType } from 'common/types/CouponType'
import {
  OneShotPlan,
  PricePlan as PricePlanType,
} from 'common/types/OfferInterface'
import { OfferPricing } from 'common/types/pricingTypes'
import { centsToEuro, getReadablePrice } from 'common/utils/priceCalculator'
import PricePlanNameUi from './ui/PricePlanNameUi'

export interface OneShotsSummaryProps {
  plan: PricePlanType
  bumpPlan?: OneShotPlan
  activePlanPricing?: OfferPricing
  bumpPlanPricing?: OfferPricing
  quantity: number
  isVatNotChargeable: boolean
  coupon?: CouponType
  locale: string
  amountFontFamily?: string
  mobileAmountFontFamily?: string
  amountFontSize: number
  mobileAmountFontSize?: number
  amountFontStyle?: string
  mobileAmountFontStyle?: string
  amountFontWeight?: string
  mobileAmountFontWeight?: string
  amountLineHeight: number
  mobileAmountLineHeight?: number
  amountColor: string
  mobileAmountColor?: string
  fontFamily?: string
  mobileFontFamily?: string
  fontSize: number
  mobileFontSize?: number
  fontStyle?: string
  mobileFontStyle?: string
  fontWeight?: string
  mobileFontWeight?: string
  lineHeight: number
  mobileLineHeight?: number
  color: string
  mobileColor?: string
}

const OneShotsSummary = ({
  plan,
  activePlanPricing,
  bumpPlanPricing,
  isVatNotChargeable,
  coupon,
  locale,
  amountFontSize,
  mobileAmountFontSize,
  amountFontStyle,
  mobileAmountFontStyle,
  amountFontWeight,
  mobileAmountFontWeight,
  amountFontFamily,
  mobileAmountFontFamily,
  amountLineHeight,
  mobileAmountLineHeight,
  amountColor,
  mobileAmountColor,
  fontSize,
  mobileFontSize,
  fontStyle,
  mobileFontStyle,
  fontWeight,
  mobileFontWeight,
  fontFamily,
  mobileFontFamily,
  lineHeight,
  mobileLineHeight,
  color,
  mobileColor,
}: OneShotsSummaryProps) => {
  const { t } = useTranslation(['common', 'publisher'])
  const currency = plan.currency
  const totalGrossAmount =
    (activePlanPricing?.grossAmount ?? 0) + (bumpPlanPricing?.grossAmount ?? 0)
  const totalNetAmount =
    (activePlanPricing?.netAmount ?? 0) + (bumpPlanPricing?.netAmount ?? 0)
  const totalTaxAmount =
    (activePlanPricing?.taxAmount ?? 0) + (bumpPlanPricing?.taxAmount ?? 0)
  const totalDiscountAmount =
    (activePlanPricing?.discountAmount ?? 0) +
    (bumpPlanPricing?.discountAmount ?? 0)

  const readableTotalAmountWithTax = getReadablePrice(
    centsToEuro(totalGrossAmount),
    currency,
    locale,
  )

  const readableTotalAmountWithoutTaxAndDiscount = getReadablePrice(
    centsToEuro(totalNetAmount),
    currency,
    locale,
  )

  const readableTotalTax = getReadablePrice(
    centsToEuro(totalTaxAmount),
    currency,
    locale,
  )

  return (
    <PricePlanUi>
      <PricePlanItemUi>
        <PricePlanNameUi
          fontSize={fontSize}
          mobileFontSize={mobileFontSize}
          fontStyle={fontStyle}
          mobileFontStyle={mobileFontStyle}
          fontWeight={fontWeight}
          mobileFontWeight={mobileFontWeight}
          fontFamily={fontFamily}
          mobileFontFamily={mobileFontFamily}
          lineHeight={lineHeight}
          mobileLineHeight={mobileLineHeight}
          color={color}
          mobileColor={mobileColor}
          data-test-id={`offer-price-one-shot-${plan.id}`}
        >
          {/*
      since there are no taxes it could be confusing for customers
      they could wonder if they will end up paying VAT on top of that
      several users raised this issue
      and I think it makes sense
      so if users selected "Not charge VAT on their payment pages"
      then I propose we display:
      Total T.T.C.
      */}
          {t(
            isVatNotChargeable
              ? 'components.offer_price.one_shots_summary.total_amount_with_tax' // @see why https://systeme-team.slack.com/archives/G61JS7GDC/p1566808055000200
              : 'components.offer_price.one_shots_summary.total_amount',
          )}
        </PricePlanNameUi>
        <PricePlanAmountUi
          fontSize={amountFontSize}
          mobileFontSize={mobileAmountFontSize}
          fontStyle={amountFontStyle}
          mobileFontStyle={mobileAmountFontStyle}
          fontWeight={amountFontWeight}
          mobileFontWeight={mobileAmountFontWeight}
          fontFamily={amountFontFamily}
          mobileFontFamily={mobileAmountFontFamily}
          lineHeight={amountLineHeight}
          mobileLineHeight={mobileAmountLineHeight}
          color={amountColor}
          mobileColor={mobileAmountColor}
        >
          <CurrencyBadgeUi color={amountColor} mobileColor={mobileAmountColor}>
            {currency}
          </CurrencyBadgeUi>
          {readableTotalAmountWithoutTaxAndDiscount}
        </PricePlanAmountUi>
      </PricePlanItemUi>
      {coupon && (
        <Coupon
          coupon={coupon}
          discountAmount={totalDiscountAmount}
          locale={locale}
          amountFontSize={amountFontSize}
          mobileAmountFontSize={mobileAmountFontSize}
          amountFontStyle={amountFontStyle}
          mobileAmountFontStyle={mobileAmountFontStyle}
          amountFontWeight={amountFontWeight}
          mobileAmountFontWeight={mobileAmountFontWeight}
          amountFontFamily={amountFontFamily}
          mobileAmountFontFamily={mobileAmountFontFamily}
          amountLineHeight={amountLineHeight}
          mobileAmountLineHeight={mobileAmountLineHeight}
          amountColor={amountColor}
          mobileAmountColor={amountColor}
          fontSize={fontSize}
          mobileFontSize={mobileFontSize}
          fontStyle={fontStyle}
          mobileFontStyle={mobileFontStyle}
          fontWeight={fontWeight}
          mobileFontWeight={mobileFontWeight}
          fontFamily={fontFamily}
          mobileFontFamily={mobileFontFamily}
          lineHeight={lineHeight}
          mobileLineHeight={mobileLineHeight}
          color={color}
          mobileColor={mobileColor}
        />
      )}
      {totalTaxAmount > 0 && (
        <React.Fragment>
          <PricePlanItemUi>
            <PricePlanNameUi
              fontSize={fontSize}
              mobileFontSize={mobileFontSize}
              fontStyle={fontStyle}
              mobileFontStyle={mobileFontStyle}
              fontWeight={fontWeight}
              mobileFontWeight={mobileFontWeight}
              fontFamily={fontFamily}
              mobileFontFamily={mobileFontFamily}
              lineHeight={lineHeight}
              mobileLineHeight={mobileLineHeight}
              color={color}
              data-test-id={`offer-price-one-shot-tax-${plan.id}`}
            >
              {t('components.offer_price.one_shots_summary.total_tax')}
            </PricePlanNameUi>
            <PricePlanAmountUi
              fontSize={amountFontSize}
              mobileFontSize={mobileAmountFontSize}
              fontStyle={amountFontStyle}
              mobileFontStyle={mobileAmountFontStyle}
              fontWeight={amountFontWeight}
              mobileFontWeight={mobileAmountFontWeight}
              fontFamily={amountFontFamily}
              mobileFontFamily={mobileAmountFontFamily}
              lineHeight={amountLineHeight}
              mobileLineHeight={mobileAmountLineHeight}
              color={amountColor}
              mobileColor={mobileAmountColor}
            >
              <CurrencyBadgeUi
                color={amountColor}
                mobileColor={mobileAmountColor}
              >
                {currency}
              </CurrencyBadgeUi>
              {readableTotalTax}
            </PricePlanAmountUi>
          </PricePlanItemUi>
          <PricePlanItemUi>
            <PricePlanNameUi
              fontSize={fontSize}
              mobileFontSize={mobileFontSize}
              fontStyle={fontStyle}
              mobileFontStyle={mobileFontStyle}
              fontWeight={fontWeight}
              mobileFontWeight={mobileFontWeight}
              fontFamily={fontFamily}
              mobileFontFamily={mobileFontFamily}
              lineHeight={lineHeight}
              mobileLineHeight={mobileLineHeight}
              color={color}
              mobileColor={mobileColor}
              data-test-id={`offer-price-one-shot-total-amount-with-tax-${plan.id}`}
            >
              {t(
                'components.offer_price.one_shots_summary.total_amount_with_tax',
              )}
            </PricePlanNameUi>
            <PricePlanAmountUi
              fontSize={amountFontSize}
              mobileFontSize={mobileAmountFontSize}
              fontStyle={amountFontStyle}
              mobileFontStyle={mobileAmountFontStyle}
              fontWeight={amountFontWeight}
              mobileFontWeight={mobileAmountFontWeight}
              fontFamily={amountFontFamily}
              mobileFontFamily={mobileAmountFontFamily}
              lineHeight={amountLineHeight}
              mobileLineHeight={mobileAmountLineHeight}
              color={amountColor}
              mobileColor={mobileAmountColor}
            >
              <CurrencyBadgeUi
                color={amountColor}
                mobileColor={mobileAmountColor}
              >
                {currency}
              </CurrencyBadgeUi>
              {readableTotalAmountWithTax}
            </PricePlanAmountUi>
          </PricePlanItemUi>
        </React.Fragment>
      )}
      {coupon && !totalTaxAmount && (
        <>
          <PricePlanNameUi
            fontSize={fontSize}
            mobileFontSize={mobileFontSize}
            fontStyle={fontStyle}
            mobileFontStyle={mobileFontStyle}
            fontWeight={fontWeight}
            mobileFontWeight={mobileFontWeight}
            fontFamily={fontFamily}
            mobileFontFamily={mobileFontFamily}
            lineHeight={lineHeight}
            mobileLineHeight={mobileLineHeight}
            color={color}
            mobileColor={mobileColor}
            data-test-id={`offer-price-one-shot-total-amount-with-coupon-${plan.id}`}
          >
            {t(
              'components.offer_price.one_shots_summary.total_amount_with_coupon',
            )}
          </PricePlanNameUi>
          <PricePlanAmountUi
            fontSize={amountFontSize}
            mobileFontSize={mobileAmountFontSize}
            fontStyle={amountFontStyle}
            mobileFontStyle={mobileAmountFontStyle}
            fontWeight={amountFontWeight}
            mobileFontWeight={mobileAmountFontWeight}
            fontFamily={amountFontFamily}
            mobileFontFamily={mobileAmountFontFamily}
            lineHeight={amountLineHeight}
            mobileLineHeight={mobileAmountLineHeight}
            color={amountColor}
            mobileColor={mobileAmountColor}
          >
            <CurrencyBadgeUi
              color={amountColor}
              mobileColor={mobileAmountColor}
            >
              {currency}
            </CurrencyBadgeUi>
            {readableTotalAmountWithTax}
          </PricePlanAmountUi>
        </>
      )}
    </PricePlanUi>
  )
}

export default OneShotsSummary
