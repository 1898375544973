import { getQueryString } from 'common/utils/fetchHelper'
import request from 'client/utils/request'

export const fetchUserFiles = (
  type,
  lastId = null,
  limit = 30,
  search = '',
  cancelToken = '',
) => {
  const searchQuery = getQueryString({ lastId, limit, type, search })
  return request.get(`/files/user?${searchQuery}`, { cancelToken })
}

export const fetchCommonFiles = (
  type,
  lastId = null,
  limit = 30,
  search = '',
  cancelToken = '',
) => {
  const searchQuery = getQueryString({ type, lastId, limit, search })
  return request.get(`/files/common?${searchQuery}`, { cancelToken })
}

export const removeFile = fileId => {
  return request.delete(`/user/file/${fileId}`)
}

export const removeDraftFile = fileId => {
  return request.delete(`/draft-file/${fileId}`)
}

export const validateAndGetUploadOptions = (file, isCommon, mimeType) => {
  return request.post('/files/validate-and-get-upload-options', {
    editor_api_data_file: {
      fileName: file.name,
      fileSize: file.size,
      mimeType: file.type ? file.type : mimeType,
      isCommon,
    },
  })
}

export const activateFile = (fileId, optimize) => {
  return request.post(
    `/files/${fileId}/activate-file`,
    {
      optimize,
    },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  )
}

export async function uploadFile(
  file,
  uploadOptions,
  progressHandler,
  cancelToken,
) {
  const formData = new FormData()
  formData.append('key', uploadOptions.key)
  formData.append('acl', uploadOptions.acl)
  formData.append('X-Amz-Credential', uploadOptions.credential)
  formData.append('X-Amz-Algorithm', uploadOptions.algorithm)
  formData.append('X-Amz-Date', uploadOptions.date)
  formData.append('Policy', uploadOptions.policy)
  formData.append('X-Amz-Signature', uploadOptions.signature)
  formData.append('Content-Type', uploadOptions.contentType)
  formData.append('Cache-Control', uploadOptions.cacheControl)
  formData.append('file', file)

  return await request.post(uploadOptions.url, formData, {
    onUploadProgress: progressHandler,
    cancelToken,
  })
}

export const fetchUserFontFiles = () => {
  return request.get('/font-files/user')
}
