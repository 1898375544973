import styled, { css } from 'styled-components'
import { PaddingType } from 'common/types/styleTypes'

type CustomLoginContainerUiProps = {
  padding: PaddingType
  mobilePadding: PaddingType
}

const CustomLoginContainerUi = styled.div<CustomLoginContainerUiProps>`
  padding-top: ${p => p.padding.paddingTop}px;
  padding-right: ${p => p.padding.paddingRight}px;
  padding-bottom: ${p => p.padding.paddingBottom}px;
  padding-left: ${p => p.padding.paddingLeft}px;
  ${p => p.theme.phone} {
    padding-top: ${p => p.mobilePadding.paddingTop}px;
    padding-right: ${p => p.mobilePadding.paddingRight}px;
    padding-bottom: ${p => p.mobilePadding.paddingBottom}px;
    padding-left: ${p => p.mobilePadding.paddingLeft}px;
  }
  ${p =>
    p.theme.isEditorMobileMode &&
    css`
      padding-top: ${p.mobilePadding.paddingTop}px;
      padding-right: ${p.mobilePadding.paddingRight}px;
      padding-bottom: ${p.mobilePadding.paddingBottom}px;
      padding-left: ${p.mobilePadding.paddingLeft}px;
    `}
`

export default CustomLoginContainerUi
