import sortBy from 'lodash.sortby'
import { FETCH_INPUT_TYPES, SUCCESS } from '../actionTypes'

export default function (state = {}, action) {
  const { type, payload } = action

  switch (type) {
    case FETCH_INPUT_TYPES + SUCCESS:
      return {
        ...state,
        ...payload,
      }
    default:
      return state
  }
}

export const getTypeNameBySlug = (state, slug) =>
  state[slug] && state[slug].name

export const getIdNameListWithEmailAndNameFirst = state =>
  sortBy({ ...state }, ['slug'])
