import React, { MouseEvent } from 'react'
import { CommonTextPropsInterface } from 'common/types/CommonTextPropsInterface'
import { CommonContainerInterface } from 'common/types/entities/EntityInterface'
import { AlignType } from 'common/types/styleTypes'
import OrderBumpArrowUi from './ui/order-bump-arrow-ui'
import OrderBumpCheckBoxUi from './ui/order-bump-check-box-ui'
import OrderBumpTitleUi from './ui/order-bump-title-ui'
import OrderBumpUi from './ui/order-bump-ui'
import OrderBumpWrapperUi from './ui/order-bump-wrapper-ui'

type OrderBumpProps = {
  attrId: string
  title: string
  justifyContent: AlignType
  mobileJustifyContent?: AlignType
  isArrowHidden?: boolean
  toggleCheckbox?: () => void
  isChecked?: boolean
  headerBackgroundColor?: string
  mobileHeaderBackgroundColor?: string
} & CommonContainerInterface &
  CommonTextPropsInterface

function OrderBump(props: React.PropsWithChildren<OrderBumpProps>) {
  const handleCheckboxChange = () => {
    if (props.toggleCheckbox) {
      props.toggleCheckbox()
    }
  }

  const handleToggleByClick = (
    event: MouseEvent<HTMLDivElement | HTMLButtonElement>,
  ): void => {
    event.preventDefault()
    handleCheckboxChange()
  }

  return (
    <OrderBumpWrapperUi
      id={props.attrId}
      border={props.border}
      mobileBorder={props.mobileBorder}
      boxShadow={props.boxShadow}
      mobileBoxShadow={props.mobileBoxShadow}
      padding={props.padding}
      mobilePadding={props.mobilePadding}
      backgroundColor={props.backgroundColor}
      mobileBackgroundColor={props.mobileBackgroundColor}
    >
      <OrderBumpUi
        fontFamily={props.fontFamily}
        fontStyle={props.fontStyle}
        fontSize={props.fontSize}
        fontWeight={props.fontWeight}
        mobileFontFamily={props.mobileFontFamily}
        mobileFontStyle={props.mobileFontStyle}
        mobileFontWeight={props.mobileFontWeight}
        mobileFontSize={props.mobileFontSize}
        color={props.color}
        mobileColor={props.mobileColor}
        backgroundColor={props.headerBackgroundColor}
        mobileBackgroundColor={props.mobileHeaderBackgroundColor}
        justifyContent={props.justifyContent}
        mobileJustifyContent={props.mobileJustifyContent}
      >
        {!props.isArrowHidden && (
          <OrderBumpArrowUi onClick={handleToggleByClick} />
        )}
        <OrderBumpCheckBoxUi
          checked={props.isChecked}
          onChange={handleCheckboxChange}
          type="checkbox"
        />
        <OrderBumpTitleUi onClick={handleToggleByClick}>
          {props.title}
        </OrderBumpTitleUi>
      </OrderBumpUi>
      {props.children}
    </OrderBumpWrapperUi>
  )
}

export default OrderBump
