import styled from 'styled-components'

const FileUploadTitleUi = styled.div`
  font-weight: normal;
  text-transform: uppercase;
  color: #ccc;
  font-size: 30px;
  margin-bottom: 10px;
`

export default FileUploadTitleUi
