import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MarginType } from 'common/types/styleTypes'
import ReadOnlyEntityUi from './ui/ReadOnlyEntityUi'

type ReadOnlyEntityProps = {
  margin: MarginType
  mobileMargin: Partial<MarginType>
  children: React.ReactChild
  styles?: Record<string, string>
  showInDesktopMode?: boolean
  showInMobileMode?: boolean
  flex?: boolean
  flexColumn?: boolean
}

function ReadOnlyEntity({
  children,
  margin,
  mobileMargin,
  styles,
  showInMobileMode = true,
  showInDesktopMode = true,
  flex,
  flexColumn,
}: ReadOnlyEntityProps) {
  const [titleVisible, setTitleVisible] = useState(false)
  const { t } = useTranslation()

  function unHover(e: React.SyntheticEvent) {
    e.stopPropagation()
    setTitleVisible(false)
  }

  function hover(e: React.SyntheticEvent) {
    e.stopPropagation()
    setTitleVisible(true)
  }

  return (
    <ReadOnlyEntityUi
      margin={margin}
      mobileMargin={mobileMargin}
      styles={styles}
      showInMobileMode={showInMobileMode}
      showInDesktopMode={showInDesktopMode}
      onMouseOver={hover}
      onMouseOut={unHover}
      title={titleVisible ? t('components.core.read_only_entity.title') : ''}
      flex={flex}
      flexColumn={flexColumn}
    >
      {children}
    </ReadOnlyEntityUi>
  )
}

export default ReadOnlyEntity
