import { SelectedShipping } from 'common/types/entities/ShippingFeesInterface'
import { ShippingService } from 'common/types/shippingType'

export const doesShippingServicesHaveShippingMethod = (
  shippingServices: ShippingService[],
  selectedShipping: SelectedShipping | null,
) =>
  selectedShipping && shippingServices.some(({ rules }) =>
    rules.some(({ id, shippingConditions }) =>
      id === selectedShipping.ruleId && (!shippingConditions.length || 
        shippingConditions.some(({ id: conditionIdInRule }) => 
          conditionIdInRule === selectedShipping.conditionId))
    )
  )