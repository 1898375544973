//  for client/components/entities/Body/index.js
import React, { memo } from 'react'
import { useDragLayer } from 'react-dnd'
import { CommonBody } from 'common/components/entities/body'
import { BodyInterface } from 'common/types/entities/body-interface'
import ChildrenEntities from 'client/components/core/ChildrenEntities'
import { useBackgroundImage } from 'client/hooks/useBackgroundImage'
import { BackgroundUi } from './ui/background-ui'

interface BodyProps {
  entity: BodyInterface
}

export const Body = memo(({ entity }: BodyProps) => {
  const {
    background,
    backgroundColor,
    mobileBackground,
    mobileBackgroundColor,
    blur,
    id,
    type,
    childIds,
  } = entity

  const { backgroundImage, mobileBackgroundImage } = useBackgroundImage({
    backgroundFileId: entity.backgroundFileId,
    mobileBackgroundFileId: entity.mobileBackgroundFileId,
  })

  const { isDragging } = useDragLayer(monitor => ({
    isDragging: monitor.isDragging(),
    itemType: monitor.getItemType(),
  }))

  return (
    <CommonBody
      mobileBackground={mobileBackground}
      mobileBackgroundColor={mobileBackgroundColor}
      mobileBackgroundImage={mobileBackgroundImage}
      blur={blur ?? 0}
      background={background}
      backgroundColor={backgroundColor}
      backgroundImage={backgroundImage}
      styles={BackgroundUi}
    >
      <ChildrenEntities
        id={id}
        childIds={childIds as never[]}
        type={type}
        isReadOnly={!!entity.isReadOnly}
        expandedDropZone={isDragging}
        placeholderText="entities.body.empty_dropbox_text"
      />
    </CommonBody>
  )
})
