import PropTypes from 'prop-types'
import React, { memo } from 'react'
import CommonOfferPrice from 'common/components/entities/OfferPrice'
import * as types from 'common/types'
import BaseEntity from 'client/components/core/BaseEntity'
import EntityExampleWrapper from 'client/components/core/EntityExampleWrapper'
import { pricePlansStub } from 'client/constants/editorSettings'
import usePayment from 'client/hooks/usePayment'
import i18next from 'client/i18n'
import { getIsFetching, getPricePlans } from 'client/reducers/paymentReducer'
import { usePage } from 'client/store'
import pageSelectors from 'client/store/page/pageSelectors'
import translatePricePlans from 'client/utils/translatePriceStub'

function OfferPrice({ entity, isMoveDownAllowed, isMoveUpAllowed }) {
  const isFetching = usePayment(getIsFetching)
  const pricePlans = usePayment(getPricePlans)
  const pageLocale = usePage(pageSelectors.getPageLocale)
  const isTemplate = usePage(pageSelectors.isPageTemplate)
  const fixedT = i18next.getFixedT(pageLocale, ['client', 'common'])
  if (isFetching && !isTemplate) {
    return null
  }

  const isExample = pricePlans.length === 0
  const calculatedPricePlans = isExample
    ? translatePricePlans(pricePlansStub, fixedT)
    : pricePlans

  const renderOfferPrice = entity => (
    <CommonOfferPrice
      customPriceDescription={entity.options.customPriceDescription}
      pricePlans={calculatedPricePlans}
      activeOfferPricePlanId={calculatedPricePlans[0].id}
      t={fixedT}
    />
  )

  return (
    <BaseEntity
      entity={entity}
      isMoveUpAllowed={isMoveUpAllowed}
      isMoveDownAllowed={isMoveDownAllowed}
    >
      {emulatedEntity =>
        isExample ? (
          <EntityExampleWrapper label="entities.offer_price.example_warning">
            {renderOfferPrice(emulatedEntity)}
          </EntityExampleWrapper>
        ) : (
          renderOfferPrice(emulatedEntity)
        )
      }
    </BaseEntity>
  )
}

OfferPrice.propTypes = {
  entity: types.entity.isRequired,
  isMoveUpAllowed: PropTypes.bool.isRequired,
  isMoveDownAllowed: PropTypes.bool.isRequired,
}

export default memo(OfferPrice)
