// import mime from 'mime/lite'
import PropTypes from 'prop-types'
import React from 'react'
import FileIconContainerUi from './ui/FileIconContainerUi'
import FileIconUi from './ui/FileIconUi'
import FileRemoveIconUi from './ui/FileRemoveIconUi'

const getIconClassNameByFilename = filename => {
  // const fileMime = mime.getType(filename)
  // switch (fileMime) {
  //   case 'video/mp4':
  //     return 'far fa-play-circle'
  //   default:
  return 'far fa-file'
  // }
}

const FileIcon = ({ fileName, fontSize = '80px', remove }) => (
  <FileIconContainerUi>
    <FileIconUi
      className={getIconClassNameByFilename(fileName)}
      fontSize={fontSize}
    />
    {remove && <FileRemoveIconUi className="fas fa-times" onClick={remove} />}
  </FileIconContainerUi>
)

FileIcon.propTypes = {
  fileName: PropTypes.string.isRequired,
  fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  remove: PropTypes.func,
}

export default FileIcon
