import React from 'react'
import { useTranslation } from 'react-i18next'
import CurrencyBadgeUi from 'common/components/entities/OfferPriceNew/ui/CurrencyBadgeUi'
import { CouponTypeEnum } from 'common/enums/CouponTypeEnum'
import { Coupon as CouponType } from 'common/types/CouponType'
import { centsToEuro, getReadablePrice } from 'common/utils/priceCalculator'
import PricePlanAmountUi from './ui/PricePlanAmountUi'
import PricePlanItemUi from './ui/PricePlanItemUi'
import PricePlanNameUi from './ui/PricePlanNameUi'

type CouponProps = {
  coupon: CouponType
  discountAmount: number
  locale: string
  amountFontFamily?: string
  mobileAmountFontFamily?: string
  amountFontSize: number
  mobileAmountFontSize?: number
  amountFontStyle?: string
  mobileAmountFontStyle?: string
  amountFontWeight?: string
  mobileAmountFontWeight?: string
  amountLineHeight: number
  mobileAmountLineHeight?: number
  amountColor: string
  mobileAmountColor?: string
  fontFamily?: string
  mobileFontFamily?: string
  fontSize: number
  mobileFontSize?: number
  fontStyle?: string
  mobileFontStyle?: string
  fontWeight?: string
  mobileFontWeight?: string
  lineHeight: number
  mobileLineHeight?: number
  color: string
  mobileColor?: string
}

function Coupon({
  coupon,
  discountAmount,
  locale,
  amountFontSize,
  mobileAmountFontSize,
  amountFontStyle,
  mobileAmountFontStyle,
  amountFontWeight,
  mobileAmountFontWeight,
  amountFontFamily,
  mobileAmountFontFamily,
  amountLineHeight,
  mobileAmountLineHeight,
  amountColor,
  mobileAmountColor,
  fontSize,
  mobileFontSize,
  fontStyle,
  mobileFontStyle,
  fontWeight,
  mobileFontWeight,
  fontFamily,
  mobileFontFamily,
  lineHeight,
  mobileLineHeight,
  color,
  mobileColor,
}: CouponProps) {
  const { t } = useTranslation(['common', 'publisher'])

  return (
    <PricePlanItemUi>
      <PricePlanNameUi
        fontSize={fontSize}
        mobileFontSize={mobileFontSize}
        fontStyle={fontStyle}
        mobileFontStyle={mobileFontStyle}
        fontWeight={fontWeight}
        mobileFontWeight={mobileFontWeight}
        fontFamily={fontFamily}
        mobileFontFamily={mobileFontFamily}
        lineHeight={lineHeight}
        mobileLineHeight={mobileLineHeight}
        color={color}
        mobileColor={mobileColor}
        data-test-id={`offer-price-coupon-${coupon.id}`}
      >
        {t('components.offer_price.one_shots_summary.discount')}
        {coupon.discountType === CouponTypeEnum.Percent &&
          ` (-${coupon.discountAmount}%)`}
      </PricePlanNameUi>
      <PricePlanAmountUi
        fontSize={amountFontSize}
        mobileFontSize={mobileAmountFontSize}
        fontStyle={amountFontStyle}
        mobileFontStyle={mobileAmountFontStyle}
        fontWeight={amountFontWeight}
        mobileFontWeight={mobileAmountFontWeight}
        fontFamily={amountFontFamily}
        mobileFontFamily={mobileAmountFontFamily}
        lineHeight={amountLineHeight}
        mobileLineHeight={mobileAmountLineHeight}
        color={amountColor}
        mobileColor={mobileAmountColor}
      >
        <CurrencyBadgeUi color={amountColor} mobileColor={mobileAmountColor}>
          {coupon.currency}
        </CurrencyBadgeUi>
        {` -${getReadablePrice(
          centsToEuro(discountAmount),
          coupon.currency,
          locale,
        )}`}
      </PricePlanAmountUi>
    </PricePlanItemUi>
  )
}

export default Coupon
