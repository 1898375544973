import PropTypes from 'prop-types'
import React from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import CommonRow from 'common/components/entities/Row/RowOld'
import BaseEntity from 'client/components/core/BaseEntity'
import Column from 'client/components/entities/Column/ColumnOld'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'
import usePresentPage, { selectors } from 'client/hooks/usePresentPage'
import { getFileById } from 'client/reducers/filesReducer'
import { getBlockFile } from '../../../reducers/blocksReducer'

function ReadOnlyRowOld({ entity }) {
  const { isMobile } = useDeviceModeContext()

  const entities = usePresentPage(page =>
    selectors.getChildrenEntities(page, entity.childIds),
  )
  const backgroundFile = useSelector(
    state =>
      getFileById(state.files, entity.options.backgroundFileId) ||
      getBlockFile(state.blocks, entity.options.backgroundFileId),
    shallowEqual,
  )

  const mobileBackgroundFile = useSelector(
    state =>
      getFileById(state.files, entity.mobileOptions.backgroundFileId) ||
      getBlockFile(state.blocks, entity.mobileOptions.backgroundFileId),
    shallowEqual,
  )
  const calculatedMobileBackgroundFile =
    typeof entity.mobileOptions.backgroundFileId === 'undefined'
      ? backgroundFile
      : mobileBackgroundFile

  return (
    <BaseEntity entity={entity}>
      <CommonRow
        styles={entity.styles}
        mobileStyles={entity.mobileStyles}
        options={entity.options}
        mobileOptions={entity.mobileOptions}
        backgroundFile={
          isMobile ? calculatedMobileBackgroundFile : backgroundFile
        }
      >
        {
          entities.reduce(
            (acc, entity) => ({
              ...acc,
              rendered: [
                ...acc.rendered,
                <Column
                  key={entity.id}
                  entity={entity}
                  gridColumnStart={acc.gridColumnStart}
                  size={entity.options.size}
                />,
              ],
              gridColumnStart: acc.gridColumnStart + entity.options.size,
            }),
            { gridColumnStart: 1, rendered: [] },
          ).rendered
        }
      </CommonRow>
    </BaseEntity>
  )
}

ReadOnlyRowOld.propTypes = {
  entity: PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    childIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    isReadOnly: PropTypes.bool,
    styles: PropTypes.object,
    options: PropTypes.objectOf(PropTypes.any),
    mobileOptions: PropTypes.objectOf(PropTypes.any),
    mobileStyles: PropTypes.object,
  }),
}

export default ReadOnlyRowOld
