import styled from 'styled-components'

const PopupOverlayUi = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: ${p => p.zIndex};
`

export default PopupOverlayUi
