import styled from 'styled-components'

const DeviceWarningUi = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  background-color: #eb5447;
  z-index: 2;
  text-transform: uppercase;
  font-size: 10px;
  padding: 3px;
  color: #fff;
  font-family: var(--font-family);
`

export default DeviceWarningUi
