import React from 'react'
import { useTranslation } from 'react-i18next'
import CurrencyBadgeUi from 'common/components/entities/OfferPriceNew/ui/CurrencyBadgeUi'
import {
  CalculatedPriceInfo,
  PricePlan as PricePlanType,
} from '../../../types/OfferInterface'
import { ReadablePricePlanAmount } from './PricePlan'
import PricePlanAmountUi from './ui/PricePlanAmountUi'
import PricePlanItemUi from './ui/PricePlanItemUi'
import PricePlanNameUi from './ui/PricePlanNameUi'

type VatProps = CalculatedPriceInfo & {
  plan: PricePlanType
  quantity: number
  locale: string
  amountFontFamily?: string
  mobileAmountFontFamily?: string
  amountFontSize: number
  mobileAmountFontSize?: number
  amountFontStyle?: string
  mobileAmountFontStyle?: string
  amountFontWeight?: string
  mobileAmountFontWeight?: string
  amountLineHeight: number
  mobileAmountLineHeight?: number
  amountColor: string
  mobileAmountColor?: string
  fontFamily?: string
  mobileFontFamily?: string
  fontSize: number
  mobileFontSize?: number
  fontStyle?: string
  mobileFontStyle?: string
  fontWeight?: string
  mobileFontWeight?: string
  lineHeight: number
  mobileLineHeight?: number
  color: string
  mobileColor?: string
}

const Vat = ({
  plan,
  taxAmount = 0,
  taxPercent = 0,
  locale,
  amountFontSize,
  mobileAmountFontSize,
  amountFontStyle,
  mobileAmountFontStyle,
  amountFontWeight,
  mobileAmountFontWeight,
  amountFontFamily,
  mobileAmountFontFamily,
  amountLineHeight,
  mobileAmountLineHeight,
  amountColor,
  mobileAmountColor,
  fontSize,
  mobileFontSize,
  fontStyle,
  mobileFontStyle,
  fontWeight,
  mobileFontWeight,
  fontFamily,
  mobileFontFamily,
  lineHeight,
  mobileLineHeight,
  color,
  mobileColor,
}: VatProps) => {
  const { t } = useTranslation(['common', 'publisher'])
  return (
    <PricePlanItemUi>
      <PricePlanNameUi
        fontSize={fontSize}
        mobileFontSize={mobileFontSize}
        fontStyle={fontStyle}
        mobileFontStyle={mobileFontStyle}
        fontWeight={fontWeight}
        mobileFontWeight={mobileFontWeight}
        fontFamily={fontFamily}
        mobileFontFamily={mobileFontFamily}
        lineHeight={lineHeight}
        mobileLineHeight={mobileLineHeight}
        color={color}
        mobileColor={mobileColor}
        data-test-id={`offer-price-bump-vat-${plan.id}`}
      >
        {`${t('components.offer_price.vat')} (${taxPercent}%)`}
      </PricePlanNameUi>
      <PricePlanAmountUi
        fontSize={amountFontSize}
        mobileFontSize={mobileAmountFontSize}
        fontStyle={amountFontStyle}
        mobileFontStyle={mobileAmountFontStyle}
        fontWeight={amountFontWeight}
        mobileFontWeight={mobileAmountFontWeight}
        fontFamily={amountFontFamily}
        mobileFontFamily={mobileAmountFontFamily}
        lineHeight={amountLineHeight}
        mobileLineHeight={mobileAmountLineHeight}
        color={amountColor}
        mobileColor={mobileAmountColor}
      >
        <CurrencyBadgeUi color={amountColor} mobileColor={mobileAmountColor}>
          {plan.currency}
        </CurrencyBadgeUi>
        <ReadablePricePlanAmount
          plan={plan}
          amount={taxAmount}
          locale={locale}
        />
      </PricePlanAmountUi>
    </PricePlanItemUi>
  )
}

export default Vat
