import EntityTypeEnum from 'common/enums/entityTypeEnum'
import { ContentBoxInterface } from 'common/types/entities/ContentBoxInterface'
import { MasterBlockId } from 'common/types/entities/EntityInterface'
import { generateBaseEntity } from 'client/utils/createStructureUtils'

export const createContentBox = (
  parentId: string,
  masterBlockId?: MasterBlockId,
): ContentBoxInterface => {
  return {
    ...generateBaseEntity(EntityTypeEnum.ContentBox, parentId, masterBlockId),
    type: EntityTypeEnum.ContentBox,
    header: {
      visibility: true,
      color: 'rgba(255, 255, 255, 1)',
      backgroundColor: 'rgba(85, 121, 195, 1)',
      fontSize: 20,
      textAlign: 'left',
    },
    border: { width: 1, style: 'solid', color: 'rgba(85, 121, 195, 1)' },
    padding: {
      paddingTop: 10,
      paddingRight: 10,
      paddingBottom: 10,
      paddingLeft: 10,
    },
    delay: 0,
    childIds: [],
  }
}
