import React from 'react'
import { useTranslation } from 'react-i18next'
import MenuLinkInputUi from './ui/MenuLinkInputUi'

type LinkSourceInputProps = {
  url?: string
  update: (url: string) => void
  close: () => void
}

export default function LinkSourceInput({ url, update }: LinkSourceInputProps) {
  const { t } = useTranslation()

  function handleURLChange(event: React.ChangeEvent<HTMLInputElement>) {
    event.preventDefault()
    update(event.target.value)
  }

  function closeOnEnterKey(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === 'Enter') {
      setTimeout(close, 500)
    }
  }

  return (
    <MenuLinkInputUi
      onChange={handleURLChange}
      onKeyDown={closeOnEnterKey}
      placeholder={t('components.core.text_editor.link_menu.placeholder')}
      value={url}
    />
  )
}
