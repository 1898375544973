import { DraftInlineStyle } from 'draft-js'
import React from 'react'
import StyleControls from './StyleControls'

const INLINE_STYLES = [
  { label: '<i class="fa-solid fa-bold" />', style: 'BOLD' },
  { label: '<i class="fa-regular fa-italic" />', style: 'ITALIC' },
  { label: '<i class="fa-regular fa-underline" />', style: 'UNDERLINE' },
  { label: '<i class="fa-regular fa-strikethrough" />', style: 'STRIKETHROUGH' },
]

type InlineStyleControlsProps = {
  currentStyle: DraftInlineStyle
  toggleStyle: (value: string) => void
}

const InlineStyleControls = ({
  currentStyle,
  toggleStyle,
}: InlineStyleControlsProps) => (
  <StyleControls
    checkActive={style => currentStyle.has(style)}
    controls={INLINE_STYLES}
    handleClick={toggleStyle}
  />
)

export default InlineStyleControls
