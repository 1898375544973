import React from 'react'
import CommonInline from 'common/components/entities/Inline'
import * as types from 'common/types'
import { getEmulatedEntity } from 'client/components/core/BaseEntity/utils'
import ChildrenEntities from 'client/components/core/ChildrenEntities'
import emulationMobileStyles from 'client/components/entities/Popup/emulationMobileStyles'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'
import { useBackgroundImage } from 'client/hooks/useBackgroundImage'

function Inline({ entity }) {
  const { isMobile } = useDeviceModeContext()
  const { id, childIds, options, styles, mobileStyles, type } =
    getEmulatedEntity(entity, isMobile, emulationMobileStyles)

  const { backgroundImage, mobileBackgroundImage } = useBackgroundImage({
    backgroundFileId: entity.options.backgroundFileId,
    mobileBackgroundFileId: entity.mobileOptions.backgroundFileId,
  })

  return (
    <CommonInline
      styles={{
        ...styles,
        backgroundImage,
      }}
      options={options}
      mobileStyles={{
        ...mobileStyles,
        backgroundImage: mobileBackgroundImage,
      }}
    >
      <ChildrenEntities id={id} type={type} childIds={childIds} />
    </CommonInline>
  )
}

Inline.propTypes = {
  entity: types.entity.isRequired,
}

export default Inline
