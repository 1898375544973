import styled from 'styled-components'
import { CommonContainerInterface } from 'common/types/entities/EntityInterface'
import { containerCss } from 'common/utils/styleUtilsNew'

const OrderBumpWrapperUi = styled.div<CommonContainerInterface>`
  overflow: hidden;
  ${containerCss}
`

export default OrderBumpWrapperUi
