import { AnyAction } from 'redux'
import { AutomationRuleActionsEnum } from 'client/enums/AutomationRulesEnum'
import { ActionSaveType } from 'client/types/AutomationRulesActionsEditInterface'
import {
  ActionTagInterface,
  AutomationRulesActionsType,
  AutomationRuleSaveTriggerInterface,
  AutomationRuleTriggerInterface,
} from 'client/types/AutomationRulesActionsInterface'
import {
  FETCH_PAGE_AUTOMATION_RULES,
  FetchPageAutomationRules,
  SAVE_AUTOMATION_RULE,
  SAVE_AUTOMATION_RULES_STATE,
  SAVE_DELETED_AUTOMATION_RULE,
  SaveAutomationRule,
  SaveAutomationRulesState,
} from './automationRulesActionTypes'

export interface AutomationRuleInterface {
  id: number
  actions: Array<{ id: number } & ActionTagInterface>
  triggers: AutomationRuleTriggerInterface[]
}

export interface AutomationRuleSaveInterface {
  id?: number
  automationTriggers: AutomationRuleSaveTriggerInterface[]
  automationActions: ActionSaveType[]
}

export interface AutomationRulesState {
  editedPageAutomationRules: AutomationRuleSaveInterface[]
  originalPageAutomationRules: AutomationRuleSaveInterface[]
  deletedAutomationRules: AutomationRuleSaveInterface[]
}

const defaultState = {
  editedPageAutomationRules: [],
  deletedAutomationRules: [],
  originalPageAutomationRules: [],
}

const getEditActionsFromApi = (action: AutomationRulesActionsType) => {
  switch (action.type) {
    case AutomationRuleActionsEnum.remove_tag:
    case AutomationRuleActionsEnum.add_tag:
      return {
        id: action.id,
        type: action.type,
        tag: action.tag,
      }
    case AutomationRuleActionsEnum.subscribe_to_campaign:
    case AutomationRuleActionsEnum.unsubscribe_from_campaign:
      return {
        id: action.id,
        type: action.type,
        campaign: action.campaign,
      }
    case AutomationRuleActionsEnum.send_email:
      return {
        id: action.id,
        type: action.type,
        systemeEmail: action.systemeEmail,
      }
    case AutomationRuleActionsEnum.send_email_to_specific_address:
      return {
        id: action.id,
        type: action.type,
        emailMessageWithRecipient: {
          id: action.emailMessageWithRecipient.id,
          mailing: {
            currentEmail: action.emailMessageWithRecipient.mailing.currentEmail,
          },
        },
      }
    case AutomationRuleActionsEnum.revoke_access_to_course:
      return {
        id: action.id,
        type: action.type,
        course: action.course,
      }
    case AutomationRuleActionsEnum.enroll_in_course:
      return {
        id: action.id,
        type: action.type,
        course: action.course,
        courseAccessType: action.accessType,
      }
    case AutomationRuleActionsEnum.revoke_access_to_course_bundle:
      return {
        id: action.id,
        type: action.type,
        courseBundle: action.courseBundle,
      }
    case AutomationRuleActionsEnum.enroll_in_course_bundle:
      return {
        id: action.id,
        type: action.type,
        courseBundle: action.courseBundle,
        enrollmentAccessType: action.enrollmentAccessType,
      }
    case AutomationRuleActionsEnum.send_webhook:
      return {
        id: action.id,
        type: action.type,
        webhookUrl: action.webhookUrl,
      }
    case AutomationRuleActionsEnum.grant_access_to_community:
      return {
        id: action.id,
        type: action.type,
        community: action.community,
      }
    case AutomationRuleActionsEnum.revoke_access_to_community:
      return {
        id: action.id,
        type: action.type,
        community: action.community,
      }
    case AutomationRuleActionsEnum.create_user:
      return {
        id: action.id,
        type: action.type,
        locale: action.locale,
      }
    default:
      break
  }
  return
}

const getEditTriggerFromApi = (trigger: AutomationRuleTriggerInterface) => {
  return {
    id: trigger.id,
    type: trigger.type,
    buttonId: trigger.buttonId,
    checkBoxId: trigger.checkBoxId,
  }
}

const handleSaveAutomationRule = (
  payload: AutomationRuleSaveInterface,
  state: AutomationRuleSaveInterface[],
): AutomationRuleSaveInterface[] => {
  const isRuleBeingModified = (
    rule: AutomationRuleSaveInterface,
    payload: AutomationRuleSaveInterface,
  ) =>
    (payload.automationTriggers[0].checkBoxId &&
      rule.automationTriggers[0].checkBoxId ===
        payload.automationTriggers[0].checkBoxId) ||
    (payload.automationTriggers[0].buttonId &&
      rule.automationTriggers[0].buttonId ===
        payload.automationTriggers[0].buttonId)

  const existingRule = state.find(rule => isRuleBeingModified(rule, payload))

  if (existingRule) {
    return state.map(rule => {
      if (isRuleBeingModified(rule, payload)) {
        return {
          ...rule,
          automationActions: payload.automationActions,
        }
      }
      return rule
    })
  }

  return [...state, payload]
}

export default function automationRulesReducer(
  state: AutomationRulesState = defaultState,
  action: AnyAction,
) {
  const { type } = action

  switch (type) {
    case FETCH_PAGE_AUTOMATION_RULES:
      return {
        ...state,
        editedPageAutomationRules: [
          ...(action as FetchPageAutomationRules).payload.map(el => {
            return {
              id: el.id,
              automationActions: el.actions.map(action =>
                getEditActionsFromApi(action),
              ).filter(Boolean),
              automationTriggers: el.triggers.map(trigger =>
                getEditTriggerFromApi(trigger),
              ).filter(Boolean),
            }
          }),
        ],
        originalPageAutomationRules: [
          ...(action as FetchPageAutomationRules).payload.map(el => {
            return {
              id: el.id,
              automationActions: el.actions.map(action =>
                getEditActionsFromApi(action),
              ).filter(Boolean),
              automationTriggers: el.triggers.map(trigger =>
                getEditTriggerFromApi(trigger),
              ).filter(Boolean),
            }
          }),
        ],
      }
    case SAVE_AUTOMATION_RULE:
      return {
        ...state,
        editedPageAutomationRules: handleSaveAutomationRule(
          (action as SaveAutomationRule).payload,
          state.editedPageAutomationRules,
        ),
      }
    case SAVE_DELETED_AUTOMATION_RULE:
      return {
        ...state,
        deletedAutomationRules: handleSaveAutomationRule(
          (action as SaveAutomationRule).payload,
          state.deletedAutomationRules,
        ),
      }
    case SAVE_AUTOMATION_RULES_STATE:
      return { ...(action as SaveAutomationRulesState).payload }
    default:
      return state
  }
}
