import styled, { css } from 'styled-components'
import { CommonTextPropsInterface } from 'common/types/CommonTextPropsInterface'
import { AlignType } from 'common/types/styleTypes'
import {
  textCss,
  mobileTextCss,
  backgroundColorCss,
  mobileBackgroundColorCss,
  mobileJustifyContentCss,
  justifyContentCss,
  paddingsCss,
  mobilePaddingsCss,
} from 'common/utils/styleUtilsNew'

const OrderBumpUi = styled.label<
  CommonTextPropsInterface & {
    backgroundColor?: string
    mobileBackgroundColor?: string
    justifyContent?: AlignType
    mobileJustifyContent?: AlignType
  }
>`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  min-height: 59px;
  ${backgroundColorCss}
  ${textCss}
  ${justifyContentCss}
  ${p =>
    p.theme.isEditorMobileMode &&
    css`
      ${mobileTextCss}
      ${mobileBackgroundColorCss}
      ${mobileJustifyContentCss}
    `}
  ${p => p.theme.phone} {
    ${mobileTextCss}
    ${mobileBackgroundColorCss}
    ${mobileJustifyContentCss}
  }
`

export default OrderBumpUi
