import { Middleware, MiddlewareAPI, Dispatch, AnyAction } from 'redux'
import { REMOVE_NODE } from 'client/actionTypes'
import { getAllDescendantIds } from 'client/reducers/pageReducer'
import { saveAutomationRulesState } from 'client/store/automationRules/automationRulesActions'
import { RootState } from 'client/store'

// @ts-expect-error we will fix it while migrating to the redux toolkit
export const removeLostRulesMiddleware: Middleware<
  Dispatch<AnyAction>,
  RootState
> =
  (storeApi: MiddlewareAPI<Dispatch<AnyAction>, RootState>) =>
  (next: Dispatch<AnyAction>) =>
  (action: AnyAction) => {
    if (action.type === REMOVE_NODE) {
      const state = storeApi.getState()

      const page = state.page.present

      const descendantIds = getAllDescendantIds(page, action.payload.id)

      const ids = [action.payload.id, ...descendantIds]

      const rules = state.automationRules

      const editedPageAutomationRules = rules.editedPageAutomationRules.filter(
        rule =>
          !rule.automationTriggers.some(
            trigger =>
              ids.includes(trigger.buttonId) ||
              ids.includes(trigger.checkBoxId),
          ),
      )
      storeApi.dispatch(
        saveAutomationRulesState({
          ...rules,
          editedPageAutomationRules,
        }),
      )
    }
    return next(action as AnyAction)
  }
