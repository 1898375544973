import {
  FETCH_PAGE_AUTOMATION_RULES,
  FetchPageAutomationRules,
  SAVE_AUTOMATION_RULE,
  SAVE_AUTOMATION_RULES_STATE,
  SAVE_DELETED_AUTOMATION_RULE,
  SaveAutomationRule,
  SaveAutomationRulesState,
  SaveDeletedAutomationRule,
} from './automationRulesActionTypes'
import {
  AutomationRuleInterface,
  AutomationRuleSaveInterface,
  AutomationRulesState,
} from './automationRulesReducer'

export function fetchPageAutomationRules(
  rules: AutomationRuleInterface[],
): FetchPageAutomationRules {
  return {
    type: FETCH_PAGE_AUTOMATION_RULES,
    payload: rules,
  }
}

export function saveAutomationRule(
  automationRule: AutomationRuleSaveInterface,
): SaveAutomationRule {
  return {
    type: SAVE_AUTOMATION_RULE,
    payload: automationRule,
  }
}

export function saveAutomationRulesState(
  automationRules: AutomationRulesState,
): SaveAutomationRulesState {
  return { type: SAVE_AUTOMATION_RULES_STATE, payload: automationRules }
}

export function saveDeletedAutomationRule(
  automationRule: AutomationRuleSaveInterface,
): SaveDeletedAutomationRule {
  return {
    type: SAVE_DELETED_AUTOMATION_RULE,
    payload: automationRule,
  }
}

export type AutomationRulesActions =
  | FetchPageAutomationRules
  | SaveAutomationRule
  | SaveAutomationRulesState
  | SaveDeletedAutomationRule
