import styled, { css } from 'styled-components'

const ColumnOldUi = styled.div`
  display: flex;
  flex-direction: column;
  grid-column-start: ${p => p.gridColumnStart};
  grid-column-end: ${p => p.gridColumnStart + p.size};
  justify-content: ${p => p.alignSelf};
  ${p =>
    p.isMobile &&
    !p.disableStacking &&
    css`
      grid-column-start: 1;
      grid-column-end: 12;
    `}
`

export default ColumnOldUi
