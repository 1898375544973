import styled from 'styled-components'
import { DEFAULT_WIDTH } from '../useExtendedMenu'

export interface MenuPlacement {
  direction: 'top' | 'bottom'
  offset: number
}

type MenuUiProps = {
  width: number
  placement: MenuPlacement
}

const MenuUi = styled.div<MenuUiProps>`
  position: absolute;
  ${({placement}) => `${placement.direction}: ${placement.offset}px`};
  display: flex;
  flex-wrap: wrap;
  right: -2px;
  width: ${p => p.width}px;
  justify-content: start;
  border-radius: 5px 5px 0 0;
  border-bottom-left-radius: ${p => (p.width > DEFAULT_WIDTH ? 5 : 0)};
  background-color: rgb(20, 158, 252);
  z-index: 20;
  cursor: default;
  font-family: var(--font-family);
`

export default MenuUi
