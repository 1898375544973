import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

const Button = styled.button`
  margin: 2px;
  background-color: transparent;
  color: #fff;
  padding: ${p => (p.small ? '5px' : '5px 10px')};
  font-size: 14px;
  font-weight: bold;
  display: inline-block;
  border: none;
  cursor: pointer;
  outline: none;
  font-family: var(--font-family);
  min-width: 25px;
  display: inline-flex;
  align-items: center;
  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
  margin-left: ${props => props.marginLeft || 0};
  ${props =>
    props.active &&
    css`
      background-color: rgba(0, 0, 0, 0.1);
      &:after {
        height: 3px;
      }
    `}
`
// onMouseDown because we need to catch event before blur and prevent it
const MenuButton = ({ active = false, onToggle, ...restProps }) => (
  <Button active={active} onMouseDown={onToggle} {...restProps} />
)

MenuButton.propTypes = {
  active: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
}

export default MenuButton
