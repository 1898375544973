import PropTypes from 'prop-types'
import React from 'react'
import EmbedPlayerUi from './ui/EmbedPlayerUi'

function EmbedPlayer({ entity }) {
  return (
    <EmbedPlayerUi
      styles={entity.styles}
      mobileStyles={entity.mobileStyles}
      attrId={entity.options.attrId}
      dangerouslySetInnerHTML={{ __html: entity.options.embedIframeTag }}
    />
  )
}

EmbedPlayer.propTypes = {
  entity: PropTypes.shape({
    styles: PropTypes.objectOf(PropTypes.any).isRequired,
    mobileStyles: PropTypes.objectOf(PropTypes.any).isRequired,
    options: PropTypes.shape({
      attrId: PropTypes.string,
      embedIframeTag: PropTypes.string,
    }).isRequired,
  }).isRequired,
}

export default EmbedPlayer
