import { ColumnTypeEnum } from 'common/components/entities/Column/enums/ColumnTypeEnum'
import { ColumnInterface } from 'common/components/entities/Column/types/ColumnInterface'
import { RowInterface } from 'common/components/entities/Row/types/RowInterface'
import {
  BaseEntityWithChildIdsInterface,
  EntityWithChildIdsInterface,
} from 'common/types/entities/EntityInterface'
import { OldEntityWithChildIds } from 'common/types/entities/OldEntityInterface'
import { createColumn } from 'client/components/core/Sidebar/components/Settings/entities/RowSettings/utils/create-column'
import { createRow } from 'client/components/core/Sidebar/components/Settings/entities/RowSettings/utils/create-row'
import { insertChild } from 'client/utils/createStructureUtils'

type InputType<T extends ColumnTypeEnum = ColumnTypeEnum> = {
  parentEntity: OldEntityWithChildIds | BaseEntityWithChildIdsInterface
  position: number
  columnType?: T
}

type BaseOutputType = {
  row: RowInterface
  structure: Record<
    string,
    | OldEntityWithChildIds
    | EntityWithChildIdsInterface
    | RowInterface
    | ColumnInterface
  >
  column1: ColumnInterface
}

type OutputType<T extends ColumnTypeEnum = ColumnTypeEnum> =
  T extends ColumnTypeEnum.COLUMN_3
    ? {
        column2: ColumnInterface
        column3: ColumnInterface
        column4: ColumnInterface
      } & BaseOutputType
    : T extends ColumnTypeEnum.COLUMN_4
    ? {
        column2: ColumnInterface
        column3: ColumnInterface
        column4?: never
      } & BaseOutputType
    : T extends ColumnTypeEnum.COLUMN_6
    ? {
        column2: ColumnInterface
        column3?: never
        column4?: never
      } & BaseOutputType
    : {
        column2?: never
        column3?: never
        column4?: never
      } & BaseOutputType

export const createRowColumnLayout = <
  T extends ColumnTypeEnum = ColumnTypeEnum,
>(
  input: InputType<T>,
): OutputType<T> => {
  const parentEntity = input.parentEntity
  const position = input.position
  const columnType = input.columnType ?? ColumnTypeEnum.COLUMN_12
  const row = createRow(parentEntity.id, parentEntity.masterBlockId)
  const column1 = createColumn({
    columnType,
    parentId: row.id,
    masterBlockId: parentEntity.masterBlockId,
  })

  let column2: ColumnInterface | undefined
  let column3: ColumnInterface | undefined
  let column4: ColumnInterface | undefined

  const rowChildIds = [column1.id]

  if (
    columnType === ColumnTypeEnum.COLUMN_3 ||
    columnType === ColumnTypeEnum.COLUMN_4 ||
    columnType === ColumnTypeEnum.COLUMN_6
  ) {
    column2 = createColumn({
      columnType,
      parentId: row.id,
      masterBlockId: parentEntity.masterBlockId,
    })
    rowChildIds.push(column2.id)
  }
  if (
    columnType === ColumnTypeEnum.COLUMN_4 ||
    columnType === ColumnTypeEnum.COLUMN_3
  ) {
    column3 = createColumn({
      columnType,
      parentId: row.id,
      masterBlockId: parentEntity.masterBlockId,
    })
    rowChildIds.push(column3.id)
  }
  if (columnType === ColumnTypeEnum.COLUMN_3) {
    column4 = createColumn({
      columnType,
      parentId: row.id,
      masterBlockId: parentEntity.masterBlockId,
    })
    rowChildIds.push(column4.id)
  }

  row.childIds = rowChildIds

  return {
    row,
    column1,
    column2,
    column3,
    column4,
    structure: {
      [parentEntity.id]: {
        ...parentEntity,
        childIds: insertChild(parentEntity.childIds, row.id, position),
      },
      [row.id]: row,
      [column1.id]: column1,
      ...(column2 ? { [column2.id]: column2 } : {}),
      ...(column3 ? { [column3.id]: column3 } : {}),
      ...(column4 ? { [column4.id]: column4 } : {}),
    },
  } as OutputType<T>
}
