import { PaymentMethodEnum } from 'common/enums/PaymentMethodEnum'
import { PaymentState } from '../index'

export const getProduct = (paymentState: PaymentState) => paymentState.product

export const getPaymentMethods = (paymentState: PaymentState) =>
  paymentState.paymentMethods || []

export const isPaymentMethodAvailable = (
  paymentState: PaymentState,
  paymentMethod: PaymentMethodEnum,
) => paymentState.paymentMethods.includes(paymentMethod)

export const getOffer = (paymentState: PaymentState) => paymentState.offer

export function getOfferBump(state: PaymentState) {
  return state.offerBump
}

export const paymentSelectors = {
  getProduct,
  getOffer,
  getPaymentMethods,
}

export default paymentSelectors
