import {
  FETCH_PAYMENT_DATA_SUCCESS,
  FETCH_AGREEMENT,
  SUCCESS,
} from 'client/actionTypes'
import request from 'client/utils/request'

export const fetchAgreement = () => async (dispatch, getState) => {
  const {
    present: { id, isTemplate },
  } = getState().page

  if (isTemplate) {
    dispatch({
      type: FETCH_AGREEMENT + SUCCESS,
      payload: '',
    })
  } else {
    const { data } = await request.get(`/page/${id}/agreement`)

    dispatch({
      type: FETCH_AGREEMENT + SUCCESS,
      payload: data.agreement,
    })
  }
}

export const fetchPaymentData = () => (dispatch, getState) => {
  const { id, isTemplate } = getState().page.present

  const fetchPaymentDataUrl = isTemplate
    ? `/page-template/${id}/offer/data`
    : `/page/${id}/offer/data`

  return request.get(fetchPaymentDataUrl)
}

export const fetchPaymentDataSuccess = data => ({
  type: FETCH_PAYMENT_DATA_SUCCESS,
  payload: data,
})
