import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import provideMobileStyles from 'common/hoc/provideMobileStyles'
import CustomerTypeRadioUi from './ui/CustomerTypeRadioUi'
import CustomerTypeUi from './ui/CustomerTypeUi'

const customerTypes = {
  COMPANY: 'company',
  PERSONAL: 'personal',
}

export const isCustomerCompany = customer => customer === customerTypes.COMPANY

export const isCustomerPersonal = customer =>
  customer === customerTypes.PERSONAL

const CustomerType = ({
  htmlAttrId,
  toggle = () => {},
  styles,
  mobileStyles,
  activeType = null,
}) => {
  const { t } = useTranslation('common')
  return (
    <CustomerTypeUi
      attrId={htmlAttrId}
      styles={styles}
      mobileStyles={mobileStyles}
    >
      <label htmlFor="customer-type-personal">
        <CustomerTypeRadioUi
          type="radio"
          id="customer-type-personal"
          data-testid="customer-type-personal"
          name="customer-type"
          onChange={() => toggle(customerTypes.PERSONAL)}
          defaultChecked={isCustomerPersonal(activeType)}
        />
        {t('customer_type.personal')}
      </label>
      <label htmlFor="customer-type-company">
        <CustomerTypeRadioUi
          type="radio"
          id="customer-type-company"
          data-testid="customer-type-company"
          name="customer-type"
          onChange={() => toggle(customerTypes.COMPANY)}
          defaultChecked={isCustomerCompany(activeType)}
        />
        {t('customer_type.company')}
      </label>
    </CustomerTypeUi>
  )
}

CustomerType.propTypes = {
  htmlAttrId: PropTypes.string,
  toggle: PropTypes.func,
  mobileStyles: PropTypes.objectOf(PropTypes.any).isRequired,
  styles: PropTypes.objectOf(PropTypes.any).isRequired,
  activeType: PropTypes.string,
}

export default provideMobileStyles(CustomerType)
