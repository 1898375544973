const translatePricePlans = (pricePlans, fixedT) => {
  return pricePlans.map(plan =>
    Object.keys(plan).reduce(
      (prev, key) => ({
        ...prev,
        [key]: key === 'name' ? fixedT(plan[key]) : plan[key],
      }),
      {},
    ),
  )
}

export default translatePricePlans
