import PropTypes from 'prop-types'
import React, { useState, memo, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { buttonActionsTypes } from 'common/constants/settings'
import FileManager from 'client/components/FileManager/FileManager'
import { mimeTypes } from 'client/constants/editorSettings'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'
import { usePage } from 'client/store'
import pageSelectors from 'client/store/page/pageSelectors'
import MenuCloseButtonUi from '../../TextEditorNew/Menu/ui/MenuCloseButtonUi'
import MenuButtonUi from './ui/MenuButtonUi'
import MenuConfirmButtonHaveChangesUi from './ui/MenuConfirmButtonHaveChangesUi'
import MenuConfirmButtonUi from './ui/MenuConfirmButtonUi'
import MenuInputGroupUi from './ui/MenuInputGroupUi'
import MenuLinkInputUi from './ui/MenuLinkInputUi'
import MenuLinkUi from './ui/MenuLinkUi'
import MenuSelectUi from './ui/MenuSelectUi'

const REL_NO_FOLLOW = 'nofollow'
const TARGET_BLANK = '_blank'

function MenuLink({ currentLink, update, remove, close }) {
  const { t } = useTranslation()
  const { isMobile } = useDeviceModeContext()
  const [link, setLink] = useState(currentLink)
  const [showFileManager, setShowFileManager] = useState(false)
  const [showPopup, setShowPopup] = useState(Boolean(link.popupId))
  const popups = usePage(pageSelectors.getPopupEntities)

  const isLinkReadyForSave =
    currentLink.url !== link.url || currentLink.popupId !== link.popupId

  useEffect(() => {
    setLink(currentLink)
  }, [currentLink])

  const handleURLChange = e => {
    e.preventDefault()
    setLink({
      ...link,
      url: e.target.value,
      action: buttonActionsTypes.openUrl,
      popupId: null,
    })
  }

  const closeOnEnterKey = e => {
    if (e.keyCode === 13 && isLinkReadyForSave) {
      update(link)
      setTimeout(close, 500)
    }
  }

  const afterUpload = file => {
    setLink({
      ...link,
      action: buttonActionsTypes.openUrl,
      url: file.path,
      popupId: null,
    })
    setShowPopup(false)
  }

  const handlePopupChange = e => {
    setLink({
      ...link,
      action: buttonActionsTypes.showPopup,
      url: null,
      popupId: e.target.value,
    })
  }

  const toggleMakeOpenInNew = () => {
    update({
      ...link,
      target: link.target === TARGET_BLANK ? '' : TARGET_BLANK,
    })
  }

  const toggleNoFollow = () => {
    update({
      ...link,
      rel: link.rel === REL_NO_FOLLOW ? '' : REL_NO_FOLLOW,
    })
  }

  const togglePopup = () => {
    setShowPopup(!showPopup)
  }

  const toggleShowFileManager = () => {
    setShowFileManager(!showFileManager)
  }

  const save = () => {
    update(link)
    setTimeout(close, 500)
  }

  return (
    <MenuLinkUi>
      <MenuInputGroupUi>
        {showPopup ? (
          <MenuSelectUi
            onChange={handlePopupChange}
            defaultValue={link.popupId}
          >
            <option value="">
              {t('components.core.text_editor.select_popup')}
            </option>
            {popups.map(popup => (
              <option key={popup.id} value={popup.id}>
                {popup.options.title}
              </option>
            ))}
          </MenuSelectUi>
        ) : (
          <React.Fragment>
            <MenuLinkInputUi
              onChange={handleURLChange}
              onKeyDown={closeOnEnterKey}
              short={isMobile}
              placeholder={t(
                'components.core.text_editor.link_menu.placeholder',
              )}
              value={link.url}
            />
          </React.Fragment>
        )}
        <MenuConfirmButtonUi
          onClick={save}
          title={t('components.core.text_editor.link_menu.confirm')}
          disabled={!isLinkReadyForSave}
        >
          <span className="fas fa-save" />
          {isLinkReadyForSave && <MenuConfirmButtonHaveChangesUi />}
        </MenuConfirmButtonUi>
      </MenuInputGroupUi>
      <MenuButtonUi
        onClick={toggleShowFileManager}
        title={t('components.core.text_editor.link_menu.use_file_as_link')}
      >
        <span className="fa fa-download" />
      </MenuButtonUi>
      {showFileManager && (
        <FileManager
          closeFileManager={toggleShowFileManager}
          mimeTypes={mimeTypes.all}
          onInsert={afterUpload}
        />
      )}
      <MenuButtonUi
        onClick={togglePopup}
        active={showPopup}
        title={t('components.core.text_editor.link_menu.open_popup')}
      >
        <span className="far fa-window-restore" />
      </MenuButtonUi>
      <MenuButtonUi
        onClick={toggleMakeOpenInNew}
        active={link.target === TARGET_BLANK}
        title={t('components.core.text_editor.link_menu.open_in_new_window')}
      >
        <span className="fas fa-external-link-alt" />
      </MenuButtonUi>
      <span style={{ color: '#fff', borderRight: '1xp solid #fff' }} />
      <MenuButtonUi
        onClick={toggleNoFollow}
        active={link.rel === REL_NO_FOLLOW}
        title={t('components.core.text_editor.link_menu.no_follow')}
      >
        <span className="fa fa-ban" />
      </MenuButtonUi>
      <MenuButtonUi
        onClick={remove}
        title={t('components.core.text_editor.link_menu.unlink')}
      >
        <span className="fas fa-unlink" />
      </MenuButtonUi>
      <MenuButtonUi
        style={{ backgroundColor: 'transparent', cursor: 'initial' }}
      />
      <MenuCloseButtonUi
        onClick={close}
        marginLeft="auto"
        title={t('components.core.text_editor.link_menu.close_menu')}
      >
        <span className="fa fa-times" />
      </MenuCloseButtonUi>
    </MenuLinkUi>
  )
}

MenuLink.propTypes = {
  remove: PropTypes.func.isRequired,
  currentLink: PropTypes.shape({
    url: PropTypes.string.isRequired,
    target: PropTypes.string,
    action: PropTypes.string,
    popupId: PropTypes.string,
    rel: PropTypes.string,
  }).isRequired,
  close: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
  popups: PropTypes.arrayOf(PropTypes.object),
}
export default memo(MenuLink)
