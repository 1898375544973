import PropTypes from 'prop-types'
import React from 'react'
import { Trans } from 'react-i18next'
import Badge from 'client/components/core/Badge'
import EntityExampleWrapperUi from './ui/EntityExampleWrapperUi'

const EntityExampleWrapper = ({
  children,
  label,
  warning = true,
  visible = true,
  needTranslate = true,
}) =>
  visible ? (
    <EntityExampleWrapperUi>
      {children}
      <Badge warning={warning}>
        {needTranslate ? <Trans parent="span">{label}</Trans> : label}
      </Badge>
    </EntityExampleWrapperUi>
  ) : (
    children
  )

EntityExampleWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  label: PropTypes.string.isRequired,
  warning: PropTypes.bool,
  visible: PropTypes.bool,
  needTranslate: PropTypes.bool,
}

export default EntityExampleWrapper
