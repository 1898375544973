import PropTypes from 'prop-types'
import React from 'react'
import CommonSurvey, {
  actions,
  QuestionPropType,
} from 'common/components/entities/Survey'
import useManagement, { selectors } from '../../../hooks/useManagement'
import BaseEntity from '../../core/BaseEntity'
import ChildrenEntities from '../../core/ChildrenEntities'

function Survey({ entity, isMoveUpAllowed, isMoveDownAllowed }) {
  const isSurveyResult = useManagement(selectors.isSurveyResult)
  return (
    <BaseEntity
      entity={entity}
      isMoveUpAllowed={isMoveUpAllowed}
      isMoveDownAllowed={isMoveDownAllowed}
    >
      {(emulatedEntity, _, isMobile) => {
        return (
          <CommonSurvey
            styles={emulatedEntity.styles}
            choose={() => {}}
            question={entity.options.questions[0]}
            questionTitleFontSize={emulatedEntity.options.questionTitleFontSize}
            mobileQuestionTitleFontSize={
              entity.mobileOptions.questionTitleFontSize
            }
            questionSubTitleFontSize={
              emulatedEntity.options.questionSubTitleFontSize
            }
            mobileQuestionSubTitleFontSize={
              entity.mobileOptions.questionSubTitleFontSize
            }
            answerFontSize={emulatedEntity.options.answerFontSize}
            mobileAnswerFontSize={entity.mobileOptions.answerFontSize}
            answerColor={entity.options.answerColor}
            answerOutlineColor={entity.options.answerOutlineColor}
            answerBackgroundColor={entity.options.answerBackgroundColor}
            questionTitleColor={entity.options.questionTitleColor}
            questionSubTitleColor={entity.options.questionSubTitleColor}
            showResult={isSurveyResult}
            answerBorderRadius={
              isMobile &&
              Object.keys(entity.mobileOptions.answerBorderRadius).length > 0
                ? entity.mobileOptions.answerBorderRadius
                : entity.options.answerBorderRadius
            }
            mobileAnswerBorderRadius={entity.mobileOptions.answerBorderRadius}
          >
            <ChildrenEntities
              id={entity.id}
              type={entity.type}
              childIds={entity.childIds}
              isReadOnly={entity.isReadOnly}
            />
          </CommonSurvey>
        )
      }}
    </BaseEntity>
  )
}

export const SurveyEntityPropTypes = PropTypes.shape({
  options: PropTypes.shape({
    questions: PropTypes.arrayOf(QuestionPropType).isRequired,
    questionTitleFontSize: PropTypes.string.isRequired,
    questionSubTitleFontSize: PropTypes.string.isRequired,
    answerFontSize: PropTypes.string.isRequired,
    questionTitleColor: PropTypes.string.isRequired,
    questionSubTitleColor: PropTypes.string.isRequired,
    answerColor: PropTypes.string.isRequired,
    action: PropTypes.oneOf(Object.keys(actions)).isRequired,
    redirectUrl: PropTypes.string,
    popupId: PropTypes.string,
  }).isRequired,
  styles: PropTypes.shape({
    fontFamily: PropTypes.string,
    padding: PropTypes.string.isRequired,
    marginTop: PropTypes.string.isRequired,
    marginRight: PropTypes.string.isRequired,
    marginBottom: PropTypes.string.isRequired,
    marginLeft: PropTypes.string.isRequired,
  }).isRequired,
})

Survey.propTypes = {
  entity: SurveyEntityPropTypes,
  isMoveUpAllowed: PropTypes.bool.isRequired,
  isMoveDownAllowed: PropTypes.bool.isRequired,
}

export default Survey
