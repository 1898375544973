import React, { memo } from 'react'
import { CountdownTypesEnum } from 'common/components/entities/Countdown/enums/CountdownTypesEnum'
import Timer from 'common/components/entities/Timer'
import { CountdownInterface } from 'common/types/entities/CountdownInterface'
import BaseEntityNew from 'client/components/core/BaseEntity/BaseEntityNew'
import { getClientTimeFromCountdown } from 'client/components/entities/Countdown/utils/get-client-time-from-countdown'
import { pageSelectors, useManagement, usePage } from 'client/store'
import managementSelectors from 'client/store/management/managementSelectors'

interface CountdownProps {
  entity: CountdownInterface
  isMoveUpAllowed: boolean
  isMoveDownAllowed: boolean
}

function Countdown({
  entity,
  isMoveDownAllowed,
  isMoveUpAllowed,
}: CountdownProps) {
  const pageLocale = usePage(pageSelectors.getPageLocale)
  const userTimeZone = useManagement(managementSelectors.getUserTimezone)
  const { days, hours, minutes, seconds } = getClientTimeFromCountdown(
    entity,
    userTimeZone,
  )
  return (
    <BaseEntityNew
      entity={entity}
      isMoveUpAllowed={isMoveUpAllowed}
      isMoveDownAllowed={isMoveDownAllowed}
    >
      <Timer
        fontFamily={entity.fontFamily}
        fontStyle={entity.fontStyle}
        fontWeight={entity.fontWeight}
        locale={pageLocale}
        labelStyles={entity.labelStyles}
        timeStyles={entity.timeStyles}
        mobileFontFamily={entity.mobileFontFamily}
        mobileFontStyle={entity.mobileFontStyle}
        mobileFontWeight={entity.mobileFontWeight}
        id={entity.htmlAttrId}
        days={days}
        hours={hours}
        minutes={minutes}
        seconds={seconds}
        hideDays={entity.countdownType === CountdownTypesEnum.daily}
      />
    </BaseEntityNew>
  )
}

export default memo(Countdown)
