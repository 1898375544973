import styled from 'styled-components'

const BlogPostContentPlaceholderUi = styled.div`
  color: #acc9de;
  font-size: 80px;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export default BlogPostContentPlaceholderUi
