import React from 'react'
import { useTranslation } from 'react-i18next'
import CommonBlogPostImage from 'common/components/entities/BlogPostImage'
import { EntityTypeEnum } from 'common/enums/entityTypeEnum'
import { BlogPostImageInterface } from 'common/types/entities/BlogPostImageInterface'
import { MasterBlockId } from 'common/types/entities/EntityInterface'
import { EntityProps } from 'client/types'
import {
  generateHtmlAttrId,
  generateId,
} from 'client/utils/createStructureUtils'
import Badge from '../../../components/core/Badge'
import BaseEntityNew from '../../../components/core/BaseEntity/BaseEntityNew'

function BlogPostImage({
  entity,
  isMoveDownAllowed,
  isMoveUpAllowed,
}: EntityProps<BlogPostImageInterface>) {
  const { t } = useTranslation()
  const blogPostImageUrl = `${process.env.EDITOR_ASSETS_HOST}/assets/images/blog_post_image_placeholder.png`

  return (
    <BaseEntityNew
      entity={entity}
      isMoveDownAllowed={isMoveDownAllowed}
      isMoveUpAllowed={isMoveUpAllowed}
    >
      <>
        <CommonBlogPostImage
          border={entity.border}
          mobileBorder={entity.mobileBorder}
          boxShadow={entity.boxShadow}
          mobileBoxShadow={entity.mobileBoxShadow}
          src={blogPostImageUrl}
          background={entity.background}
          mobileBackground={entity.mobileBackground}
        />
        <Badge wide>
          {t('entities.blog_post_image.warnings.do_not_remove')}
        </Badge>
      </>
    </BaseEntityNew>
  )
}

export function createBlogPostImage(
  parentId: string,
  masterBlockId?: MasterBlockId,
): BlogPostImageInterface {
  return {
    id: generateId(),
    type: EntityTypeEnum.BlogPostImage,
    parentId: parentId,
    masterBlockId: masterBlockId,
    appearance: {
      desktop: true,
      mobile: true,
    },
    border: {
      radius: 3,
      width: 1,
      style: 'none',
    },
    margin: {
      marginTop: 0,
      marginRight: 0,
      marginBottom: 0,
      marginLeft: 0,
    },
    mobileMargin: {
      marginTop: 0,
      marginRight: 0,
      marginBottom: 0,
      marginLeft: 0,
    },
    htmlAttrId: generateHtmlAttrId(EntityTypeEnum.BlogPostImage),
  }
}

export default BlogPostImage
